// import React, { useEffect, useState, useRef } from "react"; // useRef
// import { Checkdevice } from "../../components";
// import L from "leaflet";
// import spf from './spfboundary.json';
// import twocrime from './2crime.json'
// import fivecrime from './5crime.json'
// import preventioncrime from './preventioncrime.json';
// import './Jmap.css'

// function Jmap(props) {

//     const [firsttime, setFirsttime] = useState(true)
//     // const [view, setView] = useState('map') // map, 
//     const [yr, setYr] = useState(2020)
//     const [topbar, setTopbar] = useState(false)
//     const device = Checkdevice()
//     const mapRef = useRef(null);
//     const layerRef = useRef(null);

//     // based on year
//     useEffect(() => {
//         if (firsttime) {
//             props.hidetopbar(true)
//             setTopbar(true)
//             setFirsttime(false)
//         }
//     }, [])

//     useEffect(() => {
//         if (topbar) {
//             mapRef.current = L.map('map', {
//                 center: [1.3521, 103.8198],
//                 zoom: 12,
//                 layers: [ L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' })],
//                 scrollWheelZoom: true
//             })
//             var geojson = L.geoJSON(spf).addTo(mapRef.current)
//             mapRef.current.createPane('labels');
//             mapRef.current.getPane('labels').style.zIndex = 650;
//             mapRef.current.getPane('labels').style.pointerEvents = 'none';
//             geojson.eachLayer(function (layer) {
//                 layer.bindPopup(layer.feature.properties.Description);
//                 // var npc = layer.feature.properties.NPC


//                 // console.log('-----')
//                 // console.log(npc)
//                 // console.log(twocrime[yr][npc])
//                 // console.log(fivecrime[yr][npc])
//                 // console.log('-----')

//                 // if (layer._latlngs[0][0].lat && layer._latlngs[0][0].lng) {
//                 //     L.circle([layer._latlngs[0][0].lat, layer._latlngs[0][0].lng], {
//                 //         color: 'red',
//                 //         fillColor: '#f03',
//                 //         fillOpacity: 0.5,
//                 //         radius: 500
//                 //     }).addTo(mapRef.current);
//                 // }

//                 // for (let i = 0; i < preventioncrime['result']['records'].length; i++) {
//                 //     console.log(preventioncrime['result']['records'][i])
//                 // }
//                 // console.log(layer.feature.properties.Npc)

//             });

//             layerRef.current = L.layerGroup().addTo(mapRef.current);
//         }
//     }, [topbar, yr])

//     return <div className="Jmap_root">
//         {device === 'WEB'
//             ? <div id="map" style={{ width: "calc( 100vw - 300px )", height: '100vh' }} />
//             : <div>Please use desktop to access this feature.</div>}
//         {device === 'WEB'
//             ? <div className="Jmap_rightroot">
//                 <div className="Jmap_btnsrootrow">
//                     <button className={"Jmap_rowbtn" + (2018 === yr)} onClick={() => setYr(2018)} >2018</button>
//                     <button className={"Jmap_rowbtn" + (2019 === yr)} onClick={() => setYr(2019)}>2019</button>
//                     <button className={"Jmap_rowbtn" + (2020 === yr)} onClick={() => setYr(2020)} >2020</button>
//                 </div>
//                 <div className="Jmap_btnsroot">
//                     <button className="Jmap_btn">test</button>
//                 </div>
//             </div>
//             : null}
//     </div>

// }
// export default Jmap

import React from "react"; // useRef

function Jmap(props) {
    return <div />
}
export default Jmap