const IsNumeric = (input) => { return (input - 0) === input && ('' + input).trim().length > 0 && Number.isInteger(input) }
// const IsNumeric = (input) => { return typeof input==='String'}

// return true implies got error,
// return false implies no error
export const Validateform = (items) => {
    const { form_key, form_parts } = items
    switch (form_key) {
        case 'pricelistname': {
            if (form_parts.pricelistname === '') {
                return true
            }
            if (form_parts.pricelistname.length > 0) { // cannot be empty
                return false;
            }
            return true;
        }
        case 'destination_addnote': {
            if (form_parts.destination_addnote === '' || form_parts.destination_addnote.split(' ').length < 500) {
                return false;
            }
            else {
                return true
            }
        }
        case 'duration_fixedval': {
            if (isNaN(form_parts.duration_fixedval)) {
                return true // duration_fixedVal is not a number
            }
            else {
                if (form_parts.duration_fixedval < 1) { // less than 1 hr => got error
                    return true
                }
                return false
            }
        }
        case 'duration_rangestartval': {
            if (isNaN(form_parts.duration_rangestartval)) {
                return true // duration_rangeStartVal is not a number
            }
            else {
                if (form_parts.duration_rangestartval > 0) return false
            }
            return false
        }
        case 'duration_rangeendval': {
            if (isNaN(form_parts.duration_rangeendval)) { // 
                return true // duration_rangeEndVal is not a number
            }
            else {
                if (form_parts.duration_rangestartval > 0) return false
            }
            return false
        }
        case 'duration_addnote': {
            if (form_parts.duration_addnote === '' || form_parts.duration_addnote.split(' ').length < 500) { // allow empty note
                return false
            }
            else {
                return true
            }
        }
        case 'price_fixedval': {

            if (IsNumeric(parseInt(form_parts.price_fixedval, 10)) === true && parseInt(form_parts.price_fixedval, 10) > 0) { return false }
            else return true;
        }
        case 'price_rangestartval': {
            if (IsNumeric(parseInt(form_parts.price_rangestartval, 10)) === true && parseInt(form_parts.price_rangestartval, 10) > 0) { return false }
            else return true;
        }
        case 'price_rangeendval': {
            if (IsNumeric(parseInt(form_parts.price_rangeendval, 10)) === true && parseInt(form_parts.price_rangeendval, 10) > 0) { return false }
            else return true;
        }
        case 'price_addnote': {
            if (form_parts.price_addnote === '' || form_parts.price_addnote.split(' ').length < 500) { // allow empty note
                return false
            }
            else {
                return true
            }
        }
        case 'deliverables_items': {
            if (form_parts.deliverables_items.length === 0) {
                return true
            }
            else {
                for (let i = 0; i < form_parts.deliverables_items.length; i++) {
                    if (form_parts.deliverables_items[i].length === 0) {
                        return true;
                    }
                }
            }
            return false
        }
        case 'additionalcharges_items': {
            for (let i = 0; i < form_parts.additionalcharges_items.length; i++) {
                if (form_parts.additionalcharges_items[i].length === 0) {
                    return true
                }
            }
            return false
        }
        case 'description': {
            if (form_parts.description === '' || form_parts.description.split(' ').length < 500) { // allow empty note
                return false
            }
            else {
                return true
            }
        }
        case 'others': {
            if (form_parts.others === '' || form_parts.others.split(' ').length < 500) { // allow empty note
                return false
            }
            else {
                return true
            }
        }
        case 'tnc': {
            if (form_parts.tnc === '' || form_parts.tnc.split(' ').length < 500) { // allow empty note
                return false
            }
            else {
                return true
            }
        }
        default: {
            return null
        }
    }


}
