import React, { Component } from 'react'
import { ControlLabel, FormControl, FormGroup, InputGroup, HelpBlock } from "react-bootstrap"
import { READABLE_VENDORTYPE_MAP, EMPTYSPACE, EMPTY, OPTIONAL, IMPORTANT, IMG_ALT, PRESENCE_LOCATION, CURRENCY_MAP, promotion, LEFTARROW_ICON_SMALL, PRICELIST_FILTER_WEDDING, PRICELIST_FILTER_EVENT, INVERSE_READABLE_VENDORTYPE_MAP } from '../../../../../../config/constants'
import { BUTTONSTYLE } from '../../../../../../config/cssconstants'
import { VENDOR_PRICELIST_CREATE } from '../../../../../../config/api'
import { Validateform } from '../Validateform'
import { Imagedownsize, dataURLToBlob } from '../../../../components/Imagedownsize/Imagedownsize'
import { Checklandscape, refresh } from '../../../../../../components'
import TogglebadgeBar from '../../../../../../components/TogglebadgeBar';
import DatePicker from "react-datepicker";
import axios from 'axios'
import Creatingloading from '../../../Components/Creationloading/Creatingloading'
import './Pricelistcreation.css'


const PREWEDDING = 0
const ACTUALWEDDING = 1
const PREACTUAL = 2
const CORPORATEEVENT = 3
const PERSONALPARTY = 4

const wedtype_map = { '-1': 'None', 0: 'Pre-wedding', 1: 'Actual-wedding', 2: 'Pre + Actual' }
const eventtype_map = { '-1': 'None', 0: 'Corporate event', 1: 'Personal party' }
const wedeventtype_map = { '-1': 'None', 0: 'Pre-wedding', 1: 'Actual-wedding', 2: 'Pre + Actual', 3: 'Corporate event', 4: 'Personal party' }

const single_destination = ['Local', 'Overseas', 'Both']
const multi_destination = ['Local + Local', 'Local + Overseas', 'Overseas + Local', 'Overseas + Overseas']

const MAXSUBIMG = 5
function FieldGroup({ id, label, help, ...props }) {
    return (
        <FormGroup controlId={id}>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
    );
}

class Pricelistcreation extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showmodal: this.props.showmodal,

            secondaryvendortype: this.props.secondaryvendortype,// for record purpose
            secondaryvendortype_id: this.props.secondaryvendortype_id,
            vendortype: this.props.vendortype, // for record purpose
            vendortype_id: this.props.vendortype_id,

            curr_vendortype: this.props.vendortype, // set to vendortype irregardless if there secondary_vendortype
            curr_vendortype_id: this.props.vendortype_id, // curr_vendortype and id are to tell where exactly the pricelist is for what vendortype

            vendortype_map: this.props.vendortype_map,

            username: this.props.username,

            token: this.props.token,
            // for all
            pricelistname: EMPTY,

            // NOTE **
            // -1 is set to indicate, the selection is optional
            // 0 is set to indicate, the selection is mandatory

            eventorwed: 'Wedding', // both as default 

            tags: [], // tags of the pricelist

            wedtype: PREWEDDING, // MANDATORY, 0 is 'Pre-wedding', 1 is 'Actual-wedding', 2 is 'Pre + Actual' , 3 is 'Corporate Event', 4 is 'Personal Party'

            description: '', // description

            destination: 'None', // OPTIONAL,  -1 is dosent exist, 0 is 'Local' or 'Local + Local', 1 is 'Overseas' or 'Local + Overseas' ......
            destination_addnote: EMPTY, // for overseas note after choosing wed dest

            duration: 'None', // OPTIONAL,  -1 is dosent exist, selected is 'Fixed' hour, 1 is 'Range' hour
            duration_fixedval: 0, // value in fixed hour
            duration_rangestartval: 0, // value for start
            duration_rangeendval: 0, // value for end
            duration_addnote: EMPTY, // extra note on fixed hour

            currency: 'SGD', // SGD

            price: 'Fixed',// MANDATORY
            price_fixedval: 0, // value in fixed hour
            price_rangestartval: 0, // value for start
            price_rangeendval: 0, // value for end
            price_addnote: EMPTY, // extra note on fixed hour

            deliverables: 0, // MANDATORY, store the total number of input field for delieverables
            deliverables_items: [], // this contain the text of deliverables

            additionalcharges: 0, // MANDATORY, store the total number of input field for additionalcharges
            additionalcharges_items: [], // this contain the text of deliverables

            others: EMPTY, // other necessary items

            tnc: EMPTY, // terms and conditions

            listing: true, // whether to list in pricelist page

            promo: false, // whether to list as promotion 
            promodestroydt: EMPTY, // promotion destroy date
            promoprice: 0,

            outline: EMPTY, // outline of the pricelist

            displaymsg: EMPTY,

            logofile: EMPTY,

            file: EMPTY,
            imgblob_main: EMPTY,

            file_sub: [], // accept up to 5 images able to extend next time
            imgblob_sub: [], // accept up to 5 images able to extend next time

            location: 'Singapore',

            ytlink: EMPTY,

            disabled: false,


        }

        this.onMainmgchange = this.onMainmgchange.bind(this);
        this.onSubimgchange = this.onSubimgchange.bind(this);


    }

    createpackage = () => {

        const SUCCESS = 'Successfully created new pricelist'
        const FAILURE = 'Fail to create new pricelist. Please make sure all required fields are filled out correctly'
        const {
            token,
            username,
            vendortype, vendortype_id,
            secondaryvendortype, secondaryvendortype_id,

            curr_vendortype, curr_vendortype_id,

            pricelistname,

            eventorwed,

            wedtype,

            description,

            destination, destination_addnote,

            duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote,

            price, price_fixedval, price_rangestartval, price_rangeendval, price_addnote,

            //deliverables, 
            deliverables_items,

            //additionalcharges,
            additionalcharges_items,

            others,

            tnc,

            outline,
            listing, // whether to list in pricelist page
            currency,
            logofile, // logo image file
            file, // hero image file
            file_sub, // sub image file

            location, // Singapore by default,  

            ytlink,

            promo,
            promodestroydt,
            promoprice

        } = this.state
        var error = []

        var validate_fields = () => {

            var result = true

            this.setState({ displaymsg: EMPTY })

            if (Validateform({ form_key: 'pricelistname', form_parts: { pricelistname } })) {
                error.push(<div key='pricelistname_error' style={{ display: 'flex', flexDirection: 'row' }}>Error: Pricelist name field{IMPORTANT} </div>)
                result = false
            }

            if (price === 'Fixed' && promo && ((promodestroydt.toString().length > 0) !== true)) {
                error.push(<div key='promoexpdate_none'>Error: Promotion Expiration Date field </div>)
                result = false
            }

            if (price === 'Fixed' && promo && (parseInt(promoprice, 10) > parseInt(price_fixedval, 10) || parseInt(promoprice, 10) === 0)) {
                error.push(<div key='promoprice_none'>Error: Promotion Price has to be less than Fixed Price and greater than 0</div>)
                result = false
            }

            // destination check
            if (destination !== 'None') {
                if (Validateform({ form_key: 'destination_addnote', form_parts: { destination, destination_addnote } })) {
                    error.push(<div key='destination_addnote'>Error: Destination extra-note field </div>)
                    result = false
                }
            }

            // duration check
            if (duration !== 'None') {
                if (duration === 'Fixed' && Validateform({ form_key: 'duration_fixedval', form_parts: { duration, duration_fixedval } })) {
                    error.push(<div key='duration_fields' style={{ display: 'flex', flexDirection: 'row' }} >Error: Duration (fixed) input field{IMPORTANT}</div>)
                    result = false
                }
                if (duration === 'Range' && (Validateform({ form_key: 'duration_rangestartval', form_parts: { duration, duration_rangestartval } }) && Validateform({ form_key: 'duration_rangeendval', form_parts: { duration_rangeendval } }))) {
                    error.push(<div key='duration_rangestartval' style={{ display: 'flex', flexDirection: 'row' }}>Error: Duration (range) input fields{IMPORTANT}</div>)
                    result = false
                }
                if (Validateform({ form_key: 'duration_addnote', form_parts: { duration, duration_addnote } })) {
                    error.push(<div key='duration_addnote'>Error: Duration extra-note field</div>)
                    result = false
                }
            }

            // price check
            if (price === 'Fixed') {// is fixed value
                if (Validateform({ form_key: 'price_fixedval', form_parts: { price_fixedval } })) {
                    error.push(<div key='price_fixedval' style={{ display: 'flex', flexDirection: 'row' }}>Error: Price (fixed) input field, price cannot be empty or $0{IMPORTANT} </div>)
                    result = false
                }
            }
            else { // is range value
                if (
                    Validateform({ form_key: 'price_rangestartval', form_parts: { price_rangestartval } }) &&
                    Validateform({ form_key: 'price_rangeendval', form_parts: { price_rangeendval } })
                ) {
                    error.push(<div key='price_rangestartval' style={{ display: 'flex', flexDirection: 'row' }}>Error: Price (range) input field, price cannot be empty or $0{IMPORTANT} </div>)
                    result = false
                }
            }
            if (Validateform({ form_key: 'price_addnote', form_parts: { price_addnote } })) {
                error.push(<div key='price_addnote'>Error: Price extra-note field</div>)
                result = false
            }

            // delieverables check
            if (Validateform({ form_key: 'deliverables_items', form_parts: { deliverables_items } })) {
                error.push(<div key='deliverables_items' style={{ display: 'flex', flexDirection: 'row' }}>Error: Deliverables input field{IMPORTANT} </div>)
                result = false
            }

            // additional charges check
            if (Validateform({ form_key: 'additionalcharges_items', form_parts: { additionalcharges_items } })) {
                error.push(<div key='additionalcharges_items'>Error: Additional charges input fields</div>)
                result = false
            }

            // description checks
            if (Validateform({ form_key: 'description', form_parts: { description } })) {
                error.push(<div key='description'>Error: Description field </div>)
                result = false
            }

            // other checks
            if (Validateform({ form_key: 'others', form_parts: { others } })) {
                error.push(<div key='others'>Error: Others field </div>)
                result = false
            }

            if (Validateform({ form_key: 'tnc', form_parts: { tnc } })) {
                error.push(<div key='tnc'>{'Error: Terms & Conditions field '}</div>)
                result = false
            }

            return result
        }
        var SendCreateRequest = async () => {
            var data = new FormData()
            data.append('vendortype', vendortype)
            data.append('vendortype_id', vendortype_id)
            data.append('secondaryvendortype', secondaryvendortype)
            data.append('secondaryvendortype_id', secondaryvendortype_id)
            data.append('curr_vendortype', curr_vendortype)
            data.append('curr_vendortype_id', curr_vendortype_id)
            data.append('name', pricelistname)
            data.append('username', username)

            if (eventorwed === 'Wedding') {
                data.append('wedtype', wedtype_map[wedtype])
            }
            else if (eventorwed === 'Event') {
                data.append('wedtype', eventtype_map[wedtype])
            }
            else if (eventorwed === 'Wedding & Event' || eventorwed === 'Both') {
                data.append('wedtype', wedeventtype_map[wedtype])
            }
            else {
                refresh()
            }
            data.append('destination', destination)
            data.append('destination_addnote', destination_addnote)
            data.append('duration', duration)
            data.append('duration_fixedval', parseInt(duration_fixedval, 10))
            data.append('duration_rangestartval', parseInt(duration_rangestartval, 10))
            data.append('duration_rangeendval', parseInt(duration_rangeendval, 10))
            data.append('duration_addnote', duration_addnote)
            data.append('price', price)
            data.append('price_fixedval', parseInt(price_fixedval, 10))
            data.append('price_rangestartval', parseInt(price_rangestartval, 10))
            data.append('price_rangeendval', parseInt(price_rangeendval, 10))
            data.append('price_addnote', price_addnote)
            data.append('currency', currency)
            data.append('promoprice', promoprice)
            data.append('promodestroydt', promodestroydt)
            data.append('promo', promo)

            // continuously loop and add new item to delieverables_items
            for (var i = 0; i < deliverables_items.length; i++) {
                data.append('deliverables_items', deliverables_items[i])
            }

            for (var x = 0; x < additionalcharges_items.length; x++) {
                data.append('additionalcharges_items', additionalcharges_items[x])
            }

            data.append('eventorwed', eventorwed)
            data.append('description', description)
            data.append('others', others)
            data.append('tnc', tnc)
            data.append('outline', outline)
            data.append('listing', listing)
            data.append('location', location)
            data.append('vlinks', [ytlink])

            // image files 
            data.append('file', logofile) // logo image
            data.append('file', file) // hero image
            for (let i = 0; i < file_sub.length; i++) { data.append('file', file_sub[i]) } // sub images  

            let options = {
                method: 'POST',
                url: VENDOR_PRICELIST_CREATE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' },
                data: data
            }
            axios(options).then((response) => {
                if (response.data.error === false && response.status === 200) {
                    this.setState({ displaymsg: SUCCESS }, () => {
                        this.props.closemodalAndUpdate(response.data.newpricelist_id) // update vendor profile pricelist page 
                    })
                }
                else {
                    this.setState({ displaymsg: FAILURE }, () => { refresh() })
                }
            }).catch(e => { this.setState({ displaymsg: FAILURE }) })

        }

        if (validate_fields()) { // special vendortype
            this.setState({ disabled: true }, () => {
                SendCreateRequest()
            })
        }
        else { // fields got error
            this.setState({ displaymsg: error })
            return
        }


    }

    reset = () => {
        this.setState({
            pricelistname: EMPTY,

            // will only appear if vendor is ('Beautyhairandmakeup', 'Photographers', 'Videographers')
            wedtype: 0, // selected is 'Pre-wedding'. 

            description: EMPTY,

            destination: 'None', // selected is 'Local' or 'Local + Local' etc
            destination_addnote: EMPTY, // for overseas note after choosing wed dest

            duration: 'None', // selected is 'Fixed' hour, 1 is 'Range' hour
            duration_fixedval: 0, // value in fixed hour
            duration_rangestartval: 0, // value for start
            duration_rangeendval: 0, // value for end
            duration_addnote: EMPTY, // extra note on fixed hour

            price: 'Fixed',
            price_fixedval: 0, // value in fixed hour
            price_rangestartval: 0, // value for start
            price_rangeendval: 0, // value for end
            price_addnote: EMPTY, // extra note on fixed hour

            deliverables: 0, // store the total number of input field for delieverables
            deliverables_items: [], // this contain the text of deliverables

            additionalcharges: 0, // store the total number of input field for additionalcharges
            additionalcharges_items: [], // this contain the text of deliverables

            others: EMPTY, // other necessary items
            tnc: EMPTY, // Terms & Conditions


            outline: EMPTY, // outline of the pricelist
            listing: false,

            displaymsg: EMPTY,

            file: null,
            imgblob_main: null,

            file_sub: [], // accept up to 5 images able to extend next time
            imgblob_sub: [], // accept up to 5 images able to extend next time

            promoprice: 0,
            promodestroydt: EMPTY,
            promo: false,

            tags: []


        })
    }

    onMainmgchange(e) {
        var reader = new FileReader()
        let file = e.target.files[0]
        reader.onload = (e1) => {
            var img = document.createElement("img");
            img.onload = () => {
                if (Checklandscape(img)) { //landscape
                    var logoresult = Imagedownsize(img, 'logo', null, null)
                    this.setState({
                        imgblob_main: logoresult[0],
                        logofile: new File([logoresult[1]], '0', { type: logoresult[1].type, lastModified: Date.now() }),
                        file: new File([dataURLToBlob(img.src)], '1', { type: logoresult[1].type, lastModified: Date.now() })
                    })
                }
                else {
                    alert('Only Landscape image.')
                }
            }
            img.src = e1.target.result;
        }
        reader.readAsDataURL(file);
    }

    onSubimgchange(e) {
        var reader = new FileReader()
        let file = e.target.files[0]
        reader.onloadend = (e1) => {
            var img = document.createElement("img");
            if (e1.loaded > 1500000) {
                alert('please upload image with file size less than or equal to 1.5MB')
            }
            else {
                img.onload = () => {
                    var result = Imagedownsize(img, 'scale', img.width, img.height) //img, type, max_width, max_height
                    var dataurl = result[0]
                    var assignfilename = parseInt(this.state.file_sub.length, 10) + 2// 2 referring to logimg and mainimg
                    this.setState({
                        file_sub: [...this.state.file_sub, new File([dataURLToBlob(img.src)], assignfilename.toString(), { type: result[1].type, lastModified: Date.now() })],
                        imgblob_sub: [...this.state.imgblob_sub, dataurl],
                        // file_sub: newfile,
                        // imgblob_sub: dataurl,
                    })
                }
                img.src = e1.target.result;
            }
        }
        reader.readAsDataURL(file);
    }

    removeImg = (index) => {
        // removal of image
        const { file_sub, imgblob_sub } = this.state
        var local_file_sub = file_sub.slice()
        var local_imgblob_sub = imgblob_sub.slice()
        local_file_sub.splice(index, 1)
        local_imgblob_sub.splice(index, 1)
        this.setState({ file_sub: local_file_sub, imgblob_sub: local_imgblob_sub })
    }

    updateTag(e) {
        const { value } = e.target
        if (this.state.tags.includes(value)) {
            let local_tags = this.state.tags.slice()
            local_tags.splice(local_tags.indexOf(value), 1)
            this.setState({ tags: local_tags })
        }
        else {
            this.setState({ tags: [...this.state.tags, value] })
        }
    }

    handleClick = (user_click_eventorwed, eventorwed) => {
        if (eventorwed !== user_click_eventorwed) {
            this.setState({ eventorwed: user_click_eventorwed, wedtype: 0, destination: 'None', destination_addnote: EMPTY });
        }
    }

    render() {
        const { wedtype, imgblob_main, imgblob_sub, disabled } = this.state
        // main image dom elem
        const render_uploadimg = (
            <div style={{ display: 'flex', flexDirection: 'column', padding: 0, width: '100%', }}>
                <div style={styles.casualbox}>
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Upload an image that best represent your pricelist [ Only Landscape ]{IMPORTANT}</ControlLabel>
                    <div style={{ display: 'flex', width: 200, height: 200, border: 'dashed thin #D9D8D8', justifyContent: 'center', alignItems: 'center', outline: 'none', }}>
                        {imgblob_main ? <img alt={IMG_ALT} style={{ overflow: 'hidden', width: 200, height: 200 }} src={imgblob_main} /> : <div>+</div>}
                        <input
                            accept="image/*"
                            className="inputfile"
                            id="formControlsFile"
                            type='file'
                            name="input-file"
                            style={{ opacity: 0, width: 200, height: 200, position: 'absolute' }}
                            onChange={this.onMainmgchange}
                        />
                    </div>
                </div>
                {EMPTYSPACE}
            </div>
        )

        // sub image dom elem
        const render_uploadsubimg = (
            <div style={{ display: 'flex', flexDirection: 'column', padding: 0, width: '100%', }}>
                <div style={styles.casualbox}>
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Upload sub images [ MAX {' ' + MAXSUBIMG + ' '} ]{OPTIONAL}</ControlLabel>
                    <div style={{ display: 'flex', width: 40, height: 40, border: 'dashed thin #D9D8D8', justifyContent: 'center', alignItems: 'center', outline: 'none', backgroundColor: imgblob_sub && imgblob_sub.length < MAXSUBIMG ? 'white' : 'grey', borderRadius: 10, padding: 5, margin: 5 }}>
                        <div>+</div>
                        {imgblob_sub && imgblob_sub.length < MAXSUBIMG
                            ? <input
                                accept="image/*"
                                className="inputfile"
                                id="formControlsFile"
                                type='file'
                                name="input-file"
                                style={{ opacity: 0, width: 50, height: 50, position: 'absolute' }}
                                onChange={this.onSubimgchange}
                            />
                            : null}
                    </div>
                    {imgblob_sub && imgblob_sub.length > 0 && imgblob_sub.map((src, index) => {
                        return index < MAXSUBIMG
                            ? <img
                                key={index + 'subimg'}
                                src={src}
                                alt={IMG_ALT}
                                style={{ overflow: 'hidden', width: 50, height: 50, marginLeft: 5, cursor: 'pointer' }}
                                onClick={() => { this.removeImg(index) }} />
                            : null
                    })}
                </div>
                {EMPTYSPACE}
            </div>
        )

        const render_videolink = (
            <div style={{ display: 'flex', flexDirection: 'column', padding: 0, width: '100%', }}>
                <div style={styles.casualbox}>
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Youtube embed URL{OPTIONAL}</ControlLabel>
                    <FormGroup controlId="Videolink" style={{ marginLeft: 10, marginRight: 10, marginTop: 5, }}>
                        <FormControl
                            onChange={(event) => { this.setState({ ytlink: event.target.value }) }}
                            value={this.state.ytlink}
                        />
                    </FormGroup>
                </div>
                {EMPTYSPACE}
            </div>
        )

        //vendortype, secondaryvendortype or both
        const render_vendortypeselection = () => {
            const { vendortype = EMPTY, secondaryvendortype = EMPTY } = this.props
            if (vendortype === EMPTY) return <div />
            if (vendortype !== EMPTY && secondaryvendortype === EMPTY) {// single vendortype
                return (
                    <FieldGroup //package name
                        id="vendortypeselection"
                        type="vendortypeselection"
                        disabled={true}
                        label={
                            <div style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                                Select your vendor type{IMPORTANT}
                            </div>
                        }
                        value={this.state.vendortype}
                    />

                )
            }
            if (vendortype !== EMPTY && secondaryvendortype !== EMPTY) { // two vendortype
                return (
                    // drop down for vendortype and secondaryvendortype
                    <FormGroup controlId="formControlsSelect" >
                        <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}> Select your vendor type {IMPORTANT}</ControlLabel>
                        <FormControl // select vendortype
                            key={this.props.vendortype}
                            value={this.state.curr_vendortype}
                            onChange={(event) => {
                                this.setState({
                                    curr_vendortype: event.target.value,
                                    curr_vendortype_id: event.target.value !== 'Both' ? this.state.vendortype_map[event.target.value] : 'Both'
                                }, () => {
                                    this.reset()
                                })
                            }}
                            componentClass="select"
                            placeholder={this.state.curr_vendortype}>
                            <option key={vendortype + 1} value={vendortype}>{READABLE_VENDORTYPE_MAP[vendortype]}</option>
                            <option key={secondaryvendortype + 2} value={secondaryvendortype}>{READABLE_VENDORTYPE_MAP[secondaryvendortype]}</option>
                            <option key={'both' + 3} value={'Both'}>Both</option>
                        </FormControl>
                    </FormGroup>
                )
            }

        }

        //name
        const render_name = () => {
            return (
                <FieldGroup //package name
                    id="pricelistname"
                    type="pricelistname"
                    label={
                        <div style={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                            Pricelist name {IMPORTANT}
                        </div>
                    }
                    onChange={(event) => { this.setState({ pricelistname: event.target.value }) }}
                    value={this.state.pricelistname}
                />
            )
        }

        //category
        const render_categoryselection = () => {
            const { eventorwed } = this.state
            return <div>
                <div style={styles.casualbox}>
                    <ControlLabel // categories Wedding or Event or Both
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        Categories {IMPORTANT}
                    </ControlLabel>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', color: 'rgb(217, 216, 216)' }}>
                        <button
                            style={eventorwed === 'Wedding' ? styles.category_eventorwed_selected : styles.category_eventorwed_notselected}
                            onClick={() => { this.handleClick('Wedding', eventorwed) }}>
                            Wedding
                        </button>
                        <button
                            style={eventorwed === 'Event' ? styles.category_eventorwed_selected : styles.category_eventorwed_notselected}
                            onClick={() => { this.handleClick('Event', eventorwed) }}>
                            Event
                        </button>
                        <button
                            style={(eventorwed === 'Wedding & Event' || eventorwed === 'Both') ? styles.category_eventorwed_selected : styles.category_eventorwed_notselected}
                            onClick={() => { this.handleClick('Wedding & Event', eventorwed) }}>
                            {'Wedding & Event'}
                        </button>
                    </div>
                </div>
                {EMPTYSPACE}
            </div>

        }

        //tags
        const render_tags = () => {

            let vts_tags_map = {}
            function getVttagmap(vt) {
                vts_tags_map[vt] = new Set()
                const { active, Keys } = PRICELIST_FILTER_WEDDING[vt]
                if (active) {
                    let allkeys = Keys.slice()
                    allkeys.splice(allkeys.indexOf('Price'), 1)
                    allkeys.splice(allkeys.indexOf('Wedding'), 1)
                    allkeys.splice(allkeys.indexOf('Event'), 1)
                    for (let i = 0; i < allkeys.length; i++) {
                        let currkey = allkeys[i]
                        for (let j = 0; j < PRICELIST_FILTER_WEDDING[vt][currkey].length; j++) {
                            vts_tags_map[vt].add(PRICELIST_FILTER_WEDDING[vt][currkey][j]['header'])
                        }
                    }
                }
                // convert to array instead of set
                vts_tags_map[vt] = Array.from(vts_tags_map[vt])
            }

            if (this.state.curr_vendortype === this.state.vendortype || this.state.curr_vendortype === 'Both') {
                getVttagmap(this.state.vendortype)
            }

            if (this.state.curr_vendortype === this.state.secondaryvendortype || this.state.curr_vendortype === 'Both') {
                getVttagmap(this.state.secondaryvendortype)
            }

            if (
                (this.state.curr_vendortype !== 'Both' && vts_tags_map[this.state.curr_vendortype].length > 0)
                || (this.state.curr_vendortype === 'Both' && (vts_tags_map[this.state.vendortype].length > 0 || vts_tags_map[this.state.secondaryvendortype].length > 0))
            ) {
                return <div>
                    <div style={styles.casualbox}>
                        <ControlLabel
                            style={{ display: 'flex', flexDirection: 'row' }}>
                            Tags {IMPORTANT}
                        </ControlLabel>
                        <div className='Pricelistcreation_tagroot'>
                            {this.state.curr_vendortype && this.state.curr_vendortype !== 'Both'
                                ? <div className='Pricelistcreation_col' >
                                    <div>{READABLE_VENDORTYPE_MAP[this.state.curr_vendortype]}</div>
                                    <div className='Pricelistcreation_rowwrap'>
                                        {vts_tags_map[this.state.curr_vendortype].map((tag, index) => {
                                            return <button
                                                key={index + tag}
                                                className={this.state.tags.includes(tag) ? 'Pricelistcreation_tagselected' : 'Pricelistcreation_tagunselected'}
                                                onClick={(e) => this.updateTag(e)}
                                                value={tag}
                                            >
                                                {tag}
                                            </button>
                                        })
                                        }
                                    </div>
                                </div>
                                : null}

                            {this.state.curr_vendortype === 'Both' && vts_tags_map[this.state.vendortype].length > 0
                                ? <div className='Pricelistcreation_col' >
                                    <div>{READABLE_VENDORTYPE_MAP[this.state.vendortype]}</div>
                                    <div className='Pricelistcreation_rowwrap'>
                                        {vts_tags_map[this.state.vendortype].map((tag, index) => {
                                            return <button
                                                key={index + tag}
                                                className={this.state.tags.includes(tag) ? 'Pricelistcreation_tagselected' : 'Pricelistcreation_tagunselected'}
                                                onClick={(e) => this.updateTag(e)}
                                                value={tag}
                                            >
                                                {tag}
                                            </button>
                                        })}
                                    </div>
                                </div>
                                : null}

                            {this.state.curr_vendortype === 'Both' && vts_tags_map[this.state.secondaryvendortype].length > 0
                                ? <div className='Pricelistcreation_col' >
                                    <div>{READABLE_VENDORTYPE_MAP[this.state.secondaryvendortype]}</div>
                                    <div className='Pricelistcreation_rowwrap'>
                                        {vts_tags_map[this.state.secondaryvendortype].map((tag, index) => {
                                            return <button
                                                key={index + tag}
                                                className={this.state.tags.includes(tag) ? 'Pricelistcreation_tagselected' : 'Pricelistcreation_tagunselected'}
                                                onClick={(e) => this.updateTag(e)}
                                                value={tag}
                                            >
                                                {tag}
                                            </button>
                                        })}
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    {EMPTYSPACE}
                </div>
            }
            else {
                return null
            }

        }


        // stoppped here
        const render_type = () => { // Actual-wedding, Pre-wedding, Pre + Actual, Personal Party, Corporate Event
            const { wedtype, eventorwed, curr_vendortype, vendortype, secondaryvendortype } = this.state //curr_vendortype
            var baritems = []

            if (eventorwed === 'Wedding & Event') {
                PRICELIST_FILTER_WEDDING[vendortype]['Wedding'].forEach(({ header }) => { baritems.push(header) });
                PRICELIST_FILTER_EVENT[secondaryvendortype]['Event'].forEach(({ header }) => { baritems.push(header) });
                baritems = [...new Set(baritems)];
            }
            else if (eventorwed === 'Wedding') {
                if (curr_vendortype === 'Both') {
                    PRICELIST_FILTER_WEDDING[vendortype]['Wedding'].forEach(({ header }) => { baritems.push(header) });
                    PRICELIST_FILTER_WEDDING[secondaryvendortype]['Wedding'].forEach(({ header }) => { baritems.push(header) });
                    baritems = [...new Set(baritems)];
                }
                else {
                    PRICELIST_FILTER_WEDDING[curr_vendortype]['Wedding'].forEach(({ header }) => { baritems.push(header) });
                }

            }
            else if (eventorwed === 'Event') {
                if (curr_vendortype === 'Both') {
                    PRICELIST_FILTER_EVENT[vendortype]['Event'].forEach(({ header }) => { baritems.push(header) });
                    PRICELIST_FILTER_EVENT[secondaryvendortype]['Event'].forEach(({ header }) => { baritems.push(header) });
                    baritems = [...new Set(baritems)];
                }
                else {
                    PRICELIST_FILTER_EVENT[curr_vendortype]['Event'].forEach(({ header }) => { baritems.push(header) });
                }
            }


            return (
                <div key={'type' + wedtype + eventorwed} style={styles.casualbox}>
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Type {IMPORTANT}</ControlLabel>
                    <TogglebadgeBar
                        baritems={baritems}
                        selectedBadge={this.state.wedtype}
                        onButtonClick={(index) => {
                            if (wedtype !== index) {
                                this.setState({
                                    wedtype: index,
                                    destination: 'None', // need to reset Wed Destinations to first index
                                    destination_addnote: EMPTY,
                                })
                            }
                        }}
                    />
                </div>
            )
        }

        const render_description = () => {
            return (
                <FormGroup controlId="description">
                    <FormControl
                        onChange={(event) => this.setState({ description: event.target.value })}
                        value={this.state.description}
                        componentClass="textarea"
                    />
                </FormGroup>
            )
        }

        const render_destination = () => {
            const { destination, eventorwed } = this.state
            var destinationitems = []
            var destinationadditional = <div />
            if (wedtype === PREACTUAL) {
                destinationitems = multi_destination
            }
            else {
                destinationitems = single_destination
            }
            if (destination !== 'None') {
                destinationadditional = (
                    <div style={styles.extranote}>
                        <FormGroup controlId="extra-note">
                            <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                                Additional-note {OPTIONAL}
                            </ControlLabel>
                            <FormControl
                                onChange={(event) => { this.setState({ destination_addnote: event.target.value }) }}
                                value={this.state.destination_addnote}
                                componentClass="textarea"
                                placeholder="Extra notes on wedding destination"
                            />
                        </FormGroup>
                    </div>
                )
            }

            return (
                <div>
                    <div key={wedtype + eventorwed} style={{ ...styles.casualbox, }}>
                        <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                            Destinations{OPTIONAL}
                        </ControlLabel>
                        <TogglebadgeBar
                            type={'CAN_BE_EMPTY'}
                            key={destinationitems}
                            baritems={destinationitems}
                            onButtonClick={(index) => {
                                const item = destinationitems[index]
                                if (destination === item) { // user toggling same button, we reset to -1
                                    this.setState({ destination: 'None', destination_addnote: EMPTY })
                                }
                                else {
                                    this.setState({ destination: item })
                                }
                            }}
                        />
                        {destinationadditional}
                    </div>
                    {EMPTYSPACE}
                </div>
            )
        }

        //location
        const render_locationdropdown = () => {
            return <div style={styles.fixedroot}>
                <div style={styles.currencyroot}>
                    <FormControl
                        key={'selectcountry'}
                        componentClass="select"
                        placeholder="select"
                        value={this.state.location}
                        onChange={(event) => {
                            this.setState({ location: event.target.value, currency: CURRENCY_MAP[event.target.value] })
                        }}
                    >
                        {PRESENCE_LOCATION.map((val, index) => {
                            return <option key={val + index} value={val}>{val}</option>
                        })}
                    </FormControl>
                </div>
            </div>
        }

        //price
        const render_price_bar = () => {
            return (
                <TogglebadgeBar
                    baritems={['Fixed', 'Range']}
                    selectedBadge={['Fixed', 'Range'].indexOf(this.state.price)}
                    onButtonClick={(index) => {
                        const item = ['Fixed', 'Range'][index]
                        this.setState({ price: item })
                    }}
                />
            )
        }
        const render_price_Fixed = () => {
            const { price, currency } = this.state
            return (
                price === 'Fixed' ?
                    <div style={styles.fixedroot}>
                        <div style={styles.fixedlowerroot}>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    onChange={(event) => { this.setState({ price_fixedval: event.target.value }) }}
                                    value={this.state.price_fixedval}
                                />
                                <InputGroup.Addon style={{ backgroundColor: 'white' }}>{currency}</InputGroup.Addon>
                            </InputGroup>
                        </div>
                        {render_price_addnote()}
                    </div>
                    :
                    null
            )

        }
        const render_price_Range = () => {
            const { price, currency } = this.state

            return (
                price === 'Range' ?
                    <div style={styles.fixedroot}>
                        <div style={styles.fixedlowerroot}>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    onChange={(event) => { this.setState({ price_rangestartval: event.target.value }) }}
                                    value={this.state.price_rangestartval}
                                />
                                <InputGroup.Addon style={{ backgroundColor: 'white' }}>{currency}</InputGroup.Addon>
                            </InputGroup>
                            <div style={styles.to}> to </div>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    onChange={(event) => { this.setState({ price_rangeendval: event.target.value }) }}
                                    value={this.state.price_rangeendval}
                                />
                                <InputGroup.Addon style={{ backgroundColor: 'white' }}>{currency}</InputGroup.Addon>
                            </InputGroup>

                        </div>
                        {render_price_addnote()}
                    </div>
                    :
                    null
            )
        }
        const render_price_addnote = () => {
            return (
                <div style={styles.extranote}>
                    <FormGroup controlId="extra-note">
                        <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                            Additional-note
                            {OPTIONAL}
                        </ControlLabel>
                        <FormControl
                            onChange={(event) => {
                                this.setState({ price_addnote: event.target.value })
                            }}
                            value={this.state.price_addnote}
                            componentClass="textarea"
                            placeholder="Extra notes on the price"
                        />
                    </FormGroup>
                </div>
            )
        }

        // deliverables
        const render_deliverables = () => {
            const render_line_by_line = () => {
                const { deliverables, deliverables_items } = this.state
                var result = []
                for (let i = 0; i < deliverables; i++) {
                    result.push(
                        <InputGroup
                            key={'deliverables' + i}
                            style={{ marginLeft: 10, marginTop: 5, marginRight: 10, }}
                        >
                            <InputGroup.Addon style={{ backgroundColor: 'white' }}>{i + 1}</InputGroup.Addon>
                            <FormControl
                                type="text"
                                onChange={(event) => {
                                    var temp = deliverables_items.slice()
                                    temp[i] = event.target.value
                                    this.setState({ deliverables_items: temp })
                                }}
                            />
                        </InputGroup>
                    )
                }
                return result
            }
            return (
                <div style={styles.additionalchargessubroot}>
                    <div style={styles.addAndMinusroot}>
                        <button // add button
                            style={styles.addbadge}
                            onClick={() => {
                                this.setState({
                                    deliverables: this.state.deliverables + 1,
                                    deliverables_items: [...this.state.deliverables_items, []]
                                })
                            }}
                        >
                            +
                        </button>
                        {
                            this.state.deliverables > 0 ?
                                <button // delete button
                                    style={styles.deletebutton}
                                    onClick={() => {
                                        this.setState({
                                            deliverables: this.state.deliverables - 1,
                                            deliverables_items: this.state.deliverables_items.slice(0, -1)
                                        })
                                    }}>
                                    -
                                </button>
                                :
                                null
                        }
                    </div>
                    {render_line_by_line()}
                </div>
            )
        }

        // additional charges
        const render_additionalcharges = () => {
            const render_line_by_line = () => {
                const { additionalcharges, additionalcharges_items } = this.state
                var result = []
                for (let i = 0; i < additionalcharges; i++) {
                    result.push(
                        <InputGroup
                            key={'additionalcharges' + i}
                            style={{ marginLeft: 10, marginRight: 10, marginTop: 5, }}
                        >
                            <InputGroup.Addon style={{ backgroundColor: 'white' }}>{i + 1} </InputGroup.Addon>
                            <FormControl
                                type="text"
                                onChange={(event) => {
                                    additionalcharges_items[i] = event.target.value
                                    this.setState({
                                        additionalcharges_items: additionalcharges_items
                                    })
                                }}
                            />
                        </InputGroup>
                    )
                }
                return result
            }
            return (
                <div style={styles.additionalchargessubroot}>
                    <div style={styles.addAndMinusroot}>
                        <button // add button
                            style={styles.addbadge}
                            onClick={() => {
                                this.setState({
                                    additionalcharges: this.state.additionalcharges + 1,
                                    additionalcharges_items: [...this.state.additionalcharges_items, []]
                                })
                            }}
                        >
                            +
                        </button>
                        {
                            this.state.additionalcharges > 0 ?
                                <button // delete button
                                    style={styles.deletebutton}
                                    onClick={() => {
                                        this.setState({
                                            additionalcharges: this.state.additionalcharges - 1,
                                            additionalcharges_items: this.state.additionalcharges_items.slice(0, -1)
                                        })
                                    }}>
                                    -
                                </button>
                                :
                                null
                        }
                    </div>
                    <div>
                        {render_line_by_line()}
                    </div>
                </div>
            )
        }


        // others
        const render_others = () => {
            return (
                <FormGroup controlId="others">
                    <FormControl
                        onChange={(event) => {
                            this.setState({ others: event.target.value }, () => {
                            })
                        }}
                        value={this.state.others}
                        componentClass="textarea"
                    />
                </FormGroup>
            )
        }

        // terms and conditions
        const render_tnc = () => {
            return (
                <FormGroup controlId="tnc">
                    <FormControl
                        onChange={(event) => {
                            this.setState({ tnc: event.target.value }, () => {
                            })
                        }}
                        value={this.state.tnc}
                        componentClass="textarea"
                    />
                </FormGroup>
            )
        }


        // duration
        const render_duration = () => {
            const { duration } = this.state
            const render_duration_Fixed = () => {
                return (
                    duration === 'Fixed' ?
                        <div style={styles.fixedroot}>
                            <div style={styles.fixedlowerroot}>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        onChange={(event) => { this.setState({ duration_fixedval: event.target.value }) }}
                                        value={this.state.duration_fixedval}
                                    />
                                    <InputGroup.Addon style={{ backgroundColor: 'white' }}>Hr</InputGroup.Addon>
                                </InputGroup>
                            </div>
                            {render_duration_addnote()}
                        </div>
                        :
                        null
                )
            }
            const render_duration_Range = () => {
                return (
                    duration === 'Range' ?
                        <div style={styles.fixedroot}>
                            <div style={styles.fixedlowerroot}>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        onChange={(event) => { this.setState({ duration_rangestartval: event.target.value }) }}
                                        value={this.state.duration_rangestartval}
                                    />
                                    <InputGroup.Addon style={{ backgroundColor: 'white' }}>Hr</InputGroup.Addon>
                                </InputGroup>
                                <div style={styles.to}> to </div>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        onChange={(event) => { this.setState({ duration_rangeendval: event.target.value }) }}
                                        value={this.state.duration_rangeendval}
                                    />
                                    <InputGroup.Addon style={{ backgroundColor: 'white' }}>Hr</InputGroup.Addon>
                                </InputGroup>
                            </div>
                            {render_duration_addnote()}
                        </div>
                        :
                        null
                )
            }
            const render_duration_addnote = () => {
                return (
                    <div style={styles.extranote}>
                        <FormGroup controlId="extra-note">
                            <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                                Additional-note{OPTIONAL}
                            </ControlLabel>
                            <FormControl
                                onChange={(event) => {
                                    this.setState({ duration_addnote: event.target.value }, () => {
                                    })
                                }}
                                value={this.state.duration_addnote}
                                componentClass="textarea"
                                placeholder="Extra notes on the duration"
                            />
                        </FormGroup>
                    </div>

                )
            }
            return (
                <div>
                    <TogglebadgeBar
                        type={'CAN_BE_EMPTY'}
                        baritems={['Fixed', 'Range']}
                        selectedBadge={['Fixed', 'Range'].indexOf(duration)}
                        onButtonClick={(index) => {
                            const item = ['Fixed', 'Range'][index]
                            if (item === duration) {
                                this.setState({ duration: 'None', duration_addnote: EMPTY })
                            }
                            else {
                                this.setState({ duration: item })
                            }
                        }}
                    />
                    {
                        duration !== 'None' ?
                            <div>
                                {render_duration_Fixed()}
                                {render_duration_Range()}
                            </div>
                            :
                            <div />
                    }

                </div>

            )
        }

        const render_listing = () => {
            const { listing } = this.state
            return (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', overflowX: 'auto', overflowY: 'hidden', margin: '10px 10px 10px 0px' }}>
                    <div style={styles.currencyroot}>
                        <FormControl
                            key={'selectcountry'}
                            componentClass="select"
                            placeholder="select"
                            value={listing ? 'Yes' : 'No'}
                            onChange={(event) => {
                                this.setState({ listing: event.target.value === 'Yes' ? true : false })
                            }}
                        >
                            {['Yes', 'No'].map((val, index) => <option key={val + index} value={val}>{val}</option>)}
                        </FormControl>
                    </div>

                </div>
            )


        }

        const render_promotion = () => {
            const { promo, promodestroydt, promoprice, currency } = this.state

            return (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', overflowX: 'auto', overflowY: 'hidden', margin: '10px 10px 10px 0px', flexDirection: 'column' }}>
                    <div style={{ ...styles.currencyroot, alignSelf: 'flex-start' }}>
                        <FormControl
                            key={'selectcountry'}
                            componentClass="select"
                            placeholder="select"
                            value={promo ? 'Yes' : 'No'}
                            onChange={(event) => {
                                this.setState({ promo: event.target.value === 'Yes' ? true : false })
                            }}
                        >
                            {['Yes', 'No'].map((val, index) => <option key={val + index} value={val}>{val}</option>)}
                        </FormControl>
                    </div>


                    {promo
                        ? <div style={{ ...styles.fixedroot, alignSelf: 'flex-start', display: 'flex' }}>
                            {EMPTYSPACE}
                            <div style={{ paddingLeft: 10, fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
                                <div>Promotion Price ( Must be lesser than Fixed Price )</div>
                                <div>{IMPORTANT}</div>
                            </div>
                            <div style={styles.fixedlowerroot}>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        onChange={(event) => { this.setState({ promoprice: event.target.value }) }}
                                        value={promoprice}
                                    />
                                    <InputGroup.Addon style={{ backgroundColor: 'white' }}>{currency}</InputGroup.Addon>
                                </InputGroup>
                            </div>
                        </div>
                        : null}

                    {promo
                        ? <div style={{ paddingLeft: 10, display: 'flex', alignSelf: 'flex-start', flexDirection: 'column' }}>
                            <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
                                <div style={{ paddingBottom: 7 }}>Promotion Expiration Date</div>
                                <div>{IMPORTANT}</div>
                            </div>
                            <DatePicker
                                dateFormat={'dd/MM/yyyy'}
                                selected={promodestroydt}
                                placeholderText={'dd/mm/yyyy'}
                                fixedHeight
                                onChange={(e) => this.setState({ promodestroydt: e })}
                            />
                        </div>
                        : null}
                </div>
            )
        }

        const displaymsg_div = <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >{this.state.displaymsg}</div>
        const Btnsrow = (ret, cre) => {
            return <div style={{ display: 'flex', fontSize: 14, placeContent: 'space-between', margin: '0px 0px 20px 0px', fontWeight: 'bold', alignItems: 'center' }}>
                {ret ? <button
                    className="createprojectcontent_return"
                    disabled={disabled}
                    style={{ ...BUTTONSTYLE, outline: '' }}
                    onClick={this.props.onReturn}>
                    {LEFTARROW_ICON_SMALL}
                    <div className="createprojectcontent_returntxt">Return</div>
                </button> : null}
                {cre ? <button
                    className="createprojectcontent_save"
                    disabled={disabled}
                    style={{ ...BUTTONSTYLE, outline: '' }}
                    onClick={this.createpackage}>
                    <div className="createprojectcontent_savetxt">Create</div>
                </button> : null}
            </div>
        }

        return !disabled
            ? <div style={{ padding: '0px 10px', width: '100%' }}>
                {render_name()}
                {render_vendortypeselection()}
                {render_uploadimg}
                {render_uploadsubimg}
                {render_videolink}
                {render_categoryselection()}
                {render_type()}
                {EMPTYSPACE}
                {render_tags()}
                {render_destination()}

                <div style={styles.casualbox}>{/* Duration */}
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        Duration{OPTIONAL}
                    </ControlLabel>
                    <div style={styles.fixedOrRangeroot}>
                        {render_duration()}
                    </div>
                </div>
                {EMPTYSPACE}
                <div style={styles.casualbox}>{/* Location */}
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        Location{IMPORTANT}
                    </ControlLabel>
                    <div style={styles.fixedOrRangeroot}>
                        {render_locationdropdown()}
                    </div>
                </div>
                {EMPTYSPACE}
                <div style={styles.casualbox}>{/* Price */}
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        Price{IMPORTANT}
                    </ControlLabel>
                    <div style={styles.fixedOrRangeroot}>
                        {render_price_bar()}
                        {render_price_Fixed()}
                        {render_price_Range()}
                    </div>
                </div>
                {EMPTYSPACE}
                <div style={styles.casualbox}>{/* Delieverables */}
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        Deliverables{IMPORTANT}
                    </ControlLabel>
                    <div style={styles.deliverablesroot}>
                        {render_deliverables()}
                    </div>
                </div>
                {EMPTYSPACE}
                <div style={styles.casualbox}>{/* Additional charges */}
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        Additional charges{OPTIONAL}
                    </ControlLabel>
                    {render_additionalcharges()}
                </div>
                {EMPTYSPACE}
                <div style={styles.casualbox}> {/*List on Pricelist page*/}
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        List on Pricelist page{OPTIONAL}
                    </ControlLabel>
                    {render_listing()}
                </div>
                {EMPTYSPACE}

                {promotion && this.state.price === 'Fixed'  // promotion will appear if only is Fixed type of pricing
                    ? <div style={styles.casualbox}> {/*Pricelist set to promotion*/}
                        <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                            List as Promotion{OPTIONAL}
                        </ControlLabel>
                        {render_promotion()}
                    </div>
                    : null}

                {EMPTYSPACE}
                <div>{/*Description page*/}
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        Description{OPTIONAL}
                    </ControlLabel>
                    {render_description()}
                </div>
                {EMPTYSPACE}
                <div>{/*Others page*/}
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        Others{OPTIONAL}
                    </ControlLabel>
                    {render_others()}
                </div>
                {EMPTYSPACE}
                <div>
                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>
                        {'Terms & Conditions'}{OPTIONAL}
                    </ControlLabel>
                    {render_tnc()}
                </div>
                {EMPTYSPACE}


                <div style={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
                    {displaymsg_div}
                    {Btnsrow(true, true)}
                </div>

            </div>
            : <Creatingloading />
    }
}

const styles = {

    questionmarkbadge: {
        height: 20,
        width: 20,
        display: 'flex',
        justifyContent: 'center'
    },
    //shared components
    casualbox: {
        border: 'thin solid rgb(217, 216, 216)',
        padding: 5,
    },
    extranote: {
        marginLeft: 10,
        marginRight: 10,
    },
    to: {
        fontWeight: 'bold',
        paddingLeft: 5,
        paddingRight: 5
    },
    // shared between Duration and price
    fixedOrRangeroot: {
        display: 'flex',
        flexDirection: 'column',
    },
    fixedroot: {
        display: 'flex',
        flexDirection: 'column',
    },
    fixedlowerroot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    dollarfont: {
        marginRight: 10,
        fontWeight: 'bold'
    },

    hourrootfont: {
        paddingLeft: 10,
        paddingTop: 10,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold'
    },
    deliverablesroot: {

    },
    additionalchargessubroot: {
        display: 'flex',
        flexDirection: 'column',
    },
    addAndMinusroot: {
        display: 'flex',
        flexDirection: 'row',
    },
    addbadge: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        border: 'thin solid rgb(217, 216, 216)',
        padding: 5,
        margin: 5,
        outline: 'none',
        fontWeight: 'bold',
        height: 40,
        width: 40,
        whiteSpace: 'nowrap',
        backgroundColor: 'white',

    },
    deletebutton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        border: 'thin solid rgb(217, 216, 216)',
        padding: 5,
        margin: 5,
        outline: 'none',
        fontWeight: 'bold',
        height: 40,
        width: 40,
        whiteSpace: 'nowrap',
        backgroundColor: 'white',

    },
    currencyroot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingRight: 10,
        marginLeft: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    category_eventorwed_notselected: {
        width: 'max-content',
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
        margin: 5,
        border: 'solid thin #D9D8D8',
        borderRadius: 5,
        outline: 'none',
        fontWeight: 'bold',
        backgroundColor: 'white',
        color: '#B5B5B5',
    },
    category_eventorwed_selected: {
        fontWeight: 'bold',
        width: 'max-content',
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
        margin: 5,
        border: 'solid thin #D9D8D8',
        borderRadius: 5,
        outline: 'none',
        backgroundColor: 'rgb(239, 239, 239)',
        color: '#555555',

    }

};


export default Pricelistcreation

