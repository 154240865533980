import React, { useEffect, useRef, useState } from "react";
import { Checkdevice, ScreenWidth } from "../../components";
import * as d3 from "d3";
import './bchart.css'

const Barchart = props => {
    const ref = useRef(null);
    const cache = useRef(null);
    const [title, setTitle] = useState('')
    const [data, setData] = useState([])
    const [device_wt_map, setDevice_wt_map] = useState({})
    const device = Checkdevice()
    const sw = ScreenWidth()

    // parse the date / time
    var parseTime = d3.utcParse("%Y/%m/%d")

    var margin = { top: 10, right: 10, bottom: 100, left: 40 },
        margin2 = { top: 430, right: 10, bottom: 20, left: 40 },
        width = device_wt_map[device] - margin.left - margin.right,
        height = 500 - margin.top - margin.bottom,
        height2 = 500 - margin2.top - margin2.bottom;

    useEffect(() => {
        const { data, title } = props
        setData(data)
        setTitle(title)
        cache.current = data
    }, [props])

    useEffect(() => {
        setDevice_wt_map({
            'WEB': 950,
            'TABLET': 680,
            'MOBILE': sw - 40,
        })
    }, [sw])

    useEffect(() => {
        if (Object.keys(device_wt_map).length > 0) {
            props.clear()

            var local_data = []

            for (let i = 0; i < data.length; i++) { local_data.push({ date: parseTime(data[i].date), count: data[i].count }) }

            const root = d3.select(ref.current)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(0," + margin.top + ")")
                .data([local_data])

            var x = d3.scaleBand().rangeRound([0, width]).padding(0.5),
                x2 = d3.scaleBand().rangeRound([0, width]).padding(0.5),
                y = d3.scaleLinear().range([height, 0]),
                y2 = d3.scaleLinear().range([height2, 0]);

            x.domain(local_data.map(function (d) { return d.date }));
            y.domain([0, d3.max(local_data, function (d) { return d.count; })]);
            x2.domain(x.domain());
            y2.domain(y.domain());

            var brush = d3.brushX(x2).extent([[0, 0], [width, height],]); // control the brush size

            brush.on('brush', brushed);

            var epsilon = Math.pow(10, -7);

            var xAxis = d3.axisBottom(x).tickFormat(d3.timeFormat("%m/%d")),
                xAxis2 = d3.axisBottom(x2).tickValues([]),
                yAxis = d3.axisLeft(y)
                    .tickFormat(function (d) {
                        if (((d - Math.floor(d)) > epsilon) && ((Math.ceil(d) - d) > epsilon))
                            return;
                        return d;
                    })
                    

            var focus = root.append("g")
                .attr("class", "focus")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

            var context = root.append("g")
                .attr("class", "context")
                .attr("transform", "translate(" + margin2.left + "," + margin2.top + ")");

            root.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(" + margin.left + "," + (height + margin.top) + ")")

            focus.append("g")
                .attr("class", "y axis")
                .call(yAxis);

            // X Axis Label
            context.append("text")
                .attr("class", "xaxislabel")
                .attr("transform", "translate(" + (width / 2) + " ," + 0 + ")")
                .style("text-anchor", "middle")
                .text(title[1]);

            // Y Axis Label
            root.append("text")
                .attr("class", "yaxislabel")
                .attr("transform", "rotate(-90)")
                .attr("y", 0)
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(title[0])

            enter(local_data)
            updateScale(local_data)

            var subBars = context
                .selectAll('.subBar')
                .data(local_data)

            subBars.enter()
                .append("rect")
                .style("fill", "var(--mainpink)")
                .classed('subBar', true)
                .attr("x", function (d) { return x2(d.date); })
                .attr("width", x.bandwidth())
                .attr("y", function (d) { return y2(d.count) })
                .attr("height", function (d) { return height2 - y2(d.count); })

            context.append("g")
                .attr("class", "x2 axis")
                .attr("transform", "translate(0," + height2 + ")")
                .call(xAxis2)

            context.append("g")
                .attr("class", "x brush")
                .call(brush)
                .selectAll("rect")
                .attr("y", -6)
                .attr("height", height2 + 7)

            function brushed() {
                // get bounds of selection
                var s = d3.event.selection,
                    updatedData = [];

                // for each "tick" in our domain is it in selection
                x2.domain().forEach((d, i) => {
                    var pos = x2(d) + x2.bandwidth() / 2;
                    if (pos > s[0] && pos < s[1]) {
                        updatedData.push(local_data[i]);
                    }
                });
                if (updatedData.length === 0) updatedData = local_data.slice(0, local_data.length)

                d3.selectAll(".x.axis").remove(); // remove x-axis
                d3.selectAll(".y.axis").remove(); // remove y-axis
                d3.selectAll(".bar").remove(); // remove bar
                d3.selectAll('.text').remove(); //  remove label

                update(updatedData);
                enter(updatedData);
                exit(updatedData);
                updateScale(updatedData)
            }

            function updateScale() {
                var brushValue = brush.extent()[1] - brush.extent()[0];
                if (brushValue === 0) { brushValue = width; }

                root.append("g")
                    .attr("class", "x axis")
                    .attr("transform", "translate(" + margin.left + "," + (height + margin.top) + ")")

                root.select(".x.axis")
                    .call(xAxis)
                    .selectAll("text")
                    .style("text-anchor", "end")
                    .attr("dx", "-.8em")
                    .attr("dy", "-.55em")
                    .attr("font-weight", (e) => { if (new Date(e).getDay() === 0) return 'bold' })
                    .attr("color", (e) => { if (new Date(e).getDay() === 0) return 'red' })
                    .attr("transform", "rotate(-30)")

                focus.append("g").attr("class", "y axis")

                focus.select(".y.axis").call(yAxis)

                // var tickScale = d3.scale.pow().range([data.length / 10, 0]).domain([data.length, 0]).exponent(.5)

                // var brushValue = brush.extent()[1] - brush.extent()[0];
                // if(brushValue === 0){
                //   brushValue = width;
                // }

                // var tickValueMultiplier = Math.ceil(Math.abs(tickScale(brushValue)));  
                // var filteredTickValues = data.filter(function(d, i){return i % tickValueMultiplier === 0}).map(function(d){ return d.date})

                // focus.select(".x.axis").call(xAxis.tickValues(filteredTickValues));
                // focus.select(".y.axis").call(yAxis);

            }

            function update(data) {

                x.domain(data.map(function (d) { return d.date }));
                y.domain([0, d3.max(data, function (d) { return d.count; })]);

                var bars = focus
                    .selectAll('.bar')
                    .data(data)

                bars.selectAll('.bar')
                    .data(data)
                    .attr("x", function (d) { return x(d.date); })
                    .attr("width", x.bandwidth())
                    .attr("y", function (d) { return y(d.count) })
                    .attr("height", function (d) { return d.count === 0 ? 0 : height - y(d.count); })
                // .attr("height", function (d) { return height - y(d.count); })
                // .attr("height", function (d) { return d === 0 ? 0 : height - y(d.count); })
            }

            function exit(local_data) {
                var bars = root.selectAll('.bar').data(local_data)
                bars.exit().remove()
            }

            function enter(data) {

                x.domain(data.map(function (d) { return d.date }));
                y.domain([0, d3.max(data, function (d) { return d.count; })]);

                var bars = focus.selectAll('.bar').data(data)

                bars.enter()
                    .append("rect")
                    .style("fill", "var(--mainpink)")
                    .classed('bar', true)
                    .attr("width", x.bandwidth())
                    .attr("x", function (d) { return x(d.date); })
                    .attr("y", function (d) { return y(d.count) })
                    .attr("height", function (d) { return d.count === 0 ? 0 : height - y(d.count); })
                    .on("mousemove", function (d, i, nodes) {

                        div.transition()
                            .duration(100)
                            .style("opacity", .9);

                        div.html("<div style=\"display:flex;flex-direction:column;color:white\"><div style=\"margin:3px 3px 0px 3px\">" + new Date(d.date).toLocaleDateString() + "</div><br/><div style=\"margin:0px 3px 3px 3px\">" + props.tooltip_data.text + " " + d.count + " " + props.tooltip_data.unit + "</div></div>")
                            .style("left", (d3.event.pageX) + "px")
                            .style("top", (d3.event.pageY - 28) + "px");

                    })
                    .on("mouseout", function (d) {
                        div.transition()
                            .duration(100)
                            .style("opacity", 0)
                    })
            }

            var div = d3.select("body")
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)

            cache.current = props.data;
        }
    }, [data, device_wt_map])

    return <svg key={'barchart'} width={device_wt_map[device]} height={500}><g ref={ref} /></svg>

};

export default Barchart;