import { ScreenWidth } from './Dimension'
import { WEB, TABLET } from './../config/constants'
export function Checkdevice() {

    const W = ScreenWidth()

    if (W > WEB) {
        return 'WEB'
    }
    else if (W > TABLET) {
        return 'TABLET'
    }
    else {
        return 'MOBILE'
    }

}
