import React, { useEffect, useState } from 'react'
import { Checkdevice } from "../Checkdevice"
import { u1F464 } from 'react-icons-kit/noto_emoji_regular/u1F464'
import { u1F4DC } from 'react-icons-kit/noto_emoji_regular/u1F4DC'
import { u1F49E } from 'react-icons-kit/noto_emoji_regular/u1F49E'
import { u1F4AA } from 'react-icons-kit/noto_emoji_regular/u1F4AA'
import { u1F4DD } from 'react-icons-kit/noto_emoji_regular/u1F4DD'
import { u1F4C2 } from 'react-icons-kit/noto_emoji_regular/u1F4C2'
import { u1F3E0 } from 'react-icons-kit/noto_emoji_regular/u1F3E0'
import { u1F52E } from 'react-icons-kit/noto_emoji_regular/u1F52E'
import { u2934 } from 'react-icons-kit/noto_emoji_regular/u2934'
import Icon from 'react-icons-kit'
import { levelgate, sharegate } from '../../config/constants'
import './Vptopbar.css'
const vl = '/business/vendorprofile/'
const n_link_map = {
    home: { l: '/', i: u1F3E0, t: 'Home' },
    general: { l: vl + 'general', i: u1F464, t: 'Profile', gate: true },
    project: { l: vl + 'project', i: u1F4C2, t: 'Project', gate: true },
    pricelist: { l: vl + 'pricelist', i: u1F4DC, t: 'Pricelist', gate: true },
    review: { l: vl + 'review', i: u1F4DD, t: 'Review', gate: true },
    partner: { l: vl + 'partner', i: u1F4AA, t: 'Partner', gate: true },
    share: { l: vl + 'share', i: u1F49E, t: 'Share', gate: sharegate },
    trend: { l: '/business/trend', i: u1F52E, t: 'Trend', gate: true },
    level: { l: '/business/level', i: u2934, t: 'Level', gate: levelgate }
}

function Vptopbar(props) {
    const [nav, setNav] = useState('')
    const [firsttime, setFirsttime] = useState(true)
    const device = Checkdevice()
    useEffect(() => {
        if (firsttime) {
            var localnav = props.history.location.pathname.split('/')[props.history.location.pathname.split('/').length - 1]
            if (localnav === '') localnav = 'home'
            if (Object.keys(n_link_map).indexOf(localnav) === -1) props.history.push('/')
            setNav(localnav)
            setFirsttime(false)
        }
    }, [props])
    const menuClick = (n) => { setNav(n); props.updateNav(n); props.history.push(n_link_map[n]['l']); }
    return <div className="Vptopbar_root" style={{ justifyContent: device === 'MOBILE' ? 'flex-start' : 'center' }}>
        {Object.keys(n_link_map).map((key) => {
            const { i, t, gate } = n_link_map[key]
            if(gate){
                return <button key={key} className={"Vptopbar_" + (nav === key)} onClick={() => menuClick(key)}><Icon size={28} icon={i} /> <div>{t}</div></button>
            }
            else{
                return null
            }
            
        })}
    </div>
}
export default Vptopbar