import React from 'react'
import { b_fs } from '../../../../../config/constants'

function Creatingloading() {
    return <div style={{ height: '100vh', width: '100vw', position: 'absolute', display: 'flex', top: 0, left: 0 }}>
        <div style={{ color: 'white', height: '100vh', width: '100vw', zIndex: 1, placeContent: 'center', display: 'flex', alignItems: 'center', fontSize: b_fs }}>Create In Progress...</div>
        <div style={{ height: '100vh', width: '100vw', backgroundColor: '#0000004a', position: 'absolute', display: 'flex', justifyContent: 'center', fontWeight: 'bold', top: 0, left: 0, alignItems: 'center' }} />
    </div>
}


export default Creatingloading