import {
    CREATE_VENDORCREATE_ACTION_FULFILLED,
    CREATE_VENDORCREATE_ACTION_PENDING,
    CREATE_VENDORCREATE_ACTION_REJECTED,

    LOGIN_VENDORLOGIN_ACTION_FULFILLED,
    LOGIN_VENDORLOGIN_ACTION_PENDING,
    LOGIN_VENDORLOGIN_ACTION_REJECTED

} from '../actions/app.action'

const DEFAULT_STR = ''

const initialState = {
    token: DEFAULT_STR,
}

export default (state = initialState, action) => {

    switch (action.type) {
        //vendor create
        case CREATE_VENDORCREATE_ACTION_FULFILLED:
            return {
                ...state,
                token: action.payload.data.data
            }
        case CREATE_VENDORCREATE_ACTION_PENDING:
            return [...state, action.payload]
        case CREATE_VENDORCREATE_ACTION_REJECTED:
            return [...state, action.payload]

        //vendor login
        case LOGIN_VENDORLOGIN_ACTION_FULFILLED:
            return {
                ...state,
                token: action.payload.data.data
            }
        case LOGIN_VENDORLOGIN_ACTION_PENDING:
            return [...state, action.payload]
        case LOGIN_VENDORLOGIN_ACTION_REJECTED:
            return [...state, action.payload]


        default:
            return state
    }
}