import React, { useState, useEffect } from 'react';
import { Checklandscape } from '../../../../../../components';
import { IMG_ALT,PATTERN_DIM, EMPTY  } from '../../../../../../config/constants';
import { Imagedownsize } from '../../../../components/Imagedownsize/Imagedownsize';

function Patterntwo(props) {
    //  ___________
    //  |    |    |
    //  |    |    |
    //  |    |____|
    //  |    |    |
    //  |    |    |
    //  |____|____|

    const [img1, setImg1] = useState(EMPTY);
    const [img2, setImg2] = useState(EMPTY);
    const [img3, setImg3] = useState(EMPTY);

    const img1_dim = { width: 150, height: 231.5 }
    const img23_dim = { width: 80, height: 115.5 }

    useEffect(() => {
        if (props !== null && props !== undefined && props !== []) {
            const { editable, img1, img2, img3 } = props
            if (editable) { // can edit
                setImg1(<div style={{ width: img1_dim.width - 4, height: img1_dim.height - 4, borderTopLeftRadius: 10, borderTopRightRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>+</div>)
                setImg2(<div style={{ width: img23_dim.width - 4, height: img23_dim.height - 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>+</div>)
                setImg3(<div style={{ width: img23_dim.width - 4, height: img23_dim.height - 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>+</div>)
            }
            else if (!editable) { // cannt edit
                setImg1(<img alt={IMG_ALT} style={{ width: img1_dim.width - 2, height: img1_dim.height - 1, borderTopLeftRadius: 10, }} src={img1} />)
                setImg2(<img alt={IMG_ALT} style={{ width: img23_dim.width - 2, height: img23_dim.height - 1, borderTopRightRadius: 10, }} src={img2} />)
                setImg3(<img alt={IMG_ALT} style={{ width: img23_dim.width - 2, height: img23_dim.height - 1, }} src={img3} />)
            }

        }
    }, [props.editable])


    const onImgchange1 = (event) => {
        try {
            let file = event.target.files[0];
            var reader = new FileReader();
            reader.onloadend = (e) => {
                var img = document.createElement("img");
                img.onload = () => {
                    if (!Checklandscape(img) && file.size < 8000000) { // 2MB : 2097152, 20MB : 20000000
                        var result = Imagedownsize(img, 'scale', 500, 500);
                        var img1 = result[0];
                        var imgfile1 = new File([result[1]], 'selectedimg_main', { type: result[1].type, lastModified: Date.now() });
                        setImg1(<img alt={IMG_ALT} style={{ width: img1_dim.width - 2, height: img1_dim.height - 2, borderTopLeftRadius: 10, }} src={img1} />);
                        props.update([imgfile1, 'main']);
                    }
                    else {
                        alert('Please ensure image is in portrait orientation, and size under 8MB')
                        return;
                    }
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
        catch (e) {
        }
    };
    const onImgchange2 = (event) => {
        try {
            let file = event.target.files[0];
            var reader = new FileReader();
            reader.onloadend = (e) => {
                var img = document.createElement("img");
                img.onload = () => {
                    if (!Checklandscape(img) && file.size < 8000000 ) { //accept only landscape images
                        var result = Imagedownsize(img, 'scale', 500, 500);
                        var img2 = result[0];
                        var imgfile2 = new File([result[1]], 'selectedimg_left', { type: result[1].type, lastModified: Date.now() });
                        setImg2(<img alt={IMG_ALT} style={{ width: img23_dim.width - 2, height: img23_dim.height - 2, borderTopRightRadius: 10, }} src={img2} />);
                        props.update([imgfile2, 'top']);
                    }
                    else {
                        alert('Please ensure image is in portrait orientation, and size under 8MB')
                        return;
                    }
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
        catch (e) {
        }
    };
    const onImgchange3 = (event) => {
        try {
            let file = event.target.files[0];
            var reader = new FileReader();
            reader.onloadend = (e) => {
                var img = document.createElement("img");
                img.onload = () => {
                    if (!Checklandscape(img) && file.size < 8000000 ) { //accept only landscape images
                        var result = Imagedownsize(img, 'scale', 500, 500);
                        var img3 = result[0];
                        var imgfile3 = new File([result[1]], 'selectedimg_right', { type: result[1].type, lastModified: Date.now() });
                        setImg3(<img alt={IMG_ALT} style={{ width: img23_dim.width - 2, height: img23_dim.height - 2 }} src={img3} />);
                        props.update([imgfile3, 'down']);
                    }
                    else {
                        alert('Please ensure image is in portrait orientation, and size under 8MB')
                        return;
                    }
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
        catch (e) {
        }
    };

    const { editable } = props
    return (<div style={{ ...PATTERN_DIM, display: 'flex', flexDirection: 'row', border: editable ? 'dashed thin black' : '', margin: editable ? '4% 0px' : '', borderTopLeftRadius: 10, borderTopRightRadius: 10, alignItems: 'center' }}>
        <div style={{ ...img1_dim, display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', borderRight: editable ? 'dashed thin black' : '' }}>
            {editable ? <input key="input_main" accept="image/*" className="input_main" type='file' name="input-file" style={{ ...img1_dim, opacity: 0, position: 'absolute' }} onChange={onImgchange1} /> : null}
            {img1}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ ...img23_dim, display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', borderBottom: editable ? 'dashed thin black' : '' }}>
                {editable ? <input key="input_main" accept="image/*" className="input_main" type='file' name="input-file" style={{ ...img23_dim, opacity: 0, position: 'absolute' }} onChange={onImgchange2} /> : null}
                {img2}
            </div>
            <div style={{ ...img23_dim, display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', }}>
                {editable ? <input key="input_main" accept="image/*" className="input_main" type='file' name="input-file" style={{ ...img23_dim, opacity: 0, position: 'absolute' }} onChange={onImgchange3} /> : null}
                {img3}
            </div>
        </div>
    </div>);
}


export default Patterntwo