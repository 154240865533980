import React, { useState, useEffect } from 'react'
import { ARROWLEFT_ICON, ARROWRIGHT_ICON, EMPTYSPACE, SOFT_GREY } from '../../../../../../config/constants'
import Patternone from '../Projectcardpatterns/Patternone'
import Patterntwo from '../Projectcardpatterns/Patterntwo'
import Patternthree from '../Projectcardpatterns/Patternthree'

function Selectpattern(props) {

    const [patternpointer, setPatternpointer] = useState(null) // patternpointer at 0 is Patternone, 1 is Patterntwo, 2 is Patternthree

    useEffect(() => {
        if (props !== null && props !== undefined && props !== []) {
            setPatternpointer(props.pattern)
        }
    }, [props])

    const pattern = {
        0: <Patternone editable={true} update={(result) => { props.onSelect(result, patternpointer) }} />,
        1: <Patterntwo editable={true} update={(result) => { props.onSelect(result, patternpointer) }} />,// havent do yet 
        2: <Patternthree editable={true} update={(result) => { props.onSelect(result, patternpointer) }} />// havent do yet
    }

    const patterntitle = {
        0: <div style={{ color: SOFT_GREY }}> All Landscape</div>,
        1: <div style={{ color: SOFT_GREY }}> All Portrait</div>,
        2: <div style={{ color: SOFT_GREY }}> Single Portrait </div>
    }

    const extrareminder = <div>Image in display card must be image best represent the whole gallery.</div>


    const { onNext, onBack } = props
    return (
        <div style={{ backgroundColor: 'white', border: 'thin solid rgb(217, 216, 216)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>

            <div style={{ padding: 10, cursor: 'pointer' }} onClick={onBack}>
                {ARROWLEFT_ICON}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                {EMPTYSPACE}
                {patterntitle[patternpointer]}
                {EMPTYSPACE}
                {pattern[patternpointer]}
                {EMPTYSPACE}
                {extrareminder}
                {EMPTYSPACE}
            </div>
            <div style={{ padding: 10, cursor: 'pointer' }} onClick={onNext} >
                {ARROWRIGHT_ICON}
            </div>

        </div>
    )
}

export default Selectpattern