import { RE_EMAIL, RE_NUMBER, EMPTY, PRESENCE_LOCATION } from '../../../../config/constants'

const gterror = true
// return true implies got error,
// return false implies no error
export const Validateform = (items) => {
    const { form_key, form_parts } = items
    switch (form_key) {
        case 'vendortype': { // vendor type cannot be empty
            if (form_parts.vendortype === '') {
                // console.log('form_parts.vendortype got error')
                return true
            }
            if (form_parts.secondaryvendortype === form_parts.vendortype) {
                return true
            }
            else return false
        }
        case 'introduction': {
            if (form_parts.introduction.length > 1000) {
                return true;
            }
            else return false;
        }
        case 'location': {
            if (form_parts.location === '') {
                return true;
            }
            else if (PRESENCE_LOCATION.includes(form_parts.location)) {
                return false
            }
            else return true;
        }
        case 'about': { // at least 10 characters, at most 110 characters
            if (form_parts.about.length < 10 || form_parts.about.length > 200) return true;
            else return false;
        }
        case 'description': { // at least 10 characters, at most 1000 characters
            if (form_parts.description.length < 10 || form_parts.description.length > 1000) return true;
            return false;
        }
        case 'displayname': { // at most 20 characters
            if (form_parts.displayname === '') { return true }
            return false;
        }
        case 'name': { // name must be all chars
            // if (RE_NAME.test(String(form_parts.name.firstname).toLowerCase()) === false || form_parts.name.firstname.length > 50 || form_parts.name.firstname.length < 2) { return true }
            // if (RE_NAME.test(String(form_parts.name.lastname).toLowerCase()) === false || form_parts.name.lastname.length > 50 || form_parts.name.firstname.length < 2) { return true }
            // else return false;

            if (form_parts.name.firstname.length > 50) { return true }
            if (form_parts.name.lastname.length > 50) { return true }
            else return false;
        }
        case 'publicemail': { // email must be email
            if (RE_EMAIL.test(String(form_parts.publicemail).toLowerCase()) === false || form_parts.publicemail.length < 7 || form_parts.publicemail.length > 100) { return true }
            else return false;
        }

        case 'publicemails': { //must not be empty
            const { publicemails = [] } = form_parts
            console.log('publicemails', publicemails)
            for (let i = 0; i < publicemails.length; i++) {
                const { title, content } = publicemails[i]
                console.log('content', content)
                console.log('title', title)
                if (title.length > 100) { return true }
                if (RE_EMAIL.test(String(content).toLowerCase()) === false || content.length < 7 || content.length > 100) { return true }
            }
            return false
        }

        case 'privateemail': { // email must be email
            if (RE_EMAIL.test(String(form_parts.privateemail).toLowerCase()) === false) { return true }
            else return false;
        }
        case 'contact': {  // must be number
            try {
                var intContact = parseInt(form_parts.contact, 10)
                var strContact = intContact.toString()

                if (strContact.length === 0) return false
                if (strContact === 'NaN') return false
                // check length
                if (strContact.length < 8 || strContact.length > 20) {
                    return gterror
                }
                return false

            }
            catch (e) {
                return gterror
            }
        }


        case 'contacts': { //must not be empty

            function isIntegerString(str) {
                return /^\d+$/.test(str);
            }

            try {
                const { contacts = [] } = form_parts
                for (let i = 0; i < contacts.length; i++) {
                    const { title, content } = contacts[i]

                    if (title.length > 100) { return true }

                    if (strContact === 'NaN') return false
                    if (content.length < 8 || content.length > 20) {
                        return gterror
                    }
                }
                return false

            }
            catch (e) {
                return gterror
            }
        }

        case 'address': {
            // if (form_parts.address.length === 0) return false;
            if (form_parts.address.length > 101) return true;//form_parts.address.length < 5 ||
            else return false;
        }
        case 'gender': { //must not be empty
            if (form_parts.gender === '') return true;
            else return false;
        }
        case 'since': {
            try {
                // check if is number, if is not number change to number
                var local_since = form_parts.since
                if (typeof local_since !== Number) { // is not number, make it into number
                    local_since = parseInt(local_since, 10)
                }

                if (local_since === 0 || (local_since > 1900 && local_since < 3000)) {
                    return false
                }
                else {
                    return gterror
                }

            }
            catch (e) {
                return gterror
            }
        }
        case 'hosted': {
            try {
                // check if is number, if is not number change to number
                var local_hosted = form_parts.hosted
                if (typeof local_hosted !== Number) { // is not number, make it into number
                    local_hosted = parseInt(local_hosted, 10)
                }

                if (local_hosted > -1 && local_hosted < 1000) {
                    return false
                }
                else {
                    return true
                }

            }
            catch (e) {
                return true
            }
        }
        case 'teamnumber': {
            try {
                // check if is number, if is not number change to number
                var local_tn = form_parts.teamnumber
                if (typeof local_tn !== Number) { // is not number, make it into number
                    local_tn = parseInt(local_tn, 10)
                }

                if (local_tn > -1 && local_tn < 1000) {
                    return false
                }
                else {
                    return true
                }

            }
            catch (e) {
                return true
            }


        }
        case 'category': {
            if (form_parts.eventorwed === EMPTY) {
                return true
            }
            else if (form_parts.eventorwed === 'Both' || form_parts.eventorwed === 'Wedding' || form_parts.eventorwed === 'Event') {
                return false
            }
            else {
                return true
            }
        }

        case 'vendortypeminprice': {
            if (form_parts.vendortype_minprice === EMPTY || form_parts.vendortype_minprice.length > 10) {
                return true
            }
            else if (form_parts.vendortype_minprice > 0) {
                return false
            }
            else {
                return true
            }
        }

        case 'secondaryvendortypeminprice': {
            if (form_parts.secondaryvendortype_minprice === EMPTY || form_parts.secondaryvendortype_minprice.length > 10) {
                return true
            }
            else if (form_parts.secondaryvendortype_minprice > 0) {
                return false
            }
            else {
                return true
            }
        }

        case 'presence': { // have to at least presence in one country
            try {
                const { sg, my, ch, hk, tw, kr } = form_parts
                if (sg || my || ch || hk || tw || kr) {
                    return false
                }
                else { // all presence are false 
                    return true
                }
            }
            catch (e) {
                return true
            }
        }

        case 'vendortypespecific': {//must be email
            return false;
        }
        case 'secondaryvendortypespecific': {//must be email
            return false;
        }
        case 'website_link': { //must not be empty
            const { website_link = '' } = form_parts
            if (typeof website_link !== 'string' || form_parts.website_link.length === 0) { // website is empty, most likely vendor dont have website
                return false
            }
            if (form_parts.website_link.length < 9 || form_parts.website_link.length > 100) return true;
            else return false;
        }
        case 'website_links': { //must not be empty
            const { website_links = [] } = form_parts
            for (let i = 0; i < website_links.length; i++) {
                const { content, title } = website_links[i]
                if (content.length < 9 || content.length > 100) return true;
                if (title.length > 100) return true;
            }
            return false
        }
        case 'facebook_link': {//must not be empty
            const { facebook_link = '' } = form_parts
            if (typeof facebook_link !== 'string' || form_parts.facebook_link.length === 0) { // website is empty, most likely vendor dont have website
                return false
            }
            if (form_parts.facebook_link.length < 9 || form_parts.facebook_link.length > 500) return true;
            else return false;
        }
        case 'instagram_link': {//must not be empty
            const { instagram_link = '' } = form_parts
            if (typeof instagram_link !== 'string' || form_parts.instagram_link.length === 0) { // website is empty, most likely vendor dont have website
                return false
            }
            if (form_parts.instagram_link.length < 4 || form_parts.instagram_link.length > 500) return true;
            else return false;
        }
        case 'youtube_link': {//must not be empty
            const { youtube_link = '' } = form_parts
            if (typeof youtube_link !== 'string' || form_parts.youtube_link.length === 0) { // website is empty, most likely vendor dont have website
                return false
            }
            if (form_parts.youtube_link.length < 4 || form_parts.youtube_link.length > 500) return true;
            else return false;
        }
        case 'whatsapp_link': {//must not be empty
            const { whatsapp_link = '' } = form_parts
            if (typeof whatsapp_link !== 'string' || form_parts.whatsapp_link.length === 0) { // website is empty, most likely vendor dont have website
                return false
            }
            if (RE_NUMBER.test(String(form_parts.whatsapp_link)) === false || form_parts.whatsapp_link.length < 5 || form_parts.whatsapp_link.length > 20) return true;
            else return false
        }
        case 'wechat_link': {//must not be empty
            const { wechat_link = '' } = form_parts
            if (typeof wechat_link !== 'string' || form_parts.wechat_link.length === 0) { // website is empty, most likely vendor dont have website
                return false
            }
            if (form_parts.wechat_link.length < 5 || form_parts.wechat_link.length > 30) return true;
            else return false
        }
        case 'telegram_link': {//must not be empty
            const { telegram_link = '' } = form_parts
            if (typeof telegram_link !== 'string' || form_parts.telegram_link.length === 0) { // website is empty, most likely vendor dont have website
                return false
            }
            if (RE_NUMBER.test(String(form_parts.telegram_link)) === false || form_parts.telegram_link.length < 5 || form_parts.telegram_link.length > 30) return true;
            else return false
        }
        case 'line_link': {//must not be empty
            const { line_link = '' } = form_parts
            if (typeof line_link !== 'string' || form_parts.line_link.length === 0) { // website is empty, most likely vendor dont have website
                return false
            }
            if (RE_NUMBER.test(String(form_parts.line_link)) === false || form_parts.line_link.length < 5 || form_parts.line_link.length > 30) return true;
            else return false
        }
        case 'oldpassword': {
            if (form_parts.oldpassword.length < 200 && form_parts.oldpassword.length > 5) {
                return false
            }
            else {
                return true
            }
        }
        case 'newpassword': {
            if (form_parts.newpassword1 === form_parts.newpassword2 && form_parts.newpassword1 !== '' && form_parts.newpassword1.length < 30 && form_parts.newpassword1.length > 5) {
                return false
            }
            else {
                return true
            }

        }
        default: {
            return null
        }
    }


}