import React, { useState, useEffect } from 'react'
import { Checkdevice, Jfmodal, Notiboard } from '../../../../components'
import { INVERSE_READABLE_VENDORTYPE_MAP, IMG_ALT, HTTPSWEBSITE_URL } from '../../../../config/constants'//INVERSE_READABLE_VENDORTYPE_MAP
import { VENDOR_READPARTNER, VENDOR_ADDPARTNER, VENDOR_SUBPARTNER } from '../../../../config/api'
import { BUTTONSTYLE } from '../../../../config/cssconstants'
import useEndpoint from '../../../../async_components/useEndpoint'
import axios from "axios";
import './Partner.css'
import { getS3fullpath_withbucketname } from '../../../../config/commonfunctions'

const ALLVTS = Object.keys(INVERSE_READABLE_VENDORTYPE_MAP)
const vtcard_css = { display: 'flex', flexDirection: 'column', width: 150, height: 200, border: 'thin solid #707070', borderRadius: 5, margin: '10px 5px', padding: '5px 10px', }
function Partner(props) {

    const device = Checkdevice()
    const { vendor_id, token, ready } = props//username
    const [selectedvt, setSelectedvt] = useState('') // change of vt, sideeffect trigger modal
    const [untouchvt, setUntouchvt] = useState('') // raw form of vt
    const [addvendormodal, setAddvendormodal] = useState(false)
    const [subvendormodal, setSubvendormodal] = useState(false)
    const [pplinkmap_ready, setPplinkmap_ready] = useState(false) // indcate pplinkmap ready for use
    const [pplinkmap, setPplinkmap] = useState({}) // { vt : [profile img link, profile img lin], vt:[profile img lin, profile img lin], ....} 
    const [inputchange, setInputchange] = useState('') // input field for add new vendor
    const [inputsubchange, setInputsubchange] = useState('') // input field for add new vendor
    const partner_data = useEndpoint({
        method: 'POST',
        url: VENDOR_READPARTNER,
        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        data: { vendor_id, token }
    })
    useEffect(() => {
        if (partner_data.data && partner_data.data.message === 'SUCCESS') {
            const { ppid_map, links } = partner_data.data
            var newpplink_map = constructPartnerMap(links, ppid_map)
            setPplinkmap(newpplink_map)
        }
    }, [partner_data.complete])
    useEffect(() => {
        if (pplinkmap && pplinkmap) {
            setPplinkmap_ready(true)
        }
    }, [pplinkmap])
    const addV = (vt) => {
        setAddvendormodal(true);
        setSelectedvt(INVERSE_READABLE_VENDORTYPE_MAP[vt]);
        setUntouchvt(vt);
    }
    const subV = (vt) => {
        setSubvendormodal(true);
        setSelectedvt(INVERSE_READABLE_VENDORTYPE_MAP[vt]);
        setUntouchvt(vt);
    }
    if (ready && partner_data.complete && pplinkmap_ready) {
        return (
            <div className="partner_root" >
                <Notiboard text="Partners are vendors that you collaborate with and would like your customers know them." />
                <div className="partner_subroot" style={{ justifyContent: device === 'WEB' ? 'center' : 'flex-start' }}>
                    <div className="partner_subsubroot" style={{ display: 'grid', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' , width:'100%'}}>
                        {ALLVTS.map((vt, index) => {
                            var imglinks = []
                            try {
                                for (let k = 0; k < pplinkmap[vt].length; k++) {
                                    var imglink = pplinkmap[vt][k][0]
                                    var username = pplinkmap[vt][k][1]
                                    imglinks.push(
                                        <a href={ HTTPSWEBSITE_URL+ '/vendorlookup/' + username} style={{ width: 32, height: 32, border: 'thin solid #707070', borderRadius: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={imglink + k} target="_blank">
                                            <img 
                                            alt={IMG_ALT} 
                                            style={{ overflow: 'hidden', width: 30, height: 30, borderRadius: 30, padding: 2 }} 
                                            src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', imglink)}
                                             />
                                        </a>
                                    )
                                }
                            }
                            catch (e) {
                                imglinks = []
                            }

                            return (
                                <li key={vt + index} style={vtcard_css}>
                                    <div style={{ height: '25%' }}>{vt}</div> {/*vt*/}
                                    <div style={{ height: '72%', display: 'flex', flexDirection: 'row' }}>{imglinks}</div>{/*p_imgs*/}
                                    <div style={{ height: '18%', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                        <div style={{ cursor: 'pointer', height: '100%', display: 'flex', width: '30%', justifyContent: 'center', alignItems: 'center', padding: 5, borderRadius: 5, backgroundColor: 'white', border: 'thin solid rgb(217, 216, 216)', color: '#707070', fontWeight: 'bold', fontSize: 'x-large' }} onClick={() => subV(vt)}>-</div>
                                        <div style={{ cursor: 'pointer', height: '100%', display: 'flex', width: '67%', justifyContent: 'center', alignItems: 'center', padding: 5, borderRadius: 5, backgroundColor: '#eb5d7b', color: 'white', fontWeight: 'bold', fontSize: 'x-large' }} onClick={() => addV(vt)}>+</div>
                                    </div>
                                </li>
                            )
                        })}
                    </div>
                    <Jfmodal
                        show={addvendormodal}
                        title={'Add new partner'}
                        onHide={() => { setAddvendormodal(false) }}
                        device={device}
                        content={
                            <div className="partner_modalroot" >
                                <input className="form-control" placeholder={'Partner\'s displayname'} value={inputchange} onChange={(e) => { setInputchange(e.target.value) }} />
                                <button
                                    style={{ ...BUTTONSTYLE, alignSelf: 'flex-end', marginTop: 20 }}
                                    onClick={() => {
                                        try {
                                            let options = {
                                                method: 'POST',
                                                url: VENDOR_ADDPARTNER,
                                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                data: { untouchvt, selectedvt, displayname: inputchange, }
                                            }
                                            axios(options).then((result) => {
                                                setAddvendormodal(false)
                                                setSelectedvt('')
                                                const { message, ppid_map, links } = result.data
                                                if (message === 'SUCCESS') {
                                                    var newpplink_map = constructPartnerMap(links, ppid_map)
                                                    setPplinkmap(newpplink_map)
                                                }
                                            })
                                        }
                                        catch (e) {
                                            setInputchange('')
                                            setAddvendormodal(false)
                                        }
                                        setInputchange('')
                                        setAddvendormodal(false)

                                    }}>Add</button>
                            </div>
                        }
                    />
                    <Jfmodal
                        show={subvendormodal}
                        title={'Remove a partner'}
                        onHide={() => { setSubvendormodal(false) }}
                        device={device}
                        content={
                            <div className="partner_modalroot">
                                <input className="form-control"  placeholder={'Partner\'s displayname'} value={inputsubchange} onChange={(e) => { setInputsubchange(e.target.value) }} />
                                <button
                                    style={{ ...BUTTONSTYLE, alignSelf: 'flex-end', marginTop: 20 }}
                                    onClick={() => {
                                        try {
                                            let options = {
                                                method: 'POST',
                                                url: VENDOR_SUBPARTNER,
                                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                data: { untouchvt, selectedvt, displayname: inputsubchange, }
                                            }
                                            axios(options).then((result) => {
                                                setSubvendormodal(false)
                                                setSelectedvt('')
                                                const { message, ppid_map, links } = result.data
                                                if (message === 'SUCCESS') {
                                                    var newpplink_map = constructPartnerMap(links, ppid_map)
                                                    setPplinkmap(newpplink_map)
                                                }
                                            })
                                        }
                                        catch (e) {
                                            setSubvendormodal(false)
                                        }
                                        setInputsubchange('')
                                        setSubvendormodal(false)
                                    }}>Remove</button>
                            </div>
                        }
                    />
                </div>

            </div >
        )
    }
    else {
        return <div />
    }

    function constructPartnerMap(links, ppid_map) {
        var newlinks = {}
        // unpack links
        for (let i = 0; i < links.length; i++) {
            newlinks[links[i]._id] = links[i].link
        }
        // assign vt to the new map
        var newpplink_map = {}
        for (let x = 0; x < ALLVTS.length; x++) {
            newpplink_map[ALLVTS[x]] = []
        }
        var allvts = Object.keys(ppid_map)
        // change ppid_map to {vt:[ [imglink, username], [imglink, username] ], vt:[[imglink, username]].....}
        for (let i = 0; i < allvts.length; i++) {
            var vt = allvts[i]
            var imglink_username_pair = ppid_map[vt] // pair, imglink and username
            var currvt_imglink_username_pair = []
            for (let j = 0; j < imglink_username_pair.length; j++) {
                var username = imglink_username_pair[j][1]
                var awslink = newlinks[imglink_username_pair[j][0]]
                currvt_imglink_username_pair.push([awslink, username])
            }
            newpplink_map[vt] = currvt_imglink_username_pair
        }
        return newpplink_map
    }
}

export default Partner