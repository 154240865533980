import React from 'react'
import { Checkdevice } from '../Checkdevice'
import './Ad.css'

function Ad() {

    const device = Checkdevice()
    const datum = [
        {
            title: 'CNY promotion',
            bgimg: 'https://images.unsplash.com/photo-1606716809549-b0266c29c8c2?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1300&q=80',
            description: 'Should you need a short break from the hustle and bustle of a typical Kampong Glam evening, take a short walk towards the intersection of Beach Road and Arab Street, where this quaint Halal Swedish bistro sits.',
            orgprice: 500,
            price: 400,
            deduct: '10%',
        }
    ]
    return <div className={"ad_grid" + device}>
        {datum.map(({ title, bgimg, description, orgprice, price, deduct }, i) => {
            return <div key={i + 'card'} className={"ad_cardroot" + device} >
                <div className={"Ad_rightname"}> {title}</div>
                <div className={"Ad_top" + device} target="_blank" >
                    <div className={"Ad_left"}>
                        <div className={"Ad_leftimg"} style={{ backgroundImage: 'url(' + bgimg + ')' }} />
                    </div>
                    <div className={"Ad_right"}>
                        <div className={"Ad_rightprice"}>{'$ ' + price}</div>
                        <div className={"Ad_rightdescrip"}>{description}</div>
                        <div className={"Ad_rightbook"} replace target="_blank" >Visit</div>
                    </div>
                </div>
            </div>
        })}
    </div>
}

export default Ad