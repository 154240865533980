import React from 'react'
import './Topmenu.css'
function Topmenu(props) {
    const { selectedmenu, indicatemenu, onSelected, menus, device } = props
    return <div className={"topmenu_root" + device}>
        {menus.map((menu, i) => {
            const currentmenu = menu
            return <div key={'menu' + i} className={'topmenu' + (selectedmenu === menu)} onClick={() => onSelected(currentmenu)}> {menu} </div>
        })}
        {indicatemenu && indicatemenu.length > 0 && selectedmenu === indicatemenu
            ? <div key={'menuactive'} className={'topmenu' + (selectedmenu === indicatemenu)} >{indicatemenu}</div>
            : null}
    </div>
}
export default Topmenu;