
import React, { useState } from 'react'
import { Checklandscape } from '../../../../../../../../components'
import './Twoportraitinput.css'

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)) // eslint-disable-line
}

function Twoportraitinput({updateimg1, updateimg2}) {

    const key = uuidv4()
    const [img1, setImg1] = useState('')
    const [img2, setImg2] = useState('')

    return <div key={key} value={"IP"} className="twoimageinput_root">
        <div className="twoportrait_imginputholder">
            <img className="twoportrait_img" src={img1} alt="" />
            <input className="twoportrait_input" accept="image/*" type='file' name="input-file"
                onChange={(event) => {
                    let file = event.target.files[0];
                    if (file) {
                        var reader = new FileReader();
                        reader.onloadend = (e) => {
                            var img = document.createElement("img");
                            img.onload = () => {
                                if (!Checklandscape(img)) { // it must be portrait
                                    setImg1(reader.result)
                                    updateimg1(file, 'portrait')
                                }
                                else return
                            }
                            img.src = e.target.result;
                        }
                        reader.readAsDataURL(file);
                    }
                }} />
        </div>
        <div className="twoportrait_imginputholder">
            <img className="twoportrait_img" src={img2} alt="" />
            <input className="twoportrait_input" accept="image/*" type='file' name="input-file"
                onChange={(event) => {
                    let file = event.target.files[0];
                    if (file) {
                        var reader = new FileReader();
                        reader.onloadend = (e) => {
                            var img = document.createElement("img");
                            img.onload = () => {
                                if (!Checklandscape(img)) { // it must be portrait
                                    setImg2(reader.result)
                                    updateimg2(file)
                                }
                                else return
                            }
                            img.src = e.target.result;
                        }
                        reader.readAsDataURL(file);
                    }
                    event.target.value = null; // users can reupload similar file
                }} />
        </div>
    </div>

}

export default Twoportraitinput