import React, { useEffect, useState } from 'react';
import { Headtitle } from '../../components';
import { Checkdevice } from '../../components/Checkdevice';
import { b_fs, FS_LH_MAP, a_fs } from '../../config/constants';
import { READ_FAQ } from '../../config/api';
import axios from 'axios'

// const FAQ_MAINTITLE = ['Cereweave', 'USER', 'VENDOR', 'Frequently Asked Questions']
const TITLE_MAP = { 'VENOR': '0' }
const selected = 'VENDOR'
const subtitle_css_selected = { color: '#eb5d7b', margin: '-5px 0px 5px', transition: '.05s', backgroundColor: 'white', fontSize: a_fs, lineHeight: FS_LH_MAP[a_fs], outline: 'none', border: 'none', justifyContent: 'flex-start', display: 'flex', textAlign: 'left' }
const subtitle_css_unselected = { color: '#d4d4d4', margin: '-5px 0px 5px', transition: '.05s', backgroundColor: 'white', fontSize: b_fs, lineHeight: FS_LH_MAP[b_fs], outline: 'none', border: 'none', justifyContent: 'flex-start', display: 'flex', textAlign: 'left' }

function Faq(props) {
    const { accttype } = props
    const device = Checkdevice()
    const outerlayer_padding = device === 'WEB' ? '90px 80px 80px' : device === 'TABLET' ? '50px 16px 16px' : device === 'MOBILE' ? '' : ''
    const outerlayer_width = device === 'WEB' ? '' : device === 'TABLET' ? '' : device === 'MOBILE' ? '100%' : ''

    const [firsttime, setFirsttime] = useState(true)
    const [currsubtitle, setCurrsubtitle] = useState('')
    const [selected_faq_map, setSelected_faq_map] = useState({ 'VENDOR': [], 'USER': [] })

    useEffect(() => {
        if (firsttime) {
            setFirsttime(false)
            try {
                let options = {
                    method: 'GET',
                    url: READ_FAQ,
                }
                axios(options).then((item) => {
                    const { faqs } = item.data
                    var curr_selectfaqmap = { 'VENDOR': [], 'USER': [] }
                    for (let i = 0; i < faqs.length; i++) {
                        const { title } = faqs[i]
                        curr_selectfaqmap[title].push(faqs[i])
                    }
                    setSelected_faq_map(curr_selectfaqmap)
                    setCurrsubtitle(curr_selectfaqmap && curr_selectfaqmap.length > 0 ? curr_selectfaqmap[0].subtitle : null) // subtitle of the first index
                }).catch((e) => { })
            }
            catch (e) { setFirsttime(false) }
        }
    }, [firsttime])

    const showcontent = (title, subtitle, content) => {
        setCurrsubtitle(subtitle)
    }

    const loginredirect = <div>Please Login To View</div>

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', color: '#707070', touchAction: 'manipulation' }}>
            <Headtitle name={'FAQ'} />
            <div className="container" style={{ padding: outerlayer_padding, width: outerlayer_width, margin: '0 auto', display: 'flex', flexDirection: 'column', }}>
                {selected_faq_map && selected_faq_map[selected].map((item, index) => { // whocanview : 0 - vendor, 1 - user, 2 - all 
                    const { title, subtitle, content, whocanview } = item
                    console.log(item)
                    return content === ''
                        ? <div key={subtitle} style={{ displsy: 'flex', flexDirection: 'column', width: '100%', padding: '10px 0px', borderTop: 'thin solid rgb(212, 212, 212)' }} />
                        : <div key={subtitle} style={{ displsy: 'flex', flexDirection: 'column', width: '100%', padding: '10px 0px' }}>
                            <button style={currsubtitle === subtitle ? subtitle_css_selected : subtitle_css_unselected} onClick={() => showcontent(title, subtitle, content)}>
                                {(index + 1) + ' ' + subtitle}
                            </button>
                            {subtitle === currsubtitle
                                ? <div style={{ display: 'flex', fontSize: device === 'WEB' ? 20 : 15, padding: 10, whiteSpace: 'break-spaces' }}>
                                    {TITLE_MAP[accttype] === whocanview.toString()  || whocanview.toString() === '2' ? content : loginredirect}
                                </div>
                                : null}
                        </div>
                })}
            </div>
        </div>
    )
}

export default Faq