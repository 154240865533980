import React, { useState, useEffect } from 'react'; // { useState, useEffect, } 
import { Checkdevice, Loading } from '../../components';
import './Pricingmodel.css'

const donationfeatures = ['Listed in Weavebliss', 'Listed in multiple countries', 'Listed in Wedding & Event category', 'Access to analytics', 'Access to projects management', 'Access to pricelists management', 'Realtime chat system', 'Priority in buying advertising space', 'We will send you email of appreciation']
const freefeatures = ['Listed in Weavebliss', 'Listed in multiple countries', 'Listed in Wedding & Event category', 'Access to analytics', 'Access to projects management', 'Access to pricelists management', 'Realtime chat system']

function Pricingmodel() {

    const [firsttime, setFirsttime] = useState(true)
    const device = Checkdevice()
    useEffect(() => {
        setTimeout(() => {
            setFirsttime(false)
        }, 500)
    }, [firsttime])

    function pricingcard_dom(title, subtitle, amount, symbol, features, redirectbtn, bgcolor, border) {
        return <div className="pricingmodel_card" style={{ backgroundColor: bgcolor, border: border }}>
            <div className="pricingmodel_cardroot">
                <h3 className="pricingmodel_cardtitle">{title}</h3>
                {subtitle !== 'EMPTY' ? <div className="pricingmodel_cardsubtitle">{subtitle}</div> : <div className="pricingmodel_cardsubtitleempty">{subtitle}</div>}
                <div className="pricingmodel_cardamtfeaturesroot">
                    <div className="pricingmodel_dollarmoneyroot">
                        <div className="pricingmodel_dollarsign">{'$'}</div>
                        <div className="pricingmodel_dollaramt">{amount}</div>
                        <div className="pricingmodel_symbol">{symbol}</div>
                    </div>
                    {features.map((item) => <div className="pricingmodel_carditem" key={item + title} >{item}</div>)}
                </div>
            </div>
            <div className="pricingmodel_redirectbtn">{redirectbtn}</div>
        </div>
    }

    return firsttime ?
        <Loading />
        : <div className="pricingmodel_root">
            <div className='pricingmodel_bgimg' />
            <div className="pricingmodel_herotitle">
                <div className={"pricingmodel_heropricingtitle" + device}>Pricing</div>
            </div>
            <div className={'pricingmodel_subtitle'+device}>
                <div>The price you see on our website is the true price of your purchase. We do not have any hidden fees or additional surcharges.</div>
            </div>
            <div className="pricingmodel_subroot" >
                {pricingcard_dom('Free', 'EMPTY', '0', '', freefeatures, null, 'white', 'thin solid black')}
                {pricingcard_dom('Subscription', 'Available on mid 2021', '30', ' / mth', donationfeatures, null, '#fff3da', 'thin solid black')} {/*#fffbf3   <div style={{ display: 'flex', padding: 10, margin: 10 }}>{PAYPALDONATE}</div>*/}
            </div>
        </div>
}

export default Pricingmodel