import React, { Component } from 'react'

const DEFAULT_BG_COLOR = 'white'
const DEFAULT_FONT_COLOR = '#B5B5B5'
const SELECTED_BG_COLOR = 'rgb(239, 239, 239)'
const SELECTED_FONT_COLOR = 'rgb(85, 85, 85)'


// this only render a single togglebadge
class TogglebadgeBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colorstyle: []
        }
    }

    UNSAFE_componentWillMount() {
        const { baritems, selectedBadge } = this.props //         const { baritems, selectedBadge, onButtonClick } = this.props
        //const { bgcolor } = this.state //  const { bgcolor } = this.state
        var new_colorstyle = []
        for (let i = 0; i < baritems.length; i++) {
            if (i !== selectedBadge) {
                var localStyle1 = {
                    backgroundColor: DEFAULT_BG_COLOR,
                    color: DEFAULT_FONT_COLOR
                }
                new_colorstyle.push(localStyle1) // push default color
            }
            else { // is selected badge
                var localStyle2 = {
                    backgroundColor: SELECTED_BG_COLOR,
                    color: SELECTED_FONT_COLOR
                }
                new_colorstyle.push(localStyle2) // push default color
            }
        }
        this.setState({
            colorstyle: new_colorstyle,
        })
    }

    render() {
        
        
        const { baritems, onButtonClick, type='AT_LEAST_ONE' } = this.props

        var atLeastOne = () => {
            var result = []
            for (let i = 0; i < baritems.length; i++) {
                var badge = baritems[i]
                var index = i
                result.push(
                    <button
                        key={badge}
                        style={{ ...styles.badge, ...this.state.colorstyle[index] }}
                        onClick={(item) => {
                            var selectedindex = i
                            var new_colorstyle = this.state.colorstyle
                            for (let x = 0; x < this.state.colorstyle.length; x++) {
                                if (x === selectedindex) {
                                    new_colorstyle[x] = {
                                        backgroundColor: SELECTED_BG_COLOR,
                                        color: SELECTED_FONT_COLOR
                                    }
                                }
                                else {
                                    new_colorstyle[x] = {
                                        backgroundColor: DEFAULT_BG_COLOR,
                                        color: DEFAULT_FONT_COLOR
                                    }
                                }
                            }
                            this.setState({
                                colorstyle: new_colorstyle
                            }, () => {
                                onButtonClick(selectedindex)
                            })
                        }}>
                        {badge}
                    </button>
                )
            }
            return result
        }

        var canBeEmpty = ()=>{
            var result = []
            for (let i = 0; i < baritems.length; i++) {
                var badge = baritems[i]
                var index = i
                result.push(
                    <button
                        key={badge}
                        style={{ ...styles.badge, ...this.state.colorstyle[index] }}
                        onClick={(item) => {
                            var selectedindex = i
                            var new_colorstyle = this.state.colorstyle
                            for (let x = 0; x < this.state.colorstyle.length; x++) {
                               
                                if ( new_colorstyle[x].color===SELECTED_FONT_COLOR && new_colorstyle[x].backgroundColor===SELECTED_BG_COLOR) {
                                    new_colorstyle[x] = {
                                        backgroundColor: DEFAULT_BG_COLOR,
                                        color: DEFAULT_FONT_COLOR
                                    }
                                }
                                else if(  x === selectedindex ){
                                    new_colorstyle[x] = {
                                        backgroundColor: SELECTED_BG_COLOR,
                                        color: SELECTED_FONT_COLOR
                                    }
                                }
                                else {
                                    new_colorstyle[x] = {
                                        backgroundColor: DEFAULT_BG_COLOR,
                                        color: DEFAULT_FONT_COLOR
                                    }
                                }
                            }
                            this.setState({
                                colorstyle: new_colorstyle
                            }, () => {
                                onButtonClick(selectedindex)
                            })
                        }}>
                        {badge}
                    </button>
                )
            }
            return result
        }

        return (
            <div style={styles.root}>
                {type==='CAN_BE_EMPTY'? canBeEmpty():atLeastOne()  }
            </div>
        )
    }


}

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap:'wrap',
    },
    badge: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        border: 'thin solid rgb(217, 216, 216)',
        padding:10,
        margin:5,
        outline: 'none',
        fontWeight: 'bold',
        height: 40,
        whiteSpace: 'nowrap',
    }
}

export default TogglebadgeBar

