import React, { useState, useEffect } from 'react'
import { FormControl, FormGroup, } from "react-bootstrap"
import { EMPTY, EMPTYSPACE, FORM_SECOND } from '../../../../config/constants'
import { BUTTONSTYLE } from './../../../../config/cssconstants'
import { VENDOR_UPDATEQNS } from '../../../../config/api'
import axios from 'axios'
const MAINTITLE_CSS = { fontSize: 'large', fontWeight: 'bold', display: 'flex', flexDirection: 'row', padding: 5, boxShadow: 'rgba(25, 25, 25, 0.25) 1px 2px 7px', width: 'max-content', marginBottom: 20, border: 'thin solid #707070' }

function Vendortypeqns(props) {

    const [qnsans, setQnsans] = useState([])
    const [msg, setMsg] = useState(EMPTY)

    useEffect(() => {
        setQnsans(props.qnsans)
    }, [props])

    const reduce = () => {
        var currqns = qnsans.slice()
        currqns.splice(currqns.length - 1, 1)
        setQnsans(currqns)
    }

    const add = () => {
        var currqns = qnsans.slice()
        currqns.push({ 'qn': '', 'ans': '' })
        setQnsans(currqns)
    }

    const update = () => {
        const { vt_id, svt_id } = props
        console.log(vt_id)
        try {
            let options = {
                method: 'POST',
                url: VENDOR_UPDATEQNS,
                headers: { 'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json', }, //need token from the web
                data: { qnsans: qnsans, vt_id: vt_id, svt_id: svt_id }
            }
            axios(options).then((response) => {
                const { data } = response
                if (data.message === 'SUCCESS') {
                    setMsg('Successfully Updated')
                    setTimeout(() => { setMsg(EMPTY) }, FORM_SECOND)
                }
                else {

                }
            })
        }
        catch (e) {

        }
    }
    return <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <div style={MAINTITLE_CSS}>{'Questions & Answers'}</div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button style={{ ...BUTTONSTYLE, borderRadius: 5, width: 30, height: 30, padding: 0, alignItems: 'center' }} onClick={reduce}>-</button>
            {EMPTYSPACE}
            <button style={{ ...BUTTONSTYLE, borderRadius: 5, width: 30, height: 30, padding: 0, alignItems: 'center' }} onClick={add}>+</button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {qnsans.map((item, i) => {
                return <div key={'qnsans' + i} style={{ display: 'flex', flexDirection: 'column', padding: '10px 0px 0px 0px' }}>
                    <FormGroup controlId={'QNA' + i} >
                        <FormControl
                            type={i + 'qn'}
                            value={item['qn']}
                            placeholder={'Question'}
                            style={{ width: '100%', marginBottom: 5 }}
                            onChange={(e) => {
                                var currqnasans = qnsans.slice()
                                currqnasans[i]['qn'] = e.target.value
                                setQnsans(currqnasans)
                            }}
                        />
                        <textarea
                            rows="4"
                            cols="50"
                            name="comment"
                            form="usrform"
                            style={{ display: 'block', outlineColor: '#69b1e9', padding: '6px 12px', lineHeight: '1.42857143', color: '#555', backgroundColor: '#fff', backgroundImage: 'none', width: '100%', transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s', boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)', borderRadius: 4, border: '1px solid #ccc' }}
                            onChange={(e) => {
                                if (e.target.value.length < 1000) {
                                    var currqnasans = qnsans.slice()
                                    currqnasans[i]['ans'] = e.target.value
                                    setQnsans(currqnasans)
                                }
                            }}
                            value={item['ans']}
                        />
                    </FormGroup>
                </div>
            })}

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignContent: 'center', textAlign: 'center' }}>
                {msg}
            </div>

            <div style={{ width: '100%', minHeight: 40, marginBottom: 5 }}>
                <button style={{ float: 'right', ...BUTTONSTYLE }} onClick={update} >Update</button>
            </div>

        </div>
    </div>
}


export default Vendortypeqns