import React from 'react'
import { Link } from 'react-router-dom'
import { FACEBOOK_ICON, INSTAGRAM_ICON } from '../../config/constants'
import { Checkdevice } from '../Checkdevice'
import './Footer.css'
const sg_icon = "https://cereweavecloud.s3-ap-southeast-1.amazonaws.com/assets/footer/sg.png"
function Footer() {
    const device = Checkdevice()
    return <div className={"Footer_root" + device}>
        <div className={"Footer_subroot" + device} style={{ width: device==='MOBILE'?'80%':'50%' }}>
            <div className="Footer_title">Cereweave</div>
            <div className="Footer_content">
                <div className="Footer_item">{'Plan and organise your perfect Wedding & Event'}</div>
                <div className="Footer_divider"/>
                <img src={sg_icon} width="100px" alt=""/>
            </div>
        </div>
        <div className={"Footer_subroot" + device}>
            <div className="Footer_title">About</div>
            <div className="Footer_content">
                <Link className="Footer_item" to="/faq">FAQ</Link>
                <Link className="Footer_item" to="/blog">Blog</Link>
            </div>
        </div>
        <div className={"Footer_subroot" + device}>
            <div className="Footer_title">Social Media</div>
            <div className="Footer_content">
                <a className="Footer_iconrow" href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                    <div className="Footer_icon" >{INSTAGRAM_ICON}</div>
                    <div className="Footer_item">Instagram</div>
                </a>
                <a className="Footer_iconrow" href="https://www.facebook.com/cereweaveweddingevent/" target="_blank" rel="noopener noreferrer">
                    <div className="Footer_icon" >{FACEBOOK_ICON}</div>
                    <div className="Footer_item">Facebook</div>
                </a>
            </div>
        </div>
        <div className={"Footer_subroot" + device} style={{fontWeight:'bold'}}>
            v1.3
        </div>

    </div>
}

export default Footer