import React, { useState } from 'react'
import { EMPTY } from '../../config/constants'//BOTTOM_BAR_HEIGHT, NAV_BAR_HEIGHT,
import { Link } from 'react-router-dom'
import { VENDOR_CREATE } from '../../config/api'
import { Textfield, Vregsignupmain } from '../../components'
import axios from 'axios'
import './VendorSignup.css'

const MESSAGE_USER_REGISTER_SUCCESSFUL = 'You have successfully registered and logged in.'
const MESSAGE_USER_REGISTER_UNSUCCESSFUL = 'Email already being used.'
function Verify_Email_Syntax(privateemail) {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;  // eslint-disable-line
    return re.test(String(privateemail).toLowerCase())
}
function VendorSignup(props) {
    const [verificationcode, setVerificationcode] = useState(EMPTY)
    const [privateemail, setPrivateemail] = useState(EMPTY)
    const [password1, setPassword1] = useState(EMPTY)
    const [password2, setPassword2] = useState(EMPTY)
    const [username, setUsername] = useState(EMPTY)
    const [signupclicked, setSignupclicked] = useState(0)
    const [error, setError] = useState(EMPTY)
    const pw_register = (e) => {
        setSignupclicked(signupclicked + 1)
        setError(EMPTY)
        if (signupclicked < 5) { // click more than 10 times, return to home page

            if (privateemail === '' || password1 === '' || password2 === '' || username === '') setError('Please fill in all fields')
            else if (!Verify_Email_Syntax(privateemail)) setError('Incorrect Email')
            else {
                var provider = "userpw"

                if (password1 !== password2) setError('Password is wrong.')

                let options = {
                    method: 'POST',
                    url: VENDOR_CREATE,
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
                    data: { provider, username, password1, password2, privateemail, verificationcode }
                }
                axios(options).then((result) => {
                    const { data, status } = result
                    const { error, token, user_id, message } = data

                    if (error === false && status === 200) {
                        const bool_success = true
                        const curr_token = token
                        const curr_id = user_id
                        updateLocal(bool_success, curr_token, curr_id, true, 'USER');
                        setError(MESSAGE_USER_REGISTER_SUCCESSFUL)
                    }
                    else {
                        if (message === 'ERROR_CREATION') {
                            setError('Failed to create account.')
                            this.setState({ error: 'Failed to create account.' })
                        }
                        else if (message === 'NAME_USED') {
                            setError('Username has already been taken.')
                        }
                        else if (message === 'UNAME_LEN') {
                            setError('Username character max limit : 30, min limit: 5')
                        }
                        else if (message === 'SPECIAL_CHAR') {
                            setError('Username cannot contain unique character & space')
                        }
                        else {
                            setError(MESSAGE_USER_REGISTER_UNSUCCESSFUL)
                        }
                    }
                }).catch((e) => { setError('Verification code is wrong.') })
            }
            return

        }
        else props.history.push('/')
    }
    const updateLocal = (bool_success, curr_token, curr_id) => {
        try {
            if (bool_success) {
                localStorage.token = curr_token
                setPassword1(EMPTY)
                setPassword2(EMPTY)
                setPrivateemail(EMPTY)
                setUsername(EMPTY)
                setVerificationcode(EMPTY)
                window.location = '/'
            }

        }
        catch (e) { }
    }

    return <div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', touchAction: 'manipulation', justifyContent: 'center', minHeight: '90vh' }}>
            <div style={{ backgroundColor: '#fff', width: '100%', maxWidth: 500, flexDirection: 'column', alignItems: 'center', textAlign: 'center', borderRadius: '0 0 10px', display: 'flex', justifyContent: 'center', alignContent: 'center', }}>
                {Vregsignupmain('Business account signup')}{/*200*/}
                <div style={{ height: 350, width: 300 }}>
                    <div style={{ width: 300 }}>
                        <Textfield id={'registration'} label={'Invitation Code'} val={verificationcode} type={'text'} onchange={(e) => { setVerificationcode(e.target.value); setError(EMPTY); }} />
                        <Textfield id={'username'} label={'Username / Login id'} val={username} type={'text'} onchange={(e) => { setUsername(e.target.value); setError(EMPTY); }} />
                        <Textfield id={'password1_login'} label={'Password'} val={password1} type={'password'} onchange={(e) => { setPassword1(e.target.value); setError(EMPTY); }} />
                        <Textfield id={'password2_login'} label={'Password'} val={password2} type={'password'} onchange={(e) => { setPassword2(e.target.value); setError(EMPTY); }} />
                        <Textfield id={'email_login'} label={'Email'} val={privateemail} type={'text'} onchange={(e) => { setPrivateemail(e.target.value); setError(EMPTY); }} />
                    </div>
                    <div style={{ width: 300, height: 40, marginTop: 8, }}>
                        {error.length > 0
                            ? <div>{error}</div>
                            : <button className='Vendorsignup_signupbtn'onClick={pw_register}>Sign up</button>}
                    </div>
                </div>

                <div style={{ height: 50, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                    <div>Already have a vendor account? </div>
                    <Link to="/business/login" style={{ color: '#3897f0' }} >
                        <div style={{ paddingLeft: 5 }}>Login</div>
                    </Link>
                </div>

            </div>
        </div>
    </div>
}

export default VendorSignup