import React from 'react'
import { cancel} from '../../assets/notfound'
import { EMPTYSPACE } from '../../config/constants'
import { Checkdevice } from '../../components/Checkdevice'

function Notfound(){
    const device = Checkdevice()
    const outerlayer_padding = device === 'WEB' ? '90px 80px 80px' : device === 'TABLET' ? '50px 16px 16px' : '50px 16px 16px'
    const card_impt = device === 'WEB' ?
        { width: 1000, height: 500, padding: 10, borderRadius: 5, backgroundColor: 'rgba(251, 225, 229, 0.52)' }
        :
        device === 'TABLET' ?
            { width: '100%', height: 400, backgroundColor: 'rgba(251, 225, 229, 0.52)' }
            :
            { width: 320, height: 190, borderRadius: 5, backgroundColor: 'rgba(251, 225, 229, 0.52)' }


    return (
        <div className="container" style={{padding:outerlayer_padding,display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', touchAction:'manipulation' }}>
            <h3 style={{display:'flex', justifyContent:'center', alignContent:'center', color:'#707070'}}>Page Not Found</h3>
            <object style={{ ...card_impt, padding: 10, borderRadius: 5 }} id={Math.random()} data={cancel} type="image/svg+xml">{EMPTYSPACE}</object>
        </div>
    )
}

export default Notfound