import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
// import resgisterServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker';
import Store from './redux/store'
let storeInstance = Store()
// storeInstance.subscribe(()=>{console.log('State upadted!', storeInstance.getState() )})
ReactDOM.render(
    <Provider store={storeInstance}>
        <App />
    </Provider>, document.getElementById('root')
);
unregister();
// resgisterServiceWorker()

