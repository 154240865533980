
import React, { useState } from 'react'
import { Link, } from "react-router-dom";
import { IMG_ALT, READABLE_VENDORTYPE_MAP, BOXSHADOW_CARD } from '../../config/constants';
import Jfmodal from '../Jfmodal/Jfmodal';
import { getS3fullpath_withbucketname } from '../../config/commonfunctions';
import './Projectcard.css'

const mouseleave = ''

function Projectcard(props) {

    const { item, device, viewtype } = props
    const { selectedimg_pattern, _id, collaborator_names } = item

    const [showCollaborators, setShowCollaborators] = useState(false)
    const [hover, setHover] = useState(mouseleave)
    var pattern_width = 0
    var pattern_height = 0
    var pattern_separator_width = 0
    var packed_dim_obj = {}

    if (selectedimg_pattern === 0) {

        if (device === 'WEB') {
            pattern_width = 220
            pattern_height = 330
            pattern_separator_width = pattern_width * 0.87
            packed_dim_obj = {
                width: pattern_width,
                height: (pattern_height - 100) * 0.7,
                smallwidth: pattern_width / 2,
                smallheight: (pattern_height - 100) * 0.3,
                nameheight: 50,
            }
        }
        else if (device === 'MOBILE') {
            pattern_width = 170
            pattern_height = 275
            pattern_separator_width = pattern_width * 0.87
            packed_dim_obj = {
                width: pattern_width,
                height: (pattern_height - 100) * 0.7,
                smallwidth: pattern_width / 2,
                smallheight: (pattern_height - 100) * 0.3,
                nameheight: 50,
            }
        }
        else if (device === 'TABLET') {
            pattern_width = 220
            pattern_height = 330
            pattern_separator_width = pattern_width * 0.87
            packed_dim_obj = {
                width: pattern_width,
                height: (pattern_height - 100) * 0.7,
                smallwidth: pattern_width / 2,
                smallheight: (pattern_height - 100) * 0.3,
                nameheight: 50,
            }
        }

    }
    else if (selectedimg_pattern === 1) {
        if (device === 'WEB') {
            pattern_width = 220
            pattern_height = 330
            pattern_separator_width = pattern_width * 0.87
            packed_dim_obj = {
                width: pattern_width,
                height: pattern_height - 100,
                leftwidth: pattern_width * 0.7,
                rightwidth: pattern_width * 0.3,
                rightheight: (pattern_height - 100) / 2,
                nameheight: 50
            }
        }
        else if (device === 'MOBILE') {
            pattern_width = 170
            pattern_height = 275
            pattern_separator_width = pattern_width * 0.87
            packed_dim_obj = {
                width: pattern_width,
                height: pattern_height - 100,
                leftwidth: pattern_width * 0.7,
                rightwidth: pattern_width * 0.3,
                rightheight: (pattern_height - 100) / 2,
                nameheight: 50
            }
        }
        else if (device === 'TABLET') {
            pattern_width = 220
            pattern_height = 330
            pattern_separator_width = pattern_width * 0.87
            packed_dim_obj = {
                width: pattern_width,
                height: pattern_height - 100,
                leftwidth: pattern_width * 0.7,
                rightwidth: pattern_width * 0.3,
                rightheight: (pattern_height - 100) / 2,
                nameheight: 50
            }
        }
    }
    else {
        if (device === 'WEB') {
            pattern_width = 220
            pattern_height = 330
            pattern_separator_width = pattern_width * 0.87
            packed_dim_obj = {
                width: pattern_width,
                height: pattern_height - 100,
                nameheight: 50
            }
        }
        else if (device === 'MOBILE') {
            pattern_width = 170
            pattern_height = 275
            pattern_separator_width = pattern_width * 0.87
            packed_dim_obj = {
                width: pattern_width,
                height: pattern_height - 100,
                leftwidth: pattern_width * 0.7,
                rightwidth: pattern_width * 0.3,
                rightheight: (pattern_height - 100) / 2,
                nameheight: 50
            }
        }
        else if (device === 'TABLET') {
            pattern_width = 220
            pattern_height = 330
            pattern_separator_width = pattern_width * 0.87
            packed_dim_obj = {
                width: pattern_width,
                height: pattern_height - 100,
                leftwidth: pattern_width * 0.7,
                rightwidth: pattern_width * 0.3,
                rightheight: (pattern_height - 100) / 2,
                nameheight: 50
            }
        }
    }

    const RenderModalCollaborators = () => {
        const { item } = props
        const { collaborator_profileimg_ids, collaborator_names, collaborator_displaynames, collaborator_vendortypes, } = item
        try {
            var display_result = []
            if (collaborator_names) {
                for (let i = 0; i < collaborator_profileimg_ids.length; i++) {
                    const { link } = collaborator_profileimg_ids[i]
                    display_result.push(
                        <Link key={link + i} to={'/vendorlookup/' + collaborator_names[i]} target="_blank" style={{ textDecoration: 'none' }}>
                            <div className="projcard_collab">
                                <img className="projcard_collabimg" alt={IMG_ALT} src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', link)} />
                                <div className="projcard_collabrow" >
                                    <div className="projcard_collabdn" >{collaborator_displaynames[i]}</div>
                                    <div className="projcard_collabvt">{READABLE_VENDORTYPE_MAP[collaborator_vendortypes[i]]}</div>
                                </div>
                            </div>
                            {(i < collaborator_profileimg_ids.length - 1)
                                ? <div style={{ height: 1, borderTop: 'thin solid #f4f4f4' }} />
                                : null
                            }
                        </Link>

                    )
                }
            }
            return display_result

        }
        catch (e) {
            return null
        }
    }

    const NameFuncDom = (n, w, h) => {
        // return <div className="projcard_name" style={{ height: h, width: w }}>{n.length > 15 ? n.slice(0, 15) + '...' : n}</div>
        return <div className="projcard_name" style={{ height: h, width: w, overflowWrap: 'break-word', overflowY: 'hidden', fontSize: 13 }}>{n.length > 45 ? n.slice(0, 45) + '...' : n}</div>
    }

    const RenderUserCardContent = (packed_dim_obj) => {
        try {
            const { item } = props // device
            const { selectedimg_pattern, project_id, selectedimg_extlinks, name } = item
            const { width, nameheight } = packed_dim_obj

            const namedom = NameFuncDom(name, width, nameheight)

            if (selectedimg_pattern === 0) {
                const { width, height, smallwidth, smallheight } = packed_dim_obj
                return (
                    <Link to={'/projectlookup/' + project_id} target="_blank" style={{ textDecoration: 'none', overflow: 'hidden' }}>
                        <div className="projcard_00" style={{ height: height, width: width, backgroundImage: 'url("' + getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[0])  + '")', borderBottom: '1px solid white' }} />
                        <div className="projcard_01" style={{ display: 'flex', flexDirection: 'row', }} >
                            <div className="projcard_02" style={{ height: smallheight, width: smallwidth, backgroundImage: 'url("' + getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[1]) + '")', borderRight: '1px solid white' }} />
                            <div className="projcard_03" style={{ height: smallheight, width: smallwidth, backgroundImage: 'url("' + getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[2]) + '")' }} />
                        </div>
                        {namedom}
                    </Link>
                )
            }
            else if (selectedimg_pattern === 1) {
                const { height, leftwidth, rightwidth, rightheight } = packed_dim_obj
                return (
                    <Link to={'/projectlookup/' + project_id} target="_blank" style={{ textDecoration: 'none', overflow: 'hidden' }}>
                        <div className="projcard_10" style={{ height: height, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div className="projcard11" style={{ height: height, width: leftwidth, backgroundImage: 'url("' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[0]) + '")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', borderRight: '1px solid white' }} />
                            <div className="projcard12" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div className="projcard13" style={{ height: rightheight, width: rightwidth, backgroundImage: 'url("' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[1]) + '")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', borderBottom: '1px solid white' }} />
                                <div className="projcard14" style={{ height: rightheight, width: rightwidth, backgroundImage: 'url("' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[2]) + '")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} />
                            </div>
                        </div>
                        {namedom}
                    </Link>
                )
            }
            if (selectedimg_pattern === 2) {
                const { width, height } = packed_dim_obj
                return (
                    <Link to={'/projectlookup/' + project_id} target="_blank" style={{ textDecoration: 'none', overflow: 'hidden' }}>
                        <div className="projcard_20" style={{ height: height, width: width, }}>
                            <div className="projcard_21" style={{ backgroundImage: 'url(' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[0])  + ')', backgroundPosition: 'center', backgroundSize: 'cover', height: height, width: width, }} />
                        </div>
                        {namedom}
                    </Link>
                )
            }
        }
        catch (e) { }
    }

    const RenderEditableCardContent = (packed_dim_obj) => {
        try {
            const { item, onClick_0, onDelete_0 } = props // device
            const { selectedimg_pattern, selectedimg_extlinks, name } = item
            const { width, nameheight } = packed_dim_obj

            const deletedom = <div className="projcard_1del" onClick={onDelete_0}>X</div>
            const namedom = NameFuncDom(name, width, nameheight)

            if (selectedimg_pattern === 0) {
                const { width, height, smallwidth, smallheight } = packed_dim_obj
                return (
                    <div >
                        {deletedom}
                        <div className="projcard_00" style={{ height: height, width: width, backgroundImage: 'url("' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[0]) + '")' }} onClick={onClick_0} />
                        <div className="projcard_01" onClick={onClick_0}>
                            <div className="projcard_02" style={{ height: smallheight, width: smallwidth, backgroundImage: 'url("' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[1]) + '")' }} />
                            <div className="projcard_03" style={{ height: smallheight, width: smallwidth, backgroundImage: 'url("' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[2]) + '")' }} />
                        </div>
                        {namedom}
                    </div>
                )
            }
            else if (selectedimg_pattern === 1) {
                const { width, height, leftwidth, rightwidth, rightheight } = packed_dim_obj
                return (
                    <div>
                        {deletedom}
                        <div className="projcard_10" onClick={onClick_0} style={{ height: height, width: width, }}>
                            <div className="projcard_11" style={{ height: height, }}>
                                <div className="projcard_12" style={{ height: height, width: leftwidth, backgroundImage: 'url("' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[0]) + '")' }} />
                                <div className="projcard_13" >
                                    <div className="projcard_14" style={{ height: rightheight, width: rightwidth, backgroundImage: 'url("' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[1]) + '")' }} />
                                    <div className="projcard_15" style={{ height: rightheight, width: rightwidth, backgroundImage: 'url("' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[2]) + '")' }} />
                                </div>
                            </div>
                        </div>
                        {namedom}
                    </div>
                )
            }
            if (selectedimg_pattern === 2) {
                const { width, height } = packed_dim_obj
                return (
                    <div >
                        {deletedom}
                        <div className="projcard_20" onClick={onClick_0} style={{ height: height, width: width, }}>
                            <div className="projcard_21" style={{ backgroundImage: 'url(' +  getS3fullpath_withbucketname('VENDOR_PROJECT_PORTFOLIO_IMG',selectedimg_extlinks[0]) + ')', height: height, width: width, }} />
                        </div>
                        {namedom}
                    </div>
                )
            }
        }
        catch (e) { }
    }

    const RenderCollabbtn = () => {// exist collaborators
        return <button className="projcard_collabroot" onClick={() => { setShowCollaborators(true) }}>
            <font className="projcard_collabfixedtext">Collaborators</font>
            <div className="projcard_arrowdown" />
        </button>
    }

    const RenderName = (item, viewtype) => {
        const { username, creator_profileimg_id, displayname, vendortype } = item
        try {
            const displayname_dom = <font style={{ whiteSpace: 'nowrap', overflow: 'hidden', fontWeight: 'bold', color: "#707070" }}>{displayname && displayname.length > 25 ? displayname.slice(0, 25) + '...' : displayname}</font>
            const vt_dom = <font style={{ color: '#707070' }}>{READABLE_VENDORTYPE_MAP[vendortype]}</font>
            if (viewtype === 0) {
                const { profileimg_0 } = props
                // src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_0) }
                return <Link to={'/vendorlookup/' + username} style={{ width: '100%', color: '#707070', overflow: 'hidden', textDecoration: 'none' }} target="_blank">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 45, width: '100%' }}>
                        <img alt={IMG_ALT}  src={profileimg_0}style={{ width: 32, height: 32, minWidth: 32, minHeight: 32, borderRadius: 25, margin: 0, border: 'thin solid #7070703d' }} />
                        <div style={{ margin: '0px 5px', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>{displayname_dom}{vt_dom}</div>
                    </div>
                </Link>
            }
            else {
                return <Link to={'/vendorlookup/' + username} style={{ width: '100%', color: '#707070', overflow: 'hidden', textDecoration: 'none' }} target="_blank">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 45, width: '100%' }}>
                        <img alt={IMG_ALT} src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', creator_profileimg_id.link) }  style={{ width: 32, height: 32, minWidth: 32, minHeight: 32, borderRadius: 25, margin: 0, border: 'thin solid #7070703d' }} />
                        <div style={{ margin: '0px 5px', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>{displayname_dom}{vt_dom}</div>
                    </div>
                </Link>
            }
        }
        catch (e) { }
    }

    const RenderModal = ({ name }) => {
        return <Jfmodal
            show={showCollaborators}
            title={name}
            onHide={() => setShowCollaborators(false)}
            device={device === 'MOBILE' ? device : 'WEB'}
            content={
                <div style={{ color: 'rgb(112, 112, 112)', padding: '0px 5px', height: 'max-content' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {RenderModalCollaborators()}
                    </div>
                </div>
            }
        />
    }

    return (
        <div
            key={_id}
            className="projcard_root"
            onMouseOver={() => { if (device !== 'TABLET') setHover(BOXSHADOW_CARD) }}
            onMouseLeave={() => { if (device !== 'TABLET') setHover(mouseleave) }}
            style={{ height: pattern_height, width: pattern_width, boxShadow: hover, margin:  '7px 5px' }}
        >
            {viewtype === 0 ? RenderEditableCardContent(packed_dim_obj) : RenderUserCardContent(packed_dim_obj)}
            <div style={{ width: pattern_separator_width, alignSelf: 'center', height: 1, borderTop: 'thin solid #f4f4f4' }} />
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontSize: 12, height: 49, maxHeight: 49, padding: collaborator_names ? '0px 10px' : '0px 10px 0px 5px', alignItems: 'center' }}>
                {collaborator_names ? RenderCollabbtn() : RenderName(item, viewtype)}
                {RenderModal(item)}
            </div>
        </div>
    )

}

export default Projectcard