import React, { Component } from 'react'

const DEFAULT_BG_COLOR = 'white'
const DEFAULT_FONT_COLOR = 'rgb(112, 112, 112)'
const SELECTED_BG_COLOR = 'rgb(239, 239, 239)'

const styles = {
    rootbadge: {
        padding: 1,
        outline: 'none',
        backgroundColor: 'transparent',
        border: 'solid thin transparent',
    },
    badge: {
        margin: '2px 0 5px 5px',
        border:'thin solid rgb(217, 216, 216)',
        padding: '5px 15px',
        color: 'rgb(112, 112, 112)',
        borderRadius: 5,
    },

    test:{
        margin: '2px 0 5px 5px',
        border: 'thin solid rgb(112, 112, 112)',
        padding: '5px 15px',
        color: 'rgb(112, 112, 112)',
        borderRadius: 5,
    }
}

// this only render a single togglebadge
class Togglebadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bgcolor: DEFAULT_BG_COLOR,
            fontcolor: DEFAULT_FONT_COLOR,
        }
    }
    UNSAFE_componentWillMount() {

        const { selected_arr, children } = this.props
        if (selected_arr) {
            for (let i = 0; i < selected_arr.length; i++) {
                if (selected_arr[i] === children) {
                    this.setState({
                        bgcolor: SELECTED_BG_COLOR, fontcolor: DEFAULT_FONT_COLOR
                    })
                }
            }
        }
    }

    render() {
        const { children, width, height, togglebtn_clicked } = this.props
        const { bgcolor, fontcolor } = this.state

        return (
            <button
                style={{ ...styles.rootbadge }}
                onClick={(item) => {
                    if (bgcolor === DEFAULT_BG_COLOR) this.setState({ bgcolor: SELECTED_BG_COLOR, fontcolor: DEFAULT_FONT_COLOR })
                    else this.setState({ bgcolor: DEFAULT_BG_COLOR, fontcolor: DEFAULT_FONT_COLOR })
                    togglebtn_clicked(children)
                }}
            >
                <div style={{ ...styles.badge, backgroundColor: bgcolor, color: fontcolor, width: width, height: height, }}>
                    {children}
                </div>
            </button>
        )
    }


}

export default Togglebadge

