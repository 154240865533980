import React, { PureComponent } from 'react';
import WordCloud from "react-d3-cloud";

const fontSizeMapper = (w) => Math.log2(w.value) * 10
const rotate = (w) => 0

class Wc extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            wt: 0,
            data: []
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data })
        }
        if(this.props.wt!==prevProps.wt){
            this.setState({ wt: this.props.wt })
        }
    }

    render() {
        return <WordCloud key={'wordcloud'} width={this.props.wt} height={750} data={this.props.data} fontSizeMapper={fontSizeMapper} rotate={rotate} padding={2} />
    }
}

export default Wc