import React, { useState, useEffect } from 'react';
import { Retrieve_personal_info } from '../../config/commonfunctions';
import { Notiboard, Vptopbar } from '../../components'
import { VENDOR_LEVELUSER } from '../../config/api';
import { Link } from 'react-router-dom';
import axios from 'axios'
import './VendorLevel.css'

const { token } = Retrieve_personal_info()

function VendorLevel(props) {
    const [firsttime, setFirsttime] = useState(true)
    const [nav, setNav] = useState('level')
    const [loading, setLoading] = useState(true)
    const [email, setEmail] = useState('')
    const [feedbacktext, setFeedbacktext] = useState('')

    useEffect(() => {
        if (firsttime) {
            try {
                setLoading(false)
            }
            catch (e) { setFirsttime(true) }
        }
    }, [firsttime])

    const uplevel = () => {
        setEmail('')
        setFeedbacktext('Updating...')
        axios({
            method: 'POST',
            url: VENDOR_LEVELUSER,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }, // need token from the web
            data: { email }
        }).then((response) => {
            const { status } = response
            if (status === 200) {
                setFeedbacktext('Success leveling up ' + email + '!')
            }
            else{
                setFeedbacktext('Fail to level up ' + email + '!')
            }
        })
    }

    return (
        <div className="container">
            {props.un && props.dn
                ? <Link to={'/vendorlookup/' + props.un} target="_blank" style={{ padding: '20px 0px', marginBottom: 5, fontWeight: 'bold', fontSize: 24, color: '#707070' }}><div style={{ height: 100, display: 'flex', alignItems: 'center' }}> {props.dn}</div></Link>
                : null}
            <Vptopbar {...props} nav={nav} updateNav={(nav) => setNav(nav)} />
            {loading ? null : <div className="Vendorlevel_root">
                <Notiboard text="Vendors can level up users to allow them to enjoy features that are not available to regular users. Leveling up of a user is irreversible. Currently, there is only Level 0 and Level 1. Level cap will be increased in the future." />
                <div className="Vendorlevel_col">
                    <div className="Vendorlevel_row">
                        <input className="Vendorlevel_input" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder={'Email'}/>
                        <button className="Vendorlevel_btn" onClick={uplevel}>Up Level</button>
                    </div>
                    <div className="Vendorlevel_txt">{feedbacktext}</div>
                </div>
            </div>}
        </div>
    )
}
export default VendorLevel