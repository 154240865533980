import React, { Component } from 'react'
import { ControlLabel, FormControl, FormGroup, InputGroup, Radio, HelpBlock, Form } from "react-bootstrap"
import { VENDOR_LOGO_UPDATE, VENDOR_BG_UPDATE, VENDOR_PROFILE_READ, VENDOR_REMOVEPROFILEIMG, VENDOR_REMOVEPROFILEBGIMG, VENDOR_PRIVATEPROFILE_UPDATE, VENDOR_PUBLICPROFILE1_UPDATE, VENDOR_PUBLICPROFILE2_UPDATE, VENDOR_CHANGEPASSWORD_UPDATE, VENDOR_ENABLEDELEGATE } from '../../config/api'
import { Project, Pricelist, Firsttime, Reviews, Share, Partner } from './Categories'
import { EMPTY, EMPTYSPACE, IMPORTANT, FORM_SECOND, FORM_ERROR_STYLE, VENDORPROFILE_COLLECTION_VENDORTYPE_EVENT, VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING, SUCCESS_UPDATE, FAILURE_UPDATE, POPUP_FORM_MOBILE, IMG_ALT, TABLET, PRESENCE_LOCATION, SOFT_GREY, PRESENCELOC_ABBREV_MAP, ABBREV_PRESENCELOC_MAP, ABBREVCITY_CURRENCY_MAP, HTTPSWEBSITE_URL, production, S3_DIRECTORY_NONPRODUCTION, S3_DIRECTORY_PRODUCTION, S3CLOUD, DEFAULT_PROFILE_IMG, DEFAULT_PROFILE_BACKGROUND_IMG, sharegate, TRASH_ICON } from '../../config/constants'
import { BUTTONSTYLE } from '../../config/cssconstants'
import { Retrieve_personal_info, getS3fullpath_withbucketname } from '../../config/commonfunctions'
import { Vendortypeqns, Vendortypespecific } from './components'
import { Validateform } from './components/Validateform/Validateform'
import { Jfmodal, Loading, refresh, Vptopbar } from '../../components';
import axios from 'axios'
import './VendorProfile.css'
import { Imagedownsize } from './components/Imagedownsize/Imagedownsize'

const PRESENCEBTN_CSS = { display: 'flex', padding: 5, margin: 5, width: 'max-content', backgroundColor: 'white', outline: 'none', border: 'thin solid #707070' }
const MAINTITLE_CSS = { fontSize: 'large', fontWeight: 'bold', display: 'flex', flexDirection: 'row', padding: 5, boxShadow: 'rgba(25, 25, 25, 0.25) 1px 2px 7px', width: 'max-content', marginBottom: 20, border: 'thin solid #707070' }
const LOGO = 'logo'

function FieldGroup({ id, label, help, ...props }) {
    return (
        <FormGroup controlId={id}>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
    );
}

function Opendiv(content, clickfunc) {
    return <div className='vp_titlerow' onClick={clickfunc}>
        <div className="vp_content">{content === 'Lookup Info' ? [content, IMPORTANT] : content}</div>
        <div className="vp_content">{'+'}</div>
    </div>
}

function Closediv(content, clickfunc) {
    return (
        <div className="vp_closedivroot" onClick={clickfunc}>
            <div className="vp_content">{content}</div>
            <div className="vp_content">{'-'}</div>
        </div>
    )
}

class VendorProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            screen_width: 0,
            screen_height: 0,

            renderstate: 0, // 0 is loading, 1 is firsttime, 2 is weavebliss vendor 
            nav: '', //show any one of the following : general, project, pricelist, review 
            show_changepwmodal: false,

            vendortype: EMPTY,
            vendortype_errormsg: EMPTY,
            secondaryvendortype: EMPTY,
            secondaryvendortype_errormsg: EMPTY,
            username: EMPTY,

            profileimg_id: EMPTY,

            profileimg: '+',
            file: EMPTY, //profileimg
            profileimg_msg: EMPTY,

            profilebgimg: '+',
            bgfile: EMPTY,
            bgimg_btnstate: true,
            bgimg_msg: EMPTY,

            introduction: EMPTY, //public1
            displayname: EMPTY,
            about: EMPTY,
            description: EMPTY,
            name: { firstname: EMPTY, lastname: EMPTY },
            publicemail: EMPTY,
            publicemails: [], // [ { title : '', content : ''}, { title : '', content : ''} ]
            contact: EMPTY,
            contacts: [], // [ { title : '', content : ''}, { title : '', content : ''} ]
            location: EMPTY,
            address: EMPTY,
            showmap: false,
            latitude: EMPTY,
            longitude: EMPTY,
            latlong: [],
            gender: EMPTY,
            eventorwed: EMPTY,
            delegate: false,

            qnsans: [],//qns

            curr_presence: 'sg', // presence and pricing
            sg: false,
            ch: false,
            my: false,
            hk: false,
            tw: false,
            kr: false,
            idn: false,

            sgminprice_vt: 0, // vt minprice
            chminprice_vt: 0,
            myminprice_vt: 0,
            hkminprice_vt: 0,
            twminprice_vt: 0,
            krminprice_vt: 0,
            idnminprice_vt: 0,

            sgminprice_svt: 0, // svt minprice
            chminprice_svt: 0,
            myminprice_svt: 0,
            hkminprice_svt: 0,
            twminprice_svt: 0,
            krminprice_svt: 0,
            idnminprice_svt: 0,


            c0: false, // c0 is 

            ready: false,

            since: -1,
            hosted: -1,
            teamnumber: -1,


            public1_btnstate: false,
            public1_msg: EMPTY,

            vendortype_specific: [],
            secondaryvendortype_specific: [],

            website_link: EMPTY, //public2
            website_links: [], // [ { title : '', content : ''}, { title : '', content : ''} ]
            facebook_link: EMPTY,
            instagram_link: EMPTY,
            youtube_link: EMPTY,
            linkedin_link: EMPTY,
            tiktok_link: EMPTY,
            wechat_link: EMPTY,
            whatsapp_link: EMPTY,
            telegram_link: EMPTY,
            line_link: EMPTY,
            public2_btnstate: false,
            public2_msg: EMPTY,

            privateemail: EMPTY, //private
            private_btnstate: false,
            oldpassword: EMPTY,
            newpassword1: EMPTY,
            newpassword2: EMPTY,
            changepw_btnstate: false,
            private_msg: EMPTY,
            private_changepw_msg: EMPTY,

            project_ids: [],
            allpricelist_ids: [],

            currtoken: EMPTY, //token without JWT
            vendor_id: EMPTY, //for update purpose, to check update coming frm
            vendortype_id: EMPTY,
            secondaryvendortype_id: EMPTY,
            vendortypeinterface_id: EMPTY,

            gtsvt: false,

            seeProfileimg: false,
            seePublic: false,
            seeTags: false,
            seeQns: false,
            seeContact: false,
            seePrivateemailpw: false,
        };
    }

    initialize = () => {
        const { token, _id, acct_type } = Retrieve_personal_info()
        if (acct_type === 'VENDOR') {
            try {
                let options = {
                    method: 'POST',
                    url: VENDOR_PROFILE_READ,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { vendor_id: _id }
                }

                axios(options).then((response) => {
                    const { status } = response
                    const { firsttime, } = response.data// error, message

                    if (status === 200) {
                        if (firsttime) { // is first time, show vendor
                            this.setState({
                                renderstate: 1,
                                currtoken: token,
                                vendor_id: _id,    // this will set the id
                                vendortype_id: '',
                            })
                        }
                        else { //is not first time, lets read the data
                            const { privateprofile, presenceandprice, gtsvt } = response.data

                            let publicprofile = JSON.parse(JSON.stringify(response.data.publicprofile))
                            publicprofile['profileimg'] = publicprofile['profileimg'] === DEFAULT_PROFILE_IMG
                                ? DEFAULT_PROFILE_IMG
                                : getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', publicprofile['profileimg'])

                            publicprofile['profilebgimg'] = publicprofile['profilebgimg'] === DEFAULT_PROFILE_BACKGROUND_IMG
                                ? DEFAULT_PROFILE_BACKGROUND_IMG
                                : getS3fullpath_withbucketname('VENDOR_PROFILEBACKGROUND_IMG', publicprofile['profilebgimg'])

                            this.setState({
                                renderstate: 2,
                                currtoken: token,

                                vendor_id: _id, // this will set the id
                                vendortype_id: response.data.ids.vendortype_id,
                                vendortypeinterface_id: response.data.ids.vendortypeinterface_id,

                                secondaryvendortype_id: response.data.ids.secondaryvendortype_id,
                                vendortype_specific: response.data.specific.vendortype_specific,
                                secondaryvendortype_specific: response.data.specific.secondaryvendortype_specific,

                                latitude: publicprofile.latlong[1],
                                longitude: publicprofile.latlong[0],

                                gtsvt: gtsvt,

                                nav: this.props.history.location.pathname.split('/')[this.props.history.location.pathname.split('/').length - 1],

                                ...publicprofile,
                                ...privateprofile,
                                ...presenceandprice
                            })


                        }
                    }
                    else {
                        this.props.history.push("/")
                    }

                })

            }
            catch (e) {
                this.props.history.push('/')
            }
        }
    }

    componentDidMount = () => {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions)
        this.initialize()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
        window.removeEventListener('storage', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        this.setState({ screen_width: window.innerWidth, screen_height: window.innerHeight });
    }

    resetVendorprofilestates = () => {
        this.setState({
            vendortype_errormsg: EMPTY,
            secondaryvendortype_errormsg: EMPTY,
            public1_msg: EMPTY,
            public2_msg: EMPTY,
            private_msg: EMPTY,
            private_changepw_msg: EMPTY
        })
    }

    updateVendorpublic1 = (e) => {
        e.preventDefault()

        const {// location will default switch to Singapore
            name, gender, about, description, publicemail, publicemails, contact, contacts, location, address, latlong, showmap, vendor_id, currtoken, since, hosted, teamnumber, eventorwed,
            sg, ch, my, hk, tw, kr, idn, c0,
            sgminprice_vt, chminprice_vt, myminprice_vt, hkminprice_vt, twminprice_vt, krminprice_vt, idnminprice_vt, // vt minprice
            sgminprice_svt, chminprice_svt, myminprice_svt, hkminprice_svt, twminprice_svt, krminprice_svt, idnminprice_svt,// svt minprice
        } = this.state//public1_msg
        var localError = []
        var gotError_validate = () => {
            var gotError = false
            if (Validateform({ form_key: 'name', form_parts: { name } })) {
                localError.push(<div key='name_error' style={FORM_ERROR_STYLE}>Error : Name field {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'gender', form_parts: { gender } })) {
                localError.push(<div key='gender_error' style={FORM_ERROR_STYLE}>Error : Gender field{IMPORTANT}</div>)
                gotError = true
            }
            // if (Validateform({ form_key: 'about', form_parts: { about } })) {
            //     localError.push(<div key='about_error' style={FORM_ERROR_STYLE}>Error : About field{IMPORTANT}</div>)
            //     gotError = true
            // }
            // if (Validateform({ form_key: 'description', form_parts: { description } })) {
            //     localError.push(<div key='description_error' style={FORM_ERROR_STYLE}>Error : Description field{IMPORTANT}</div>)
            //     gotError = true
            // }
            // if (Validateform({ form_key: 'publicemail', form_parts: { publicemail } })) {
            //     localError.push(<div key='publicemail_error' style={FORM_ERROR_STYLE}>Error : Public email field{IMPORTANT}</div>)
            //     gotError = true
            // }
            if (Validateform({ form_key: 'publicemails', form_parts: { publicemails } })) {
                localError.push(<div key='publicemails_error' style={FORM_ERROR_STYLE}>Error : Public email field{IMPORTANT}</div>)
                gotError = true
            }
            // if (Validateform({ form_key: 'contact', form_parts: { contact } })) {
            //     localError.push(<div key='contact_error' style={FORM_ERROR_STYLE}>Error : Contact field{IMPORTANT}</div>)
            //     gotError = true
            // }
            if (Validateform({ form_key: 'contacts', form_parts: { contacts } })) {
                localError.push(<div key='contacts_error' style={FORM_ERROR_STYLE}>Error : Contact field{IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'address', form_parts: { address } })) {
                localError.push(<div key='address_error' style={FORM_ERROR_STYLE}>Error : Address field{IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'since', form_parts: { since } })) {
                localError.push(<div key='since_error' style={FORM_ERROR_STYLE}>Error : Since Year field{IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'hosted', form_parts: { hosted } })) {
                localError.push(<div key='hosted_error' style={FORM_ERROR_STYLE}>Error : Hosted field{IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'teamnumber', form_parts: { teamnumber } })) {
                localError.push(<div key='teamnumber_error' style={FORM_ERROR_STYLE}>Error : Team members field{IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'category', form_parts: { eventorwed } })) {
                localError.push(<div key='eventorwed_error' style={FORM_ERROR_STYLE}>Error : Category field{IMPORTANT}</div>)
                gotError = true

            }
            if (Validateform({ form_key: 'location', form_parts: { location } })) {
                localError.push(<div key='location_error' style={FORM_ERROR_STYLE}>Error : Location field{IMPORTANT}</div>)
                gotError = true

            }
            if (Validateform({
                form_key: 'presence', form_parts: {
                    sgminprice_vt, myminprice_vt, chminprice_vt, hkminprice_vt, twminprice_vt, krminprice_vt, idnminprice_vt,
                    sgminprice_svt, myminprice_svt, chminprice_svt, hkminprice_svt, twminprice_svt, krminprice_svt, idnminprice_svt,
                    sg, my, ch, hk, tw, kr, idn,
                }
            })) {
                localError.push(<div key='vtsvtpresence' style={FORM_ERROR_STYLE}>Error : You Have To Be Presence At Least In One Country{IMPORTANT}</div>)
                gotError = true
            }

            return gotError
        }

        if (!gotError_validate()) {
            // axios options
            let options = { // this will update to both vendortype and secondaryvendortype  
                method: 'POST',
                url: VENDOR_PUBLICPROFILE1_UPDATE,
                headers: { 'Authorization': 'Bearer ' + currtoken, 'Content-Type': 'application/json', }, //need token from the web
                data: {
                    vendor_id, item: {
                        name, gender, about, description, publicemail, contact: parseInt(contact, 10), publicemails, contacts, location, latlong, showmap, address, since, hosted, teamnumber, eventorwed,
                        sg, ch, my, hk, tw, kr, idn,
                        c0,

                        sgminprice_vt: sgminprice_vt && !isNaN(sgminprice_vt) ? sgminprice_vt : 0, // vt minprice must be number and not empty
                        chminprice_vt: chminprice_vt && !isNaN(chminprice_vt) ? chminprice_vt : 0,
                        myminprice_vt: myminprice_vt && !isNaN(myminprice_vt) ? myminprice_vt : 0,
                        hkminprice_vt: hkminprice_vt && !isNaN(hkminprice_vt) ? hkminprice_vt : 0,
                        twminprice_vt: twminprice_vt && !isNaN(twminprice_vt) ? twminprice_vt : 0,
                        krminprice_vt: krminprice_vt && !isNaN(krminprice_vt) ? krminprice_vt : 0,
                        idnminprice_vt: idnminprice_vt && !isNaN(idnminprice_vt) ? idnminprice_vt : 0,

                        sgminprice_svt: sgminprice_svt && !isNaN(sgminprice_svt) ? sgminprice_svt : 0, // svt minprice must be number and not empty
                        chminprice_svt: chminprice_svt && !isNaN(chminprice_svt) ? chminprice_svt : 0,
                        myminprice_svt: myminprice_svt && !isNaN(myminprice_svt) ? myminprice_svt : 0,
                        hkminprice_svt: hkminprice_svt && !isNaN(hkminprice_svt) ? hkminprice_svt : 0,
                        twminprice_svt: twminprice_svt && !isNaN(twminprice_svt) ? twminprice_svt : 0,
                        krminprice_svt: krminprice_svt && !isNaN(krminprice_svt) ? krminprice_svt : 0,
                        idnminprice_svt: idnminprice_svt && !isNaN(idnminprice_svt) ? idnminprice_svt : 0,

                    }
                }
            }
            // execute axios
            axios(options).then((response) => {
                const { status } = response
                const { error, } = response.data// message, data 
                if (error === false && status === 200) {
                    const { ready } = this.state
                    if (!ready) refresh()
                    else {
                        this.setState({ public1_msg: SUCCESS_UPDATE })
                        setTimeout(() => {
                            this.setState({ public1_msg: EMPTY })
                        }, FORM_SECOND)
                    }
                }
                else {
                    this.setState({ public1_msg: FAILURE_UPDATE })
                }
            }).catch((e) => {
                this.setState({ public1_msg: FAILURE_UPDATE })
            })
        }
        else {
            this.setState({ public1_msg: localError })
        }


    }

    updateVendorpublic2 = (e) => {
        e.preventDefault()
    }

    updateVendorpublic3 = (e) => {
        e.preventDefault()

        const { website_link, website_links, facebook_link, tiktok_link, linkedin_link, instagram_link, youtube_link, wechat_link, whatsapp_link, telegram_link, line_link, vendor_id, currtoken } = this.state
        var localError = []
        var gotError_validate = () => {
            var gotError = false
            if (Validateform({ form_key: 'website_links', form_parts: { website_links } })) {
                localError.push(<div key='website_error' style={FORM_ERROR_STYLE}>Error : Your Website field {IMPORTANT}</div>)
                gotError = true
            }
            // if (Validateform({ form_key: 'website_link', form_parts: { website_link } })) {
            //     localError.push(<div key='website_error' style={FORM_ERROR_STYLE}>Error : Your Website field {IMPORTANT}</div>)
            //     gotError = true
            // }
            if (Validateform({ form_key: 'facebook_link', form_parts: { facebook_link } })) {
                localError.push(<div key='facebook_error' style={FORM_ERROR_STYLE}>Error : Your Facebook field {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'tiktok_link', form_parts: { tiktok_link } })) {
                localError.push(<div key='tiktok_error' style={FORM_ERROR_STYLE}>Error : Your Facebook field {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'linkedin_link', form_parts: { linkedin_link } })) {
                localError.push(<div key='linkedin_error' style={FORM_ERROR_STYLE}>Error : Your Facebook field {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'instagram_link', form_parts: { instagram_link } })) {
                localError.push(<div key='instagram_error' style={FORM_ERROR_STYLE}>Error : Your Instagram field {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'youtube_link', form_parts: { youtube_link } })) {
                localError.push(<div key='youtube_error' style={FORM_ERROR_STYLE}>Error : Your Instagram field {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'whatsapp_link', form_parts: { whatsapp_link } })) {
                localError.push(<div key='whatsapp_error' style={FORM_ERROR_STYLE}>Error : Your Whatsapp field {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'wechat_link', form_parts: { wechat_link } })) {
                localError.push(<div key='wechat_error' style={FORM_ERROR_STYLE}>Error : Your WeChat field {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'telegram_link', form_parts: { telegram_link } })) {
                localError.push(<div key='telegram_error' style={FORM_ERROR_STYLE}>Error : Your Telegram field {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'line_link', form_parts: { line_link } })) {
                localError.push(<div key='line_error' style={FORM_ERROR_STYLE}>Error : Your Line field {IMPORTANT}</div>)
                gotError = true
            }
            return gotError
        }
        if (!gotError_validate()) {

            let options = {
                method: 'POST',
                url: VENDOR_PUBLICPROFILE2_UPDATE,
                headers: { 'Authorization': 'Bearer ' + currtoken, 'Content-Type': 'application/json', }, //need token from the web
                data: { vendor_id, item: { website_link, facebook_link, tiktok_link, linkedin_link, instagram_link, youtube_link, wechat_link, whatsapp_link, telegram_link, line_link, website_links } }
            }
            axios(options).then((response) => {
                const { status } = response
                const { error } = response.data//, message, data
                if (error === false && status === 200) {
                    this.setState({ public2_msg: SUCCESS_UPDATE })
                    setTimeout(() => { this.setState({ public2_msg: EMPTY }) }, FORM_SECOND)
                }
                else {
                    console.log(FAILURE_UPDATE)
                }
            }).catch((e) => {
                console.log(FAILURE_UPDATE)
            })
        }
        else {
            this.setState({ public2_msg: localError })
        }
    }

    updateVendorprivate = (event) => {
        event.preventDefault()
        const { privateemail, vendor_id, currtoken } = this.state
        //checks
        if (Validateform({ form_key: 'privateemail', form_parts: { privateemail } })) {
            this.setState({ private_msg: <div>Error : Private email field</div> })
            return
        }
        let options = {
            method: 'POST',
            url: VENDOR_PRIVATEPROFILE_UPDATE,
            headers: { 'Authorization': 'Bearer ' + currtoken, 'Content-Type': 'application/json', }, //need token from the web
            data: { vendor_id, item: { privateemail } }
        }
        axios(options).then((response) => {
            const { status } = response
            const { error } = response.data//, message, data 
            if (error === false && status === 200) {
                this.setState({
                    private_msg: SUCCESS_UPDATE
                })
            }
            else {
                console.log(FAILURE_UPDATE)
            }
        }).catch((e) => {
            console.log(FAILURE_UPDATE)
        })
    }

    updateVendorpassword = (e) => {
        e.preventDefault()

        const { vendor_id, currtoken, oldpassword, newpassword1, newpassword2 } = this.state
        //checks
        if (Validateform({ form_key: 'oldpassword', form_parts: { oldpassword } })) {
            this.setState({ private_changepw_msg: FAILURE_UPDATE })
            return
        }
        else if (Validateform({ form_key: 'newpassword', form_parts: { newpassword1, newpassword2 } })) {
            this.setState({ private_changepw_msg: FAILURE_UPDATE })
            return
        }
        else {
            let options = {
                method: 'POST',
                url: VENDOR_CHANGEPASSWORD_UPDATE,
                headers: { 'Authorization': 'Bearer ' + currtoken, 'Content-Type': 'application/json', }, //need token from the web
                data: { vendor_id: vendor_id, item: { provider: 'userpw', oldpassword, newpassword1, newpassword2 } }
            }
            axios(options).then((response) => {
                const { status } = response
                const { error } = response.data//, message, data
                if (error === false && status === 200) {
                    this.setState({ private_changepw_msg: SUCCESS_UPDATE, oldpassword: EMPTY, newpassword1: EMPTY, newpassword2: EMPTY })
                }
                else {
                    this.setState({ private_changepw_msg: FAILURE_UPDATE })
                }
            }).catch((e) => {
                this.setState({ private_changepw_msg: FAILURE_UPDATE }, () => { refresh() })
            })
        }
    }

    toggleLoclisting = (r) => {
        const { sg, my, ch, hk, tw, kr, idn } = this.state
        if (r === 'sg') this.setState({ sg: !sg })
        else if (r === 'my') this.setState({ my: !my })
        else if (r === 'ch') this.setState({ ch: !ch })
        else if (r === 'hk') this.setState({ hk: !hk })
        else if (r === 'tw') this.setState({ tw: !tw })
        else if (r === 'kr') this.setState({ kr: !kr })
        else if (r === 'idn') this.setState({ idn: !idn })
    }

    onClickquestionmark = () => {
        // alert('Your lookup page is currently unavailable, filled out all required fields that are marked with *')
        alert('Your lookup page is not ready yet, fill up all the fields marked with * to enable your lookup page.')
    }

    enableDelegate = () => {
        try {
            const { currtoken, delegate } = this.state
            let options = {
                method: 'POST',
                url: VENDOR_ENABLEDELEGATE,
                headers: { 'Authorization': 'Bearer ' + currtoken, 'Content-Type': 'application/json' }, //need token from the web
            }

            axios(options).then((response) => {
                const { status } = response
                if (status === 200) this.setState({ delegate: !delegate })
            })

        }
        catch (e) {
            this.props.history.push('/')
        }
    }

    menuOnclick = (m) => {
        if (this.state.nav !== m) {
            this.setState({ nav: m }, () => this.resetVendorprofilestates())
        }
    }

    render() {
        const { renderstate, vendortype_specific, secondaryvendortype_specific, vendortype_id, secondaryvendortype_id, gtsvt, vendortype, secondaryvendortype, eventorwed, ready, qnsans, currtoken, delegate } = this.state
        var renderFirsttime = () => {
            const { vendor_id, currtoken, username } = this.state
            return <Firsttime vendor_id={vendor_id} currtoken={currtoken} username={username} />
        }
        var renderCharactersLength_component = (subject) => {
            return <div style={{ display: 'flex', whiteSpace: 'none', fontSize: 12, flexDirection: 'row-reverse' }}>{subject.length}  chars </div>
        }
        var renderName = () => {
            return (
                <a href={HTTPSWEBSITE_URL + '/vendorlookup/' + this.state.username} target="_blank" style={{ padding: '20px 0px', marginBottom: 5, fontWeight: 'bold', fontSize: 24, color: 'var(--maindark)' }}>
                    <div style={{ height: 100, display: 'flex', alignItems: 'center' }}>
                        {this.state.displayname}
                    </div>
                </a>
            )
        }
        var renderGeneral = () => {
            var logo_ = () => {
                return (
                    <div className="vp_glogo">
                        <div className="vp_profileimgtitle">Profile Image {IMPORTANT}</div>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                            <div style={{ display: 'flex', width: 210, height: 210, border: 'dashed thin #D9D8D8', borderRadius: 10, outline: 'none', placeContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                <div style={{ position: 'absolute', width: 200, height: 200, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    {this.state.profileimg !== DEFAULT_PROFILE_IMG
                                        ? <img alt={IMG_ALT} style={{ overflow: 'hidden', borderRadius: 10, width: 200, height: 200 }} src={this.state.profileimg} />
                                        : <div>+</div>}
                                </div>
                                <input
                                    accept="image/*"
                                    className="inputfile"
                                    id="uploadprofileimg"
                                    type='file'
                                    name="input-file"
                                    disabled={this.state.profileimg !== DEFAULT_PROFILE_IMG}
                                    style={{ opacity: 0, width: 200, height: 200, position: 'absolute' }}
                                    onChange={(event) => {
                                        try {
                                            event.preventDefault()
                                            var reader = new FileReader()
                                            let file = event.target.files[0]
                                            reader.onload = (e) => {
                                                var img = document.createElement("img");
                                                img.onload = () => {
                                                    var result = Imagedownsize(img, LOGO, null, null) // this has to be logo, defined inside
                                                    // var dataurl = result[0]
                                                    var newfile = result[1]

                                                    try {
                                                        var data = new FormData();

                                                        data.append('file', newfile);
                                                        data.append('vendor_id', this.state.vendor_id)
                                                        data.append('username', this.state.username)

                                                        var rawToken = localStorage.getItem('token')
                                                        const localCurrtoken = rawToken.substring(4, rawToken.length)   // remove 'JWT '
                                                        let options = {
                                                            method: 'POST',
                                                            url: VENDOR_LOGO_UPDATE,
                                                            headers: { 'Authorization': 'Bearer ' + localCurrtoken, 'Content-Type': 'multipart/form-data', }, //need token from the web
                                                            data: data
                                                        }
                                                        axios(options).then((response) => {
                                                            const { message, new_profileimg_link } = response.data
                                                            if (message === 'SUCCESS') {
                                                                this.setState(
                                                                    {
                                                                        file: EMPTY,
                                                                        profileimg_msg: 'Successfully Updated.',
                                                                        // profileimg: S3CLOUD + (production ? S3_DIRECTORY_PRODUCTION['VENDOR_PROFILE_IMG'] : S3_DIRECTORY_NONPRODUCTION['VENDOR_PROFILE_IMG']) + new_profileimg_link
                                                                        profileimg: getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', new_profileimg_link)

                                                                    },
                                                                    () => {
                                                                        setTimeout(() => {
                                                                            this.setState({ profileimg_msg: '' })
                                                                        }, 1000)
                                                                    })
                                                            }
                                                            else {
                                                                this.setState(
                                                                    { profileimg_msg: 'Fail to update profile image. Please Try again' },
                                                                    () => {
                                                                        setTimeout(() => {
                                                                            this.setState({ profileimg_msg: '' })
                                                                        }, 1000)
                                                                    })
                                                            }
                                                        })
                                                    }
                                                    catch (e) { }
                                                }
                                                img.src = e.target.result;
                                            }
                                            reader.readAsDataURL(file);
                                        }
                                        catch (e) {

                                        }
                                    }}
                                />
                            </div>

                            {this.state.profileimg === DEFAULT_PROFILE_IMG
                                ? null
                                : <button
                                    style={BUTTONSTYLE}
                                    onClick={() => {
                                        const { token } = Retrieve_personal_info()
                                        const { profileimg_id } = this.state
                                        try {
                                            let options = {
                                                method: 'POST',
                                                url: VENDOR_REMOVEPROFILEIMG,
                                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                data: { profileimg_id }
                                            }
                                            axios(options).then((response) => {
                                                const { message, new_profileimg_link } = response.data
                                                if (message === 'SUCCESS') {
                                                    this.setState(
                                                        { profileimg: new_profileimg_link, profileimg_msg: 'Successfully Deleted' },
                                                        () => {
                                                            setTimeout(() => {
                                                                this.setState({ profileimg_msg: '' })
                                                            }, 1000)
                                                        })
                                                }
                                                else {
                                                    this.setState(
                                                        { profileimg_msg: 'Fail to update profile image. Please Try again' },
                                                        () => {
                                                            setTimeout(() => {
                                                                this.setState({ profileimg_msg: '' })
                                                            }, 1000)
                                                        })
                                                }
                                            })
                                        }
                                        catch (e) { }
                                    }}
                                >
                                    Delete
                                </button>
                            }

                            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: 20 }}>
                                {this.state.profileimg_msg}
                            </div>

                        </div>
                    </div>
                )
            }

            var bg_ = () => {  // eslint-disable-line
                return (
                    <div className="vp_glogo">
                        <div className="vp_profileimgtitle">Background Banner{IMPORTANT}</div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                            <div
                                style={{ display: 'flex', width: 280, height: 210, border: 'dashed thin #D9D8D8', borderRadius: 10, outline: 'none', placeContent: 'center', alignItems: 'center', marginBottom: 20 }}
                                onClick={() => { document.getElementById('uploadbgimg').click() }}
                            >

                                {this.state.profilebgimg !== DEFAULT_PROFILE_BACKGROUND_IMG
                                    ? <img alt={IMG_ALT} style={{ width: 270, height: 200, overflow: 'hidden', borderRadius: 10, objectFit: 'contain', backgroundColor: 'black' }} src={this.state.profilebgimg} />
                                    : <div>+</div>}

                                <input
                                    accept="image/*"
                                    className="inputfile"
                                    id="uploadbgimg"
                                    type='file'
                                    name="input-file"
                                    disabled={this.state.profilebgimg !== DEFAULT_PROFILE_IMG}
                                    style={{ opacity: 0, width: 0, height: 0, visibility: 'none', position: 'absolute' }}
                                    onChange={(event) => {
                                        try {
                                            event.preventDefault()
                                            var reader = new FileReader()
                                            let file = event.target.files[0]
                                            reader.onload = (e) => {
                                                var img = document.createElement("img");
                                                img.onload = () => {
                                                    console.log(img.width, img.height)
                                                    // must be landscape
                                                    if (img.width > img.height && img.height > 279) {
                                                        // convert to height 280px
                                                        if (img.height !== 280) {
                                                            var targetwt = (280 * img.width) / img.height
                                                            var result = Imagedownsize(img, 'bg', targetwt, 280) // this has to be logo, defined inside
                                                            // var dataurl = result[0]
                                                            var newfile = result[1]
                                                            // this.setState({ bgfile: newfile, profilebgimg: dataurl, bgimg_btnstate: false, });
                                                            try {
                                                                var data = new FormData();

                                                                data.append('file', newfile);
                                                                data.append('vendor_id', this.state.vendor_id)
                                                                data.append('username', this.state.username)

                                                                var rawToken = localStorage.getItem('token')
                                                                const localCurrtoken = rawToken.substring(4, rawToken.length)   // remove 'JWT '
                                                                let options = {
                                                                    method: 'POST',
                                                                    url: VENDOR_BG_UPDATE,
                                                                    headers: { 'Authorization': 'Bearer ' + localCurrtoken, 'Content-Type': 'multipart/form-data', }, //need token from the web
                                                                    data: data
                                                                }
                                                                axios(options).then((response) => {
                                                                    const { message, new_profilebg } = response.data
                                                                    console.log(getS3fullpath_withbucketname('VENDOR_PROFILEBACKGROUND_IMG', new_profilebg))
                                                                    if (message === 'SUCCESS') {
                                                                        this.setState(
                                                                            {
                                                                                file: EMPTY,
                                                                                bgimg_msg: 'Successfully Updated.',
                                                                                // profilebgimg: S3CLOUD + (production ? S3_DIRECTORY_PRODUCTION['VENDOR_PROFILEBACKGROUND_IMG'] : S3_DIRECTORY_NONPRODUCTION['VENDOR_PROFILEBACKGROUND_IMG']) + new_profilebg
                                                                                profilebgimg: getS3fullpath_withbucketname('VENDOR_PROFILEBACKGROUND_IMG', new_profilebg)
                                                                            },
                                                                            () => {
                                                                                setTimeout(() => {
                                                                                    this.setState({ bgimg_msg: '' })
                                                                                }, 1000)
                                                                            })
                                                                    }
                                                                    else {
                                                                        this.setState(
                                                                            { bgimg_msg: 'Fail to update profile image. Please Try again' },
                                                                            () => {
                                                                                setTimeout(() => {
                                                                                    this.setState({ bgimg_msg: '' })
                                                                                }, 1000)
                                                                            })
                                                                    }
                                                                })
                                                            }
                                                            catch (e) { }
                                                        }
                                                    }
                                                    else {
                                                        alert('Require larger image dimension.')
                                                    }
                                                }
                                                img.src = e.target.result;
                                            }
                                            reader.readAsDataURL(file);
                                        }
                                        catch (e) {

                                        }
                                    }}
                                />
                            </div>
                            {this.state.profilebgimg === DEFAULT_PROFILE_BACKGROUND_IMG
                                ? null
                                : <button
                                    style={BUTTONSTYLE}
                                    onClick={() => {
                                        const { token } = Retrieve_personal_info()
                                        const { profileimg_id } = this.state
                                        try {
                                            let options = {
                                                method: 'POST',
                                                url: VENDOR_REMOVEPROFILEBGIMG,
                                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                                data: { profileimg_id }
                                            }
                                            axios(options).then((response) => {
                                                const { message, new_profilebg } = response.data
                                                if (message === 'SUCCESS') {
                                                    this.setState(
                                                        { profilebgimg: new_profilebg, bgimg_msg: 'Successfully Deleted' },
                                                        () => {
                                                            setTimeout(() => {
                                                                this.setState({ bgimg_msg: '' })
                                                            }, 1000)
                                                        })
                                                }
                                                else {
                                                    this.setState(
                                                        { bgimg_msg: 'Fail to update profile image. Please Try again' },
                                                        () => {
                                                            setTimeout(() => {
                                                                this.setState({ bgimg_msg: '' })
                                                            }, 1000)
                                                        })
                                                }
                                            })
                                        }
                                        catch (e) { }
                                    }}
                                >
                                    Delete
                                </button>
                            }

                            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: 20 }}>
                                {this.state.bgimg_msg}
                            </div>

                        </div>
                    </div>
                )
            }

            var public1_ = () => {
                const {
                    sgminprice_vt, myminprice_vt, chminprice_vt, hkminprice_vt, twminprice_vt, krminprice_vt, idnminprice_vt,
                    sgminprice_svt, myminprice_svt, chminprice_svt, hkminprice_svt, twminprice_svt, krminprice_svt, idnminprice_svt,
                    sg, my, ch, hk, tw, kr, idn,
                    curr_presence,
                } = this.state

                // construct map for curr_presence and min price

                var PRESENCE_MINPRICEVT_MAP = {
                    'sg': sgminprice_vt,
                    'my': myminprice_vt,
                    'ch': chminprice_vt,
                    'hk': hkminprice_vt,
                    'tw': twminprice_vt,
                    'kr': krminprice_vt,
                    'idn': idnminprice_vt
                }
                var PRESENCE_MINPRICESVT_MAP = {
                    'sg': sgminprice_svt,
                    'my': myminprice_svt,
                    'ch': chminprice_svt,
                    'hk': hkminprice_svt,
                    'tw': twminprice_svt,
                    'kr': krminprice_svt,
                    'idn': idnminprice_svt
                }

                if (this.state.seePublic) {
                    const PUBLICROOT_CSS = { display: 'flex', flexDirection: 'column', borderBottom: 'thin dashed black', padding: '30px 0px' }
                    const { eventorwed } = this.state
                    return (
                        <div className="vp_sectionroot">

                            {/* PUBLIC */}
                            {Closediv('', () => this.setState({ seePublic: false }))}
                            <div style={PUBLICROOT_CSS}>
                                <div style={MAINTITLE_CSS}>{'Public Profile'} {IMPORTANT}</div>
                                <Form inline style={{ marginBottom: 15 }}>
                                    <FormGroup controlId="formInlineName">
                                        <ControlLabel style={{ fontWeight: 500 }}>First Name</ControlLabel>{' '}
                                        <FieldGroup
                                            type="vendorname"
                                            placeholder="First name"
                                            value={this.state.name.firstname}
                                            onChange={(event) => {
                                                this.setState({ name: { firstname: event.target.value, lastname: this.state.name.lastname } })
                                            }}
                                        />
                                    </FormGroup>{' '}
                                    <FormGroup controlId="formInlineEmail">
                                        <ControlLabel style={{ fontWeight: 500 }}>Last Name</ControlLabel>{' '}
                                        <FieldGroup
                                            type="vendorname"
                                            placeholder="Last name"
                                            value={this.state.name.lastname}
                                            onChange={(event) => {
                                                this.setState({ name: { firstname: this.state.name.firstname, lastname: event.target.value } })
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Gender</ControlLabel>
                                <FormGroup style={{ marginBottom: 15 }}>
                                    <Radio name="radioGroup" inline={true} value={'male'} inputRef={ref => { this.input = ref }}
                                        onChange={() => {
                                            this.setState({ gender: 'male' })
                                        }}
                                        checked={this.state.gender === 'male'}>
                                        Male
                                    </Radio>
                                    <Radio name="radioGroup" inline={true} value={'female'} inputRef={ref => { this.input = ref }}
                                        onChange={() => {
                                            this.setState({ gender: 'female' })
                                        }}
                                        checked={this.state.gender === 'female'}>
                                        Female
                                    </Radio>
                                    <Radio name="radioGroup" inline={true} value={'others'} inputRef={ref => { this.input = ref }}
                                        onChange={() => {
                                            this.setState({ gender: 'others' })
                                        }}
                                        checked={this.state.gender === 'others'}>
                                        Others
                                    </Radio>
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Location<div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}>( location you are based in )</div> </ControlLabel>
                                <FormGroup controlId="formControlsSelectCountry">
                                    <FormControl
                                        key={'selectcountry'}
                                        componentClass="select"
                                        placeholder="select"
                                        value={this.state.location}
                                        onChange={(event) => {
                                            this.setState({ location: event.target.value })
                                        }}
                                    >
                                        {['', ...PRESENCE_LOCATION].map((val, index) => {
                                            return <option key={val + index} value={val}>{val}</option>
                                        })}
                                    </FormControl>
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>About <div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}> ( 10 - 110 chars )</div></ControlLabel>
                                <FormGroup controlId="formControlsTextarea" >
                                    <FormControl
                                        value={this.state.about}
                                        placeholder="Enter about"
                                        onChange={(event) => { this.setState({ about: event.target.value }) }}
                                        componentClass="textarea"
                                    />
                                    {renderCharactersLength_component(this.state.about)}
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Description<div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}> ( 10 - 1000 chars )</div></ControlLabel>
                                <FormGroup controlId="formControlsTextarea" >
                                    <FormControl
                                        value={this.state.description}
                                        placeholder="Enter description"
                                        onChange={(event) => { this.setState({ description: event.target.value }) }}
                                        componentClass="textarea"
                                    />
                                    {renderCharactersLength_component(this.state.description)}
                                </FormGroup>
                            </div>
                            {EMPTYSPACE}

                            {/*  INFO */}
                            <div style={PUBLICROOT_CSS}>
                                <div style={MAINTITLE_CSS}>{'Info'} {IMPORTANT}</div>
                                {/* <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Public Email<div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}> ( 7 - 100 chars )</div></ControlLabel>
                                <FormGroup controlId="formInlineName" >
                                    <FormControl
                                        key={'email'}
                                        type="email"
                                        placeholder="Enter email"
                                        value={this.state.publicemail}
                                        onChange={(event) => { this.setState({ publicemail: event.target.value }) }}
                                    />
                                </FormGroup> */}

                                <div className='vp_emailsroot' >
                                    <div className='vp_emailstitle'>{`Public Emails ( max 5 )`}</div>
                                    <div className='vp_emailssubtitle'>The email address(es) displayed on your vendorlookup page allow others to reach out to you and can include multiple addresses.</div>
                                    <div className='vp_emailmaincol'>
                                        {this.state.publicemails.map((publicemail, index) => {
                                            return <div className='vp_emailrow' key={'publicemailsmain_' + index}>
                                                <div className='vp_emailcol'>
                                                    <input
                                                        className='vp_emailinputtop'
                                                        key={'publicemailstitle_' + index}
                                                        placeholder="Email Title"
                                                        value={publicemail['title']}
                                                        onChange={(e) => {
                                                            let new_publicemails = JSON.parse(JSON.stringify(this.state.publicemails))
                                                            new_publicemails[index]['title'] = e.target.value
                                                            this.setState({ publicemails: new_publicemails })
                                                        }}
                                                    />
                                                    <input
                                                        className='vp_emailinputbtm'
                                                        key={'publicemailscontent_' + index}
                                                        placeholder="Email"
                                                        value={publicemail['content']}
                                                        onChange={(e) => {
                                                            let new_publicemails = JSON.parse(JSON.stringify(this.state.publicemails))
                                                            new_publicemails[index]['content'] = e.target.value
                                                            this.setState({ publicemails: new_publicemails })
                                                        }}
                                                    />
                                                </div>
                                                <button
                                                    className='vp_emailtrash'
                                                    onClick={() => {
                                                        let new_publicemails = JSON.parse(JSON.stringify(this.state.publicemails))
                                                        new_publicemails.splice(index, 1)
                                                        this.setState({ publicemails: new_publicemails })
                                                    }}
                                                > {TRASH_ICON}</button>
                                            </div>
                                        })}
                                        {this.state.publicemails.length === 5
                                            ? null
                                            : <button
                                                className='vp_emailadd'
                                                onClick={() => {
                                                    if (this.state.publicemails.length < 5) {
                                                        let newrow = { 'title': '', 'content': '' }
                                                        let new_publicemails = JSON.parse(JSON.stringify(this.state.publicemails))
                                                        new_publicemails.push(newrow)
                                                        this.setState({ publicemails: new_publicemails })
                                                    }
                                                }}
                                            >
                                                {`Add Email
( ${this.state.publicemails.length} / 5 )`}
                                            </button>}
                                    </div>
                                </div>



                                {/* 
                                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Contact<div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}> ( 8 - 20 chars  )</div></ControlLabel>
                                <FormGroup controlId="formControlsTextarea">
                                    <FormControl
                                        key={'contact'}
                                        type="contact"
                                        placeholder="Please enter your phone number including country code (e.g., +65 for SG)."
                                        value={this.state.contact}
                                        onChange={(event) => { this.setState({ contact: event.target.value }) }}
                                        maxLength="20"
                                    />
                                </FormGroup>
                                */}




                                <div className='vp_emailsroot' >
                                    <div className='vp_emailstitle'>{`Contacts ( max 5 )`}</div>
                                    <div className='vp_emailssubtitle'>The contact(s) displayed on your vendorlookup page allow others to reach out to you and can include multiple contacts.</div>
                                    <div className='vp_emailmaincol'>
                                        {this.state.contacts.map((contact, index) => {
                                            return <div className='vp_emailrow' key={'publiccontactsmain_' + index}>
                                                <div className='vp_emailcol'>
                                                    <input
                                                        className='vp_emailinputtop'
                                                        key={'contacttitle_' + index}
                                                        placeholder="Contact Description"
                                                        value={contact['title']}
                                                        onChange={(e) => {
                                                            let new_contacts = JSON.parse(JSON.stringify(this.state.contacts))
                                                            new_contacts[index]['title'] = e.target.value
                                                            this.setState({ contacts: new_contacts })
                                                        }}
                                                    />
                                                    <input
                                                        className='vp_emailinputbtm'
                                                        key={'contactcontent_' + index}
                                                        placeholder="Contact Number"
                                                        value={contact['content']}
                                                        onChange={(e) => {
                                                            let new_contacts = JSON.parse(JSON.stringify(this.state.contacts))
                                                            new_contacts[index]['content'] = e.target.value
                                                            this.setState({ contacts: new_contacts })
                                                        }}
                                                    />
                                                </div>
                                                <button
                                                    className='vp_emailtrash'
                                                    onClick={() => {
                                                        let new_contacts = JSON.parse(JSON.stringify(this.state.contacts))
                                                        new_contacts.splice(index, 1)
                                                        this.setState({ contacts: new_contacts })
                                                    }}
                                                > {TRASH_ICON}</button>
                                            </div>
                                        })}
                                        {this.state.contacts.length === 5
                                            ? null
                                            : <button
                                                className='vp_emailadd'
                                                onClick={() => {
                                                    if (this.state.contacts.length < 5) {
                                                        let newrow = { 'title': '', 'content': '' }
                                                        let new_contacts = JSON.parse(JSON.stringify(this.state.contacts))
                                                        new_contacts.push(newrow)
                                                        this.setState({ contacts: new_contacts })
                                                    }
                                                }}
                                            >
                                                {`Add Contact
( ${this.state.contacts.length} / 5 )`}
                                            </button>}
                                    </div>
                                </div>

                                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Address<div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}> ( 5 - 100 chars  )</div></ControlLabel>
                                <FormGroup controlId="formControlsInputAddress">
                                    <FormControl
                                        key={'address'}
                                        type="text"
                                        placeholder="Enter your address"
                                        value={this.state.address}
                                        onChange={(event) => { this.setState({ address: event.target.value }) }}
                                    />
                                </FormGroup>

                                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Show Map</ControlLabel>

                                <div className='vp_btnrow'>
                                    <button className={this.state.showmap ? 'vp_btnon vp_btnonselected' : 'vp_btnon'} onClick={() => { this.setState({ showmap: true }) }}>On</button>
                                    <button className={!this.state.showmap ? 'vp_btnoff vp_btnoffselected' : 'vp_btnoff'} onClick={() => { this.setState({ showmap: false }) }}>Off</button>
                                </div>

                                {this.state.showmap
                                    ? <Form inline style={{ marginBottom: 15 }}>
                                        <FormGroup controlId="formInlineLongtiude">
                                            <ControlLabel style={{ fontWeight: 500 }}>Longitude</ControlLabel>{' '}
                                            <FieldGroup
                                                type="text"
                                                placeholder="0"
                                                value={this.state.longitude || ""}
                                                onChange={(event) => {
                                                    if (event.target.value.length > 30) this.setState({ latitude: 0, longitude: 0, latlong: [0, 0] })
                                                    else this.setState({ longitude: event.target.value, latlong: [event.target.value, this.state.latitude] })
                                                }}
                                            />
                                        </FormGroup>{' '}
                                        <FormGroup controlId="formInlineLatitude">
                                            <ControlLabel style={{ fontWeight: 500 }}>Latitude</ControlLabel>{' '}
                                            <FieldGroup
                                                type="text"
                                                placeholder="0"
                                                value={this.state.latitude || ""}
                                                onChange={(event) => {
                                                    if (event.target.value.length > 100) this.setState({ latitude: 0, longitude: 0, latlong: [0, 0] })
                                                    else this.setState({ latitude: event.target.value, latlong: [this.state.longitude, event.target.value] })
                                                }}
                                            />
                                        </FormGroup>
                                    </Form>
                                    : null}


                            </div>
                            {EMPTYSPACE}

                            {/*  EXPERIENCE */}
                            <div style={PUBLICROOT_CSS}>
                                <div style={MAINTITLE_CSS}>Experience</div>

                                <ControlLabel style={{ fontWeight: 500 }}>Since Year</ControlLabel>
                                <FormGroup controlId="formControlsSelectSinceyear">
                                    <FormControl
                                        type="text"
                                        placeholder="Since when your company started"
                                        value={this.state.since}
                                        onChange={(event) => { this.setState({ since: event.target.value }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Hosted<div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}> ( At most 999 ) </div></ControlLabel>
                                <FormGroup controlId="formControlsSelectNohosted">
                                    <FormControl
                                        type="text"
                                        placeholder="Enter no. of wedding hosted"
                                        value={this.state.hosted}
                                        onChange={(event) => { this.setState({ hosted: event.target.value }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Team Members<div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}> ( At most 999 ) </div></ControlLabel>
                                <FormGroup controlId="formControlsSelectTeamnum">
                                    <FormControl
                                        type="text"
                                        placeholder="No. of team members"
                                        value={this.state.teamnumber}
                                        onChange={(event) => { this.setState({ teamnumber: event.target.value }) }}
                                    />
                                </FormGroup>
                            </div>
                            {EMPTYSPACE}

                            {/*  CATEGORY certain vts can only select wedding listing only */}
                            <div style={PUBLICROOT_CSS}>
                                <div style={MAINTITLE_CSS}>{'Category'} {IMPORTANT}</div>

                                <div style={{ display: 'flex', padding: '0 0 10px 0', flexDirection: 'row' }}>
                                    {eventorwed === 'Wedding' ? <ControlLabel style={{ fontWeight: 500 }}>Your profile will be viewable on Wedding listing only. Vendor types that are not relevant in the category will not appear in the listing.</ControlLabel> : null}
                                    {eventorwed === 'Event' ? <ControlLabel style={{ fontWeight: 500 }}>Your profile will be viewable on Event listing only. Vendor types that are not relevant in the category will not appear in the listing.</ControlLabel> : null}
                                    {eventorwed === 'Both' ? <ControlLabel style={{ fontWeight: 500 }}>Your profile will be viewable on both Event and Wedding listing. Vendor types that are not relevant in the category will not appear in the listing.</ControlLabel> : null}
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 15 }}>
                                    <button
                                        className={this.state.eventorwed === 'Wedding' ? 'vp_ew_selected' : 'vp_ew_notselected'}
                                        onClick={() => { this.setState({ eventorwed: 'Wedding' }) }}>
                                        Wedding
                                    </button>
                                    <button
                                        className={this.state.eventorwed === 'Event' ? 'vp_ew_selected' : 'vp_ew_notselected'}
                                        onClick={() => { this.setState({ eventorwed: 'Event' }) }}>
                                        Event
                                    </button>
                                    <button
                                        className={this.state.eventorwed === 'Both' ? 'vp_ew_selected' : 'vp_ew_notselected'}
                                        onClick={() => { this.setState({ eventorwed: 'Both' }) }}>
                                        Both
                                    </button>
                                </div>
                            </div>

                            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 15 }}>
                                    {VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING.includes(this.state.vendortype)
                                        ? <button
                                            className={this.state.eventorwed === 'Wedding' ? 'vp_ew_selected' : 'vp_ew_notselected'}
                                            onClick={() => { this.setState({ eventorwed: 'Wedding' }) }}>
                                            Wedding
                                        </button>
                                        : <button
                                            disabled
                                            className={this.state.eventorwed === 'Wedding' ? 'vp_ew_selected' : 'vp_ew_notselected'}
                                            onClick={() => { this.setState({ eventorwed: 'Wedding' }) }}>
                                            Wedding
                                        </button>}
                                    {VENDORPROFILE_COLLECTION_VENDORTYPE_EVENT.includes(this.state.vendortype)
                                        ? <button
                                            className={this.state.eventorwed === 'Event' ? 'vp_ew_selected' : 'vp_ew_notselected'}
                                            onClick={() => { this.setState({ eventorwed: 'Event' }) }}>
                                            Event
                                        </button>
                                        : <button
                                            disabled
                                            className={this.state.eventorwed === 'Event' ? 'vp_ew_selected' : 'vp_ew_notselected'}
                                            onClick={() => { this.setState({ eventorwed: 'Event' }) }}>
                                            Event
                                        </button>}
                                    {VENDORPROFILE_COLLECTION_VENDORTYPE_EVENT.includes(this.state.vendortype) && VENDORPROFILE_COLLECTION_VENDORTYPE_WEDDING.includes(this.state.vendortype)
                                        ? <button
                                            className={this.state.eventorwed === 'Both' ? 'vp_ew_selected' : 'vp_ew_notselected'}
                                            onClick={() => { this.setState({ eventorwed: 'Both' }) }}>
                                            Both
                                        </button>
                                        : <button
                                            disabled
                                            className={this.state.eventorwed === 'Both' ? 'vp_ew_selected' : 'vp_ew_notselected'}
                                            onClick={() => { this.setState({ eventorwed: 'Both' }) }}>
                                            Both
                                        </button>}
                                </div>
                            </div> */}

                            {EMPTYSPACE}

                            {/* Presence and Pricing */}
                            <div style={PUBLICROOT_CSS}>
                                <div style={MAINTITLE_CSS}>{'Presence & Pricing'} {IMPORTANT}</div>

                                <div style={{ display: 'flex', color: 'rgb(181, 181, 181)', padding: '0 0 10px 0', flexDirection: 'row' }}>
                                    <div>Your profile can be listed in multiples countries.</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 15, overflowX: 'auto', overflowY: 'hidden' }}>
                                    {PRESENCE_LOCATION.map((loc, index) => {
                                        var mapkey = PRESENCELOC_ABBREV_MAP[loc]
                                        var showkeystate
                                        if (mapkey === 'sg') showkeystate = this.state.sg
                                        else if (mapkey === 'my') showkeystate = this.state.my
                                        else if (mapkey === 'ch') showkeystate = this.state.ch
                                        else if (mapkey === 'hk') showkeystate = this.state.hk
                                        else if (mapkey === 'tw') showkeystate = this.state.tw
                                        else if (mapkey === 'kr') showkeystate = this.state.kr
                                        else if (mapkey === 'idn') showkeystate = this.state.idn
                                        var local_bordercss = mapkey === this.state.curr_presence ? 'thin dashed black' : 'thin solid ' + SOFT_GREY
                                        return (
                                            <button key={loc + index} onClick={(e) => { this.setState({ curr_presence: mapkey }) }} style={{ borderRadius: 5, backgroundColor: 'white', alignItems: 'center', padding: 5, margin: 5, display: 'flex', height: 'max-content', textAlign: 'center', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', border: local_bordercss, letterSpacing: 'nowrap', minWidth: 100, outline: 'none' }}>
                                                <div style={{ padding: '5px 0px' }}>{loc}</div>
                                                <div style={{ padding: '5px 0px', color: showkeystate ? '#3e9e58' : 'rgb(235, 100, 117)' }}>{showkeystate ? 'ON' : 'OFF'}</div>
                                            </button>
                                        )
                                    })}
                                </div>
                                <ControlLabel style={{ fontWeight: 500 }}>{'Toggle to change visibility in ' + ABBREV_PRESENCELOC_MAP[curr_presence] + ' listing'} </ControlLabel>
                                {curr_presence === 'sg' ? <button key={curr_presence + 'ONOFF'} onClick={() => { this.toggleLoclisting('sg') }} style={PRESENCEBTN_CSS}> {sg ? 'OFF' : 'ON'}</button> : null}
                                {curr_presence === 'my' ? <button key={curr_presence + 'ONOFF'} onClick={() => { this.toggleLoclisting('my') }} style={PRESENCEBTN_CSS}> {my ? 'OFF' : 'ON'}</button> : null}
                                {curr_presence === 'ch' ? <button key={curr_presence + 'ONOFF'} onClick={() => { this.toggleLoclisting('ch') }} style={PRESENCEBTN_CSS}> {ch ? 'OFF' : 'ON'}</button> : null}
                                {curr_presence === 'hk' ? <button key={curr_presence + 'ONOFF'} onClick={() => { this.toggleLoclisting('hk') }} style={PRESENCEBTN_CSS}> {hk ? 'OFF' : 'ON'}</button> : null}
                                {curr_presence === 'tw' ? <button key={curr_presence + 'ONOFF'} onClick={() => { this.toggleLoclisting('tw') }} style={PRESENCEBTN_CSS}> {tw ? 'OFF' : 'ON'}</button> : null}
                                {curr_presence === 'kr' ? <button key={curr_presence + 'ONOFF'} onClick={() => { this.toggleLoclisting('kr') }} style={PRESENCEBTN_CSS}> {kr ? 'OFF' : 'ON'}</button> : null}
                                {curr_presence === 'idn' ? <button key={curr_presence + 'ONOFF'} onClick={() => { this.toggleLoclisting('idn') }} style={PRESENCEBTN_CSS}> {idn ? 'OFF' : 'ON'}</button> : null}
                                {EMPTYSPACE}
                                <ControlLabel style={{ fontWeight: 500 }}>Vendortype Minimum Price</ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        placeholder="Minimum price of your service"
                                        value={PRESENCE_MINPRICEVT_MAP[curr_presence]}
                                        onChange={(e) => {
                                            if (curr_presence === 'sg') this.setState({ sgminprice_vt: e.target.value })
                                            else if (curr_presence === 'my') this.setState({ myminprice_vt: e.target.value })
                                            else if (curr_presence === 'ch') this.setState({ chminprice_vt: e.target.value })
                                            else if (curr_presence === 'hk') this.setState({ hkminprice_vt: e.target.value })
                                            else if (curr_presence === 'tw') this.setState({ twminprice_vt: e.target.value })
                                            else if (curr_presence === 'kr') this.setState({ krminprice_vt: e.target.value })
                                            else if (curr_presence === 'idn') this.setState({ idnminprice_vt: e.target.value })
                                        }}
                                    />
                                    <InputGroup.Addon>{ABBREVCITY_CURRENCY_MAP[curr_presence]}</InputGroup.Addon>
                                </InputGroup>
                                {EMPTYSPACE}
                                {gtsvt
                                    ? <div>
                                        <ControlLabel style={{ fontWeight: 500 }}>Secondary Vendortype Minimum Price</ControlLabel>
                                        <InputGroup>
                                            <FormControl
                                                type="text"
                                                placeholder="Minimum price of your service"
                                                value={PRESENCE_MINPRICESVT_MAP[curr_presence]}
                                                onChange={(e) => {

                                                    if (curr_presence === 'sg') this.setState({ sgminprice_svt: e.target.value })
                                                    else if (curr_presence === 'my') this.setState({ myminprice_svt: e.target.value })
                                                    else if (curr_presence === 'ch') this.setState({ chminprice_svt: e.target.value })
                                                    else if (curr_presence === 'hk') this.setState({ hkminprice_svt: e.target.value })
                                                    else if (curr_presence === 'tw') this.setState({ twminprice_svt: e.target.value })
                                                    else if (curr_presence === 'kr') this.setState({ krminprice_svt: e.target.value })
                                                    else if (curr_presence === 'idn') this.setState({ idnminprice_svt: e.target.value })
                                                }}
                                            />
                                            <InputGroup.Addon>{ABBREVCITY_CURRENCY_MAP[curr_presence]}</InputGroup.Addon>
                                        </InputGroup>
                                        {EMPTYSPACE}
                                    </div>
                                    : null}
                            </div>
                            {EMPTYSPACE}

                            {/* Covid19 Flexible Policy */}
                            {/* <div style={PUBLICROOT_CSS}>
                                <div style={MAINTITLE_CSS}>Covid19 Flexible Policy</div>
                                <div style={{ display: 'flex', padding: '0 0 10px 0', flexDirection: 'column' }}>
                                    <ControlLabel style={{ fontWeight: 500 }}>By enabling Covid19 Flexible Policy, you allow the customer to reschedule/postpone of the purchased package due to Covid19.</ControlLabel>
                                    {this.state.c0
                                        ? <button onClick={() => { this.setState({ c0: !this.state.c0 }) }} style={{ ...BUTTONSTYLE, width: 'max-content', backgroundColor: '#3e9e58', color: 'white', border: 'thin solid transparent' }}>
                                            Enabled
                                        </button>
                                        : <button onClick={() => { this.setState({ c0: !this.state.c0 }) }} style={{ ...BUTTONSTYLE, width: 'max-content', backgroundColor: 'white', color: '#707070', }}>
                                            Enable
                                        </button>}
                                </div>
                            </div>
                            {EMPTYSPACE} */}

                            {/* MESSAGE */}
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>{this.state.public1_msg}</div>

                            {/*UPDATE BTN*/}
                            <div style={{ width: '100%', minHeight: 40, marginBottom: 5 }}>
                                <button onClick={this.updateVendorpublic1} style={{ float: 'right', ...BUTTONSTYLE }} >Update All</button>
                            </div>



                        </div >
                    )
                }
                else return Opendiv('Lookup Info', () => this.setState({ seePublic: true, seeProfileimg: false, seeTags: false, seeContact: false, seePrivateemailpw: false, seeQns: false, }))

            }

            var public1half_tags = () => {
                if (this.state.seeTags) {
                    return <div className="vp_sectionroot">
                        {Closediv('', () => this.setState({ seeTags: false }))}
                        <Vendortypespecific curr_vt={vendortype} eventorwed={eventorwed} specific={vendortype_specific} curr_vt_id={vendortype_id} type_vt={'vendortypespecific'} MAINTITLE_CSS={MAINTITLE_CSS} showtopline={false} />
                        {gtsvt ? <Vendortypespecific curr_vt={secondaryvendortype} eventorwed={eventorwed} specific={secondaryvendortype_specific} curr_vt_id={secondaryvendortype_id} type_vt={'secondaryvendortypespecific'} MAINTITLE_CSS={MAINTITLE_CSS} showtopline={true} /> : <div />}
                    </div>
                }
                else return Opendiv('Tags', () => this.setState({ seeTags: true, seePublic: false, seeProfileimg: false, seeContact: false, seePrivateemailpw: false, seeQns: false, }))
            }

            var public_qns = () => {
                if (this.state.seeQns) {
                    return <div className="vp_sectionroot">
                        {Closediv('', () => this.setState({ seeQns: false }))}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Vendortypeqns curr_vt={vendortype} eventorwed={eventorwed} qnsans={qnsans} token={currtoken} vt_id={vendortype_id} svt_id={secondaryvendortype_id} />
                        </div>
                    </div>
                }
                else return Opendiv('Questions & Answers', () => this.setState({ seeQns: true, seeTags: false, seePublic: false, seeProfileimg: false, seeContact: false, seePrivateemailpw: false, }))

            }

            var public2_ = () => {
                if (this.state.seeContact) {
                    return (
                        <div className="vp_sectionroot">
                            {Closediv('', () => this.setState({ seeContact: false }))}
                            <div style={{ display: 'flex', flexDirection: 'column', borderBottom: 'thin dashed black' }}>
                                <div style={MAINTITLE_CSS}>Social Media</div>

                                {/* 
                                <ControlLabel style={{ fontWeight: 500 }}>Your Website</ControlLabel>
                                <FormGroup controlId="formControlsSelect" style={{ display: 'flex', flexDirection: 'row', placeItems: 'center', }}>
                                    <FormLabel style={{ padding: '0px 10px 0px 0px', fontSize: 14 }}>https://</FormLabel>
                                    <FormControl
                                        type="vendorname"
                                        placeholder="https://wwww.weavebliss.com"
                                        value={this.state.website_link}
                                        onChange={(event) => { this.setState({ website_link: event.target.value }) }}
                                    />
                                </FormGroup>
                                */}

                                <div className='vp_emailsroot' >
                                    <div className='vp_emailstitle'>{`Websites ( max 5 )`}</div>
                                    <div className='vp_emailssubtitle'>The website(s) displayed on your vendorlookup page allow others to reach out to you and can include multiple website_links.</div>
                                    <div className='vp_emailmaincol'>
                                        {this.state.website_links.map((website, index) => {
                                            return <div className='vp_emailrow' key={'publicwebsitesmain_' + index}>
                                                <div className='vp_emailcol'>
                                                    <input
                                                        className='vp_emailinputtop'
                                                        key={'websitelinkstitle_' + index}
                                                        placeholder="Contact Title"
                                                        value={website['title']}
                                                        onChange={(e) => {
                                                            let new_website_links = JSON.parse(JSON.stringify(this.state.website_links))
                                                            new_website_links[index]['title'] = e.target.value
                                                            this.setState({ website_links: new_website_links })
                                                        }}
                                                    />
                                                    <input
                                                        className='vp_emailinputbtm'
                                                        key={'websitescontent_' + index}
                                                        placeholder="Contact"
                                                        value={website['content']}
                                                        onChange={(e) => {
                                                            let new_website_links = JSON.parse(JSON.stringify(this.state.website_links))
                                                            new_website_links[index]['content'] = e.target.value
                                                            this.setState({ website_links: new_website_links })
                                                        }}
                                                    />
                                                </div>
                                                <button
                                                    className='vp_emailtrash'
                                                    onClick={() => {
                                                        let new_website_links = JSON.parse(JSON.stringify(this.state.website_links))
                                                        new_website_links.splice(index, 1)
                                                        this.setState({ website_links: new_website_links })
                                                    }}
                                                > {TRASH_ICON}</button>
                                            </div>
                                        })}
                                        {this.state.website_links.length === 5
                                            ? null
                                            : <button
                                                className='vp_emailadd'
                                                onClick={() => {
                                                    if (this.state.website_links.length < 5) {
                                                        let newrow = { 'title': '', 'content': '' }
                                                        let new_website_links = JSON.parse(JSON.stringify(this.state.website_links))
                                                        new_website_links.push(newrow)
                                                        this.setState({ website_links: new_website_links })
                                                    }
                                                }}
                                            >
                                                {`Add Websites
( ${this.state.website_links.length} / 5 )`}
                                            </button>}
                                    </div>
                                </div>



                                <ControlLabel style={{ fontWeight: 500 }}>Your Facebook</ControlLabel>
                                <FormGroup controlId="formControlsSelect">
                                    <FormControl
                                        type="Facebook"
                                        placeholder="Exact format required: https://www.facebook.com/groups/123456789"
                                        value={this.state.facebook_link}
                                        onChange={(event) => { this.setState({ facebook_link: event.target.value }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500 }}>Your Instagram</ControlLabel>
                                <FormGroup controlId="formControlsSelect">
                                    <FormControl
                                        type="Instagram"
                                        placeholder="Exact format required: https://www.instagram.com/weavebliss/"
                                        value={this.state.instagram_link}
                                        onChange={(event) => { this.setState({ instagram_link: event.target.value }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500 }}>Your Youtube</ControlLabel>
                                <FormGroup controlId="formControlsSelect">
                                    <FormControl
                                        type="Instagram"
                                        placeholder="Exact format required: https://www.youtube.com/@weavebliss"
                                        value={this.state.youtube_link}
                                        onChange={(event) => { this.setState({ youtube_link: event.target.value }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500 }}>Your Tiktok</ControlLabel>
                                <FormGroup controlId="formControlsSelect">
                                    <FormControl
                                        type="Facebook"
                                        placeholder=""
                                        value={this.state.tiktok_link}
                                        onChange={(event) => { this.setState({ tiktok_link: event.target.value }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500 }}>Your Linkedin</ControlLabel>
                                <FormGroup controlId="formControlsSelect">
                                    <FormControl
                                        type="Facebook"
                                        placeholder=""
                                        value={this.state.linkedin_link}
                                        onChange={(event) => { this.setState({ linkedin_link: event.target.value }) }}
                                    />
                                </FormGroup>
                            </div>
                            {EMPTYSPACE}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={MAINTITLE_CSS}>Other contacts</div>
                                <ControlLabel style={{ fontWeight: 500 }}>Your Whatsapp Number</ControlLabel>
                                <FormGroup controlId="formControlsSelect">
                                    <FormControl
                                        type="Whatsapp"
                                        placeholder="Note: Country/Location Calling Code Required. Format: 65XXXXXXXX"
                                        value={this.state.whatsapp_link}
                                        onChange={(event) => { this.setState({ whatsapp_link: event.target.value.replace(/ /g, '') }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500 }}>Your Wechat ID</ControlLabel>
                                <FormGroup controlId="formControlsSelect">
                                    <FormControl
                                        type="Wechat"
                                        placeholder="Wechat ID"
                                        value={this.state.wechat_link}
                                        onChange={(event) => { this.setState({ wechat_link: event.target.value }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500 }}>Your Telegram Number</ControlLabel>
                                <FormGroup controlId="formControlsSelect">
                                    <FormControl
                                        type="Telegram"
                                        placeholder="Telegram"
                                        value={this.state.telegram_link}
                                        onChange={(event) => { this.setState({ telegram_link: event.target.value }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ fontWeight: 500 }}>Your Line ID</ControlLabel>
                                <FormGroup controlId="formControlsSelect">
                                    <FormControl
                                        type="Line"
                                        placeholder="Line ID"
                                        value={this.state.line_link}
                                        onChange={(event) => { this.setState({ line_link: event.target.value }) }}
                                    />
                                </FormGroup>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignContent: 'center', textAlign: 'center' }}> {this.state.public2_msg}</div> {/*this will show message*/}

                            <div style={{ width: '100%', height: '40px', marginBottom: 5 }}>
                                <button style={{ float: 'right', ...BUTTONSTYLE }} onClick={this.updateVendorpublic3}>
                                    Update
                                </button>
                            </div>
                        </div>
                    )
                }
                else return Opendiv('Social Media & Contacts', () => this.setState({ seeContact: true, seeProfileimg: false, seePublic: false, seeTags: false, seePrivateemailpw: false, seeQns: false, }))
            }

            var private_ = () => {
                if (this.state.seePrivateemailpw) {
                    return (
                        <div style={{ border: 'solid thin black', borderRadius: 5, padding: 10, paddingTop: 5, marginBottom: 50 }}>
                            {Closediv('', () => this.setState({ seePrivateemailpw: false }))}
                            <div style={MAINTITLE_CSS} >Private</div>
                            {EMPTYSPACE}
                            <ControlLabel style={{ fontWeight: 500 }}>Private Email</ControlLabel>
                            <FormGroup controlId="formControlsSelect">
                                <FormControl
                                    type="privateemail"
                                    placeholder="Enter private email"
                                    value={this.state.privateemail}
                                    onChange={(event) => { this.setState({ privateemail: event.target.value }) }}
                                />
                            </FormGroup>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignContent: 'center', textAlign: 'center' }}> {this.state.private_msg}</div>
                            <div style={{ width: '100%', height: '40px', marginBottom: 5 }}>
                                <button
                                    onClick={this.updateVendorprivate}
                                    style={{ ...BUTTONSTYLE, float: 'right' }}
                                    color='rgb(85, 85, 85)'>
                                    Update
                                </button>
                            </div>
                            <ControlLabel style={{ fontWeight: 500 }}>Password</ControlLabel>
                            <div style={{ width: '100%' }}>
                                <button
                                    onClick={() => { this.setState({ show_changepwmodal: true }) }}
                                    style={{ width: '50%', ...BUTTONSTYLE }}>
                                    Change Password
                                </button>
                            </div>
                        </div>
                    )
                }
                else return Opendiv('Private Email & Password', () => this.setState({ seePrivateemailpw: true, seeProfileimg: false, seePublic: false, seeTags: false, seeContact: false, seeQns: false, }))
            }

            const showCpmodal = () => {
                const { show_changepwmodal, screen_width } = this.state
                return (
                    <Jfmodal
                        show={show_changepwmodal}
                        title={'Change Password'}
                        onHide={() => { this.setState({ show_changepwmodal: false, oldpassword: EMPTY, newpassword1: EMPTY, newpassword2: EMPTY, private_changepw_msg: EMPTY }) }}
                        device={screen_width < TABLET ? 'MOBILE' : 'WEB'}
                        content={
                            <div style={{ width: POPUP_FORM_MOBILE, maxHeight: 'max_content', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ControlLabel style={{ width: POPUP_FORM_MOBILE, display: 'flex', flexDirection: 'row', padding: '0px 10px' }}>Old Password</ControlLabel>
                                <FormGroup controlId="formControlsTextarea" style={{ width: POPUP_FORM_MOBILE, padding: '0px 10px' }}>
                                    <FormControl
                                        type="password"
                                        placeholder="Old password"
                                        value={this.state.oldpassword}
                                        onChange={(event) => { this.setState({ oldpassword: event.target.value }) }}
                                    />
                                </FormGroup>
                                <ControlLabel style={{ width: POPUP_FORM_MOBILE, display: 'flex', flexDirection: 'row', padding: '0px 10px' }}>New Password</ControlLabel>
                                <FormGroup controlId="formInlineName" style={{ width: POPUP_FORM_MOBILE, padding: '0px 10px' }}>
                                    <FormControl
                                        type="password"
                                        placeholder="New password"
                                        value={this.state.newpassword1}
                                        onChange={(event) => { this.setState({ newpassword1: event.target.value }) }}
                                    />
                                </FormGroup>
                                <FormGroup controlId="formInlineName" style={{ width: POPUP_FORM_MOBILE, padding: '0px 10px' }}>
                                    <FormControl
                                        type="password"
                                        placeholder="New password again"
                                        value={this.state.newpassword2}
                                        onChange={(event) => { this.setState({ newpassword2: event.target.value }) }}
                                    />
                                </FormGroup>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignContent: 'center', textAlign: 'center' }}> {this.state.private_changepw_msg}</div> {/*this will show message*/}

                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: 10, marginBottom: 5 }}>
                                    <button style={{ ...BUTTONSTYLE, }} onClick={this.updateVendorpassword}>
                                        Update
                                    </button>
                                </div>
                                {EMPTYSPACE}
                            </div>
                        }
                    />
                )

            }

            const status_ = () => {
                return (
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <ControlLabel style={{ fontSize: 'large', fontWeight: 'bold' }}>Lookup Status</ControlLabel>
                            {ready
                                ? null
                                : <div style={{ backgroundColor: 'red', color: 'white', padding: '0px 10px', margin: '0px 0px 0px 10px', borderRadius: 5, fontWeight: 'bold', alignItems: 'center', display: 'flex', justifyContent: 'center' }} onClick={this.onClickquestionmark}>?</div>
                            }
                        </div>
                        <div style={{ border: 'solid thin #D9D8D8', borderRadius: 5, padding: 10, marginBottom: 15 }}>
                            <div style={{ fontWeight: 'bold' }}>
                                {ready
                                    ? <font style={{ color: '#3e9e58' }}>Available</font>
                                    : <font style={{ color: 'red' }}>Unavailable [ Filled out all required fields that has *] </font>}</div>
                        </div>
                    </div>
                )
            }

            const delegate_ = () => {
                return (
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginBottom: 20 }}>
                        <ControlLabel style={{ fontSize: 'large', fontWeight: 'bold' }}>Delegate</ControlLabel>
                        <div style={{ border: 'solid thin #D9D8D8', borderRadius: 5, padding: 10, marginBottom: 15, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: 10 }}>You may be busy, enable button below our team will help you update your profile, projects and reviews.</div>
                            {delegate
                                ? <button onClick={this.enableDelegate} style={{ ...BUTTONSTYLE, width: 'max-content', backgroundColor: '#3e9e58', color: 'white', border: 'thin solid transparent' }}>Enabled</button>
                                : <button onClick={this.enableDelegate} style={{ ...BUTTONSTYLE, width: 'max-content', backgroundColor: 'white', color: '#707070', }}>Enable</button>}
                        </div>
                    </div>
                )
            }

            return (
                <div className="vp_generalroot">
                    {status_()}
                    {delegate_()}
                    {logo_()}
                    {bg_()}
                    {public1_()}
                    {public1half_tags()}
                    {public_qns()}
                    {public2_()}
                    {private_()}
                    {showCpmodal()}
                </div>
            )
        }
        var renderMainportion = () => {
            const { nav, vendor_id, currtoken, ready, username, vendortype_id, vendortype, vendortypeinterface_id, secondaryvendortype_id, secondaryvendortype, allpricelist_ids, location, profileimg_id, profileimg } = this.state
            return (
                <div className='container' style={{ flex: 'display', flexDirection: 'column', padding: '0px 5px 20px 5px' }}>
                    {renderName()}
                    <Vptopbar {...this.props} nav={nav} updateNav={(nav) => this.setState({ nav: nav }, () => this.resetVendorprofilestates())} />
                    {nav === 'general' ? renderGeneral() : null}
                    {nav === 'project' ? <Project vendor_id={vendor_id} vendortype_id={vendortype_id} vendortype={vendortype} vendortypeinterface_id={vendortypeinterface_id} username={username} token={currtoken} profileimg={profileimg} profileimg_id={profileimg_id} ready={ready} /> : null}
                    {nav === 'pricelist' ? <Pricelist vendor_id={vendor_id} vendortype_id={vendortype_id} vendortype={vendortype} vendortypeinterface_id={vendortypeinterface_id} secondaryvendortype_id={secondaryvendortype_id} secondaryvendortype={secondaryvendortype} username={username} token={currtoken} allpricelist_ids={allpricelist_ids} location={location} ready={ready} /> : null}
                    {nav === 'review' ? <Reviews vendor_id={vendor_id} token={currtoken} ready={ready} /> : null}
                    {nav === 'share' && sharegate ? <Share vendor_id={vendor_id} token={currtoken} ready={ready} username={username} /> : null}
                    {nav === 'partner' ? <Partner {...this.props} vendor_id={vendor_id} token={currtoken} ready={ready} username={username} /> : null}
                </div>
            )
        }
        if (renderstate === 0) return <Loading />
        else if (renderstate === 1) return <div className="vp_root">{EMPTYSPACE}{renderFirsttime()}</div>
        else if (renderstate === 2) return <div className="vp_root">{renderMainportion()}</div>
    }

}

export default VendorProfile