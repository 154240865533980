import { EMPTY, S3_DIRECTORY_PRODUCTION, S3_DIRECTORY_NONPRODUCTION, production, S3CLOUD } from "./constants";

export const ParseJWT = (token) => {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    catch (e) { return null }
}
export const Retrieve_personal_info = () => {
    try {
        const rawToken = localStorage.getItem('token')
        var token = rawToken.substring(4, rawToken.length)
        const _id = ParseJWT(token).id
        var acct_type = ParseJWT(token).acct_type
        if (acct_type !== 'VENDOR' && acct_type !== 'USER') { acct_type = 'UNKNOWN'; token = 'NONE'; }
        else return { token: token, _id: _id, acct_type: acct_type, }
    }
    catch (e) { return { token: EMPTY, _id: EMPTY, acct_type: EMPTY } }
}

function get_bigrams(string) {
    var s = string.toLowerCase()
    var v = s.split('');
    for (var i = 0; i < v.length; i++) { v[i] = s.slice(i, i + 2); }
    return v;
}

export const str_similarity = (str1, str2) => {
    if (str1.length > 0 && str2.length > 0) {
        var pairs1 = get_bigrams(str1);
        var pairs2 = get_bigrams(str2);
        var union = pairs1.length + pairs2.length;
        var hits = 0;
        for (var x = 0; x < pairs1.length; x++) {
            for (var y = 0; y < pairs2.length; y++) {
                if (pairs1[x] === pairs2[y]) hits++;
            }
        }
        if (hits > 0) return ((2.0 * hits) / union);
    }
    return 0.0
}


export const getS3fullpath_withbucketname = (key, filename) => {
    return (production
        ? S3CLOUD + S3_DIRECTORY_PRODUCTION[key]
        : S3CLOUD + S3_DIRECTORY_NONPRODUCTION[key])
        + filename
}
