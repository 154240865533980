import React, { useEffect, useState } from 'react'
import { SMALL_STAR_ICON, GENERAL_GREY, } from '../../config/constants';
import { Avatar } from '@material-ui/core';
import { google } from 'react-icons-kit/fa/google'
import { facebookSquare } from 'react-icons-kit/fa/facebookSquare'
import Icon from 'react-icons-kit';

const NAME_ICON_MAP = {
    'google': <Icon size={25} icon={google} />,
    'facebook': <Icon size={25} icon={facebookSquare} />,
    'cereweave': ''
}
const STAR_DEFAULT_BGCOLOR = GENERAL_GREY
const STAR_SELECTED_BGCOLOR = 'rgb(231, 190, 62)'

const BlockedRatingstar = (props) => {
    const [starsstyle, setStarsstyle] = useState([
        {
            color: STAR_DEFAULT_BGCOLOR
        },
        {
            color: STAR_DEFAULT_BGCOLOR
        },
        {
            color: STAR_DEFAULT_BGCOLOR
        },
        {
            color: STAR_DEFAULT_BGCOLOR
        },
        {
            color: STAR_DEFAULT_BGCOLOR
        }
    ])

    useEffect(() => {
        const { score } = props
        var local_starsstyle = [...starsstyle]
        for (let i = 0; i < score; i++) {
            local_starsstyle[i].color = STAR_SELECTED_BGCOLOR
        }
        setStarsstyle(local_starsstyle)
    }, [props.score])

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', float: 'right', }}>
            <span style={{ width: '100%', borderRadius: 15, padding: '0px 5px 0px 5px', justifyContent: 'center', }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                    {starsstyle.map((star_style, index) => {
                        return (
                            <div
                                key={'star' + index}
                                style={{ display: 'flex', justifyContent: 'center', color: star_style.color, padding: '0px 2px 0px 2px', outline: 'none', backgroundColor: 'transparent', border: 'none' }}
                            >
                                {SMALL_STAR_ICON}
                            </div>
                        )
                    })}
                </div>
            </span>
        </div>

    )

}

const dateConvert = (item) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var curr_date = new Date(item)
    return monthNames[curr_date.getMonth()] + ' ' + curr_date.getFullYear()
}


// for vendorlookup and vendorprofile
const Reviewcard = (props) => {

    const [obj, setObj] = useState({})
    const [index, setIndex] = useState(-1)
    useEffect(() => {
        const {
            index, // index of the review
            obj, // full data of the review
            // profileimg,
            // rating, // use in blockedratingstar
            // name,
            // maxwidth, // full width or nt 

        } = props

        if (props) {
            setIndex(index)
            // determine whether need more button
            var local_obj = obj
            local_obj = ShortenContent(local_obj)
            setObj(local_obj)
        }
    }, [props.index])

    const ShortenContent = (obj) => {
        // this function will edit obj iteself and add two new fields if need to shorten the content

        var content = obj.content
        var words = content.split(' ')
        var num_words = words.length

        // var chars = content.split('')

        // need to show content that is 240 chars 
        const maxChar = 240
        var currChar = 0
        var currSent = []

        obj.require_subcontent = false
        for (let x = 0; x < num_words; x++) {
            if (words[x].length > 50) {
                currSent = currSent + '... '
            }
            currChar = currChar + words[x].length
            currSent = currSent + words[x] + ' '
            if (currChar >= maxChar) {
                obj.require_subcontent = true
                currSent = currSent + '... '
                break
            }
        }

        obj.subcontent = currSent
        return obj
    }

    return <div style={{ display: 'flex', flexDirection: 'column', color: GENERAL_GREY, width: '100%', justifyContent: 'center', boxShadow: 'rgba(25, 25, 25, 0.25) 1px 2px 7px',border:'thin solid #eaeaea', borderRadius: 5, marginBottom: 30 }}>
            <div key={index + obj._id} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 5, width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 10 }}>
                        {props.reviewer_accttype === 'User'
                         ? <Avatar alt='' src={props.profileimg} style={{ margin: '10px 10px 10px 0px', border: 'thin solid #707070' }} />
                         : null}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ fontWeight: 'bold', width: 125, overflowX: 'hidden' }}>{props.name}</div>
                                <BlockedRatingstar score={parseInt(props.rating, 10)} />
                            </div>
                            <div style={{ fontWeight: 300, }}>{dateConvert(obj.createdAt)}</div>
                        </div>
                    </div>
                    {obj.require_subcontent
                        ? <div key={Math.random()}>
                            {obj.subcontent}
                            <button
                                style={{ outline: 'none', backgroundColor: 'white', border: 'none', color: STAR_DEFAULT_BGCOLOR, fontWeight: 'bold' }}
                                onClick={() => { setObj({ ...obj, require_subcontent: !obj.require_subcontent }) }}
                            >
                                More
                            </button>
                        </div>
                        : <div key={Math.random()} style={{ padding: 5 }}>{obj.content}</div>}
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: '#707070', marginTop: 20, width: '100%' }}>
                {props && props.sources && props.sources.length > 0
                    ? <div style={{ display: 'flex', flexDirection: 'column', padding: 5, placeSelf: 'flex-end' }}>
                        <div style={{ fontFamily: 'monospace' }}>Source</div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {props.sources.map((item, index) => <a key={index} style={{ padding: '25px ​10px 10px 10px', color: '#707070' }} target="_blank" href={item.link}>{NAME_ICON_MAP[item.key]}</a>)}
                        </div>
                    </div>
                    : <div />}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', borderTop: 'thin solid #eeeee', backgroundColor: '#fef6de', marginTop:20 }}>
                    <div>{props.statusbtn}</div>
                    <div>{props.btn}</div>
                </div>
            </div>
        </div>
}

export default Reviewcard





