import React, { useState, useEffect } from 'react'
import { EMPTY, IMPORTANT, OPTIONAL, WEB, SOFT_GREY, EMPTYSPACE, MIN_NUM_PROJECT_IMG, MAX_NUM_PROJECT_IMG, MIN_NUM_PROJECT_OBJ, MAX_NUM_PROJECT_OBJ, MAX_NUM_PROJECT_CAN_BE_CREATED, MAX_NUM_PROJECT_OBJ_TEXT_SIZE, MIN_NUM_PROJECT_OBJ_TEXT_SIZE, LEFTARROW_ICON_SMALL } from '../../../../../../config/constants'
import { ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap'
import { BUTTONSTYLE } from '../../../../../../config/cssconstants'
import { Validateform } from '../Validateform'
import { VENDOR_PROFILE_CREATE_PROJECT_LINK } from '../../../../../../config/api'
import { Dialog } from '@material-ui/core';
import { ScreenWidth, refresh } from '../../../../../../components';
import { Oneimageinput, Twoportraitinput } from './components'
import Formcompoenents from '../Formcompoenents/Formcompoenents';
import Creatingloading from '../../../Components/Creationloading/Creatingloading'
import Selectpattern from '../Selectpatterns/Selectpattern'
import axios from 'axios'
import './Projectcreation.css'
// import retrievecolortag from '../../../../../../../components/Retrievecolortag';

function FieldGroup({ id, label, help, ...props }) {
    return (
        <FormGroup controlId={id}>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
    );
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)) // eslint-disable-line
}

function Projectcontent(props) {

    const [projectmodal_name, setProjectmodal_name] = useState(EMPTY)
    const [projectmodal_maintext, setProjectmodal_maintext] = useState(EMPTY)
    const [projectmodal_extlinks, setProjectmodal_extlinks] = useState(EMPTY)
    const [projectmodal_selected_collab_name, setProjectmodal_selected_collab_name] = useState(EMPTY)
    const [projectmodal_collection_collab_names, setProjectmodal_collection_collab_names] = useState([])
    const [projectmodal_fontfamily, setProjectmodal_fontfamily] = useState(null)
    const [wedoreventorboth, setWedoreventorboth] = useState('Both') // by default is both, ['Wedding', 'Event', 'Both']
    const [displaymsg, setDisplaymsg] = useState(EMPTY)
    const [disabled, setDisabled] = useState(false) // for blocking when create bbtn pressed
    const [allCollab_names, setAllCollab_names] = useState([])
    const [location, setLocation] = useState(EMPTY)
    const [longitude, setLongitude] = useState(EMPTY)
    const [latitude, setLatitude] = useState(EMPTY)
    const [guestcount, setGuestcount] = useState(EMPTY)

    const [imgmodalgate, setImgmodalgate] = useState(false)
    const [allimgsmap, setAllimgsmap] = useState({})

    const [projectmodal_img1, setProjectmodal_img1] = useState(EMPTY)
    const [projectmodal_img2, setProjectmodal_img2] = useState(EMPTY)
    const [projectmodal_img3, setProjectmodal_img3] = useState(EMPTY)
    const [projectmodal_imgpattern, setProjectmodal_imgpattern] = useState(0) // 0 for [main, left, right], 1 for [main, top, down], 2 for [main]

    const [existingprojects, setExistingprojects] = useState([])
    const [allCollab_username_obj_map, setAllCollab_username_obj_map] = useState({})
    const [profileimg_id, setProfileimg_id] = useState(EMPTY)
    const [token, setToken] = useState(EMPTY)
    const [vendortype, setVendortype] = useState(EMPTY)
    const [vendortype_id, setVendortype_id] = useState(EMPTY)
    const [username, setUsername] = useState(EMPTY)

    // for blocks objects
    const [showblockdialog, setShowblockdialog] = useState(false) // show dialog
    const [blocks, setBlocks] = useState([<button key={uuidv4()} className="cprojcontent_addanddelbtn" onClick={(e) => { setShowblockdialog(true); setImgmodalgate(false); setAddbtnloc(0); }}>+</button>]) // first +
    const [addbtnstate, setAddbtnstate] = useState(-1) // to detect which add btn pressed
    const [addbtnloc, setAddbtnloc] = useState(0) // to detect which add btn pressed
    const [rmblk, setRmblk] = useState()  // to detect which which blk to remove
    const [blktxtmap, setBlktxtmap] = useState({})
    const [blkvidmap, setBlkvidmap] = useState({})

    const w = ScreenWidth()
    const dimW = w < WEB ? 230 : w * 0.5    // if is web max/min width is 400 else 230

    useEffect(() => {
        if (props !== null && props !== undefined && props !== []) {
            const { allCollab_names, existingprojects, allCollab_username_obj_map, profileimg_id, token, vendortype, vendortype_id, username } = props
            setAllCollab_names(allCollab_names)
            setExistingprojects(existingprojects)
            setAllCollab_username_obj_map(allCollab_username_obj_map)
            setProfileimg_id(profileimg_id)
            setToken(token)
            setVendortype(vendortype)
            setVendortype_id(vendortype_id)
            setUsername(username)
        }
    }, [props])

    useEffect(() => {
        setAddbtnloc(blocks.indexOf(addbtnstate))
    }, [addbtnstate])// if addbtnstate is setted, den implies vendor added new blk obj

    useEffect(() => {
        if (blocks.length > 1) {
            var blkobj_index = blocks.indexOf(rmblk)
            var new_blocks = blocks.slice()
            new_blocks.splice(blkobj_index, 2)
            setBlocks(new_blocks)
        }
    }, [rmblk]) // if rmblk is setted, den implies a block require to remove

    useEffect(() => {
        if (disabled) { // validated! we can now start creating new project and show loading screen
            var projectmodal_blockpattern = []
            var projectmodal_obj = []
            var projectmodal_imagefiles = []
            try {
                for (let i = 0; i < blocks.length; i++) {
                    if (i % 2 !== 0) {
                        const { value } = blocks[i].props;
                        const { key } = blocks[i];
                        projectmodal_blockpattern.push(value);
                        if (value === 'IP') { // 2 PORTRAIT  ,  Images - 2 portrait
                            var firstimg = allimgsmap[key + '1'].file
                            var secondimg = allimgsmap[key + '2'].file
                            var firstdim = allimgsmap[key + '1']['dim']
                            projectmodal_obj.push(firstdim) // there is only portrait, this will be change to aws links
                            projectmodal_imagefiles.push(firstimg)
                            projectmodal_imagefiles.push(secondimg)
                            if (firstimg && secondimg) continue
                            else return
                        }
                        else if (value === 'I') { // 1 IMAGE, PORTRAIT OR LANDSCAPE
                            const { dim, file } = allimgsmap[key]
                            projectmodal_obj.push(dim) // this will be change to aws links
                            projectmodal_imagefiles.push(file)
                            if (file) continue
                            else return
                        }
                        else if (value === 'S') { // SPACE do nothing
                            projectmodal_obj.push(null);
                        }
                        else if (value === 'T') { // TEXT
                            projectmodal_obj.push(blktxtmap[key])
                        }
                        else if (value === 'V') { // VIDEO LINK
                            projectmodal_obj.push(blkvidmap[key])
                        }
                    }
                }
            }
            catch (e) { refresh() }

            var data = new FormData()

            data.append('creator_profileimg_id', profileimg_id)

            if (projectmodal_imgpattern === 0) {
                data.append('file', projectmodal_img1)
                data.append('file', projectmodal_img2)
                data.append('file', projectmodal_img3)
            }
            else if (projectmodal_imgpattern === 1) {
                data.append('file', projectmodal_img1)
                data.append('file', projectmodal_img2)
                data.append('file', projectmodal_img3)
            }
            else if (projectmodal_imgpattern === 2) {
                data.append('file', projectmodal_img1)
            }

            data.append('selectedimg_pattern', projectmodal_imgpattern)

            var collaborator_ids = []
            var collaborator_profileimg_ids = []
            var collaborator_names = []
            var collaborator_vendortypes = []
            var collaborator_secondaryvendortypes = []
            var collaborator_displaynames = []
            try {
                if (projectmodal_collection_collab_names.length > 0) {
                    projectmodal_collection_collab_names.push(username) // need to add owner, since username is nt being used
                    for (var x = 0; x < projectmodal_collection_collab_names.length; x++) {
                        var curr_id = allCollab_username_obj_map[projectmodal_collection_collab_names[x]]._id
                        var curr_profileimg_id = allCollab_username_obj_map[projectmodal_collection_collab_names[x]].profileimg_id
                        var curr_names = allCollab_username_obj_map[projectmodal_collection_collab_names[x]].username
                        var curr_vendortype = allCollab_username_obj_map[projectmodal_collection_collab_names[x]].vendortype
                        var curr_secondaryvendortype = allCollab_username_obj_map[projectmodal_collection_collab_names[x]].secondaryvendortype
                        var curr_displayname = allCollab_username_obj_map[projectmodal_collection_collab_names[x]].displayname
                        collaborator_ids.push(curr_id)
                        collaborator_profileimg_ids.push(curr_profileimg_id)
                        collaborator_names.push(curr_names)
                        collaborator_vendortypes.push(curr_vendortype)
                        collaborator_secondaryvendortypes.push(curr_secondaryvendortype)
                        collaborator_displaynames.push(curr_displayname)
                    }
                }

            }
            catch (e) { console.log(e) }

            data.append('name', projectmodal_name)
            data.append('maintext', projectmodal_maintext)
            data.append('fontfamily', projectmodal_fontfamily)
            data.append('project_extlinks', projectmodal_extlinks)
            data.append('vendortype', vendortype)
            data.append('vendortype_id', vendortype_id)
            data.append('wedoreventorboth', wedoreventorboth)
            data.append('location', location)
            data.append('longitude', longitude)
            data.append('latitude', latitude)
            data.append('guestcount', guestcount) // is part of a tag

            data.append('collaborator_ids', collaborator_ids)
            data.append('collaborator_profileimg_ids', collaborator_profileimg_ids)
            data.append('collaborator_names', collaborator_names)
            data.append('collaborator_vendortypes', collaborator_vendortypes)
            data.append('collaborator_secondaryvendortypes', collaborator_secondaryvendortypes)
            data.append('collaborator_displaynames', collaborator_displaynames)

            data.append('obj_pattern', projectmodal_blockpattern)

            for (let i = 0; i < projectmodal_obj.length; i++) {
                data.append('obj', projectmodal_obj[i])
            }

            for (var y = 0; y < projectmodal_imagefiles.length; y++) {
                // var curr_imgorientation = Checklandscape(projectmodal_imagefiles[x]) ? 'landscape' : 'portrait'
                data.append('file', projectmodal_imagefiles[y])
            }

            let options = {//VENDOR_PROFILE_CREATE_PROJECT_LINK
                method: 'POST', url: VENDOR_PROFILE_CREATE_PROJECT_LINK,
                // headers: { 'Authorization': 'Bearer ' + token },
                headers: { 'Authorization': 'Bearer ' + token,'Content-Type': 'multipart/form-data' },
                data: data,
            }

            try {
                axios(options).then((response) => {
                    const { error, project } = response.data// message
                    const { status } = response
                    if (error === false && status === 200) {
                        props.update(project) // update to existingprojects
                        resetProjectmodal() // will close the modal
                    }
                    else {
                        setDisplaymsg('Fail to create new project.')
                    }
                })
            }
            catch (e) {
                setDisplaymsg('Fail to create new project.')
                // refresh()
            }
        }
    }, [disabled])

    const checkProjAndCreate = () => {

        function validateForm() {
            var result = true // everything is fine
            var error = []
            if (Validateform({ form_key: 'name', form_parts: { name: projectmodal_name } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='name' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project name field cannot be empty{IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'maintext', form_parts: { maintext: projectmodal_maintext } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='maintext' style={{ display: 'flex', flexDirection: 'row' }}>Error: Main Text field text cannot be empty{IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'projectmodal_img123', form_parts: { projectmodal_imgpattern: projectmodal_imgpattern, projectmodal_img1: projectmodal_img1, projectmodal_img2: projectmodal_img2, projectmodal_img3: projectmodal_img3 } })) {
                // if gt error, den result = false and input new error
                if (projectmodal_imgpattern === 0) {
                    error.push(<div key='projectmodal_img123' style={{ display: 'flex', flexDirection: 'row' }}>Error: Display format required at least 3 images{IMPORTANT} </div>)
                    result = false
                }
                else if (projectmodal_imgpattern === 1) {
                    error.push(<div key='projectmodal_img123' style={{ display: 'flex', flexDirection: 'row' }}>Error: Display format required at least 3 images{IMPORTANT} </div>)
                    result = false
                }
                else if (projectmodal_imgpattern === 2) {
                    error.push(<div key='projectmodal_img123' style={{ display: 'flex', flexDirection: 'row' }}>Error: Display format required at least 1 image{IMPORTANT} </div>)
                    result = false
                }
            }
            if (Validateform({ form_key: 'blocks_obj_min', form_parts: { blocks: blocks } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_obj_min' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project objects required at least {MIN_NUM_PROJECT_OBJ} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_obj_max', form_parts: { blocks: blocks } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_obj_max' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project objects at most {MAX_NUM_PROJECT_OBJ} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_img_min', form_parts: { blocks: blocks } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_img_min' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project objects images required at least {MIN_NUM_PROJECT_IMG} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_img_max', form_parts: { blocks: blocks } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_img_max' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project objects images at most {MAX_NUM_PROJECT_IMG} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_txt_size', form_parts: { blocks: blocks, blktxtmap: blktxtmap } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_txt_size' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project object text word limit between {MIN_NUM_PROJECT_OBJ_TEXT_SIZE + ' and ' + MAX_NUM_PROJECT_OBJ_TEXT_SIZE} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_vid_size', form_parts: { blocks: blocks, blkvidmap: blkvidmap } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_vid_size' style={{ display: 'flex', flexDirection: 'row' }}>Error: Only Youtube video link will be accepted {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_i_ntundefined', form_parts: { blocks: blocks, allimgsmap: allimgsmap } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_i_ntundefined' style={{ display: 'flex', flexDirection: 'row' }}>Error: Please ensure all images uploaded {IMPORTANT} </div>)
                result = false
            }
            return { 'validated_result': result, 'error': error }
        }
        // number of project should be MAX_NUM_PROJECT_CAN_BE_CREATED only.
        if (existingprojects.length >= MAX_NUM_PROJECT_CAN_BE_CREATED) props.closeoropen()
        var { validated_result, error } = validateForm()
        validated_result ? setDisabled(true) : setDisplaymsg(error)
    }

    const resetProjectmodal = () => {
        setProjectmodal_name(EMPTY)
        setProjectmodal_extlinks(EMPTY)
        setProjectmodal_selected_collab_name(EMPTY)
        setProjectmodal_maintext(EMPTY)
        setProjectmodal_collection_collab_names([])
        setProjectmodal_fontfamily(null)
        setDisabled(false)
        setDisplaymsg(EMPTY)
        setLocation(EMPTY)
        setLongitude(EMPTY)
        setLatitude(EMPTY)
        props.closeoropen()
    }

    const renderName = () => {
        return (
            <div style={{ maxWidth: dimW, minWidth: dimW, minHeight: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', wordBreak: 'break-all', textAlign: 'center', border: 'thin solid #d9d8d8', borderRadius: 5, fontFamily: projectmodal_fontfamily }}>
                {projectmodal_name === EMPTY ? <div style={{ color: SOFT_GREY }}>Project name</div> : projectmodal_name}
            </div>
        )
    }

    const renderMaintxt = () => {
        return (
            <div style={{ maxWidth: dimW, minWidth: dimW, minHeight: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', wordBreak: 'break-all', textAlign: 'center', border: 'thin solid #d9d8d8', borderRadius: 5, fontFamily: projectmodal_fontfamily }}>
                {projectmodal_maintext === EMPTY ? <div style={{ color: SOFT_GREY }}>Main Text</div> : projectmodal_maintext}
            </div>
        )
    }

    const renderCollaborators = () => {
        var result = []
        var renderDeletecollaborator = (x) => { // render X
            return (
                <div
                    style={{ border: 'solid thin' + SOFT_GREY, borderRadius: 50, height: '100%', }}
                    onClick={() => {
                        var temp = [...projectmodal_collection_collab_names]
                        temp.splice(x, 1)
                        setProjectmodal_collection_collab_names(temp)
                    }}>
                    <div style={{ paddingLeft: 5, paddingRight: 5, fontWeight: 'bold' }}>X</div>
                </div>
            )
        }
        for (let x = 0; x < projectmodal_collection_collab_names.length; x++) { // render whole collab badges
            result.push(
                <div
                    index={x}
                    key={projectmodal_collection_collab_names[x] + x}
                    style={{ padding: 5, margin: 2.5, borderRadius: 5, border: 'solid thin #D9D8D8', position: 'static', display: 'flex', flexDirection: 'row', overflowX: 'auto' }}
                >
                    {projectmodal_collection_collab_names[x]}
                    {EMPTYSPACE}
                    {renderDeletecollaborator(x)}
                </div>
            )
        }
        return result
    }

    const btnsRow = (ret, cre) => {
        return <div style={{ display: 'flex', fontSize: 14, placeContent: 'space-between', margin: '0px 0px 20px 0px', fontWeight: 'bold', alignItems: 'center' }}>
            {ret ? <button
                className={"createprojectcontent_return"}
                disabled={disabled}
                style={{ ...BUTTONSTYLE, outline: '' }}
                onClick={resetProjectmodal}>
                {LEFTARROW_ICON_SMALL}
                <div className="createprojectcontent_returntxt">Return</div>
            </button> : null}
            {cre ? <button
                className={"createprojectcontent_save"}
                disabled={disabled}
                style={{ ...BUTTONSTYLE, outline: '' }}
                onClick={checkProjAndCreate}>
                <div className="createprojectcontent_savetxt">Create</div>
            </button> : null}
        </div>
    }

    var addbtn = <button
        className="cprojcontent_addanddelbtn"
        key={uuidv4()}
        onClick={(e) => {
            setImgmodalgate(false)
            setShowblockdialog(true)
            setAddbtnstate(addbtn) // this addbtn is clicked!
        }}>+</button>

    const closeDialog = () => setShowblockdialog(false);

    const delbtn = (key, remv) => <button className="cprojcontent_addanddelbtn" key={key + 'xbtn'} onClick={remv} >x</button>

    const twoportrait = <button
        className="cprojcontent_modalbtn"
        onClick={() => {
            const key = uuidv4()
            const singlerow = <div key={key} value={'IP'} className="cprojcontent_singlerow">
                <Twoportraitinput
                    key={key}
                    updateimg1={(file, dim) => {
                        setAllimgsmap((currallimgsmap) => {
                            currallimgsmap[key + '1'] = { file: file, dim: dim }
                            return currallimgsmap
                        })
                    }}
                    updateimg2={(file, dim) => {
                        setAllimgsmap((currallimgsmap) => {
                            currallimgsmap[key + '2'] = { file: file, dim: dim }
                            return currallimgsmap
                        })
                    }}
                />
                {delbtn('delbtn', () => setRmblk(singlerow))}
            </div>
            var local_blocks = blocks.slice()
            local_blocks.splice(addbtnloc + 1, 0, singlerow);
            local_blocks.splice(addbtnloc + 2, 0, addbtn);
            setBlocks(local_blocks)
            closeDialog()
        }}>
        <div className="cprojcontent_inputtxt">Two Portrait</div>
    </button>

    const onelandscape = <button
        className="cprojcontent_modalbtn"
        onClick={() => {
            const key = uuidv4()
            const singlerow = <div key={key} value={'I'} dim="landscape" className="cprojcontent_singlerow">
                <Oneimageinput
                    orientation={'landscape'}
                    updateimg={(file, dim) => {
                        setAllimgsmap((currallimgsmap) => {
                            currallimgsmap[key] = { file: file, dim: dim }
                            return currallimgsmap
                        })
                    }}
                />
                {delbtn('delbtn', () => setRmblk(singlerow))}
            </div>
            var local_blocks = blocks.slice()
            local_blocks.splice(addbtnloc + 1, 0, singlerow);
            local_blocks.splice(addbtnloc + 2, 0, addbtn);
            setBlocks(local_blocks)
            closeDialog()
        }}>
        <div className="cprojcontent_inputtxt">One Landscape</div>
    </button>

    const oneportrait = <button
        className="cprojcontent_modalbtn"
        onClick={() => {
            const key = uuidv4()
            const singlerow = <div key={key} value={'I'} dim="portrait" className="cprojcontent_singlerow">
                <Oneimageinput
                    orientation={'portrait'}
                    updateimg={(file, dim) => {
                        setAllimgsmap((currallimgsmap) => {
                            currallimgsmap[key] = { file: file, dim: dim }
                            return currallimgsmap
                        })
                    }}
                />
                {delbtn('delbtn', () => setRmblk(singlerow))}
            </div>
            var local_blocks = blocks.slice()
            local_blocks.splice(addbtnloc + 1, 0, singlerow);
            local_blocks.splice(addbtnloc + 2, 0, addbtn);
            setBlocks(local_blocks)
            closeDialog()
        }}>
        <div className="cprojcontent_inputtxt">One Portrait</div>
    </button>

    const imgbtn = <div className="cprojcontent_modalbtn" onClick={() => setImgmodalgate(true)}>Image</div>

    const spacebtn = <button
        className="cprojcontent_modalbtn"
        key={'spacebtn'}
        onClick={() => {
            closeDialog()
            var index = addbtnloc
            var local_blocks = blocks.slice()
            var emptynode = <div key={uuidv4()} value={"S"} style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
                <div style={{ maxWidth: dimW, minWidth: dimW, height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', border: 'thin black dashed' }}>Empty Space</div>
                {delbtn('delbtn', () => setRmblk(emptynode))}
            </div>
            local_blocks.splice(index + 1, 0, emptynode);
            local_blocks.splice(index + 2, 0, addbtn);
            setBlocks(local_blocks)
        }}>Space</button>

    const textbtn = <button
        className="cprojcontent_modalbtn"
        key={'textbtn'}
        onClick={() => {
            closeDialog()

            var index = addbtnloc
            var local_blocks = blocks.slice()
            var key = uuidv4()

            var local_blktxtmap = blktxtmap
            local_blktxtmap[key] = ''
            setBlktxtmap(local_blktxtmap)

            var textfield = (
                <div key={key} value={"T"} style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', height: 110, paddingTop: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormGroup controlId="formControlsTextarea" >
                        <FormControl
                            style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', height: 100, alignItems: 'center', justifyContent: 'space-between', }}
                            placeholder="Enter description"
                            value={local_blktxtmap.key}
                            onChange={(event) => {
                                var inner_blktxtmap = blktxtmap
                                inner_blktxtmap[key] = event.target.value
                                setBlktxtmap(inner_blktxtmap)
                            }}
                            componentClass="textarea"
                        />
                    </FormGroup>
                    {delbtn('delbtn', () => {
                        setRmblk(textfield)
                        var inner_blktxtmap = blktxtmap
                        delete inner_blktxtmap[key]
                        setBlktxtmap(inner_blktxtmap)
                    })}
                </div>
            )
            local_blocks.splice(index + 1, 0, textfield);
            local_blocks.splice(index + 2, 0, addbtn);
            setBlocks(local_blocks)

        }}>Text</button>

    const videobtn = <button
        className="cprojcontent_modalbtn"
        key={'videobtn'}
        onClick={() => {
            closeDialog()
            var index = addbtnloc
            var local_blocks = blocks.slice()
            var key = uuidv4()

            var local_blkvidmap = blkvidmap
            local_blkvidmap[key] = ''
            setBlkvidmap(local_blkvidmap)

            var youtubetxtfield = (
                <div key={key} value={"V"} style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FieldGroup
                        id={uuidv4() + 'dynamictxtfield'}
                        style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}
                        type="dynamictxtfield"
                        placeholder="Youtube/Vimeo video link only"
                        value={local_blkvidmap.key}
                        onChange={(event) => {
                            var inner_blkvidmap = blkvidmap
                            inner_blkvidmap[key] = event.target.value
                            setBlkvidmap(inner_blkvidmap)
                        }}
                    />
                    {delbtn('delbtn', () => setRmblk(youtubetxtfield))}
                </div>
            )
            local_blocks.splice(index + 1, 0, youtubetxtfield);
            local_blocks.splice(index + 2, 0, addbtn);
            setBlocks(local_blocks)
        }}>Video</button>

    return !disabled ?
        <div style={{ padding: '0px 10px', width: '100%' }}>

            <Formcompoenents
                setName={(name_) => { setProjectmodal_name(name_) }}
                nameVal={projectmodal_name}

                setMaintext={(maintext_) => { setProjectmodal_maintext(maintext_) }}
                maintextVal={projectmodal_maintext}

                setFontfamily={(ff) => { setProjectmodal_fontfamily(ff) }}
                fontfamily={projectmodal_fontfamily}

                setLink={(link_) => { setProjectmodal_extlinks(link_) }}
                linkVal={projectmodal_extlinks}

                setLocation={(location_) => {
                    setLocation(location_)
                    if (location_ === EMPTY) { setLongitude(0); setLatitude(0); }
                    else { setLongitude(longitude); setLatitude(latitude) }
                }}
                locationVal={location}

                setLongitude={(longitude_) => { setLongitude(longitude_) }}
                longitudeVal={longitude}

                setLatitude={(latitude_) => { setLatitude(latitude_) }}
                latitudeVal={latitude}

                setWedoreventorboth={(category) => { setWedoreventorboth(category) }}
                wedoreventorbothVal={wedoreventorboth}

                setGuestcount={(guestcount_) => { setGuestcount(guestcount_) }}
                guestcountVal={guestcount}
            />

            {EMPTYSPACE}

            <div style={{ padding: 5, border: 'thin solid #d9d8d8', }}>
                <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Collaborator(s) {OPTIONAL}</ControlLabel>
                <div style={{ padding: 5, height: 'max-content' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingBottom: 5 }}>
                        <FormControl
                            style={{ marginRight: 5, height: 40 }}
                            componentClass="select"
                            placeholder="select"
                            value={projectmodal_selected_collab_name}
                            onChange={(event) => { setProjectmodal_selected_collab_name(event.target.value) }}
                        >
                            <option value=""></option>
                            {allCollab_names}
                        </FormControl>
                        <button
                            style={{ float: 'right', ...BUTTONSTYLE, overflow: 'hidden' }}
                            onClick={() => {
                                if (projectmodal_selected_collab_name !== '') {
                                    for (let i = 0; i < projectmodal_collection_collab_names.length; i++) {
                                        if (projectmodal_selected_collab_name === projectmodal_collection_collab_names[i]) { // if alrdy selected den do nothing
                                            return
                                        }
                                    }
                                    var temp = [...projectmodal_collection_collab_names]
                                    temp.push(projectmodal_selected_collab_name)
                                    setProjectmodal_collection_collab_names(temp)
                                }
                            }}
                        >
                            Add
                        </button>
                    </div>
                    <div style={{ paddingTop: 7, paddingBottom: 7, width: '100%', minHeight: 50, flexDirection: 'row', display: 'flex', overflowX: 'auto', flexWrap: 'wrap' }}>
                        {renderCollaborators()}
                    </div>
                </div>
            </div>

            {EMPTYSPACE}

            <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Display card {IMPORTANT}</ControlLabel>

            <Selectpattern
                pattern={projectmodal_imgpattern}
                onSelect={(result, pattern) => {
                    const display_type = result[1]
                    const display_img = result[0]

                    if (pattern === 0) {
                        if (display_type === 'main') setProjectmodal_img1(display_img)
                        else if (display_type === 'left') setProjectmodal_img2(display_img)
                        else if (display_type === 'right') setProjectmodal_img3(display_img)
                    }
                    else if (pattern === 1) {
                        if (display_type === 'main') setProjectmodal_img1(display_img)
                        else if (display_type === 'top') setProjectmodal_img2(display_img)
                        else if (display_type === 'down') setProjectmodal_img3(display_img)
                    }
                    else if (pattern === 2) { // accept main only
                        if (display_type === 'main') setProjectmodal_img1(display_img)
                        else refresh()
                    }
                }}
                onNext={() => {
                    projectmodal_imgpattern === 0 || projectmodal_imgpattern === 1 ? setProjectmodal_imgpattern(projectmodal_imgpattern + 1) : setProjectmodal_imgpattern(0)
                    setProjectmodal_img1(EMPTY)
                    setProjectmodal_img2(EMPTY)
                    setProjectmodal_img3(EMPTY)
                }}
                onBack={() => {
                    projectmodal_imgpattern === 2 || projectmodal_imgpattern === 1 ? setProjectmodal_imgpattern(projectmodal_imgpattern - 1) : setProjectmodal_imgpattern(2)
                    setProjectmodal_img1(EMPTY)
                    setProjectmodal_img2(EMPTY)
                    setProjectmodal_img3(EMPTY)
                }}
            />

            {EMPTYSPACE}

            <ControlLabel style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'white' }}>Project Objects ( min {MIN_NUM_PROJECT_OBJ}, max {MAX_NUM_PROJECT_OBJ} ) {IMPORTANT}</ControlLabel>

            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: 100, backgroundColor: 'white', border: 'thin solid #d9d8d8', fontFamily: projectmodal_fontfamily }}>
                {EMPTYSPACE}
                {renderName()}
                {EMPTYSPACE}
                {renderMaintxt()}
                {EMPTYSPACE}
                {blocks}
                {EMPTYSPACE}
            </div>

            <Dialog
                open={showblockdialog}
                onClose={closeDialog}
                fullScreen={false}
                aria-labelledby="responsive-dialog-title"
            >
                {imgmodalgate
                    ? <div style={{ display: 'flex', width: 105, height: 190, flexDirection: 'column', alignItems: 'center', padding: '5px 0px', justifyContent: 'space-between' }}>
                        {onelandscape}
                        {oneportrait}
                        {twoportrait}
                    </div>
                    : <div style={{ display: 'flex', width: 105, height: 250, flexDirection: 'column', alignItems: 'center', padding: '5px 0px', justifyContent: 'space-between', cursor: 'pointer' }}>
                        {imgbtn}
                        {spacebtn}
                        {textbtn}
                        {videobtn}
                    </div>}
            </Dialog>
            {EMPTYSPACE}
            <div className="editprojectcontent_displaymsgroot">
                <div className="editprojectcontent_displaymsg" >{displaymsg}</div>
                {btnsRow(true, true)}
            </div>
        </div>
        : <Creatingloading />
}

export default Projectcontent