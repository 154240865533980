
import React, { useState } from 'react'
import { Checklandscape } from '../../../../../../../../components'
import './Oneimageinput.css'

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)) // eslint-disable-line
}

function Oneimageinput({ orientation, updateimg }) {

    const key = uuidv4()
    const [img1, setImg1] = useState('')

    return <div key={key} value={"I"} className="oneimageinput_root">
        <div className={"oneimageinput_imginputholder_" + orientation}>
            <img className={"oneimageinput_img_" + orientation} src={img1} alt="" />
            <input className="oneimageinput_input" accept="image/*" type='file' name="input-file"
                onChange={(event) => {
                    let file = event.target.files[0];
                    var reader = new FileReader();
                    reader.onloadend = (e) => {
                        var img = document.createElement("img");
                        img.onload = () => {
                            if (Checklandscape(img) && orientation === 'landscape') { // true is landscape
                                setImg1(reader.result)
                                updateimg(file, 'landscape')
                            }
                            else if (!Checklandscape(img) && orientation === 'portrait') {
                                setImg1(reader.result)
                                updateimg(file, 'portrait')
                            }
                            else return
                        }
                        img.src = e.target.result;
                    }
                    reader.readAsDataURL(file);
                    event.target.value = null; // users can reupload similar file
                }} />
        </div>
    </div>

}

export default Oneimageinput