import React, { Component } from 'react';
import ToggleBadge from '../../../../components/Togglebadge'
import { SEARCH_FILTER_WEDDING, EMPTY, FORM_SECOND, IMPORTANT, FORM_ERROR_STYLE, READABLE_VENDORTYPE_MAP } from '../../../../config/constants'
import { BUTTONSTYLE } from '../../../../config/cssconstants'
import axios from 'axios'
import { VENDORTYPE_VENDORSPECIFIC_UPDATE_MAP } from '../../../../config/api';
import { Validateform } from '../Validateform/Validateform';
import refresh from '../../../../components/refresh';

const SUCCESS = 'Successfully Updated'
const FAILURE = 'Fail to update'

class Vendortypespecific extends Component {
    constructor(props) {
        super(props)
        this.state = {
            curr_vt: [],
            eventorwed: EMPTY,
            specific: [],
            filtertags_map: {},
            categories_arr: [],
            isLoaded: false,
            btnstate: false,
            msg: [],
            active: false, // this is to check if the filter is active or not
        }
    }

    initialize() {
        try {
            const { curr_vt = EMPTY, eventorwed, specific } = this.props
            if (curr_vt !== EMPTY) {
                var filtertags_map = SEARCH_FILTER_WEDDING // 'Event' and 'Both' are subset of 'Wedding' so use SEARCH_FILTER_WEDDING


                if ('Sort' in filtertags_map[curr_vt]) {
                    // removal of Sort subcategory
                    delete filtertags_map[curr_vt]['Sort']
                    filtertags_map[curr_vt].Keys.shift()
                }

                var filter_active_state
                var categories_arr

                if (filtertags_map[curr_vt]) {
                    filter_active_state = filtertags_map[curr_vt].active
                    categories_arr = filtertags_map[curr_vt].Keys
                }
                else {
                    filter_active_state = false
                    categories_arr = []
                }

                this.setState({
                    curr_vt: curr_vt,
                    categories_arr: categories_arr,
                    filtertags_map: filtertags_map,
                    isLoaded: true,
                    specific: specific,
                    active: filter_active_state,
                    eventorwed: eventorwed
                })
            }
        }
        catch (e) {
            // console.log(e)
        }

    }

    componentDidMount() {
        this.initialize()
    }

    componentDidUpdate(prevProps) {
        if (this.props.curr_vt !== prevProps.curr_vt) {
            this.initialize()
        }
    }

    onUpdate = async () => {
        const { curr_vt, specific } = this.state
        const { curr_vt_id, type_vt } = this.props
        this.setState({ btnstate: true })
        var localError = []
        var gotError_validate = () => {
            var gotError = false
            if (Validateform({ form_key: type_vt, form_parts: { specific } })) {
                localError.push(<div key='name_error' style={FORM_ERROR_STYLE}>Error : {type_vt} field {IMPORTANT}</div>)
                gotError = true
            }
            return gotError
        }
        if (!gotError_validate()) {

            var rawToken = localStorage.getItem('token')
            const token = rawToken.substring(4, rawToken.length)   // remove 'JWT '
            // axios send data back to backend
            let options = {
                method: 'POST',
                url: VENDORTYPE_VENDORSPECIFIC_UPDATE_MAP[curr_vt],
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { curr_vt, curr_vt_id, specific }
            }

            await axios(options).then((response) => {
                const { status } = response
                if (status === 200) {
                    this.setState({ msg: SUCCESS }, () => {
                        setTimeout(() => { this.setState({ msg: EMPTY }) }, FORM_SECOND)
                    })
                }
                else {
                    this.setState({ FAILURE })
                }
            }).then(() => {
                this.setState({ btnstate: false })
            }).catch(e => {
                refresh()
            })
        }
        else {
            this.setState({ msg: localError }, () => { refresh() })
        }
    }

    togglebtn_clicked = (item) => { // this is called from ToggleBadge
        const { specific } = this.state
        var local_specific = specific.slice()
        if (specific.indexOf(item) > -1) {
            var index = specific.indexOf(item)
            local_specific.splice(index, 1)
        }
        else {
            local_specific.push(item)
        }
        this.setState({ specific: local_specific })
    }

    render_filtertags = () => {
        var { curr_vt, specific, filtertags_map, categories_arr, isLoaded, active } = this.state
        if (active) {
            if (!isLoaded) return <div style={{ display: 'flex', justifyContent: 'center' }}>is loading...</div>
            var result = []
            for (let i = 0; i < categories_arr.length; i++) {
                var curr_category = categories_arr[i]
                var semi_result = []
                for (let j = 0; j < filtertags_map[curr_vt][curr_category].length; j++) {
                    const { header } = filtertags_map[curr_vt][curr_category][j]
                    semi_result.push(<ToggleBadge key={header} selected_arr={specific} togglebtn_clicked={this.togglebtn_clicked} >
                        {header}
                    </ToggleBadge >)
                }

                result.push(
                    <div key={curr_category + i + active} style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                        <div style={{ paddingLeft: 10 }}>{categories_arr[i]}</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', color: 'rgb(112, 112, 112)' }}> {semi_result} </div>
                    </div>
                )
            }
            return result

        }
        else {
            return <div></div>
        }
    }

    render() {
        var { curr_vt, btnstate, active } = this.state
        const { showtopline, MAINTITLE_CSS } = this.props
        return (
            active
                ? <div key={active} style={{ display: 'flex', flexDirection: 'column' }}>
                    {showtopline ? <div style={{ borderTop: 'thin solid black', width: '100%', marginBottom: 10, marginTop: 10, }} /> : <div />}
                    <div>
                        <div style={{ ...MAINTITLE_CSS, marginTop: 10 }}>{READABLE_VENDORTYPE_MAP[curr_vt] + ' Tags'}</div>
                        {this.render_filtertags()}
                    </div>

                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> {this.state.msg}</div> {/*this will show message*/}
                    </div>

                    <div style={{ width: '100%', height: 40, marginBottom: 5 }}>
                        <button disabled={btnstate} onClick={this.onUpdate} style={{ ...BUTTONSTYLE, float: 'right' }} > Update</button>
                    </div>

                </div>
                : <div key={'active' + active} />

        )
    }
}
export default Vendortypespecific