import React, { useState, useEffect } from 'react';
import { EMPTY, USER_RESET_PW_MSG, NAV_BAR_HEIGHT } from '../../config/constants'
import { VENDOR_FORGTPWRESETCHECK, VENDOR_FORGTPWRESET } from '../../config/api'
import { BUTTONSTYLE } from '../../config/cssconstants';
import { Textfield, Vregsignupmain } from '../../components';

import axios from 'axios'

function Resetpw(props) {
    const wt = window.innerWidth
    const ht = window.innerHeight

    const [gate, setGate] = useState(false)
    const [fieldgate, setFieldgate] = useState(false)
    const [resettoken, setResettoken] = useState(EMPTY)
    const [pw1, setPw1] = useState(EMPTY)
    const [pw2, setPw2] = useState(EMPTY)
    const [resetmsg, setResetmsg] = useState(EMPTY)

    useEffect(() => {
        try {
            const resettoken = props.match.params.resettoken
            const { ut } = props
            let options = { // check if is not intruder
                method: 'POST',
                url: ut === 'v' ? VENDOR_FORGTPWRESETCHECK : '',
                headers: { 'Authorization': 'Bearer ' + resettoken, 'Content-Type': 'application/json' }, //need token from the web
                data: { resettoken }
            }
            axios(options).then((result) => {
                const { message } = result.data
                if (message === 'SUCCESS_RESETCHECK') {
                    setResettoken(resettoken)
                    setGate(true)
                }
                else {
                    setGate(false)
                    props.history.push('/')
                }

            }).catch((error) => {
                props.history.push('/')
            })
        }
        catch (e) {
            props.history.push('/')
        }

    }, []);


    return gate
        ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: ht - NAV_BAR_HEIGHT, width: '100%', touchAction: 'manipulation', overflow: 'hidden' }}>
            <div style={{ height: 600, width: 'max-content', maxWidth: 500, padding: '50px 0px', backgroundColor: 'transparent', flexDirection: 'column', alignContent: 'center', borderRadius: '0 0 10px', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                {Vregsignupmain('Reset Password')}
                <div style={{ height: 250, width: 300 }}>

                    {/* {Textfield('resetpw1', 'New Password', pw1, 'password', (e) => { setPw1(e.target.value) })}
                    {Textfield('resetpw2', 'Verify New Password', pw2, 'password', (e) => { setPw2(e.target.value) })} */}

                    <Textfield id={'resetpw1'} label={'New Password'} val={pw1} type={'password'} onchange={(e) => { setPw1(e.target.value) }} />
                    <Textfield id={'resetpw2'} label={'Verify New Password'} val={pw2} type={'password'} onchange={(e) => { setPw2(e.target.value) }} />

                    <div style={{ height: '40px', marginTop: 8 }}>
                        <button
                            disabled={fieldgate}
                            style={{ ...BUTTONSTYLE, width: '100%', }}
                            onClick={() => {
                                if (fieldgate === false) {
                                    setFieldgate(true)
                                    if ((pw1 !== '' && pw2 !== '') || pw2 !== '' || pw1 !== '') {
                                        let options = {
                                            method: 'POST',
                                            url: props.ut === 'v' ? VENDOR_FORGTPWRESET : '',
                                            headers: { 'Authorization': 'Bearer ' + resettoken, 'Content-Type': 'application/json' }, //need token from the web
                                            data: { resettoken, pw1, pw2, provider: 'emailpw' }
                                        }
                                        axios(options).then((result) => {
                                            const { message } = result.data
                                            setResetmsg(USER_RESET_PW_MSG[message])
                                            if (message === 'SUCCESS_RESET') {
                                                setFieldgate(true)
                                            }
                                            else if (message === 'FAILURE_RESET') {
                                                setFieldgate(false)
                                            }
                                        })
                                    }
                                }
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
                <div style={{ height: 60, marginTop: 8, padding: 10 }}>
                    {resetmsg}
                </div>
            </div>
        </div>
        : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: ht, width: wt, }}>Loading</div>

}


export default Resetpw