import React, { useState, useEffect, } from 'react'
import { FormControl, FormGroup, HelpBlock, ControlLabel } from "react-bootstrap"
import axios from 'axios'
import { VENDOR_PROJECT_READ_SPECIFICVENDOREDITABLE, VENDOR_PROFILE_EDIT_PROJECT_LINK } from '../../../../../../config/api'
import { EMPTY, IMPORTANT, WEB, SOFT_GREY, EMPTYSPACE, MIN_NUM_PROJECT_IMG, MAX_NUM_PROJECT_IMG, MIN_NUM_PROJECT_OBJ, MAX_NUM_PROJECT_OBJ, MAX_NUM_PROJECT_OBJ_TEXT_SIZE, MIN_NUM_PROJECT_OBJ_TEXT_SIZE, IMG_ALT, LEFTARROW_ICON_SMALL, HTTPSWEBSITE_URL } from '../../../../../../config/constants'
import { ScreenWidth } from '../../../../../../components'
import { Validateform } from '../Validateform'
import { BUTTONSTYLE } from '../../../../../../config/cssconstants'
import { Dialog } from '@material-ui/core';
import { useEndpoint } from '../../../../../../async_components';
import Formcompoenents from '../Formcompoenents/Formcompoenents';
import Patternone from '../Projectcardpatterns/Patternone'
import Patterntwo from '../Projectcardpatterns/Patterntwo'
import Patternthree from '../Projectcardpatterns/Patternthree'
import { Oneimageinput, Twoportraitinput } from '../Projectcreation/components'
import './Editprojectcontent.css'

function FieldGroup({ id, label, help, ...props }) {
    return (
        <FormGroup controlId={id}>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
    );
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)) // eslint-disable-line
}

const patterntitle = {
    0: <div style={{ color: SOFT_GREY }}> All Landscape</div>,
    1: <div style={{ color: SOFT_GREY }}> All Portrait</div>,
    2: <div style={{ color: SOFT_GREY }}> Single Portrait </div>
}

const assignCollaboratorNames = (collaborator_names, collaborator_profileimg_ids) => {
    var collaborator_names_html = []
    if (collaborator_names !== null && collaborator_names.length!==0) {
        for (let i = 0; i < collaborator_names.length; i++) {
            collaborator_names_html.push(
                <div key={collaborator_names[i]} style={{ border: 'solid thin #D9D8D8', borderRadius: 50, margin: 1, alignItems: 'center', width: '125px', overflowX: 'auto', display: 'flex' }}>
                    <div style={{ display: 'flex', marginLeft: 2, marginBottom: 1, marginTop: 1, alignItems: 'center', overflow: 'hidden' }}>
                        <img alt={IMG_ALT} className='existingImg' style={{ borderRadius: 50, width: 30, height: 30, border: 'solid thin white', }} src={collaborator_profileimg_ids[i].link} />
                        <div style={{ marginLeft: 10, overflow: 'hidden' }}>{collaborator_names[i]}</div>
                    </div>
                </div>
            )
        }
    }
    return collaborator_names_html
}

function Editprojectcontent(props) {

    const { proj_id } = props

    const [name, setName] = useState(EMPTY)
    const [maintext, setMaintext] = useState(EMPTY)
    const [ff, setFf] = useState(null)
    const [project_extlinks, setProject_extlinks] = useState(EMPTY)
    // const [collaborator_ids, setCollaborator_ids] = useState([])
    // const [collaborator_profileimg_ids, setCollaborator_profileimg_ids] = useState([])
    const [collaborator_names, setCollaborator_names] = useState([])
    // const [collaborator_vendortypes, setCollaborator_vendortypes] = useState([])
    // const [selectedimg_extlinks, setSelectedimg_extlinks] = useState([])// not in use, is decomppose to selectedimg_main, selectedimg_left, selectedimg_right
    // const [creator, setCreator] = useState(EMPTY)
    const [wedoreventorboth, setWedoreventorboth] = useState(null) // ['Wedding', 'Event', 'Both']
    const [version_code, setVersion_code] = useState(EMPTY)// ensure this project only a single person can edit every single instance
    const [vendor_proj_token, setVendor_proj_token] = useState(EMPTY)// PUT THIS AS HEADER TO EDIT SPECIFIC PROJECT
    const [selectedimg_pattern, setSelectedimg_pattern] = useState(0) // 0 [main, left, right] , 1 [ main, top, down], 2 [main]
    const [location, setLocation] = useState(EMPTY)
    const [longitude, setLongitude] = useState(0)
    const [latitude, setLatitude] = useState(0)
    const [guestcount, setGuestcount] = useState(EMPTY)

    const [collaborator_names_html, setCollaborator_names_html] = useState([])// convert to DOM elements
    const [disable_editbtn, setDisable_editbtn] = useState(false)
    const [displaymsg, setDisplaymsg] = useState(EMPTY)
    const [fixedcard, setFixedcard] = useState([]) // show the fixed display card uneditable

    const [imgmodalgate, setImgmodalgate] = useState(false)
    const [allimgsmap, setAllimgsmap] = useState({})

    // for blocks objects
    const [showblockdialog, setShowblockdialog] = useState(false) // show dialog
    const [obj_map, setObj_map] = useState({})
    const [obj_key, setObj_key] = useState([])
    const [addbtnloc, setAddbtnloc] = useState(0) // to detect which add btn pressed
    const [addbtnstate, setAddbtnstate] = useState(-1) // to detect which add btn pressed
    const [blocks, setBlocks] = useState([<button key={uuidv4()} className="cprojcontent_addanddelbtn" onClick={(e) => { setShowblockdialog(true); setImgmodalgate(false); setAddbtnloc(0); }}>+</button>]) // first +

    const [rmblk, setRmblk] = useState(null)  // to detect which which blk to remove
    const [blktxtmap, setBlktxtmap] = useState({})
    const [blkvidmap, setBlkvidmap] = useState({})

    const w = ScreenWidth()
    const dimW = w < WEB ? 230 : w * 0.5    // if is web max/min width is 400 else 230

    const data = useEndpoint({
        method: 'POST',
        url: VENDOR_PROJECT_READ_SPECIFICVENDOREDITABLE,
        headers: { 'Authorization': 'Bearer ' + props.token },
        data: {
            project_id: props.proj_id,
            editortype_id: props.editortype_id,
        }
    })

    useEffect(() => {
        if (data.complete) {
            const { item, vendor_proj_token } = data.data
            const {
                selectedimg_extlinks, selectedimg_pattern,
                obj_map, obj_key,
                wedoreventorboth, location, locationpt, guestcount
            } = item
            const pattern = {
                0: <Patternone key={uuidv4()} editable={false} img1={selectedimg_extlinks[0]} img2={selectedimg_extlinks[1]} img3={selectedimg_extlinks[2]} />,
                1: <Patterntwo key={uuidv4()} editable={false} img1={selectedimg_extlinks[0]} img2={selectedimg_extlinks[1]} img3={selectedimg_extlinks[2]} />,// havent do yet 
                2: <Patternthree key={uuidv4()} editable={false} img1={selectedimg_extlinks[0]} />// havent do yet
            }
            var local_blocks = blocks.slice() // hold the div element
            var local_allimgsmap = allimgsmap

            for (let i = 0; i < obj_key.length; i++) {
                const key = obj_key[i]
                const { pattern, value, dimorientation } = obj_map[key]
                if (pattern === 'I') {
                    local_allimgsmap[key] = obj_map[key]
                    if (dimorientation === 'landscape') {
                        const imgdom = (
                            <div key={key} value={"I"} dim={'landscape'} style={{ display: 'flex', flexDirection: 'row', height: 180, width: 280, alignItems: 'center', justifyContent: 'space-between', }}>
                                <img alt='' style={{ width: 230, height: 160, padding: 5, border: 'thin black dashed' }} src={value} />
                                {delbtn(() => setRmblk(imgdom))}
                            </div>
                        )
                        local_blocks.push(imgdom)
                    }
                    else if (dimorientation === 'portrait') {
                        const imgdom = (
                            <div key={key} value={"I"} dim={'portrait'} style={{ display: 'flex', flexDirection: 'row', width: 280, alignItems: 'center', justifyContent: 'space-between', }}>
                                <img alt='' style={{ width: 160, height: 230, border: 'thin black dashed', padding: 5 }} src={value} />
                                {delbtn(() => setRmblk(imgdom))}
                            </div>
                        )
                        local_blocks.push(imgdom)
                    }
                }
                else if (pattern === 'IP') {
                    local_allimgsmap[key] = obj_map[key]
                    const imgdom = <div key={key} value={"IP"} dim={'portrait'} style={{ display: 'flex', flexDirection: 'row' }}>
                        <img alt='' style={{ width: 160, height: 230, border: 'thin black dashed', padding: 5 }} src={obj_map[key].value} />
                        <img alt='' style={{ width: 160, height: 230, border: 'thin black dashed', padding: 5 }} src={obj_map[key].value1} />
                        {delbtn(() => setRmblk(imgdom))}
                    </div>
                    local_blocks.push(imgdom)
                }
                else if (pattern === 'S') {
                    const emptydom = (
                        <div key={key} value={"S"} style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                            <div style={{ maxWidth: dimW, minWidth: dimW, height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', border: 'thin black dashed' }}>Empty Space</div>
                            {delbtn(() => setRmblk(emptydom))}
                        </div>
                    )
                    local_blocks.push(emptydom)
                }
                else if (pattern === 'T') {
                    var local_blktxtmap = blktxtmap
                    local_blktxtmap[key] = value
                    setBlktxtmap(local_blktxtmap)
                    const txtdom = (
                        <div key={key} value={"T"} style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', height: 110, paddingTop: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                            <FormGroup controlId="formControlsTextarea">
                                <FormControl
                                    defaultValue={value}
                                    style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', height: 100, alignItems: 'center', justifyContent: 'space-between', fontFamily: ff }}
                                    placeholder="Enter description"
                                    componentClass="textarea"
                                    onChange={(event) => {
                                        var local_blktxtmap = blktxtmap
                                        local_blktxtmap[key] = event.target.value
                                        setBlktxtmap(local_blktxtmap)
                                    }}
                                />
                            </FormGroup>
                            {delbtn(() => setRmblk(txtdom))}
                        </div>
                    )
                    local_blocks.push(txtdom)
                }
                else if (pattern === 'V') {
                    var local_blkvidmap = blkvidmap
                    local_blkvidmap[key] = value
                    setBlkvidmap(local_blkvidmap)
                    const videodom = (
                        <div key={key} value={"V"} style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <FieldGroup
                                defaultValue={value}
                                style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}
                                type="dynamictxtfield"
                                placeholder="Youtube video link only"
                                onChange={(event) => {
                                    var local_blkvidmap = blkvidmap
                                    local_blkvidmap[key] = event.target.value
                                    setBlkvidmap(local_blkvidmap)
                                }}
                            />
                            {delbtn(() => setRmblk(videodom))}
                        </div>
                    )
                    local_blocks.push(videodom)
                }

                const local_addbtn = <button
                    className="cprojcontent_addanddelbtn"
                    key={'addbtn' + i}
                    onClick={(e) => {
                        setImgmodalgate(false)
                        setShowblockdialog(true)
                        setAddbtnstate(local_addbtn) // this addbtn is clicked!
                    }}>+</button>

                local_blocks.push(local_addbtn)
            }

            var local_longitude = EMPTY
            var local_latitude = EMPTY
            if (Array.isArray(locationpt) && locationpt.length === 2 && locationpt[0] !== null && locationpt[1] !== null) {
                local_longitude = locationpt[0]
                local_latitude = locationpt[1]
            }


            // assign default index to imgs_index_sequence
            // setObj_key(item.obj_key)
            setObj_map(item.obj_map)
            setName(item.name)
            setMaintext(item.maintext)
            setFf(item.fontfamily)
            setProject_extlinks(item.project_extlinks)
            // setCollaborator_ids(item.collaborator_ids)
            // setCollaborator_profileimg_ids(item.collaborator_profileimg_ids)
            setCollaborator_names(item.collaborator_names)
            // setCollaborator_vendortypes(item.collaborator_vendortypes)
            // setSelectedimg_extlinks(item.selectedimg_extlinks)
            // setCreator(item.username)
            setWedoreventorboth(wedoreventorboth)
            setVersion_code(item.version_code)
            setVendor_proj_token(vendor_proj_token)
            setSelectedimg_pattern(item.selectedimg_pattern)
            setFixedcard(pattern[selectedimg_pattern])
            setBlocks(local_blocks)
            setAllimgsmap(local_allimgsmap)
            setCollaborator_names_html(assignCollaboratorNames(item.collaborator_names, item.collaborator_profileimg_ids))
            setLocation(location)
            setLongitude(local_longitude)
            setLatitude(local_latitude)
            setGuestcount(guestcount)
        }
    }, [data])

    useEffect(() => {
        setAddbtnloc(blocks.indexOf(addbtnstate))
    }, [addbtnstate])// if addbtnstate is setted, den implies vendor added new blk obj

    useEffect(() => {

        if (blocks.length > 1) {

            const { key } = rmblk

            const blkobj_keyindex = obj_key.indexOf(key) // remove from obj_map's key
            var new_obj_key = obj_key.slice()
            new_obj_key.splice(blkobj_keyindex, 1)
            setObj_key(new_obj_key)

            var new_obj_map = obj_map // remove from obj_map
            delete new_obj_map[key]
            setObj_map(new_obj_map)

            const blkobj_index = blocks.indexOf(rmblk) // remove dom elements 
            var new_blocks = blocks.slice()
            new_blocks.splice(blkobj_index, 2)
            setBlocks(new_blocks)

            if (allimgsmap[key]) {
                var local_allimgsmap = allimgsmap
                delete local_allimgsmap[key]
                setAllimgsmap(local_allimgsmap)
            }

        }


    }, [rmblk])

    const onSave = () => {
        const I = 'I'
        const S = 'S'
        const T = 'T'
        const V = 'V'
        const IP = 'IP'

        const validateForm = () => {

            var error = [] // array of error 
            var result = true // everything is fine
            if (Validateform({ form_key: 'name', form_parts: { name: name } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='name' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project name field cannot be empty{IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'maintext', form_parts: { maintext: maintext } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='maintext' style={{ display: 'flex', flexDirection: 'row' }}>Error: Main field cannot be empty{IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_obj_min', form_parts: { blocks: blocks } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_obj_min' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project objects required at least {MIN_NUM_PROJECT_OBJ} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_obj_max', form_parts: { blocks: blocks } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_obj_max' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project objects at most {MAX_NUM_PROJECT_OBJ} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_img_min', form_parts: { blocks: blocks } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_img_min' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project objects images required at least {MIN_NUM_PROJECT_IMG} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_img_max', form_parts: { blocks: blocks } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_img_max' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project objects images at most {MAX_NUM_PROJECT_IMG} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_txt_size', form_parts: { blocks: blocks, blktxtmap: blktxtmap } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_txt_size' style={{ display: 'flex', flexDirection: 'row' }}>Error: Project object text word limit between {MIN_NUM_PROJECT_OBJ_TEXT_SIZE + ' and ' + MAX_NUM_PROJECT_OBJ_TEXT_SIZE} {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_vid_size', form_parts: { blocks: blocks, blkvidmap: blkvidmap } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_txt_size' style={{ display: 'flex', flexDirection: 'row' }}>Error: Only Youtube video link will be accepted {IMPORTANT} </div>)
                result = false
            }
            if (Validateform({ form_key: 'blocks_i_ntundefinededit', form_parts: { blocks: blocks, allimgsmap: allimgsmap } })) {
                // if gt error, den result = false and input new error
                error.push(<div key='blocks_i_ntundefinededit' style={{ display: 'flex', flexDirection: 'row' }}>Error: Please ensure all images uploaded {IMPORTANT} </div>)
                result = false
            }
            return { 'validated_result': result, 'error': error }
        }

        setDisplaymsg(EMPTY)

        var { validated_result, error } = validateForm()
        try {
            if (validated_result) {

                setDisable_editbtn(true)

                const { proj_id, editortype_id, resetExistingprojmodal, vendortypeinterface_id } = props

                // initialize sending data
                var data = new FormData() // formData.append(name, value, filename);
                // new_obj_key, new_img, version_code, proj_id, vendortype_id, vendortypeinterface_id, name, creator, maintext, project_extlinks, new_obj_map

                // construct data 
                var new_obj_map = obj_map
                for (let i = 0; i < blocks.length; i++) { // blocks hold the correct sequence
                    if (i % 2 !== 0) {
                        const { key, props } = blocks[i]
                        const { value } = props;

                        data.append('new_obj_key', key)
                        if (value === IP) {
                            const file = allimgsmap[key]['value']
                            if (file.type && file.type.indexOf('image') !== -1) {
                                data.append('new_img', allimgsmap[key]['value'], key)
                                data.append('new_img', allimgsmap[key]['value1'], key + '1')
                            }
                        }
                        else if (value === I) { // is new image
                            if (allimgsmap && allimgsmap[key] && allimgsmap[key]['value']) {
                                const file = allimgsmap[key]['value']
                                if (file.type && file.type.indexOf('image') !== -1) {
                                    data.append('new_img', allimgsmap[key]['value'], key)
                                }
                            }
                        }
                        else if (value === S) {
                            new_obj_map[key] = { pattern: S, value: value, dimorientation: '' }
                        }
                        else if (value === T) {
                            new_obj_map[key] = { pattern: T, value: blktxtmap[key], dimorientation: '' }
                        }
                        else if (value === V) {
                            new_obj_map[key] = { pattern: V, value: blkvidmap[key], dimorientation: '' }
                        }
                    }
                }

                // credentials from state
                data.append('version_code', version_code)

                // credentials from props
                data.append('proj_id', proj_id)
                data.append('vendortype_id', editortype_id)
                data.append('vendortypeinterface_id', vendortypeinterface_id)

                data.append('location', location)
                if (location === EMPTY) {
                    data.append('longitude', 0)
                    data.append('latitude', 0)
                }
                else {
                    data.append('longitude', longitude)
                    data.append('latitude', latitude)
                }
                data.append('guestcount', guestcount) // is part of a tag

                data.append('name', name)
                data.append('maintext', maintext)
                data.append('fontfamily', ff)
                data.append('project_extlinks', project_extlinks)
                data.append('wedoreventorboth', wedoreventorboth)
                data.append('new_obj_map', JSON.stringify(new_obj_map))

                let options = {
                    method: 'POST', url: VENDOR_PROFILE_EDIT_PROJECT_LINK,
                    headers: { 'Authorization': 'Bearer ' + vendor_proj_token },
                    config: { headers: { 'Content-Type': 'multipart/form-data' } },
                    data: data,
                }

                axios(options).then((response) => {
                    const { data, status } = response
                    const { message } = data
                    if (status === 200 && message === 'SUCCESS') {
                        setDisplaymsg(EMPTY)
                        resetExistingprojmodal()
                    }
                    else if (status !== 200 || message === 'FAILURE') setDisplaymsg(response.message)
                })

            }
            else {
                setDisplaymsg(error)
            }
        }
        catch (e) {
            console.log(e)
            setDisplaymsg('Fail to save.')
        }
        setDisable_editbtn(false)
    }

    const renderName = () => {
        return (
            <div style={{ fontFamily: ff, maxWidth: dimW, minWidth: dimW, minHeight: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', wordBreak: 'break-all', textAlign: 'center', border: 'thin solid #d9d8d8', borderRadius: 5 }}>
                {name === EMPTY ? <div style={{ color: SOFT_GREY }}>Project name</div> : name}
            </div>
        )
    }

    const renderMaintxt = () => {
        return (
            <div style={{ fontFamily: ff, maxWidth: dimW, minWidth: dimW, minHeight: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', wordBreak: 'break-all', textAlign: 'center', border: 'thin solid #d9d8d8', borderRadius: 5 }}>
                {maintext === EMPTY ? <div style={{ color: SOFT_GREY }}>Main text</div> : maintext}
            </div>
        )
    }

    const btnsRow = (ret, cre) => {
        return <div key={uuidv4()} style={{ display: 'flex', fontSize: 14, placeContent: 'space-between', margin: '0px 0px 20px 0px', fontWeight: 'bold', alignItems: 'center' }}>
            {ret
                ? <button
                    className="editprojectcontent_return"
                    style={{ ...BUTTONSTYLE, outline: '' }}
                    onClick={props.back}>
                    {LEFTARROW_ICON_SMALL}
                    <div className="editprojectcontent_returntxt">Return</div>
                </button>
                : null}
            {cre
                ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <a href={HTTPSWEBSITE_URL + '/projectlookup/' + proj_id} target="_blank" className="editprojectcontent_txt" style={{ ...BUTTONSTYLE, outline: '', color: '#707070', textDecoration: 'none' }}>
                        View
                    </a>
                    <button
                        className="editprojectcontent_save"
                        disabled={disable_editbtn}
                        style={{ ...BUTTONSTYLE, outline: '' }}
                        onClick={onSave}>
                        <div className="editprojectcontent_txt">Save</div>
                    </button>
                </div>
                : null}
        </div>
    }

    var addbtn = <button
        className="cprojcontent_addanddelbtn"
        key={uuidv4()}
        onClick={(e) => {
            setImgmodalgate(false)
            setShowblockdialog(true)
            setAddbtnstate(addbtn) // this addbtn is clicked!
        }}>+</button>

    const closeDialog = () => setShowblockdialog(false);

    const delbtn = (remv) => <button key={uuidv4()} className="cprojcontent_addanddelbtn" onClick={remv} >x</button>

    const twoportrait = <button
        className="cprojcontent_modalbtn"
        onClick={() => {
            const key = uuidv4()
            const singlerow = <div key={key} value={'IP'} className="cprojcontent_singlerow">
                <Twoportraitinput
                    updateimg1={(file, dim) => {
                        setAllimgsmap((currallimgsmap) => {
                            if (!currallimgsmap.hasOwnProperty(key)) {
                                currallimgsmap[key] = { pattern: 'IP', value: null, dimorientation: null, value1: null }
                            }
                            currallimgsmap[key]['value'] = file
                            currallimgsmap[key]['dimorientation'] = dim

                            // update obj_map
                            var local_obj_map1 = obj_map
                            local_obj_map1[key] = { pattern: 'IP', value: key, value1: key + '1', dimorientation: 'portrait' }
                            setObj_map(local_obj_map1)

                            return currallimgsmap
                        })
                    }}
                    updateimg2={(file, dim) => {
                        setAllimgsmap((currallimgsmap) => {
                            if (!currallimgsmap.hasOwnProperty(key)) {
                                currallimgsmap[key] = { pattern: 'IP', value: null, dimorientation: null, value1: null }
                            }
                            currallimgsmap[key]['value1'] = file
                            return currallimgsmap
                        })
                    }}
                />
                {delbtn(() => setRmblk(singlerow))}
            </div>
            var local_blocks = blocks.slice()
            local_blocks.splice(addbtnloc + 1, 0, singlerow);
            local_blocks.splice(addbtnloc + 2, 0, addbtn);
            setBlocks(local_blocks)
            closeDialog()
        }}>
        <div className="cprojcontent_inputtxt">Two Portrait</div>
    </button>

    const onelandscape = <button
        className="cprojcontent_modalbtn"
        onClick={() => {
            const key = uuidv4()
            const singlerow = <div key={key} value={'I'} dim="landscape" className="cprojcontent_singlerow">
                <Oneimageinput
                    orientation={'landscape'}
                    updateimg={(file, dim) => {
                        setAllimgsmap((currallimgsmap) => {
                            currallimgsmap[key] = { pattern: 'I', value: file, dimorientation: dim }

                            // update obj_map
                            var local_obj_map1 = obj_map
                            local_obj_map1[key] = { pattern: 'I', value: key, dimorientation: 'landscape' }
                            setObj_map(local_obj_map1)

                            return currallimgsmap
                        })
                    }}
                />
                {delbtn(() => setRmblk(singlerow))}
            </div>
            var local_blocks = blocks.slice()
            local_blocks.splice(addbtnloc + 1, 0, singlerow);
            local_blocks.splice(addbtnloc + 2, 0, addbtn);
            setBlocks(local_blocks)
            closeDialog()
        }}>
        <div className="cprojcontent_inputtxt">One Landscape</div>
    </button>

    const oneportrait = <button
        className="cprojcontent_modalbtn"
        onClick={() => {
            const key = uuidv4()
            const singlerow = <div key={key} value={'I'} dim="portrait" className="cprojcontent_singlerow">
                <Oneimageinput
                    orientation={'portrait'}
                    updateimg={(file, dim) => {
                        setAllimgsmap((currallimgsmap) => {
                            currallimgsmap[key] = { pattern: 'I', value: file, dimorientation: dim }

                            // update obj_map
                            var local_obj_map1 = obj_map
                            local_obj_map1[key] = { pattern: 'I', value: key, dimorientation: 'portrait' }
                            setObj_map(local_obj_map1)

                            return currallimgsmap
                        })
                    }}
                />
                {delbtn(() => setRmblk(singlerow))}
            </div>
            var local_blocks = blocks.slice()
            local_blocks.splice(addbtnloc + 1, 0, singlerow);
            local_blocks.splice(addbtnloc + 2, 0, addbtn);
            setBlocks(local_blocks)
            closeDialog()
        }}>
        <div className="cprojcontent_inputtxt">One Portrait</div>
    </button>

    const imgbtn = <div className="cprojcontent_modalbtn" onClick={() => setImgmodalgate(true)}>Image</div>

    const spacebtn = <button
        className="cprojcontent_modalbtn"
        onClick={() => {
            closeDialog()
            var index = addbtnloc
            var local_blocks = blocks.slice()
            var emptynode = <div key={uuidv4()} value={"S"} style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
                <div style={{ maxWidth: dimW, minWidth: dimW, height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', border: 'thin black dashed' }}>Empty Space</div>
                {delbtn(() => setRmblk(emptynode))}
            </div>
            local_blocks.splice(index + 1, 0, emptynode);
            local_blocks.splice(index + 2, 0, addbtn);
            setBlocks(local_blocks)
        }}>Space</button>

    const textbtn = <button
        className="cprojcontent_modalbtn"
        key={'textbtn'}
        onClick={() => {
            closeDialog()

            var index = addbtnloc
            var local_blocks = blocks.slice()
            var key = uuidv4()

            var local_blktxtmap = blktxtmap
            local_blktxtmap[key] = ''
            setBlktxtmap(local_blktxtmap)

            var textfield = (
                <div key={key} value={"T"} style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', height: 110, paddingTop: 10, alignItems: 'center', justifyContent: 'space-between', fontFamily: ff }}>
                    <FormGroup controlId="formControlsTextarea" >
                        <FormControl
                            style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', height: 100, alignItems: 'center', justifyContent: 'space-between', fontFamily: ff }}
                            placeholder="Enter description"
                            value={local_blktxtmap.key}
                            onChange={(event) => {
                                var inner_blktxtmap = blktxtmap
                                inner_blktxtmap[key] = event.target.value
                                setBlktxtmap(inner_blktxtmap)
                            }}
                            componentClass="textarea"
                        />
                    </FormGroup>
                    {delbtn(() => {
                        setRmblk(textfield)
                        var inner_blktxtmap = blktxtmap
                        delete inner_blktxtmap[key]
                        setBlktxtmap(inner_blktxtmap)
                    })}
                </div>
            )
            local_blocks.splice(index + 1, 0, textfield);
            local_blocks.splice(index + 2, 0, addbtn);
            setBlocks(local_blocks)

        }}>Text</button>

    const videobtn = <button
        className="cprojcontent_modalbtn"
        key={'videobtn'}
        onClick={() => {
            closeDialog()
            var index = addbtnloc
            var local_blocks = blocks.slice()
            var key = uuidv4()

            var local_blkvidmap = blkvidmap
            local_blkvidmap[key] = ''
            setBlkvidmap(local_blkvidmap)

            var youtubetxtfield = (
                <div key={key} value={"V"} style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FieldGroup
                        id={uuidv4() + 'dynamictxtfield'}
                        style={{ maxWidth: dimW, minWidth: dimW, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}
                        type="dynamictxtfield"
                        placeholder="Youtube/Vimeo video link only"
                        value={local_blkvidmap.key}
                        onChange={(event) => {
                            var inner_blkvidmap = blkvidmap
                            inner_blkvidmap[key] = event.target.value
                            setBlkvidmap(inner_blkvidmap)
                        }}
                    />
                    {delbtn(() => setRmblk(youtubetxtfield))}
                </div>
            )
            local_blocks.splice(index + 1, 0, youtubetxtfield);
            local_blocks.splice(index + 2, 0, addbtn);
            setBlocks(local_blocks)
        }}>Video</button>

    return <div style={{ padding: '0px 10px', width: '100%' }}>

        <Formcompoenents
            setName={(name_) => { setName(name_) }}
            nameVal={name}

            setMaintext={(maintext_) => { setMaintext(maintext_) }}
            maintextVal={maintext}

            setFontfamily={(ff) => { setFf(ff) }}
            fontfamily={ff}

            setLink={(link_) => { setProject_extlinks(link_) }}
            linkVal={project_extlinks}

            setLocation={(location_) => {
                setLocation(location_)
                if (location_ === EMPTY) { setLongitude(0); setLatitude(0); }
                else { setLongitude(longitude); setLatitude(latitude) }
            }}
            locationVal={location}

            setLongitude={(longitude_) => { setLongitude(longitude_) }}
            longitudeVal={longitude}

            setLatitude={(latitude_) => { setLatitude(latitude_) }}
            latitudeVal={latitude}

            setWedoreventorboth={(category) => { setWedoreventorboth(category) }}
            wedoreventorbothVal={wedoreventorboth}

            setGuestcount={(guestcount_) => { setGuestcount(guestcount_) }}
            guestcountVal={guestcount}
        />

        {EMPTYSPACE}

        {collaborator_names.length!==0 && collaborator_names !== null
            ? <div style={{ overflowX: 'auto' }}>
                <ControlLabel>Collaborator(s)</ControlLabel>
                <div style={{ border: 'solid thin #D9D8D8', width: '100%', padding: 5, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
                    {collaborator_names_html}
                </div>
            </div>
            : null}

        {EMPTYSPACE}

        <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Display format ( Uneditable )</ControlLabel>

        <div style={{ backgroundColor: 'white', border: 'thin solid #d9d8d8', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
            {EMPTYSPACE}
            {patterntitle[selectedimg_pattern]}
            {EMPTYSPACE}
            {fixedcard}
            {EMPTYSPACE}
        </div>

        {EMPTYSPACE}

        <ControlLabel style={{ display: 'flex', flexDirection: 'row', backgroundColor: 'white' }}>Project Objects ( min {MIN_NUM_PROJECT_OBJ}, max {MAX_NUM_PROJECT_IMG} ) {IMPORTANT}</ControlLabel>

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: 100, backgroundColor: 'white', border: 'thin solid #d9d8d8', fontFamily: ff }}>
            {EMPTYSPACE}
            {renderName()}
            {EMPTYSPACE}
            {renderMaintxt()}
            {EMPTYSPACE}
            {blocks}
            {EMPTYSPACE}
        </div>

        <Dialog
            open={showblockdialog}
            onClose={closeDialog}
            fullScreen={false}
            aria-labelledby="responsive-dialog-title"
        >
            {imgmodalgate
                ? <div className="cprojcontent_modalimgsection">
                    {onelandscape}
                    {oneportrait}
                    {twoportrait}
                </div>
                : <div className="cprojcontent_modalallsection">
                    {imgbtn}
                    {spacebtn}
                    {textbtn}
                    {videobtn}
                </div>}

        </Dialog>
        {EMPTYSPACE}
        <div className="editprojectcontent_displaymsgroot">
            <div className="editprojectcontent_displaymsg" >{displaymsg}</div>
            {btnsRow(true, true)}
        </div>
    </div>
}

export default Editprojectcontent

