import React, { useState, useEffect } from 'react'
import { Retrieve_personal_info } from '../../config/commonfunctions'
import { useEndpoint } from '../../async_components';
import { VENDOR_HOMEPAGE, INIT_CONV_VENDOR, REMOV_VENDOR_FAVDATAHISTORY, REMOV_VENDOR_VIEWSDATAHISTORY } from '../../config/api'
import { EMPTYSPACE, NEAR_TRANSPARENT_GREY, SOFT_GREY, READABLE_VENDORTYPE_MAP, DOWNLOAD_SMALL, BIN2_SMALL, EYE_SMALL, HEART_ICON, HTTPSWEBSITE_URL, STARTUP_NAME } from '../../config/constants';
import { Jfmodal, Loading, Checkdevice, refresh, Vptopbar } from '../../components';
import { CSVLink } from "react-csv";
import Barchart from '../../charts/Barchart/Barchart';
import axios from 'axios'
import * as d3 from "d3";
import './Hv.css'

//  Note : No. of Pricelists obtain from vt allplids instead of plnum, plnum reflect only vt pl*

const d3reset = () => {
    d3.selectAll('g path').remove(); // remove the graph
    d3.selectAll('.dot').remove(); // remove dot
    d3.selectAll(".x.axis").remove(); // remove x-axis
    d3.selectAll(".y.axis").remove(); // remove y-axis
    d3.selectAll('.grid').remove(); // remove dot
    d3.selectAll('.text').remove();
    d3.selectAll('.yaxislabel').remove();
    d3.selectAll('.xaxislabel').remove();
    d3.selectAll('.toptitle').remove();
    d3.selectAll('.focus').remove();
    d3.selectAll('.context').remove();
    d3.selectAll('.subBar').remove();
    d3.selectAll('.tooltip').remove();
}

const ParseDate = (d) => {
    var day = d.getDate()
    var month = d.getMonth() + 1
    var year = d.getFullYear()
    if (month.toString().length < 2) month = '0' + month;
    if (day.toString().length < 2) day = '0' + day;
    return [year, month, day].join('/')
}

// return date array
var GetDateInBetween = function (start, end) {
    var datesArray = [];
    var startDate = new Date(start);
    while (startDate <= end) {
        datesArray.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
    }
    return datesArray.map(i => ParseDate(i));
}

const NUMMTH_CHARMTH_MAP = { 0: 'Jan', 1: 'Feb', 2: 'Mar', 3: 'Apr', 4: 'May', 5: 'June', 6: 'July', 7: 'Aug', 8: 'Sept', 9: 'Oct', 10: 'Nov', 11: 'Dec' }
var today_date = new Date() // today date
var date_start = new Date().setDate(today_date.getDate() - today_date.getDay())
var arr_of_dates_this_wk = GetDateInBetween(date_start, today_date)

function Homevendor(props) {

    // validate and obtain token
    const { token, _id } = Retrieve_personal_info()

    // fetch data
    const data = useEndpoint({
        method: 'POST',
        url: VENDOR_HOMEPAGE,
        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        data: { _id, token }
    })

    // check device
    const device = Checkdevice()


    const [loaded, setLoaded] = useState(false) // default is not loaded

    // init state
    const [favby, setFavby] = useState([null])
    const [vtobj, setVtobj] = useState({ displayname: '', username: '', vendortype: '', secondaryvendortype: '-', pricelist_num: '', review_num: '', project_num: '', quote_num: '', id_dt: {} })
    const [favobj, setFavobj] = useState([])    // [{ date: '01-01-2019', count: 0 }]
    const [id_date_map, setId_date_map] = useState([{ _id: '', date: '' }])
    const [viewobj, setViewobj] = useState([])  // [{ date: '01/012019', count: 0 }] for chart
    const [currchart, setCurrchart] = useState(null)
    const [cupdatedata, setCupdatedata] = useState([]) // CEREWEAVE UPDATE DATA
    const [cupdateexpand, setCupdateexpand] = useState(false)
    const [dialogbool, setDialogbool] = useState(false)

    // day data for indicator
    const [favdaydata, setFavdaydata] = useState(0)
    const [viewdaydata, setViewdaydata] = useState(0)

    // week data for indicator
    const [favwkdata, setFavwkdata] = useState(0)
    const [viewwkdata, setViewwkdata] = useState(0)

    // month data for indicator
    const [mth_fav_map, setMth_fav_map] = useState({ 'Jan': 0, 'Feb': 0, 'Mar': 0, 'Apr': 0, 'May': 0, 'June': 0, 'July': 0, 'Aug': 0, 'Sept': 0, 'Oct': 0, 'Nov': 0, 'Dec': 0 })
    const [mth_view_map, setMth_view_map] = useState({ 'Jan': 0, 'Feb': 0, 'Mar': 0, 'Apr': 0, 'May': 0, 'June': 0, 'July': 0, 'Aug': 0, 'Sept': 0, 'Oct': 0, 'Nov': 0, 'Dec': 0 })

    // for topnavbar
    const [nav, setNav] = useState('home')

    useEffect(() => {
        if (data.complete) {
            const { u_result, vt_result, id_dt, dt_c, firsttime, cupdatedata } = data.data

            if (firsttime) {
                props.history.push('business/vendorprofile')
                return
            }

            // var numbertoshow = data.data.u_result.length < 5 ? data.data.u_result.length : 5
            var numbertoshow = u_result.length

            // Favourite user detail
            var favby = favDetail(numbertoshow, u_result)
            setFavby(favby)

            // Vendor detail
            var vtobj = vendorDetail(vt_result)
            setVtobj(vtobj)

            // assemble favourite data
            var favextract = extractFav(id_dt)
            var id_dt_map = favextract[0]
            var dt_fav_map = favextract[1]
            var unique_dt = favextract[2]
            setId_date_map(id_dt_map)

            // placment of Favourite chart data & Favourite indicator
            var favdatum = assembleFavdata(unique_dt, dt_fav_map)
            var favobj = favdatum[0]
            var mth_fav_map = favdatum[1]
            var favwkdata = favdatum[2]
            setFavobj(favobj)
            setMth_fav_map(mth_fav_map)
            setFavwkdata(favwkdata)

            // placement of View chart data & View indicator
            var viewdatum = assembleViewdata(dt_c)
            var viewobj = viewdatum[0]
            var mth_view_map = viewdatum[1]
            var viewwkdata = viewdatum[2]
            setViewobj(viewobj)
            setMth_view_map(mth_view_map)
            setViewwkdata(viewwkdata)

            // CEREWEAVE UPDATE DATA
            setCupdatedata(cupdatedata)

            // set default page of chart
            setCurrchart('VIEW')
            setLoaded(true)
        }

        return () => d3reset()
    }, [data.data])


    // Start of methods used in useEffect
    function favDetail(numbertoshow, u_result) {
        var local_favby = [];
        for (let i = 0; i < numbertoshow; i++)
            local_favby.push(u_result[numbertoshow - 1 - i])
        return local_favby
    }
    function vendorDetail(vt_result) {
        var vtobj = [];
        var { username, vendortype, secondaryvendortype, allpricelist_ids, review_num, project_num, displayname, quote_num } = vt_result;
        if (secondaryvendortype !== '' && secondaryvendortype) { }
        else
            secondaryvendortype = '-';
        vtobj = { username, vendortype, secondaryvendortype, pricelist_num: allpricelist_ids.length, review_num, project_num, displayname, quote_num };
        return vtobj
    }
    function extractFav(id_dt) {
        var id_dt_map = {}
        var dt_fav_map = {}
        var unique_dt = []

        // assemble data for both favourite chart
        for (let i = 0; i < id_dt.length; i++) {
            const { like_dt, user_id } = id_dt[i]
            var date = ParseDate(new Date(like_dt))

            // construct {id:date, id:date,...}
            id_dt_map[user_id] = date

            // extract data for fav chart
            if (date in dt_fav_map) {
                dt_fav_map[date] = dt_fav_map[date] + 1
            }
            else {
                dt_fav_map[date] = 1;
                unique_dt.push(new Date(date))
            }
        }

        return [id_dt_map, dt_fav_map, unique_dt]
    }
    function assembleFavdata(unique_dt, dt_fav_map) {

        var local_favwkdata = 0


        var local_favobj = []
        var local_mth_fav_map = mth_fav_map

        if (unique_dt.length === 1) {
            const curr_date = ParseDate(unique_dt[0])
            var char_date_mth = NUMMTH_CHARMTH_MAP[new Date(curr_date).getMonth()]
            const count = dt_fav_map[ParseDate(unique_dt[0])]
            local_mth_fav_map[char_date_mth] = local_mth_fav_map[char_date_mth] + count
            local_favobj.push({ date: curr_date, count: count })
            if (new Date(curr_date).toDateString() === today_date.toDateString()) setFavdaydata(count)
            if (arr_of_dates_this_wk.includes(curr_date)) local_favwkdata = count
        }
        else {
            unique_dt.sort(function (a, b) { return new Date(a) - new Date(b) })
            var oldest_fav_date = new Date(unique_dt[0])
            var dates = GetDateInBetween(oldest_fav_date, new Date())
            for (let i = 0; i < dates.length; i++) {

                var date2 = dates[i]
                var count = dt_fav_map[date2] ? dt_fav_map[date2] : 0
                var char_date_mth1 = NUMMTH_CHARMTH_MAP[new Date(date2).getMonth()]

                if (new Date(date2).toDateString() === today_date.toDateString()) setFavdaydata(count)
                if (arr_of_dates_this_wk.includes(date2)) local_favwkdata = local_favwkdata + count

                local_mth_fav_map[char_date_mth1] = local_mth_fav_map[char_date_mth1] + count
                local_favobj.push({ date: date2, count: count })

            }

        }



        return [local_favobj, local_mth_fav_map, local_favwkdata]
    }
    function assembleViewdata(dt_c) {

        var dates_arr = Object.keys(dt_c).sort(function (a, b) { return new Date(a) - new Date(b) })
        var oldest_view_date = new Date(dates_arr[0])
        var dates_inbetween = GetDateInBetween(oldest_view_date, new Date())
        var local_viewobj = []
        var local_mth_view_map = mth_view_map
        var local_viewwkdata = 0
        for (let i = 0; i < dates_inbetween.length; i++) {
            var key = dates_inbetween[i]
            var v_count = dt_c[key] ? parseInt(dt_c[key], 10) : 0
            var char_date_mth = NUMMTH_CHARMTH_MAP[new Date(key).getMonth()]
            if (new Date(key).toDateString() === today_date.toDateString()) setViewdaydata(v_count)
            if (key in dt_c) {
                local_viewobj.push({ date: key, count: v_count })
                local_mth_view_map[char_date_mth] = local_mth_view_map[char_date_mth] + v_count
            }
            else {
                local_viewobj.push({ date: key, count: 0 })
            }
            if (arr_of_dates_this_wk.includes(key)) local_viewwkdata = local_viewwkdata + v_count

        }
        return [local_viewobj, local_mth_view_map, local_viewwkdata]
    }


    // End of methods used in useEffect
    const renderChartdiv = () => {

        var data = []
        var titles = []
        var tooltip_data = {
            unit: '',
            text: ''
        }
        if (currchart === 'FAVOURITE') {
            data = favobj
            titles = ["Favourite", "Date"]
            tooltip_data.unit = 'User'
            tooltip_data.text = 'Favourite By : '
        }
        else if (currchart === 'VIEW') {
            data = viewobj
            titles = ["Views", "Date"]
            tooltip_data.unit = 'User'
            tooltip_data.text = 'View By : '
        }
        const view_btn = <button className={"topmenu" + (currchart === 'VIEW')} onClick={() => { if (currchart !== 'VIEW') { setCurrchart('VIEW'); d3reset(); } }}> <div>{EYE_SMALL}</div><div>Views</div></button>
        const fav_btn = <button className={"topmenu" + (currchart === 'FAVOURITE')} onClick={() => { if (currchart !== 'FAVOURITE') { setCurrchart('FAVOURITE'); d3reset() } }}><div>{HEART_ICON}</div>  <div>Favourite</div> </button>
        const notenuffdata_div = <div className="hv_ntenuff">You do not have enough data to show here.</div>
        const trash = async () => {
            var local_url
            if (currchart === 'VIEW') {
                local_url = REMOV_VENDOR_VIEWSDATAHISTORY
            }
            else if (currchart === 'FAVOURITE') {
                local_url = REMOV_VENDOR_FAVDATAHISTORY
            }

            try {
                let options = {
                    method: 'POST',
                    url: local_url,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: {}
                }
                await axios(options).then((res) => {
                    const { message } = res.data
                    if (message === 'SUCCESS') {
                        if (currchart === 'VIEW') {
                            setViewobj([])
                            setViewdaydata(0)
                            setViewwkdata(0)
                            setMth_view_map({ 'Jan': 0, 'Feb': 0, 'Mar': 0, 'Apr': 0, 'May': 0, 'June': 0, 'July': 0, 'Aug': 0, 'Sept': 0, 'Oct': 0, 'Nov': 0, 'Dec': 0 })
                            if (favobj.length > 0) setCurrchart('FAVOURITE')
                        }
                        else {
                            setFavobj([])
                            setFavdaydata(0)
                            setFavwkdata(0)
                            setMth_fav_map({ 'Jan': 0, 'Feb': 0, 'Mar': 0, 'Apr': 0, 'May': 0, 'June': 0, 'July': 0, 'Aug': 0, 'Sept': 0, 'Oct': 0, 'Nov': 0, 'Dec': 0 })
                            if (viewobj.length > 0) setCurrchart('VIEW')
                        }
                    }

                }).catch(e => {
                    refresh()
                })
            }
            catch (e) {
                refresh()
            }


        }

        var CURRCHART_CHART_MAP = {
            'VIEW': viewobj.length > 2 ? <Barchart key={'VIEW chart'} data={data} title={titles} tooltip_data={tooltip_data} clear={d3reset} /> : notenuffdata_div,
            'FAVOURITE': favobj.length > 2 ? <Barchart key={'FAVOURITE chart'} data={data} title={titles} tooltip_data={tooltip_data} clear={d3reset} /> : notenuffdata_div
        }

        if (viewobj.length > 0 || favobj.length > 0) {
            return (
                <div style={{ height: 'max-content', border: 'thin solid rgb(217, 216, 216)', justifyContent: 'flex-start', borderRadius: 5, display: 'flex', flexDirection: 'column', overflowX: 'auto', margin: '20px 0px' }}>
                    <div className="hv_title_root">
                        <div>Stats</div>
                        <div className="hv_charticonroot">
                            {currchart === 'VIEW'
                                ? <CSVLink className="hv_icon" key={'view'} filename={"views.csv"} data={viewobj} >{DOWNLOAD_SMALL}</CSVLink>
                                : <CSVLink className="hv_icon" key={'favourite'} filename={"favourite.csv"} data={favobj} >{DOWNLOAD_SMALL}</CSVLink>}
                            <div className="hv_icon" onClick={() => trash()}>{BIN2_SMALL}</div>
                        </div>
                    </div>
                    <div className="hv_btnroot">
                        {viewobj.length > 0 && view_btn}
                        {favobj.length > 0 && fav_btn}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div className={"hv_periodroot" + device}>
                            <h4 className="hv_periodtitle">Today</h4>
                            {currchart === 'VIEW' ? <h3 className="hv_periodnum" >{viewdaydata ? viewdaydata : 0}</h3> : null}
                            {currchart === 'FAVOURITE' ? <h3 className="hv_periodnum">{favdaydata ? favdaydata : 0}</h3> : null}
                        </div>
                        <div className={"hv_periodroot" + device}>
                            <h4 className="hv_periodtitle">Week</h4>
                            {currchart === 'VIEW' ? <h3 className="hv_periodnum" >{viewwkdata ? viewwkdata : 0}</h3> : null}
                            {currchart === 'FAVOURITE' ? <h3 className="hv_periodnum" >{favwkdata ? favwkdata : 0}</h3> : null}
                        </div>
                        <div className={"hv_periodroot" + device}>
                            <h4 className="hv_periodtitle">{NUMMTH_CHARMTH_MAP[today_date.getMonth()]}</h4>
                            {currchart === 'VIEW' ? <h3 className="hv_periodnum" >{mth_view_map[NUMMTH_CHARMTH_MAP[today_date.getMonth()]]}</h3> : null}
                            {currchart === 'FAVOURITE' ? <h3 className="hv_periodnum" >{mth_fav_map[NUMMTH_CHARMTH_MAP[today_date.getMonth()]]}</h3> : null}
                        </div>
                    </div>
                    <button className="hv_rightresetbtn" onClick={() => {
                        const curr_chart = currchart
                        setCurrchart(null);
                        setTimeout(() => {
                            setCurrchart(curr_chart);
                        }, [50])
                    }}>Reset View</button>
                    <div style={{ margin: '20px 0px', justifyContent: 'center', borderRadius: 5, display: 'flex', flexDirection: 'column', overflowX: 'auto', overflowY: 'hidden', alignItems: 'center' }}>
                        {CURRCHART_CHART_MAP[currchart]}
                        {viewobj.length <= 0 ? <div style={{ width: 1000, height: 500, display: 'flex', justifyContent: 'center', backgroundColor: NEAR_TRANSPARENT_GREY, borderRadius: '5px' }} /> : null}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <div className="statistics_root">
                        <div className="hv_title_root">Stats</div>
                        <div className="statistics_subroot">
                            <div className="statistics_chartroot">
                                <div style={{ width: 1000, height: 500, display: 'flex', justifyContent: 'center', backgroundColor: NEAR_TRANSPARENT_GREY, borderRadius: '5px' }} />
                            </div>
                        </div>
                    </div>
                    {EMPTYSPACE}
                </div>

            )
        }

    }

    const renderCupdatediv = () => { // Cereweave Updates 
        return (
            <div className="news_root">
                <div className="hv_title_root">{'News & Updates'} </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {(cupdatedata && cupdatedata.length < 3) || cupdateexpand
                        ? cupdatedata.reverse().map(({ title, _id, date, content }, index) => {
                            return <div className="hv_newsupdatestrip" key={_id + index} style={{ borderBottom: index !== cupdatedata.length - 1 ? 'thin solid rgb(217, 216, 216)' : '', }}>
                                <div className="hv_newsupdatedate">{date}</div>
                                <div className="hv_newsupdatecontent">{content}</div>
                            </div>
                        })
                        : <div className="hv_newsupdateroot" key={_id + 1}>
                            <div className="hv_newsupdatestrip" key={_id + 2} style={{ borderBottom: 0 !== cupdatedata.length - 1 ? 'thin solid rgb(217, 216, 216)' : '' }}>
                                <div className="hv_newsupdatedate">{cupdatedata[cupdatedata.length - 1]['date']}</div>
                                <div className="hv_newsupdatecontent">{cupdatedata[cupdatedata.length - 1]['content']}</div>
                            </div>
                            <div className="hv_newsupdatestrip" key={_id + 3} style={{ borderBottom: 1 !== cupdatedata.length - 1 ? 'thin solid rgb(217, 216, 216)' : '' }}>
                                <div className="hv_newsupdatedate">{cupdatedata[cupdatedata.length - 2]['date']}</div>
                                <div className="hv_newsupdatecontent">{cupdatedata[cupdatedata.length - 2]['content']}</div>
                            </div>
                            <div className="hv_newsupdatestrip" key={_id + 4} style={{ borderBottom: 2 !== cupdatedata.length - 1 ? 'thin solid rgb(217, 216, 216)' : '' }}>
                                <div className="hv_newsupdatedate">{cupdatedata[cupdatedata.length - 3]['date']}</div>
                                <div className="hv_newsupdatecontent">{cupdatedata[cupdatedata.length - 3]['content']}</div>
                            </div>
                            <button className="hv_newsupdateexpand" onClick={() => { setCupdateexpand(true) }}> Expand</button>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const ChatUser = async (email) => {
        try {
            let options = {
                method: 'POST',
                url: INIT_CONV_VENDOR,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: { email }
            }
            await axios(options).then((res) => {
                const { message } = res.data
                if (message === 'REDIRECT') {
                    // window.open('/chat');
                    props.history.push('/chat')
                }
                else if (message === 'SUCCESS') {
                    // window.open('/chat');
                    props.history.push('/chat')
                }
                else {
                    refresh()
                }

            }).catch(e => {
                refresh()
            })
        }
        catch (e) { refresh() }
    }

    const renderContentdiv = () => {
        const profile_title_map = { username: 'Username', displayname: 'Displayname', vendortype: 'Pri Vendortype', secondaryvendortype: 'Sec Vendortype', pricelist_num: 'No. Pricelists', review_num: 'No. Reviews', project_num: 'No. Projects', quote_num: 'No. Quote (Success)' }
        const profile_keys = ['username', 'displayname', 'vendortype', 'secondaryvendortype', 'pricelist_num', 'review_num', 'project_num', 'quote_num']
        return <div className={device + '_subroot'}>
            <div className="hv_title_root"> Info </div>
            <div className={device + '_item'}>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
                    {profile_keys.map((key, index) => {
                        var content = key === 'vendortype' || key === 'secondaryvendortype' ? READABLE_VENDORTYPE_MAP[vtobj[key]] : vtobj[key]
                        return <div key={key + index} className="hv_singletxtstrip">
                            <div className="hv_texttitle">{profile_title_map[key]}</div>
                            <div>{content}</div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    }
    const renderFavdiv = () => {
        if (favby.length === 0) {
            return (
                <div className={device + '_subroot'}>
                    <div className="hv_title_root">Recent Favourite By </div>
                    <div className={device + '_item'}>
                        <div style={{ display: 'flex', flexDirection: 'column', padding: 5, }}>
                            {[{ email: '', _id: '' }, { email: '', _id: '' }, { email: '', _id: '' }, { email: '', _id: '' }, { email: '', _id: '' }].map((item, index) => {
                                const { email, _id } = item
                                const date = id_date_map[_id]
                                return (
                                    <div
                                        key={Math.random() + email}
                                        style={{ display: 'flex', flexDirection: 'row', padding: 10, margin: '3px 0px', borderRadius: 5, justifyContent: 'space-between', width: '100%', alignItems: 'center', border: 'thin solid rgb(217, 216, 216)', backgroundColor: 'rgb(238, 238, 238)' }}
                                    >
                                        <span style={{ whiteSpace: 'nowrap', overflowX: 'hidden', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                            <div style={{ padding: '0px 5px' }}>{email}</div>
                                            <div style={{ padding: '0px 5px' }}>{date}</div>
                                        </span>
                                        <button className="hv_chatbtn" onClick={() => { ChatUser(email) }}>Chat</button>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            )
        }
        else {
            return (
                <div className={device + '_subroot'}>
                    <div className="hv_title_root">
                        <div>Favourite By {favby.length > 1 ? ' ( ' + favby.length + ' )' : null}</div>
                        <button className="hv_showallbtn" onClick={() => setDialogbool(true)}>Show All</button>
                    </div>
                    <div className={device + '_item'}>
                        {favby[0] !== null
                            ? <div style={{ display: 'flex', flexDirection: 'column', padding: 5, }}>
                                {favby.map((item, index) => {
                                    const { email, _id, username } = item
                                    const date = id_date_map[_id]
                                    if (index < 5) return (
                                        <div
                                            key={Math.random() + email}
                                            style={{ display: 'flex', flexDirection: 'row', padding: 10, margin: '3px 0px', borderRadius: 5, justifyContent: 'space-between', width: '100%', alignItems: 'center', border: 'thin solid rgb(217, 216, 216)' }}
                                        >
                                            <span style={{ whiteSpace: 'nowrap', overflowX: 'hidden', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', }}>
                                                <div style={{ padding: '0px 5px', display: 'flex', flexDirection: 'column' }}>
                                                    <div>{email}</div>
                                                    <div style={{ fontSize: 13, color: SOFT_GREY }}>{username}</div>
                                                </div>
                                                <div style={{ padding: '0px 5px', alignItems: 'center', textAlign: 'center', display: 'flex', fontSize: 13, color: SOFT_GREY }}>{date}</div>
                                            </span>
                                            <button className="hv_chatbtn" onClick={() => { ChatUser(email) }}>Chat</button>
                                        </div>
                                    )
                                    else return null
                                })}
                            </div>
                            : null
                        }
                    </div>
                </div>
            )
        }
    }

    const renderName = () => {
        return vtobj.username ?
            <a href={HTTPSWEBSITE_URL + '/vendorlookup/' + vtobj.username} target="_blank" style={{ padding: '20px 0px', marginBottom: 5, fontWeight: 'bold', fontSize: 24, color:'inherit'}}>
                <div style={{ height: 100, display: 'flex', alignItems: 'center' }}>
                    {vtobj.displayname}
                </div>
            </a>
            : null
    }

    const renderModalcontent = () => {
        return favby[0] !== null
            ? <div className={"hv_modalcontent" + device}>
                {favby.map((item, i) => {
                    const { email, _id, username } = item
                    const date = id_date_map[_id]
                    return (
                        <div key={i + 'favby'} className={'hv_modalsinglestrip'}>
                            <span className="hv_modalsingleleftroot">
                                <div className="hv_modalsinglelefttop">
                                    <div>{email}</div>
                                    <div className="hv_modalsingleleftbtmun">{username}</div>
                                </div>
                                <div className="hv_modalsingleleftbtm">{date}</div>
                            </span>
                            <button className="hv_chatbtn" onClick={() => { ChatUser(email) }}>Chat</button>
                        </div>
                    )
                })}
            </div>
            : null
    }

    return loaded
        ? <div className="container" style={{ color: 'var(--maindark)', touchAction: 'manipulation', padding: '0px 5px 20px 5px' }}>
            {renderName()}
            <Vptopbar {...props} nav={nav} updateNav={(nav) => setNav(nav)} />
            <div className={device + '_root'}>
                {renderContentdiv()}
                {renderFavdiv()}
            </div>
            {renderChartdiv()}
            {renderCupdatediv()}
            <Jfmodal
                show={dialogbool}
                title={<div className="hv_modaltitle">Favourite By</div>}
                onHide={() => setDialogbool(false)}
                device={device}
                content={renderModalcontent()}
            />
        </div>
        : <Loading />


}
export default Homevendor
