import React, { useState, useEffect } from 'react';
import { Checklandscape } from '../../../../../../components';
import { PATTERN_DIM, EMPTY } from '../../../../../../config/constants';
import { Imagedownsize } from '../../../../components/Imagedownsize/Imagedownsize';

function Patternthree(props) {
    //  ___________
    //  |         |
    //  |         |
    //  |         |
    //  |         |
    //  |_________|
    const [img1, setImg1] = useState(EMPTY);
    const [img1_dim] = useState({ width: 230, height: 232 })//    const [img1_dim, setImg1_dim] = useState({ width: 230, height: 232 })
    useEffect(() => {
        if (props !== null && props !== undefined && props !== []) {
            const { editable, img1 } = props
            if (editable) {
                setImg1(<div style={{ width: img1_dim.width - 2, height: img1_dim.height - 2, borderTopLeftRadius: 10, borderTopRightRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>+</div>)
            }
            else {
                setImg1(<div style={{ backgroundImage: 'url(' + img1 + ')', backgroundPosition: 'center', backgroundSize: 'cover', width: img1_dim.width - 2, height: img1_dim.height - 2, borderTopLeftRadius: 10, borderTopRightRadius: 10, border: '1px solid white' }} />)
            }

        }
    }, [props.editable]);

    const onImgchange1 = (event) => {
        try {
            let file = event.target.files[0];
            var reader = new FileReader();
            reader.onloadend = (e) => {
                var img = document.createElement("img");
                img.onload = () => {
                    if (!Checklandscape(img) && file.size < 8000000 ) { // 2MB : 2097152, 20MB : 20000000  //accept only landscape images
                        var result = Imagedownsize(img, 'scale', 500, 500);
                        var img1 = result[0];
                        var imgfile1 = new File([result[1]], 'selectedimg_main', { type: result[1].type, lastModified: Date.now() });
                        // setImg1(<img alt={IMG_ALT}  style={{ width: img1_dim.width - 2, height: img1_dim.height - 2, borderTopLeftRadius: 10, borderTopRightRadius: 10, }} src={img1} />);
                        setImg1(
                            <div
                                key={'img.src'}
                                style={{ width: img1_dim.width - 2, height: img1_dim.height - 2, borderTopLeftRadius: 10, borderTopRightRadius: 10, backgroundImage: 'url(' + img1 + ')', backgroundPosition: 'center', backgroundSize: 'cover' }}
                            />
                        )
                        props.update([imgfile1, 'main']);
                    }
                    else {
                        alert('Please ensure image is in portrait orientation, and size under 8MB')
                        return;
                    }
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
        catch (e) {
        }
    };
    const { editable } = props

    return (<div style={{ ...PATTERN_DIM, display: 'flex', flexDirection: 'column', border: editable ? 'dashed thin black' : '', borderTopLeftRadius: 10, borderTopRightRadius: 10, margin: editable ? '4% 0px' : '', alignItems: 'center' }}>
        {editable ? <input key="input_main" accept="image/*" className="input_main" type='file' name="input-file" style={{ ...img1_dim, opacity: 0, position: 'absolute' }} onChange={onImgchange1} /> : null}
        {img1}
    </div>);
}


export default Patternthree