import React, { useState, useEffect } from 'react';
import { Retrieve_personal_info } from '../../config/commonfunctions';
import { FACEBOOK_ICON, TELEGRAM_ICON, } from '../../config/constants';
import { Checkdevice, Notiboard, Vptopbar } from '../../components'
import { VENDOR_TRENDFIRSTSEARCH, VENDOR_TRENDSEARCH } from '../../config/api';
// import { Link } from 'react-router-dom';
import { u1F52E } from 'react-icons-kit/noto_emoji_regular/u1F52E'
import Wc from './components/Wc'
import axios from 'axios'
import './VendorTrend.css'
import Icon from 'react-icons-kit';



function Dtfilter(props) {

    const allyrs = [2019, 2020, 2021]
    const first6mths = [1, 2, 3, 4, 5, 6]
    const last6mths = [7, 8, 9, 10, 11, 12]

    const [yr, setYr] = useState('')
    const [mth, setMth] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const { mth, yr, loading } = props
        setYr(yr)
        setMth(mth)
        setLoading(loading)
    }, [props])

    return <div className={"Dtfilter_root"}>
        <div className="Dtfiltertitle">Year</div>
        <div className={"Dtfilter_subroot"}>{allyrs.map((y, i) => <div key={"y" + i} className={"Dtfilteryr" + (y === yr)} onClick={() => props.updateYr(y)}>{y}</div>)}</div>
        <div className="Dtfiltertitle">Month</div>
        <div className={"Dtfilter_subroot"}>{first6mths.map((m, i) => <div key={i + "y" + m} className={"Dtfilter" + (m === mth)} onClick={() => props.updateMth(m)}>{m}</div>)}</div>
        <div className={"Dtfilter_subroot"}>{last6mths.map((m, i) => <div key={i + "y" + m} className={"Dtfilter" + (m === mth)} onClick={() => props.updateMth(m)}>{m}</div>)}</div>
        {!loading ? <button className="vendortrend_btn" onClick={() => { props.onSearch(); }} ><Icon size={28} icon={u1F52E} /> </button> : null}
    </div>
}

function Source() {
    return <div className="vendortrend_sourceroot">
        <div className="vendortrend_sourcetitle">Data Source</div>
        <div className={"vendortrend_sourcerow"}>
            <div className="vendortrend_sourceicon">{TELEGRAM_ICON}</div>
            <div className="vendortrend_sourceicon">{FACEBOOK_ICON}</div>
        </div>
    </div>
}

function Topfew({ data, device, num }) {
    function reverseArr(input) {
        var ret = [];
        for (var i = input.length - 1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }
    return <div className="vendortrend_topfewroot">
        <div className={'vendortrend_item' + device} key={'topfewtitle'}>
            <div className="vendortrend_texttitle">Word/Text</div>
            <div className="vendortrend_valuetitle">Count</div>
        </div>
        {reverseArr(data).map(({ text, value }, i) => {
            if (i < num) {
                return <div className={'vendortrend_item' + device} key={'data' + i}>
                    <div className="vendortrend_text">{text}</div>
                    <div className="vendortrend_value">{value}</div>
                </div>
            }
            else return null
        })}
    </div>
}

function VendorTrend(props) {
    const [firsttime, setFirsttime] = useState(true)
    const [nav, setNav] = useState('trend')
    const [yr, setYr] = useState('')
    const [mth, setMth] = useState('')
    const [data, setData] = useState([])
    const [wc, setWc] = useState(null)
    const [loading, setLoading] = useState(true)
    const [topnum, setTopnum] = useState(5)
    const [nothing, setNothing] = useState(true)
    const { token } = Retrieve_personal_info()
    const device = Checkdevice()
    const device_wt_map = { 'WEB': 800, 'TABLET': 700, 'MOBILE': 320 }
    const wt = device_wt_map[device]

    useEffect(() => {
        if (firsttime) {
            try {
                const d = new Date()
                const curryr = d.getFullYear()
                const currmth = d.getMonth() + 1
                axios({
                    method: 'POST',
                    url: VENDOR_TRENDFIRSTSEARCH,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
                    data: { yr: curryr, mth: currmth }
                }).then((response) => {
                    const { status } = response
                    const { data,message } = response.data
                    if (status === 200 && message==='SUCCESS') {
                        setLoading(false)
                        setFirsttime(false)
                        setYr(curryr)
                        setMth(currmth)
                        setData(data)
                        setWc(<Wc wt={wt} data={data}/>)
                        setNothing(false)
                    }
                    else{
                        setNothing(true)
                    }
                })
            }
            catch (e) { 
                setFirsttime(true) 
            }
        }
    }, [firsttime])

    const onSearch = () => {
        setLoading(true)
        setWc(null)
        try {
            axios({
                method: 'POST',
                url: VENDOR_TRENDSEARCH,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
                data: { yr: yr, mth: mth }
            }).then((response) => {
                const { status } = response
                const { data } = response.data
                if (status === 200) {
                    setLoading(false)
                    setData(data)
                    setWc(<Wc wt={wt} data={data}/>)
                }
            })
        }
        catch (e) { }
    }

    const changeNum = (i) => setTopnum(i)

    return <div className="container">
        {props.un && props.dn
            // ? <Link to={'/vendorlookup/' + props.un} target="_blank" style={{ padding: '20px 0px', marginBottom: 5, fontWeight: 'bold', fontSize: 24, color: '#707070' }}><div style={{ height: 100, display: 'flex', alignItems: 'center' }}> {props.dn}</div></Link>
            ? <a href={'https://cereweave.com/vendorlookup/' + props.un} target="_blank" style={{ padding: '20px 0px', marginBottom: 5, fontWeight: 'bold', fontSize: 24, color: 'var(--maindark)' }}><div style={{ height: 100, display: 'flex', alignItems: 'center' }}> {props.dn}</div></a>
            : null}
        <Vptopbar {...props} nav={nav} updateNav={(nav) => setNav(nav)} />
        {loading && nothing
         ? null 
         : <div className="Dtfiltercontentroot">
            <Notiboard text="Here are the major trends in recent wedding industry. Information from various sources, that are preprocessed and summerise by us." />
            <Source />
            <h3 className="vendortrend_titleroot">{(mth.toString().length === 1 ? '0' + mth : mth) + (mth ? ' / ' : '') + yr}</h3>
            <Dtfilter mth={mth} yr={yr} updateYr={(y) => setYr(y)} updateMth={(m) => { mth === m ? setMth('') : setMth(m) }} onSearch={onSearch} loading={loading} />
            <div key={'wc'} className="Dtfilterwcroot">{wc}</div>
            <div className={"vendortrend_btnroot" + device}>
                <button className={topnum === 5 ? "vendortrend_btntop_selected" : "vendortrend_btntop"} onClick={() => changeNum(5)}>Top 5</button>
                <button className={topnum === 15 ? "vendortrend_btntop_selected" : "vendortrend_btntop"} onClick={() => changeNum(15)}>Top 15</button>
                <button className={topnum === 25 ? "vendortrend_btntop_selected" : "vendortrend_btntop"} onClick={() => changeNum(25)}>Top 25</button>
            </div>
            <Topfew num={topnum} data={data} device={device} />
        </div>}
    </div>
}
export default VendorTrend