import React from 'react'
import { IMPORTANT, OPTIONAL, COUNTRIES, EMPTYSPACE, EMPTY, FFCONFIG } from '../../../../../../config/constants';
import { FormControl, FormGroup, HelpBlock, ControlLabel } from "react-bootstrap"
import './Fontcomponent.css'
function FieldGroup({ id, label, help, ...props }) {
    return (
        <FormGroup controlId={id}>
            <ControlLabel>{label}</ControlLabel>
            <FormControl {...props} />
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
    );
}

function Formcompoenents(props) {

    const proj_name = <div className="projc_fc_title">Project Name {IMPORTANT}</div>
    const main_name = <div className="projc_fc_title">Main Text {IMPORTANT}</div>
    const fontfamily_name = <div className="projc_fc_title">Font Family {OPTIONAL}</div>
    const link_name = <div className="projc_fc_title">Link {OPTIONAL}</div>
    const location_name = <div className="projc_fc_title">Location {OPTIONAL}</div>
    const longtitude_name = <div className="projc_fc_title"> Longtitude</div>
    const latitude_name = <div className="projc_fc_title">Latitude</div>
    const guestcount_name = <ControlLabel className="projc_fc_title">Guest Count {OPTIONAL}</ControlLabel>
    const category_name = <ControlLabel className="projc_fc_title">Categories {IMPORTANT}</ControlLabel>

    return (
        <div className="projc_fc_root">
            <FieldGroup // name
                id='NAME'
                label={proj_name}
                onChange={(event) => { props.setName(event.target.value) }}
                value={props.nameVal}
            />

            <FieldGroup // maintext 
                id='MAIN'
                componentClass="textarea"
                label={main_name}
                onChange={(event) => { props.setMaintext(event.target.value) }}
                value={props.maintextVal}
            />

            <FieldGroup // font family
                id='FONTFAMILY'
                label={fontfamily_name}
                onChange={(event) => { props.setFontfamily(event.target.value) }}
                value={props.fontfamily}
                componentClass="select">
                {[':', ...FFCONFIG['google'].families].map((item, index) => {
                    item =item.split(':')[0]
                    return (
                        <option key={index + item} value={item}>{item}</option>
                    )
                })}
            </FieldGroup>

            <div style={{ padding: 10, border: 'thin solid rgb(217, 216, 216)', }}>
                <FieldGroup
                    id='LOCATION'
                    label={location_name}
                    onChange={(event) => { props.setLocation(event.target.value) }}
                    value={props.locationVal}
                    componentClass="select">
                    {COUNTRIES.map((item, index) => {
                        return <option key={index + item} value={item}>{item}</option>
                    })}
                </FieldGroup>
                {props.locationVal !== EMPTY ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FieldGroup
                            id="LONGTITUDE"
                            label={longtitude_name}
                            onChange={(event) => props.setLongitude(event.target.value)}
                            value={props.longitudeVal}
                        />
                        {EMPTYSPACE}
                        <FieldGroup
                            label={latitude_name}
                            onChange={(event) => props.setLatitude(event.target.value)}
                            value={props.latitudeVal}
                        />
                    </div>
                    : null}
            </div>

            {EMPTYSPACE}

            <FieldGroup
                id='LINK'
                label={link_name}
                onChange={(event) => { props.setLink(event.target.value) }}
                value={props.linkVal}
            />

            {EMPTYSPACE}

            <div style={{ padding: 10, border: 'thin solid rgb(217, 216, 216)', }}>
                {guestcount_name}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'auto' }}>
                    <button
                        style={props.guestcountVal === 'Under 25' ? { whiteSpace: 'nowrap', fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white', whiteSpace: 'nowrap' }}
                        onClick={() => props.guestcountVal === 'Under 25' ? props.setGuestcount(EMPTY) : props.setGuestcount('Under 25')}>
                        Under 25
                    </button>
                    <button
                        style={props.guestcountVal === '25-99' ? { whiteSpace: 'nowrap', fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white', whiteSpace: 'nowrap' }}
                        onClick={() => props.guestcountVal === '25-99' ? props.setGuestcount(EMPTY) : props.setGuestcount('25-99')}>
                        25-99
                    </button>
                    <button
                        style={props.guestcountVal === '100-149' ? { whiteSpace: 'nowrap', fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white', whiteSpace: 'nowrap' }}
                        onClick={() => props.guestcountVal === '100-149' ? props.setGuestcount(EMPTY) : props.setGuestcount('100-149')}>
                        100-149
                    </button>
                    <button
                        style={props.guestcountVal === '150-199' ? { whiteSpace: 'nowrap', fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white', whiteSpace: 'nowrap' }}
                        onClick={() => props.guestcountVal === '150-199' ? props.setGuestcount(EMPTY) : props.setGuestcount('150-199')}>
                        150-199
                    </button>
                    <button
                        style={props.guestcountVal === '200-299' ? { whiteSpace: 'nowrap', fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white', whiteSpace: 'nowrap' }}
                        onClick={() => props.guestcountVal === '200-299' ? props.setGuestcount(EMPTY) : props.setGuestcount('200-299')}>
                        200-299
                    </button>
                    <button
                        style={props.guestcountVal === '300-399' ? { whiteSpace: 'nowrap', fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white', whiteSpace: 'nowrap' }}
                        onClick={() => props.guestcountVal === '300-399' ? props.setGuestcount(EMPTY) : props.setGuestcount('300-399')}>
                        300-399
                    </button>
                    <button
                        style={props.guestcountVal === '400-499' ? { whiteSpace: 'nowrap', fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white', whiteSpace: 'nowrap' }}
                        onClick={() => props.guestcountVal === '400-499' ? props.setGuestcount(EMPTY) : props.setGuestcount('400-499')}>
                        400-499
                    </button>
                    <button
                        style={props.guestcountVal === '500-999' ? { whiteSpace: 'nowrap', fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white', whiteSpace: 'nowrap' }}
                        onClick={() => props.setGuestcount('500-999')}>
                        500-999
                    </button>
                    <button
                        style={props.guestcountVal === '1000+' ? { fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white', whiteSpace: 'nowrap' }}
                        onClick={() => props.setGuestcount('1000+')}>
                        1000+
                    </button>
                </div>
            </div>

            {EMPTYSPACE}

            <div style={{ padding: 10, border: 'thin solid rgb(217, 216, 216)', }}>
                {category_name}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}>
                    <button
                        style={props.wedoreventorbothVal === 'Wedding' ? { fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white' }}
                        onClick={() => props.setWedoreventorboth('Wedding')}>
                        Wedding
                    </button>
                    <button
                        style={props.wedoreventorbothVal === 'Event' ? { fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white' }}
                        onClick={() => props.setWedoreventorboth('Event')}>
                        Event
                    </button>
                    <button
                        style={props.wedoreventorbothVal === 'Both' ? { fontWeight: 'bold', width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', backgroundColor: 'rgb(239, 239, 239)', color: '#555555', } : { width: 'max-content', display: 'flex', justifyContent: 'flex-start', padding: 10, margin: 5, border: 'solid thin #D9D8D8', borderRadius: 5, outline: 'none', color: '#B5B5B5', fontWeight: 'bold', backgroundColor: 'white' }}
                        onClick={() => props.setWedoreventorboth('Both')}>
                        {'Wedding & Event'}
                    </button>
                </div>
            </div>




        </div>
    )
}

export default Formcompoenents