import React, { Component } from 'react'
import Pricelistcreation from './components/Pricelistcreation/Pricelistcreation'
import { VENDOR_ALLPRICELIST_READ_SPECIFICVENDOR, VENDOR_PRICELIST_DESTROY_SPECIFICID } from '../../../../config/api'
import { EMPTYSPACE, TABLET, WEB } from '../../../../config/constants'
import { Pricelistcard, refresh } from '../../../../components'
import { Topmenu } from '../Components'
import axios from 'axios'
import './Pricelist.css'

const listed_css = { backgroundColor: 'rgb(226, 90, 90)', color: 'white', padding: '6px 8px', letterSpacing: '0.5px', borderTopLeftRadius: 5, borderTopRightRadius: 5, fontSize: 10 }
const promo_css = { backgroundColor: '#5a77e2', color: 'white', padding: '6px 8px', letterSpacing: '0.5px', borderTopLeftRadius: 5, borderTopRightRadius: 5, fontSize: 10 }

var localPricelist      //value would be pass in render

class Pricelist extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {

            screen_width: 0,
            screen_height: 0,

            allpricelist_ids: this.props.allpricelist_ids,
            secondaryvendortype: this.props.secondaryvendortype,
            secondaryvendortype_id: this.props.secondaryvendortype_id,
            vendortypeinterface_id: this.props.vendortypeinterface_id,
            token: this.props.token,
            username: this.props.username,
            vendor_id: this.props.vendor_id,
            vendortype: this.props.vendortype,
            vendortype_id: this.props.vendortype_id,
            location: this.props.location, // need to identify the
            startprice: 0, // starting price/ minimum price

            pricelists: [], // coming from componentDidMount

            showpricelistmodal: false,

            selectedmenu: 'Your Pricelists'
        }
    }

    UNSAFE_componentWillMount = async () => {
        const {
            vendortype, vendortype_id,
            allpricelist_ids,
            token
        } = this.state

        try {
            let options = {
                method: 'POST',
                url: VENDOR_ALLPRICELIST_READ_SPECIFICVENDOR,
                headers: { 'Authorization': 'Bearer ' + token },
                data: {
                    vendortype, vendortype_id,
                    allpricelist_ids
                }
            }
            await axios(options).then((response) => {

                const { items, } = response.data

                this.setState({
                    pricelists: items,
                    vendor_ready: true,
                })

            })
        }
        catch (e) {
            refresh()
        }

    }

    componentDidMount = () => {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        this.setState({ screen_width: window.innerWidth, screen_height: window.innerHeight });
    }

    renderCreatebtn = () => { //if more than 5 package exist hide create btn
        if (localPricelist.length > 4) return null
        else {
            return (
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <button onClick={() => { this.setState({ showpricelistmodal: true }) }}
                        style={{ display: 'flex', border: 'dashed thin #D9D8D8', backgroundColor: 'transparent', height: 100, width: '100%', alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 5, marginLeft: 0, marginRight: 0 }} >
                        <h4>Create New Pricelist</h4>
                    </button>
                </div>
            )
        }
    }

    renderCreation = () => {
        const vendortype_ = this.state.vendortype
        const vendortype_id_ = this.state.vendortype_id
        const secondaryvendortype_ = this.state.secondaryvendortype
        const secondaryvendortype_id_ = this.state.secondaryvendortype_id

        var vendortype_map = {}
        vendortype_map[vendortype_] = vendortype_id_
        vendortype_map[secondaryvendortype_] = secondaryvendortype_id_

        const { screen_width } = this.state

        return <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Pricelistcreation
                closemodalAndUpdate={(newpricelist_id) => { // will update pricelistid and close modal
                    this.setState({ allpricelist_ids: [...this.state.allpricelist_ids, newpricelist_id], selectedmenu: 'Your Pricelists' }, () => { this.UNSAFE_componentWillMount() }) // update
                }}
                onReturn={() => { // will close modal
                    this.setState({ selectedmenu: 'Your Pricelists' })
                }}
                vendor_id={this.state.vendor_id}
                vendortype={this.state.vendortype}
                vendortype_id={this.state.vendortype_id}
                secondaryvendortype={this.state.secondaryvendortype}
                secondaryvendortype_id={this.state.secondaryvendortype_id}
                vendortype_map={vendortype_map}
                username={this.state.username}
                token={this.state.token}
                screen_width={screen_width}
            />
        </div>
    }

    onHandleDelete = async (curr_item) => {
        const SUCCESS = 'Successfully removed pricelist'
        const FAILURE = 'Fail to remove pricelist'
        const { _id, curr_vendortype } = curr_item
        const { token, vendortypeinterface_id, vendortype, vendortype_id } = this.state
        try {
            let options = {
                method: 'POST',
                url: VENDOR_PRICELIST_DESTROY_SPECIFICID,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                data: { pricelist_id: _id, vendortypeinterface_id, vendortype, vendortype_id, curr_vendortype }
            }
            await axios(options).then((response) => {
                if (response.data.error === false && response.status === 200) {
                    this.setState({ displaymsg: SUCCESS }, () => {
                        this.UNSAFE_componentWillMount()
                    })
                }
                else {
                    this.setState({ displaymsg: FAILURE })
                }
            }).catch(e => {
                refresh()
            })
        }
        catch (e) {
            this.setState({ displaymsg: FAILURE }, () => { refresh() })
        }
    }

    renderExist = () => {
        const { pricelists, token, vendortypeinterface_id, vendortype_id, vendortype, screen_height, screen_width } = this.state
        // var primary_result = [] // vendortype only
        // var secondary_result = [] // secondary vendortypes only
        // var both_result = [] // both vendortype, if vendor gt two vendortypes

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '0px 5px' }}>
                {pricelists.map((curr_item, index) => {
                    const { listing, promo } = curr_item
                    var listingdom = (
                        <div style={{ display: 'flex' }}>
                            {listing ? <div style={listed_css}>LISTED</div> : null}
                        </div>
                    )
                    var promodom = (
                        <div style={{ display: 'flex' }}>
                            {promo ? <div style={promo_css}>PROMO</div> : null}
                        </div>
                    )
                    if (curr_item.curr_vendortype.length > 1) { // implies double vt pricelist
                        return (
                            <div style={{ padding: '10px 5px' }} key={'both_result_existingpricelist' + index}>
                                <div style={{ justifyContent: 'flex-start', position: 'absolute', display: 'flex', flexDirection: 'row' }}>
                                    {listingdom}
                                    {EMPTYSPACE}
                                    {promo ? promodom : null}
                                </div>
                                <Pricelistcard
                                    key={curr_item}
                                    item={curr_item}
                                    token={token}
                                    vendortypeinterface_id={vendortypeinterface_id}
                                    vendortype={vendortype} vendortype_id={vendortype_id}
                                    handleDelete={() => {
                                        this.onHandleDelete(curr_item)
                                        this.UNSAFE_componentWillMount()
                                    }}
                                    hideenquire={true}
                                />
                            </div>
                        )
                    }
                    else { // single vt pricelist
                        if (curr_item.curr_vendortype[0] === curr_item.vendortype) {
                            return (
                                <div style={{ padding: '10px 5px' }} key={'primary_result_existingpricelist' + index}>
                                    <div style={{ justifyContent: 'flex-start', position: 'absolute', display: 'flex', flexDirection: 'row' }}>
                                        {listingdom}
                                        {EMPTYSPACE}
                                        {promo ? promodom : null}
                                    </div>
                                    <Pricelistcard
                                        key={curr_item}
                                        item={curr_item}
                                        vendortype={vendortype} vendortype_id={vendortype_id}
                                        screen_width={screen_width}
                                        screen_height={screen_height}
                                        handleDelete={() => {
                                            this.onHandleDelete(curr_item)
                                            this.UNSAFE_componentWillMount()
                                        }}
                                        hideenquire={true}
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ padding: '10px 5px' }} key={'secondary_result_existingpricelist' + index}>
                                    <div style={{ justifyContent: 'flex-start', position: 'absolute', display: 'flex', flexDirection: 'row' }}>
                                        {listingdom}
                                        {EMPTYSPACE}
                                        {promo ? promodom : null}
                                    </div>
                                    <Pricelistcard
                                        key={curr_item}
                                        item={curr_item}
                                        token={token}
                                        vendortypeinterface_id={vendortypeinterface_id}
                                        vendortype={vendortype} vendortype_id={vendortype_id}
                                        hideenquire={true}
                                        handleDelete={() => {
                                            this.onHandleDelete(curr_item)
                                            this.UNSAFE_componentWillMount()
                                        }}
                                    />
                                </div>
                            )
                        }
                    }
                })}
            </div>
        )
    }

    render() {
        const { ready } = this.props
        const { selectedmenu } = this.state
        localPricelist = []
        var device = this.state.screen_width < TABLET ? 'MOBILE' : this.state.screen_width < WEB ? 'TABLET' : 'WEB'
        return (
            ready
                ? <div className={"pricelistcreation_root" + device}>
                    <Topmenu
                        menus={['Your Pricelists', 'Create New']}
                        selectedmenu={selectedmenu}
                        device={device}
                        onSelected={(val) => { this.setState({ selectedmenu: val }) }}
                    />
                    {selectedmenu === 'Your Pricelists'
                        ? <div style={{ display: 'flex', flexDirection: "column", width: '100%' }}>
                            {this.renderExist()}
                        </div>
                        : null}


                    {selectedmenu === 'Create New'
                        ? <div style={{ display: 'flex', flexDirection: "column", width: '100%' }}>
                            {this.renderCreation()}
                        </div>
                        : null}

                </div>
                : <div />
        )


    }
}

export default Pricelist