import React, { Component } from 'react'
import { ControlLabel, FormControl, FormGroup, Form } from "react-bootstrap"
import { VENDOR_VENDORTYPE_UPDATE, VENDOR_TWOVENDORTYPE_UPDATE, } from '../../../../config/api'
import { EMPTY, FORM_ERROR_STYLE, IMPORTANT, IMG_ALT, COLLECTION_VENDORTYPE } from '../../../../config/constants'
import { BUTTONSTYLE } from '../../../../config/cssconstants'
import { Validateform } from '../../components/Validateform/Validateform'
import { Imagedownsize } from '../../components/Imagedownsize/Imagedownsize'
import refresh from '../../../../components/refresh';
import axios from 'axios'

class Firsttime extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {

            vendor_id: this.props.vendor_id,
            vendortype: COLLECTION_VENDORTYPE[0].value,
            secondaryvendortype: EMPTY,
            secondaryvendortype_errormsg: EMPTY,
            currtoken: this.props.currtoken,
            displayname: EMPTY,
            username: this.props.username,

            //logo
            logo: null,
            file: EMPTY,
            files: [],
            profileimg_btnstate: true,
            //logo

            vt_errormsg: EMPTY,
        }
    }



    updateVendortype = async (e) => {
        e.preventDefault()
        const { vendor_id, vendortype, secondaryvendortype, currtoken, displayname, file } = this.state
        var localError = []
        var gotError_validate = () => {
            var gotError = false
            if (Validateform({ form_key: 'vendortype', form_parts: { vendortype, secondaryvendortype } })) {
                localError.push(<div key='vendortypeAndSecondaryvendortype_error' style={FORM_ERROR_STYLE}>Error: Unable to update vendor type {IMPORTANT}</div>)
                gotError = true
            }
            if (Validateform({ form_key: 'displayname', form_parts: { displayname } })) {
                localError.push(<div key='displayname_error' style={FORM_ERROR_STYLE}>Error: Display name field.  {IMPORTANT}</div>)
                gotError = true
            }
            if (file === EMPTY) {
                localError.push(<div key='file_error' style={FORM_ERROR_STYLE}>Error: Please upload your profile image.{IMPORTANT}</div>)
                gotError = true
            }

            if (!displayname.match("^[0-9a-zA-Z \b]+$")) {
                localError.push(<div key='displayname_specialchar' style={FORM_ERROR_STYLE}>Error:  Displayname cannot contain special character.{IMPORTANT}</div>)
                gotError = true
            }

            if (displayname.length > 30) {
                localError.push(<div key='displayname_2nametoolong' style={FORM_ERROR_STYLE}>Error:  Displayname character limit : 30.{IMPORTANT}</div>)
                gotError = true
            }

            if (displayname && (displayname.substr(displayname.length - 1, 1) === ' ' || displayname.substr(0, 1) === ' ')) {
                localError.push(<div key='displayname_1nametoolong' style={FORM_ERROR_STYLE}>Error:  Displayname last and first character cannot be space.{IMPORTANT}</div>)
                gotError = true
            }

            return gotError
        }



        if (!gotError_validate()) {

            var link = EMPTY
            var data = new FormData()
            if (secondaryvendortype && secondaryvendortype.length > 1) {
                link = VENDOR_TWOVENDORTYPE_UPDATE
                data.append('secondaryvendortype', secondaryvendortype)
            }
            else {
                link = VENDOR_VENDORTYPE_UPDATE
            }

            data.append('vendor_id', vendor_id)
            data.append('vendortype', vendortype)
            data.append('displayname', displayname)
            data.append('file', file)
            let options = {
                method: 'POST',
                url: link,
                headers: { 'Authorization': 'Bearer ' + currtoken,'Content-Type': 'multipart/form-data' }, //need token from the web
                data: data
            }
            await axios(options).then((response) => {
                const { data } = response
                const { message } = data

                if (message === 'ERROR_DISPLAYNAME') {
                    this.setState({ show_firsttime: true, vt_errormsg: 'Error: Displayname has already been taken.' })
                }
                else if (message === 'ERROR_VT') {
                    this.setState({ show_firsttime: true, vt_errormsg: 'Error: Vendortype.' })
                }
                else if (message === 'SPECIAL_CHAR') {
                    this.setState({ show_firsttime: true, vt_errormsg: 'Error: Displayname cannot contain special character.' })
                }
                else if (message === 'UNAME_LEN') {
                    this.setState({ show_firsttime: true, vt_errormsg: 'Error: Displayname character max limit : 30, min limit: 5' })
                }
                else if (message === 'ERROR') {
                    this.setState({ show_firsttime: true, vt_errormsg: 'Error occured' })
                }
                else {
                    this.setState({ show_firsttime: false, vt_errormsg: 'Proceeding to Vendorprofile page!' }, () => { refresh() })
                }
            }).catch(e => refresh())

        }
        else {
            this.setState({ vt_errormsg: localError })
        }
    }

    inputVt = (event) => {

        const { secondaryvendortype } = this.state
        if (secondaryvendortype === event.target.value) {
            this.setState({ vt_errormsg: 'Error: Please select a different primary vendortype' })
        }
        else {
            this.setState({ vendortype: event.target.value })
        }
    }

    inputSvt = (event) => {
        const { vendortype } = this.state
        if (vendortype === event.target.value) {
            this.setState({ vt_errormsg: 'Error: Please select a different secondary vendortype' })
        }
        else {
            this.setState({ secondaryvendortype: event.target.value })
        }
    }

    render() {

        const { vendortype, secondaryvendortype, vt_errormsg } = this.state
        return (
            <div className='container' style={{ color: 'rgb(112, 112, 112)' }}>
                <Form>
                    <FormGroup controlId="formControlsSelect1">
                        <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Select your vendor type{IMPORTANT}</ControlLabel>
                        <FormControl
                            key={'vt_form'}
                            value={vendortype}
                            onChange={this.inputVt}
                            componentClass="select"
                            placeholder={vendortype} >
                            {COLLECTION_VENDORTYPE.map((item, index) => {
                                return (<option key={index} value={item.value}>{item.value}</option>)
                            })}
                        </FormControl>
                    </FormGroup>

                    {vendortype && vendortype.length > 0
                        ? <FormGroup controlId="formControlsSelect2">
                            <ControlLabel>Select your secondary vendor type ( Optional )</ControlLabel>
                            <FormControl
                                key={'svt_form'}
                                value={secondaryvendortype}
                                onChange={this.inputSvt}
                                style={{ backgroundColor: 'white' }}
                                componentClass="select"
                                placeholder={secondaryvendortype} >
                                {COLLECTION_VENDORTYPE.map((item, index) => {
                                    return (<option key={index} value={item.value}>{item.value}</option>)
                                })}
                            </FormControl>
                        </FormGroup>
                        : null}

                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Display Name ( spaces, numbers, letters only and cannot be same as username ) {IMPORTANT}</ControlLabel>

                    <FormGroup controlId="formInlineName" >
                        <FormControl
                            type="displayname"
                            placeholder="Your displayname is how other users will see you."
                            value={this.state.displayname}
                            onChange={(event) => { this.setState({ displayname: event.target.value }) }}
                        />
                    </FormGroup>

                    <ControlLabel style={{ display: 'flex', flexDirection: 'row' }}>Logo/Profile image{IMPORTANT}</ControlLabel>

                    <div style={{ border: 'solid thin #D9D8D8', borderRadius: 5, padding: 10, marginBottom: 10, }}>
                        <div style={{ display: 'flex', width: 200, height: 200, border: 'dashed thin #D9D8D8', justifyContent: 'center', alignItems: 'center', outline: 'none' }}>
                            <div style={{ position: 'absolute' }}>
                                {this.state.logo ? <img alt={IMG_ALT} style={{ overflow: 'hidden', width: 200, height: 200 }} src={this.state.logo} /> : <div>+</div>}
                            </div>
                            <input
                                accept="image/*"
                                className="inputfile"
                                id="formControlsFile"
                                type='file'
                                name="input-file"
                                style={{ opacity: 0, width: 200, height: 200, position: 'absolute' }}
                                onChange={async (event) => {
                                    event.preventDefault()
                                    var reader = new FileReader()
                                    let file = event.target.files[0]
                                    reader.onload = (e) => {
                                        var img = document.createElement("img");
                                        img.onload = () => {

                                            var result = Imagedownsize(img, 'logo', null, null)
                                            var dataurl = result[0]
                                            var newfile = result[1]
                                            this.setState({
                                                file: newfile,
                                                logo: dataurl, // logo: reader.result,
                                                profileimg_btnstate: false,
                                            });
                                        }
                                        img.src = e.target.result;
                                    }
                                    reader.readAsDataURL(file);
                                }}
                            />
                        </div>

                    </div>
                    <div className='empty space' style={{ padding: 10 }}>{'\u00A0'}</div>

                    <div key={'errormsg'}>{vt_errormsg}</div>

                    <div style={{ marginTop: 5, marginBottom: 100, }}>
                        <button
                            onClick={this.updateVendortype}
                            style={{ float: 'right', ...BUTTONSTYLE }}>
                            Update
                        </button>
                    </div>
                    <div className='empty space' style={{ margin: 10 }}>{'\u00A0'}</div>
                </Form>
            </div>
        )
    }
}

export default Firsttime