import React from 'react'
import { VENDOR_PROJECT_DESTROY_SPECIFICID } from '../../../../../../config/api';
import { Checkdevice, Projectcard } from '../../../../../../components'
import axios from 'axios'
import './Existprojects.css'
import { PC_WT_MAP } from '../../../../../../config/constants';

function Existprojects(props) {
    const { existingprojects, vendortype_id, vendortype, token, vendortypeinterface_id, profileimg } = props
    const device = Checkdevice()
    return <ul style={{ display: 'grid', gridTemplateColumns: `repeat(auto-fit, minmax(${PC_WT_MAP[device]}px, max-content))`, justifyContent: 'center', width: '100%', padding: 0, margin: 0, }}>
        {existingprojects.map((item, i) => {
            const { _id, projectinterface_id, collaborator_ids } = item
            return <Projectcard
                {...props}
                key={i}
                item={item}
                device={device}
                viewtype={0} // viewtype can be 0 or 1, 0 : vendor viewing 1 : user viewing
                profileimg_0={profileimg}
                onDelete_0={(e) => {
                    if (window.confirm('Are you sure you wish to delete this item? This action is irreversible, likes and tags will be gone forever!')) {
                        e.preventDefault()
                        // const SUCCESS = 'Successfully remove project'
                        const FAILURE = 'Failed to remove project'
                        try {
                            let options = {
                                method: 'POST',
                                url: VENDOR_PROJECT_DESTROY_SPECIFICID,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                                data: { vendortype_id, vendortype, project_id: _id, projectinterface_id, collaborator_ids, vendortypeinterface_id }
                            }
                            axios(options).then((response) => {
                                if (response.data.error === false && response.status === 200) {
                                    const { projects } = response.data
                                    props.update(projects) // update to existingprojects
                                }
                                else {
                                    console.log(FAILURE)
                                }
                            })
                        }
                        catch (e) {
                            console.log(FAILURE)
                        }
                    }
                }}
                onClick_0={() => {
                    props.existprojclicked(true, existingprojects[i])
                }}
            />
        })}
    </ul>

}
export default Existprojects