export default function Checklandscape(_img) {
    var height = _img.height
    var width = _img.width
    if (width > height) {
        return true
    }
    else {
        return false
    }
}
