import React, { Component } from 'react'
import { READABLE_VENDORTYPE_MAP, WEB, EMPTY, TIME_SMALL, DOLLAR_SMALL, DELIEVERABLES_SMALL, DESTINATION_SMALL, MORE_SMALL, TABLET, HTTPSWEBSITE_URL, } from '../../config/constants'
import { BUTTONSTYLE } from '../../config/cssconstants';
import { Processmoney } from '..';

//thin solid rgb(244, 244, 244)
const expanded_css = { cursor: 'pointer', height: 'max-content', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'white', marginTop: 15, width: '100%', boxShadow: 'rgba(25, 25, 25, 0.25) 1px 2px 7px', borderRadius: 5 }
const hidden_css = { height: 'max-content', cursor: 'pointer', display: 'flex', marginTop: 15, borderRadius: 5, boxShadow: 'rgba(25, 25, 25, 0.25) 1px 2px 7px', }

function commontitle(title, icon) {
    return <div style={{ display: 'flex', width: '100%', placeContent: 'flex-start', padding: 10 }}>
        {icon ? <div>{icon}</div> : null}
        <div style={{ fontWeight: 'bold', paddingLeft: 5, fontSize: 16, color: '#707070' }}>{title}</div>
    </div>
}

function bullets(val, index, fs) {
    return <li key={index} style={{ padding: 5, margin: '5px 10px', fontSize: fs, flexWrap: 'wrap' }}>{val}</li>
}

const SKINNY_TINY_SEPARATOR_LINE_90 = <div style={{ borderLeft: '1px solid #eee' }} />

class Pricelistcard extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            device: null,
            commontext_size: null,
            expand: null
        }
    }
    componentDidMount() {
        const { screen_width, expand } = this.props
        var device
        if (screen_width < TABLET) {
            device = 'MOBILE'
        }
        else if (screen_width < WEB) {
            device = 'TABLET'
        }
        else {
            device = 'WEB'
        }
        const commontext_size = device === 'MOBILE' ? 13 : 14

        this.setState({ device, commontext_size, expand: expand })

    }
    componentDidUpdate(prevProps) {
        if (this.props.expand !== prevProps.expand) {
            this.setState({ expand: this.props.expand })
        }
    }

    nameDom = (name, wt) => {

        const fs = this.state.device === 'MOBILE' ? 24 : 32
        const ht = '60px'
        return (
            <div
                style={{ borderBottom: '1px solid #eee', display: 'flex', flexDirection: 'row', width: wt, minHeight: ht, justifyContent: 'center', alignItems: 'center', fontSize: fs, flexWrap: 'wrap', margin: '10px 5px', padding: 5, color: '#707070', borderRadius: 10, textAlign: 'center' }}
                onClick={() => { this.setState({ expand: false }) }}
            >
                {name}
            </div>
        )
    }

    indicatorDom = (wedtype, eventorwed, location, wt) => {
        const fs = this.state.device === 'MOBILE' ? 14 : 18
        const indcss = { width: wt, fontSize: fs, color: '#707070', padding: 10, display: 'flex', placeContent: 'center' }
        const indcontentcss = { display: 'flex', alignSelf: 'flex-start', width: '100%' }
        return <div style={indcss}>
            <div style={indcontentcss}>{location + ', ' + wedtype}</div>
        </div>
    }

    tagDom = (curr_vts = [], cat, wt) => {
        const fs = 12
        var tags
        if (curr_vts.length > 1) tags = [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]], READABLE_VENDORTYPE_MAP[curr_vts[1]]]
        else tags = [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]]]
        const tagcss = { display: 'flex', color: 'white', padding: '6px 8px ', margin: '0px 8px 8px 0px', letterSpacing: 0.5, borderRadius: 4, backgroundColor: '#707070' }
        return (
            <div style={{ borderBottom: '1px solid #eee', display: 'flex', flexDirection: 'row', width: wt, justifyContent: 'flex-start', fontSize: fs, padding: 10, flexWrap: 'wrap' }}>
                {tags.length > 0 && tags.map((val, i) => {
                    return <div style={tagcss} key={i + val}>
                        <div style={{ paddingLeft: 5, }}>{val && val.length > 0 ? val.toString().toUpperCase() : null}</div>
                    </div>
                })}
            </div>
        )
    }

    priceDom = (price, price_fixedval, currency, price_rangestartval, price_rangeendval, price_addnote, wt) => {
        var price_dom
        const pricecolor = 'rgb(226, 90, 90)'

        const { commontext_size } = this.state

        if (price === 'Fixed') {
            price_dom = <div style={{ display: 'flex', padding: '10px 5px', fontWeight: 'bold', color: pricecolor, overflowX: 'auto' }}>{'$ ' + Processmoney(price_fixedval) + ' ' + currency}</div>
        }
        else if (price === 'Range') {
            price_dom = <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', padding: '10px 5px', color: pricecolor, overflowX: 'auto' }}>{'$ ' + Processmoney(price_rangestartval) + ' - $ ' + Processmoney(price_rangeendval) + ' ' + currency}</div>
        }
        return (
            <div style={{ borderBottom: '1px solid #eee', display: 'flex', flexDirection: 'column', width: wt, color: '#707070', borderRadius: 10, margin: 10, minHeight: 100, justifyContent: 'center' }}>
                {commontitle('Pricing', DOLLAR_SMALL)}
                <div style={{ fontSize: 20, display: 'flex', justifyContent: 'center' }}>
                    {price_dom}
                </div>
                {price_addnote && price_addnote.length > 0 ? <div style={{ display: 'flex', justifyContent: 'center', fontSize: commontext_size, padding: '10px 15px' }}>
                    {price_addnote}
                </div> : null}
            </div>
        )
    }

    destinationDom = (destination, destination_addnote, wt) => {
        const { commontext_size } = this.state
        const DESTINATION_KEY_MAP = {
            '': 'None',
            'None': 'None',
            'Local': 'Local',
            'Overseas': 'Overseas',
            'Both': 'Both',
            'Local + Local': 'Local (Pre) & Local (Actual)',
            'Local + Overseas': 'Local (Pre) & Overseas (Actual)',
            'Overseas + Local': 'Overseas (Pre) & Local (Actual)',
            'Overseas + Overseas': 'Overseas (Pre) & Overseas (Actual)'
        }
        return destination !== 'None' || destination !== ''
            ? <div style={{ display: 'flex', flexDirection: 'column', width: '50%', color: '#707070', borderRadius: 10, margin: '10px 10px 10px 0px', minHeight: 100 }}>
                {commontitle('Destination', DESTINATION_SMALL)}
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 5px', width: '100%' }}>
                    {DESTINATION_KEY_MAP[destination]}
                </div>
                {destination_addnote && destination_addnote.length > 0 ? <div style={{ display: 'flex', justifyContent: 'center', fontSize: commontext_size, padding: '10px 15px' }}>{destination_addnote}</div> : null}
            </div>
            : null
    }

    durationDom = (duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, wt) => {
        const { commontext_size } = this.state
        const DURATION_KEY_MAP = {
            '': 'None',
            'None': 'None',
            'Range': duration_rangestartval + ' - ' + duration_rangeendval + ' hr',
            'Fixed': duration_fixedval + ' hr'
        }

        var durationval = DURATION_KEY_MAP[duration]

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%', color: '#707070', borderRadius: 10, margin: '10px 10px 10px 0px', minHeight: 100 }}>
                {commontitle('Duration', TIME_SMALL)}
                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px 5px', width: '100%' }}>
                    {durationval}
                </div>
                {duration_addnote && duration_addnote.length > 0 ? <div style={{ display: 'flex', justifyContent: 'center', fontSize: commontext_size, padding: '10px 15px' }}>{duration_addnote}</div> : null}
            </div>
        )
    }

    delieverablesDom = (deliverables_items = [], wt) => {
        const { commontext_size } = this.state
        return (
            <div style={{ borderBottom: '1px solid #eee', display: 'flex', flexDirection: 'column', width: wt, color: '#707070', borderRadius: 10, margin: 10, minHeight: 100 }}>
                {commontitle('Delieverables', DELIEVERABLES_SMALL)}
                {deliverables_items.map((val, index) => {
                    return bullets(val, index, commontext_size)
                })}
            </div>
        )
    }

    additionalchargesDom = (additionalcharges_items, wt) => {
        const { commontext_size } = this.state
        if (additionalcharges_items && additionalcharges_items.length > 0) {
            return (
                <div style={{ borderBottom: '1px solid #eee', display: 'flex', flexDirection: 'column', width: wt, color: '#707070', borderRadius: 10, margin: 10, minHeight: 100, alignContent: 'center' }}>
                    {commontitle('Additional Charges', MORE_SMALL)}
                    {additionalcharges_items.map((val, index) => {
                        return bullets(val, index, commontext_size)
                    })}
                </div>
            )
        }
        return null

    }

    descriptionDom = (description, wt) => {
        const { commontext_size } = this.state
        if (description && description.length > 0) {
            return (
                <div style={{ borderBottom: '1px solid #eee', display: 'flex', flexDirection: 'column', width: wt, color: '#707070', borderRadius: 10, margin: 10, minHeight: 100, alignContent: 'center' }}>
                    {commontitle('Description', null)}
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignContent: 'center', padding: '10px 15px', fontSize: commontext_size, }}>
                        {description}
                    </div>
                </div>
            )
        }
        return null
    }

    othersDom = (others, wt) => {
        const { commontext_size } = this.state
        if (others && others.length > 0) {
            return (
                <div style={{ borderBottom: '1px solid #eee', display: 'flex', flexDirection: 'column', width: wt, color: '#707070', borderRadius: 10, margin: 10, minHeight: 100, alignContent: 'center' }}>
                    {commontitle('Others', null)}
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignContent: 'center', padding: '10px 15px', fontSize: commontext_size, }}>
                        {others}
                    </div>
                </div>
            )
        }
        return null
    }

    termsandcondDom = (tnc, wt) => {
        const { commontext_size } = this.state
        if (tnc && tnc.length > 0) {
            return (
                <div style={{ borderBottom: '1px solid #eee', display: 'flex', flexDirection: 'column', width: wt, color: '#707070', borderRadius: 10, margin: 10, minHeight: 100, alignContent: 'center' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignContent: 'center', padding: 10 }}>
                        <div style={{ fontWeight: 'bold', paddingLeft: 5, fontSize: 16 }}>{'Terms & Conditions'}</div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', placeContent: 'flex-start', padding: '10px 15px', fontSize: commontext_size, }}>
                        {tnc}
                    </div>
                </div>
            )
        }
        return null
    }

    delDom = () => {
        const { handleDelete } = this.props
        return (
            <button
                onClick={handleDelete}
                style={{
                    borderRadius: 20,
                    border: 0,
                    top: 0,
                    right: 0,
                    width: 20,
                    height: 20,
                    backgroundColor: 'transparent',
                    outline: 'none',
                }}>
                <div style={{ padding: 0, color: 'rgb(226, 90, 90)', fontWeight: 'bold', }}>
                    X
                </div>
            </button>
        )
    }

    hidden_nameDom = (name, wt) => {
        const fs = this.state.device === 'MOBILE' ? 16 : 18
        const NUM = 50
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: wt, padding: 10, fontSize: fs }}>
                {name && name.length > NUM ? <div>{name.substring(0, 50) + '...'}</div> : <div>{name}</div>}
            </div>
        )
    }

    hidden_indicatorDom = (wedtype, eventorwed, location, wt) => {
        const fs = this.state.device === 'MOBILE' ? 12 : 14
        const indcss = { width: wt, fontSize: fs, color: '#707070', padding: '5px 10px', display: 'flex', placeContent: 'center' }
        const indcontentcss = { display: 'flex', alignSelf: 'flex-start', width: '100%' }

        return <div style={indcss}>
            <div style={indcontentcss}>{location + ', ' + wedtype}</div>
        </div>
    }

    hidden_destinationDom = (destination, wt) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: wt, color: '#707070', borderRadius: 10, }}>
                <div style={{ padding: '0px 5px' }}>{DESTINATION_SMALL}</div>
                {destination && destination.length > 0 && destination !== 'None' ? '✓' : '-'}
            </div>
        )
    }

    hidden_durationDom = (duration, wt) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: wt, color: '#707070', borderRadius: 10, }}>
                <div style={{ padding: '0px 5px' }}>{TIME_SMALL}</div>
                {duration && duration.length > 0 && duration !== 'None' ? '✓' : '-'}
            </div>
        )
    }

    hidden_tagDom = (curr_vts, cat, wt) => {
        const fs = this.state.device === 'MOBILE' ? 10 : 10
        var tags
        if (curr_vts.length > 1) tags = [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]], READABLE_VENDORTYPE_MAP[curr_vts[1]]]
        else tags = [cat, READABLE_VENDORTYPE_MAP[curr_vts[0]]]
        const tagcss = { display: 'flex', color: 'white', padding: 5, margin: '0px 8px 8px 0px', letterSpacing: 0.5, borderRadius: 4, backgroundColor: '#707070' }
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: wt, justifyContent: 'flex-start', fontSize: fs, padding: '5px 10px', flexWrap: 'wrap', }}>
                {tags.length > 0 && tags.map((val, i) => {
                    return <div style={tagcss} key={i + val}>
                        <div style={{ paddingLeft: 5, }}>{val && val.length > 0 ? val.toString().toUpperCase() : null}</div>
                    </div>
                })}
            </div>
        )
    }

    hidden_delieverablesDom = (deliverables_items = [], wt) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', width: wt, color: '#707070', borderRadius: 10, padding: '5px 10px', alignItems: 'center' }}>
                <div style={{ padding: '0px 5px' }}>{DELIEVERABLES_SMALL}</div>
                {deliverables_items.length}
            </div>
        )
    }

    hidden_priceDom = (price, price_fixedval, currency, price_rangestartval, price_rangeendval, wt) => {
        var price_dom
        const pricecolor = 'rgb(226, 90, 90)'
        const fs = this.state.device === 'MOBILE' ? 18 : 20

        if (price === 'Fixed') {
            price_dom = <div style={{ display: 'flex', padding: '10px 5px', fontWeight: 'bold', color: pricecolor, overflowX: 'auto' }}>{'$ ' + Processmoney(price_fixedval) + ' ' + currency}</div>
        }
        else if (price === 'Range') {
            price_dom = <div style={{ display: 'flex', flexDirection: 'row', fontWeight: 'bold', padding: '10px 5px', color: pricecolor, overflowX: 'auto' }}>{'$ ' + Processmoney(price_rangestartval) + ' - $ ' + Processmoney(price_rangeendval) + ' ' + currency}</div>
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: wt, color: '#707070', borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ fontSize: fs, display: 'flex', justifyContent: 'center' }}>
                    {price_dom}
                </div>
            </div>
        )
    }

    render() {
        const { item, handleDelete = EMPTY, //screen_width, screen_height, enquireService = EMPTY,
        } = this.props

        const { name, _id,
            wedtype, eventorwed, location, //indicatorDom 
            curr_vendortype, // tag
            price, price_fixedval, currency, price_rangestartval, price_rangeendval, price_addnote,// price
            destination, destination_addnote, //destination
            duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, // duration
            deliverables_items,
            additionalcharges_items,
            description,
            others, // others
            tnc// terms and cond
        } = item

        const { expand, device } = this.state

        const deleteBtn = handleDelete !== EMPTY
            ? <div style={{ display: 'flex', width: '100%', alignSelf: 'flex-end', justifyContent: 'flex-end' }}> {/* exist if the pricelistcard is called from vendor profile pricelist category */}
                {this.delDom()}
            </div>
            : null

        const pllookupBtn = (
            <a href={HTTPSWEBSITE_URL + '/pricelistlookup/' + this.props.item._id} target="_blank" style={{ padding: 0, margin: 5, textDecoration: 'none' }}>
                <div style={{ ...BUTTONSTYLE, margin: 0, backgroundColor: 'white', color: '#707070', outline: 'none', border: 'thin solid #707070', boxShadow: 'rgba(25, 25, 25, 0.2) 1px 1px 5px', }}>
                    Lookup
                </div>
            </a>
        )

        const hideBtn = (
            <div style={{ ...BUTTONSTYLE, backgroundColor: 'white', color: '#707070', margin: '5px 0px', outline: 'none', border: 'thin solid #707070', boxShadow: 'rgba(25, 25, 25, 0.2) 1px 1px 5px', height: 'max-content' }}
                onClick={() => { this.setState({ expand: false }) }}>
                Hide
            </div>
        )
        
        const wt = this.state.device === 'MOBILE' ? '100%' : '75%'
        const destanddur_css = { borderBottom: '1px solid #eee', width: wt, display: 'flex', flexDirection: 'row', justifyContent: 'center' }

        if (expand) {
            return <div style={expanded_css}>
                {deleteBtn}
                {this.nameDom(name, wt)}
                {this.indicatorDom(wedtype, eventorwed, location, wt)}
                {this.tagDom(curr_vendortype, eventorwed, wt)}
                {this.priceDom(price, price_fixedval, currency, price_rangestartval, price_rangeendval, price_addnote, wt)}
                <div style={destanddur_css}>
                    {this.destinationDom(destination, destination_addnote, wt)}
                    {SKINNY_TINY_SEPARATOR_LINE_90}
                    {this.durationDom(duration, duration_fixedval, duration_rangestartval, duration_rangeendval, duration_addnote, wt)}
                </div>
                {this.delieverablesDom(deliverables_items, wt)}
                {this.additionalchargesDom(additionalcharges_items, wt)}
                {this.descriptionDom(description, wt)}
                {this.othersDom(others, wt)}
                {this.termsandcondDom(tnc, wt)}
                <div style={{ display: 'flex', justifyContent: 'space-between', width: wt, flexDirection: 'row-reverse', marginBottom: 30 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', padding: 5 }}>
                        {hideBtn}
                        {_id ? pllookupBtn : null}
                    </div>
                </div>
            </div>

        }
        else {
            return <div style={{ ...hidden_css, flexDirection: device === 'MOBILE' ? 'column' : 'row' }} onClick={() => { this.setState({ expand: true }) }}>
                <div>{expand}</div>
                <div style={{ display: 'flex', flexDirection: 'column', width: device === 'MOBILE' ? '100%' : '70%' }}>
                    {this.hidden_nameDom(name)}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: device === 'MOBILE' ? '60%' : '45%' }}>
                            {this.hidden_indicatorDom(wedtype, eventorwed, location, '100%')}
                            {this.hidden_tagDom(curr_vendortype, eventorwed, '100%')}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: device === 'MOBILE' ? '40%' : '55%', padding: '5px 10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', padding: '5px 10px' }}>
                                {this.hidden_destinationDom(destination, '50%')}
                                {this.hidden_durationDom(duration, '50%')}
                            </div>
                            {this.hidden_delieverablesDom(deliverables_items, '100%')}
                        </div>

                    </div>
                </div>
                {this.hidden_priceDom(price, price_fixedval, currency, price_rangestartval, price_rangeendval, device === 'MOBILE' ? '100%' : '30%')}
            </div>
        }

    }
}
export default Pricelistcard