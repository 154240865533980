import React, { useState, useEffect, useRef } from 'react'
import { Link, } from "react-router-dom";
import { ScreenHeight, ScreenWidth, Headtitle, Processname } from '../../components';
import { NEAR_TRANSPARENT_GREY, EMPTYSPACE, EMPTY, WEB, SENDMSG_ICON, SOFT_GREY, TRASH_ICON, BACK_ICON, READABLE_VENDORTYPE_MAP, IMG_ALT, production, NUM_WORDS_CANSEND, LEN_MSG_CANSEND } from '../../config/constants';
import { Retrieve_personal_info } from '../../config/commonfunctions';
import { READ_CONV_VENDOR_START, READ_CONV_USER_START, READ_CONV_SPECIFIC_RM_USER, READ_CONV_SPECIFIC_RM_VENDOR, REMOV_CONV_USER, REMOV_CONV_VENDOR, B_W } from '../../config/api';
import axios from 'axios'
import io from 'socket.io-client';
const trash_name_back_color = 'rgb(100 , 100, 100)'

// Messages will be deleted after 60 days.

var once = true
function onlyonesocket() {
    // curl "https://api.weaverank.com:3000/socket.io/?EIO=4&transport=polling" -v
    // Firefox can’t establish a connection to the server at wss://api.weaverank.com:3000/socket.io/?EIO=4&transport=websocket.
    if (once) { 
        once = false
        var local_B_W = B_W.substring(0, B_W.length - 5) // remove /api/
        if (production) {
            return io(local_B_W, { transports: ["polling"], reconnection: false, secure: true }) // link should be server side link
        }
        else {
            return io(local_B_W, { transports: ["websocket", "polling"], reconnection: false, secure: true }) // link should be server side link
        }
    }
}

function constructmsg(message, user, date) { // message text, owner or other
    var justifycontent = user === 'owner' ? 'flex-end' : 'flex-start'
    var border = user === 'owner' ? '1px solid ' + SOFT_GREY : '1px solid #ffa5a5'

    return (
        <div key={Math.random()} value="message" style={{ display: 'flex', width: '100%', justifyContent: justifycontent, }}>
            <div style={{ width: 'fit-content', display: 'flex', maxWidth: '50%', border: border, borderRadius: 5, padding: 5, margin: '5px 10px', flexDirection: 'column' }}>
                <div>{message}</div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', color: 'rgba(0, 0, 0, 0.45)', fontSize: '11px' }}>{date}</div>
            </div>
        </div>
    )
}

function constructsep(createddate) {
    return <div key={createddate} style={{ display: 'flex', justifyContent: 'center', padding: 5, margin: 5 }}>{createddate}</div>

}

function Message(props) {
    
    // for both mobile and web
    const { token, _id, acct_type } = Retrieve_personal_info()
    const [socket, setSocket] = useState(onlyonesocket()) // eslint-disable-line
    const [convid, setConvid] = useState(EMPTY)
    const [name, setName] = useState(EMPTY)// vendor: displayname, user: username
    const [msgmap, setMsgmap] = useState({})
    const [msg, setMsg] = useState([])
    const [newmsgdata, setNewmsgdata] = useState([])
    const [input, setInput] = useState(EMPTY)
    const [chatclick, setChatclick] = useState(false)
    const [newmsglistener, setNewmsglistener] = useState(false)

    let scrolldown = useRef()

    // only appear in web
    const [firstpage, setFirstpage] = useState(EMPTY)

    // only appear if is user
    const [vendordisplayname, setVendordisplayname] = useState(null)
    const [vendor, setVendor] = useState(null)
    const [vendorprofileimg, setVendorprofileimg] = useState(null)

    // only appear if is vendor
    const [userusername, setUserusername] = useState(null)

    // for mobile only
    const [flip, setFlip] = useState(true)

    const [conversations_map, setConversations_map] = useState({})
    const [conversations_key, setConversations_key] = useState([])
    const wt = ScreenWidth()
    const ht = ScreenHeight()
    const device = wt < WEB ? 'MOBILE' : 'WEB'

    function constructscrolldowndom() {// scroll
        return <div key={'superscroll'} value='scrolldowndom' ref={scrolldown} style={{ width: '100%', height: 0 }} />
    }

    useEffect(() => {
        // console.log('first')// aka componentDidMount
        socket.on('connect_error', function (err) { props.history.push('/') })
        socket.on('message', (data) => { console.log('new data', data); setNewmsgdata(data) }) // this will keep supply new msg, change 

        window.onbeforeunload = function (e) { // activated when refresh
            once = true
            // if (convid !== EMPTY) 
            socket.emit('detachsocket') // this is to sub disconnect...no idea why but is not working
        };

        return () => { // when navigate to another page
            once = true
            // if (convid !== EMPTY) 
            socket.emit('detachsocket') // this is to sub disconnect...no idea why but is not working
        }

    }, [])

    useEffect(() => {
        // console.log('second')
        var link
        var index
        if (acct_type === 'VENDOR') {
            link = READ_CONV_VENDOR_START
            index = 0 // view user's stuff
        }
        else if (acct_type === 'USER') {
            link = READ_CONV_USER_START
            index = 1 // view vendor's stuff
        }

        let options = {
            method: 'POST',
            url: link,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
            data: { _id }
        }

        axios(options).then((response) => {
            const { message, result } = response.data
            if (message === 'SUCCESS') {
                var conversations_map = {}
                var conversations_key = []
                var name
                var gtconvo = false
                for (let i = 0; i < result.length; i++) {
                    // {
                    //     participants: Array(2),
                    //     profileimg_ids: Array(2),
                    //     newmsgs: Array(2), _
                    //     id: "5d44405f5044ada48fd55da8",
                    //     createdAt: "2019-08-07T15:02:17.594Z"
                    // }
                    gtconvo = true
                    const { participants, profileimg_ids, _id, newmsgs, vendortype, secondaryvendortype } = result[i]
                    name = participants[index]
                    conversations_map[_id] = {
                        name: participants[index],
                        participants: participants,
                        profileimg: profileimg_ids[index].link,

                        target: participants[index === 0 ? 1 : 0],
                        targetprofileimg: profileimg_ids[index === 0 ? 1 : 0].link,
                        newmsg: newmsgs[index],
                        vendortype: vendortype,
                        secondaryvendortype: secondaryvendortype
                    }
                    conversations_key.push(_id)
                    setConversations_map(conversations_map)
                    setConversations_key(conversations_key)
                    socket.emit('join', { convid: _id, name: name }) // join all conversation ids 

                }
                if (gtconvo) {
                    setFirstpage(<h2 key='new-first-page'>Chat with our vendors here!</h2>)
                }
                else {
                    setFirstpage(<h2>You have no one to talk to.</h2>)
                }
                setName(name)
            }
            else {
                props.history.push('/')
                return
            }
        })

    }, [token])

    useEffect(() => {
        // console.log('third')
        if (Object.keys(conversations_map).length !== 0) {
            const { message, sender, conversation_id, createdAt } = newmsgdata
            var local_conversation_map = conversations_map
            local_conversation_map[conversation_id].newmsg = convid !== conversation_id ? local_conversation_map[conversation_id].newmsg + 1 : local_conversation_map[conversation_id].newmsg// if user/vendor not in the convid room increase counter
            setNewmsglistener(!newmsglistener)
            setConversations_map(local_conversation_map)

            var temp = msgmap
            const createddate = new Date(createdAt)
            const date = createddate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
            const user = sender === name ? 'owner' : 'other'
            const msgdom = constructmsg(message, user, date)
            const scrolldowndom = constructscrolldowndom()
            if (!Array.isArray(msgmap[conversation_id]) || !msgmap[conversation_id].length) {
                temp[conversation_id] = []
            }
            else { // pop the final dom, the scrolldowndom
                temp[conversation_id].pop()
            }
            temp[conversation_id] = [...temp[conversation_id], msgdom]
            temp[conversation_id] = [...temp[conversation_id], scrolldowndom]
            setMsgmap(temp)

            if (convid === conversation_id) {
                setMsg(temp[conversation_id])
            }
        }

    }, [newmsgdata])

    useEffect(() => {
        // if (scrolldown.current != undefined) {
        //     // window.scrollTo({ behavior: 'smooth', top: scrolldown.current.offsetTop })
        //     // scrolldown.current.scrollIntoView({ behavior: 'smooth' })
        //     scrolldown.current.scrollIntoView()
        // }
        if (scrolldown.current) {
            // window.scrollTo({ behavior: 'smooth', top: scrolldown.current.offsetTop })
            // scrolldown.current.scrollIntoView({ behavior: 'smooth' })

            // ***
            scrolldown.current.scrollIntoView()


        }
    }, [msg])

    function conversationTab(key) {
        
        if (key && socket.connected) {
            var temp = conversations_map;
            temp[key].newmsg = 0;

            // convid === EMPTY ? alert('have not enter a room ever before') : alert('enter a room previously'); socket.emit('leaveroom', { convid: convid })
            // convid === EMPTY ? null : socket.emit('leaveroom', { convid: convid });
            // if (convid !== EMPTY) socket.emit('leaveroom', { convid: convid, yourname: name })

            setConversations_map(temp);
            setConvid(key);
            setChatclick(true);
            let link = acct_type === 'VENDOR' ? READ_CONV_SPECIFIC_RM_VENDOR : READ_CONV_SPECIFIC_RM_USER;
            let options = {
                method: 'POST',
                url: link,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                data: { conversation_id: key }
            };
            axios(options).then(async (result) => {
                const { message, pastmsg, vendor, participants } = result.data
                if (message === 'SUCCESS') {
                    var arr = [];
                    var prevseparator_date = null
                    for (let i = 0; i < pastmsg.length; i++) {
                        const { content, sender, createdAt } = pastmsg[i]
                        const createddate = new Date(createdAt);
                        const date = createddate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
                        const msgdom = constructmsg(content, sender === name ? 'owner' : 'other', date);
                        if (prevseparator_date === null || createddate.toLocaleDateString() !== prevseparator_date) { // assign all prev dates
                            prevseparator_date = createddate.toLocaleDateString();
                            const sepdom = constructsep(prevseparator_date);
                            arr.push(sepdom)
                        }
                        arr.push(msgdom)
                    }
                    var today_date = new Date().toLocaleDateString()

                    if (prevseparator_date && today_date !== prevseparator_date) { // if require today's date 
                        const sepdom = constructsep(today_date)
                        arr.push(sepdom)
                    }

                    // add in the scrolldown dom
                    const scrolldowndom = constructscrolldowndom()
                    arr.push(scrolldowndom)

                    var temp = msgmap;
                    temp[key] = arr
                    setMsgmap(temp)
                    setMsg(temp[key])
                    setVendor(vendor)
                    setVendorprofileimg(conversations_map[key].targetprofileimg)
                    setUserusername(participants[1])
                    socket.emit('enterroom', { convid: key, acct_type: acct_type, yourname: name }) // join all conversation ids 
                }
                else {
                    setVendor(null)
                    setVendorprofileimg(null)
                }

            })
        }
        else {
            // props.history.push('/')
        }
    }

    function singleconvTab(key, index) {
        var bc // background color
        var b // border
        if (device === 'WEB') {
            bc = convid === key ? '#e9ebeb' : 'white'
            b = {
                borderRight: '1px solid ' + NEAR_TRANSPARENT_GREY,
                borderLeft: '1px solid ' + NEAR_TRANSPARENT_GREY,
                borderBottom: '1px solid ' + NEAR_TRANSPARENT_GREY,
                borderTop: index === 0 ? '1px solid ' + NEAR_TRANSPARENT_GREY : '0px solid ' + NEAR_TRANSPARENT_GREY,
            }
        }
        else {
            bc = 'white'
            b = {
                borderRight: 'thin solid transparent',
                borderLeft: 'thin solid transparent',
                borderBottom: '1px solid ' + NEAR_TRANSPARENT_GREY,
                borderTop: '0px solid ' + NEAR_TRANSPARENT_GREY,
            }
        }

        var show_vt_n_svt
        if (acct_type === 'USER') {
            const { vendortype, secondaryvendortype } = conversations_map[key]

            if (secondaryvendortype !== EMPTY && secondaryvendortype) {
                show_vt_n_svt = <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden', overflowY: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
                    <div style={{ overflowX: 'hidden' }}>
                        {READABLE_VENDORTYPE_MAP[vendortype]}
                    </div>
                    <div style={{ overflowX: 'hidden' }}>
                        {READABLE_VENDORTYPE_MAP[secondaryvendortype]}
                    </div>
                </div>
            }
            else {
                show_vt_n_svt = <div >{vendortype}</div>
            }

        }
        else if (acct_type === 'VENDOR') {
            show_vt_n_svt = ''
        }

        // index === 0 ? '1px solid ' + SOFT_GREY : ''
        return (
            <button
                key={key + index + 'singleconvTab' + conversations_map[key].target}
                style={{
                    display: 'flex',
                    height: 70,
                    width: '100%',
                    paddingTop: 5,
                    paddingBottom: 5,
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: bc,
                    outline: 'none',
                    overflow: 'hidden',
                    ...b,
                }}
                onClick={() => {

                    if (convid !== key) {
                        conversationTab(key)
                        if (acct_type === 'USER') {
                            setFlip(!flip)
                            if (convid !== EMPTY) {
                                socket.emit('leave', { convid, yourname: name })
                            }
                            setMsg([])
                            setVendorprofileimg(null)
                            setVendordisplayname(conversations_map[key].target)
                        }
                        else if (acct_type === 'VENDOR') {
                            setFlip(!flip)
                            if (convid !== EMPTY) {
                                socket.emit('leave', { convid, yourname: name })
                            }
                            setMsg([])
                        }
                    }
                    else return


                }}
            >
                <div style={{ width: 50, height: 50, borderRadius: '50%', margin: '0px 5px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    <img alt={IMG_ALT} src={conversations_map[key].targetprofileimg} style={{ width: 50, height: 50, borderRadius: '50%', justifyContent: 'center', display: 'flex', alignItems: 'center', border: 'thin solid ' + SOFT_GREY, }} />
                </div>

                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>{Processname(conversations_map[key]['target'], '', device)}</div>
                        <div style={{ display: 'flex', fontSize: 11, textAlign: 'center', alignItems: 'center', color: SOFT_GREY, width: '100%' }}>{show_vt_n_svt}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>{conversations_map[key]['newmsg'] !== 0 ? conversations_map[key]['newmsg'] : null}</div>
                </div>
            </button>
        )
    }

    function convoTab() {

        function backConv() {
            const userBack = () => { // reset back to initial state
                setFlip(!flip);
                socket.emit('leave', { convid, yourname: name })
                setConvid(EMPTY)
                setMsg([])
                setVendordisplayname(null)
                setVendorprofileimg(null)
            }
            const vendorBack = () => { // reset back to initial state
                setFlip(!flip);
                socket.emit('leave', { convid, yourname: name });
                setConvid(EMPTY)
                setMsg([])
                setUserusername(null)
            }
            let backfunc = acct_type === 'VENDOR' ? vendorBack : userBack
            return (
                <div onClick={backfunc} style={{ height: '100%', width: '10%', justifyContent: 'center', display: 'flex', alignItems: 'center', fontWeight: 'bold' }} >
                    {BACK_ICON}
                </div>
            )
        }



        return vendor !== null && acct_type !== 'VENDOR' ?
            <div style={{ height: 62, display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', color: trash_name_back_color }}>
                <div style={{ height: '100%', width: '10%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    {removeConv()}
                </div>
                {visitVendor()}
                {backConv()}
            </div>
            :
            <div
                style={{ height: 62, width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center', color: trash_name_back_color, fontWeight: 'bold', }}
                onClick={() => { setFlip(!flip) }}
            >
                <div style={{ height: '100%', width: '10%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    {removeConv()}
                </div>
                {userUsername()}
                {backConv()}
            </div>
    }

    function removeConv() { // bin, dustbin
        let link = acct_type === 'VENDOR' ? REMOV_CONV_VENDOR : REMOV_CONV_USER
        return (
            <button
                style={{ margin: 0, padding: '0px 5px', backgroundColor: 'white', border: 'none', outline: 'none', }}
                onClick={() => {
                    if (window.confirm('Once you delete this conversation, there is no going back. Please be certain.')) {
                        socket.emit('leave', { convid, yourname: name })
                        socket.emit('disconnect', { acct_type, _id })

                        let options = {
                            method: 'POST',
                            url: link,
                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                            data: { convid, vendor }
                        }
                        axios(options).then((response) => {
                            const { message } = response.data
                            if (message === 'SUCCESS') {
                                window.location.reload();
                            }
                            else if (message === 'REDIRECT') {
                                window.location.reload();
                            }
                            else {
                                alert('Fail to remove.')
                                window.location.reload();
                            }
                        })
                    }


                }}
            >
                {TRASH_ICON}
            </button>
        )
    }

    function visitVendor() {
        return (
            vendor !== null && acct_type !== 'VENDOR' ?
                <Link
                    target="_blank"
                    to={'/vendorlookup/' + vendor}
                    style={{ height: 30, width: 'fit-content', justifyContent: 'center', display: 'flex', alignItems: 'center', textAlign: 'center', color: 'rgb(104, 104, 104)', padding: 5, margin: 0 }}
                >
                    <img alt={IMG_ALT} src={vendorprofileimg} style={{ width: 25, height: 25, borderRadius: '50%', justifyContent: 'center', display: 'flex', alignItems: 'center', border: 'thin solid ' + SOFT_GREY }} />
                    <div style={{ padding: 5, fontWeight: 'bold' }}>{vendordisplayname}</div>
                </Link>
                :
                <div></div>

        )
    }

    function userUsername() {
        return (
            <div>{userusername}</div>
        )
    }

    function updateInput(e) {
        let wrong
        var words = e.target.value.split(' ')
        if (words.length > 100) wrong = true
        for (let i = 0; i < words.length; i++) { // check if every single word is not more than 100
            if (words[i].length > NUM_WORDS_CANSEND) {
                wrong = true
                break
            }
        }
        if (e.target.value.length > LEN_MSG_CANSEND) wrong = true
        if (wrong) setInput('')
        else setInput(e.target.value)

    }

    function clickSendmsg() {
        if (input.length < 1) {
            return
        }
        else {
            if (socket.connected) {
                socket.emit('message', { convid: convid, message: input, sender: name, recepient: acct_type === 'VENDOR' ? userusername : vendordisplayname, acct_type: acct_type })
                setInput(EMPTY)
            }
            else {
                props.history.push('/')
            }
        }

    }

    const msgtextarea = <textarea
        placeholder="Type a message"
        style={{ height: '100%', width: '100%', overflow: 'hidden', overflowWrap: 'break-word', resize: 'none', outline: 'none', border: '1px solid ' + NEAR_TRANSPARENT_GREY, borderRight: 'thin solid transparent', borderTop: 'thin solid transparent', borderLeft: 'thin solid transparent', borderRadius: 0, padding: 5, margin: 0 }}
        value={input}
        onChange={updateInput}
    />

    const sendbtn = <div
        style={{ height: '100%', width: 40, display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', border: '1px solid ' + NEAR_TRANSPARENT_GREY, borderTop: '' }}// borderTop: '1px solid ' + NEAR_TRANSPARENT_GREY, 
        onClick={clickSendmsg}
    >
        {SENDMSG_ICON}
    </div>


    if (device === 'WEB') {
        return (
            <div style={{  maxHeight:'calc( 100vh - 35px)', minHeight: 'calc(100vh - 35px)', width: '100%', flexDirection: 'column', color: 'rgb(112, 112, 112)' }}>
                <Headtitle name="Message" />
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <div style={{ width: '15%', padding: '10px 10px 10px 0px', height: 'fit-content', display: 'flex', flexDirection: 'column', overflowX: 'hidden' }}>
                            {conversations_key.length > 0 ? conversations_key.map((key, index) => { return singleconvTab(key, index) }) : null}
                        </div>

                        <div style={{ width: '50%', padding: '10px 0px', display: 'flex', flexDirection: 'row' }}>
                            {
                                chatclick ?
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid ' + NEAR_TRANSPARENT_GREY, borderRadius: 5 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', width: '100%', borderBottom: '1px solid ' + NEAR_TRANSPARENT_GREY, }}>
                                            {removeConv()}
                                            {EMPTYSPACE}
                                            {visitVendor()}
                                        </div>
                                        <div style={{ width: '100%', height: '75vh', overflowY: 'auto', padding: 10, }}>
                                            {msg}
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 50, borderTop: '1px solid ' + NEAR_TRANSPARENT_GREY, }}>
                                            {msgtextarea}{sendbtn}
                                        </div>
                                    </div>
                                    :
                                    <div style={{ width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', flexDirection: 'column', border: '1px solid ' + NEAR_TRANSPARENT_GREY, borderRadius: 5 }}>
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderTop: 'transparent rgb(238, 238, 238) rgb(238, 238, 238)' }}>
                                            <div key={firstpage}>{firstpage}</div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (device === 'MOBILE') {
        const local_ht = ht - 35
        var title = null
        var content = null
        if (flip) {
            title = <Headtitle name="Message" />
            content = conversations_key.length > 0 ? conversations_key.map((key, index) => { return singleconvTab(key, index) }) : null
        }
        else {
            content = (
                <div style={{ height: local_ht, width: '100%', display: 'flex', flexDirection: 'column', }}>
                    {convoTab()}
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', WebkitOverflowScrolling: 'touch', overflowY: 'hidden' }}>
                        <div style={{ height: '88%', maxHeight: '88%', minHeight: '88%', width: '100%', backgroundColor: 'white', overflowY: 'auto', display: 'flex', flexDirection: 'column', borderTop: '1px solid ' + NEAR_TRANSPARENT_GREY, borderBottom: '1px solid ' + NEAR_TRANSPARENT_GREY }}>
                            {msg.map((item, index) => <div key={item + index} style={{ padding: 0 }}>{item}</div>)}
                        </div>
                        <div style={{ height: '12%', minHeight: '12%', maxHeight: '12%', width: '100%' }}>
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'flex-end', }}>
                                {msgtextarea}
                                {sendbtn}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div style={{ height: local_ht, maxHeight: local_ht, minHeight: local_ht, overflow: 'hidden', flexDirection: 'column', color: 'rgb(112, 112, 112)', touchAction: 'manipulation' }}>
                {title}
                <div style={{ height: local_ht, display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden' }}>
                    {content}
                </div>
            </div>
        )
    }





}

export default Message