import React, { useEffect, useState } from 'react'
import { PROJECT_READ, } from '../../../../config/api'
import { EMPTY, MAX_NUM_PROJECT_CAN_BE_CREATED } from '../../../../config/constants'
import { Checkdevice, refresh } from '../../../../components';
import Editprojectcontent from './components/Editprojectcontent/Editprojectcontent'
import Projectcreation from './components/Projectcreation/Projectcreation';
import Existprojects from './components/Existprojects/Existprojects';
import { Topmenu } from '../Components';
import axios from 'axios'
import './project.css'

function Project(props) {
    const { ready, vendor_id, vendortype_id, vendortype, vendortypeinterface_id, username, token, profileimg, profileimg_id } = props
    const device = Checkdevice()
    const [existingprojects, setExistingprojects] = useState([])
    const [allCollab_names, setAllCollab_names] = useState([])
    const [allCollab_username_obj_map, setAllCollab_username_obj_map] = useState([])
    const [show_edit, setShow_edit] = useState(false)
    const [viewing_proj_id, setViewing_proj_id] = useState([])
    const [viewing_proj_name, setViewing_proj_name] = useState('')
    const [selectedmenu, setSelectedmenu] = useState('Your Projects')
    const device_wt_map = {
        'MOBILE': '100%',
        'TABLET': 'calc( 100% - 120px)',
        'WEB': 'calc( 100% - 120px)'
    }
    const init = () => {
        try {
            let options = {
                method: 'POST',
                url: PROJECT_READ,
                headers: { 'Authorization': 'Bearer ' + token },
                data: { vendortype: vendortype, vendortype_id: vendortype_id }
            }
            axios(options).then((response) => {
                const { projects, vendortypeinterface, message } = response.data
                if (message === 'VENDOR_NOT_READY') return
                var dropdown_items = []
                var local_allCollab_username_obj_map = {}
                for (let x = 0; x < vendortypeinterface.length; x++) { // assign vendor's username to allCollab_names
                    const { vendortype, secondaryvendortype, profileimg_id, _id, displayname } = vendortypeinterface[x]
                    if (vendortypeinterface[x].username !== username) { // dont add vendor ownself as collaborator
                        dropdown_items.push(
                            <option key={'collaboption' + x} value={vendortypeinterface[x].username}>
                                {vendortypeinterface[x].username}
                                {displayname && displayname.length > 1 ? ' / ' + displayname : null}
                                {' [ ' + vendortype + ' ]'}
                                {secondaryvendortype && secondaryvendortype.length > 1 ? ' [ ' + secondaryvendortype + ' ]' : EMPTY}
                            </option>
                        )
                    }
                    local_allCollab_username_obj_map[vendortypeinterface[x].username] = {
                        '_id': _id,
                        'profileimg_id': profileimg_id && profileimg_id._id.length > 0 ? profileimg_id._id : null,
                        'username': vendortypeinterface[x].username,
                        'vendortype': vendortype,
                        'secondaryvendortype': secondaryvendortype,
                        'displayname': displayname,
                    }
                }
                setExistingprojects(projects)
                setAllCollab_names(dropdown_items)

                setAllCollab_username_obj_map(local_allCollab_username_obj_map)

            }).catch((e) => {
                refresh()
            })
        }
        catch (e) { }
    }
    useEffect(() =>  init(), [])
    if (ready) {
        return (
            <div className={"projectcreation_root" + device}>
                <Topmenu
                    menus={['Your Projects', 'Create New']}
                    indicatemenu={'Edit'} // menu only show when is activate, like during edit of project
                    selectedmenu={selectedmenu}
                    device={device}
                    onSelected={(val) => { setSelectedmenu(val) }}
                />
                {selectedmenu === 'Your Projects'
                    ? <div style={{ display: 'flex', flexDirection: "column", width: '100%' }}>
                        <Existprojects
                            {...props}
                            existingprojects={existingprojects}
                            profileimg={profileimg}
                            vendortype_id={vendortype_id}
                            vendortype={vendortype}
                            token={token}
                            vendortypeinterface_id={vendortypeinterface_id}
                            username={username}
                            existprojclicked={(show_edit, existingproject) => { // user click on proj to edit 
                                const { _id, name } = existingproject
                                setShow_edit(show_edit)
                                setViewing_proj_id(_id)
                                setViewing_proj_name(name)
                                setSelectedmenu('Edit')
                            }}
                            device={device}
                            update={(leftover_proj) => { setExistingprojects(leftover_proj) }}
                        />
                    </div>
                    : null}
                {selectedmenu === 'Edit'
                    ? <div style={{ display: 'flex', flexDirection: "column", width: device_wt_map[device] }}>
                        <Editprojectcontent
                            resetExistingprojmodal={() => {
                                setViewing_proj_id(EMPTY);
                                setSelectedmenu('Your Projects')
                                init()
                            }}
                            back={() => setSelectedmenu('Your Projects')}
                            modal_open={show_edit}
                            proj_id={viewing_proj_id}
                            editor_id={vendor_id}
                            editortype_id={vendortype_id}
                            vendortypeinterface_id={vendortypeinterface_id}
                            token={token}
                            name={viewing_proj_name}
                        />
                    </div>
                    : null}
                {selectedmenu === 'Create New' && existingprojects.length < MAX_NUM_PROJECT_CAN_BE_CREATED
                    ? <div style={{ display: 'flex', flexDirection: "column", width: device_wt_map[device] }}>
                        <Projectcreation
                            allCollab_names={allCollab_names}
                            allCollab_username_obj_map={allCollab_username_obj_map}
                            profileimg_id={profileimg_id}
                            vendortype={vendortype}
                            vendortype_id={vendortype_id}
                            token={token}
                            username={username}
                            existingprojects={existingprojects}
                            closeoropen={() => { setSelectedmenu('Your Projects') }}
                            update={(new_ep) => { setExistingprojects([...existingprojects, new_ep]) }}
                        />
                    </div>
                    : null}
                {selectedmenu === 'Create New' && existingprojects.length === MAX_NUM_PROJECT_CAN_BE_CREATED
                    ? <div style={{ display: 'flex', flexDirection: "column", width: '100%', textAlign:'center', height:300, placeContent:'center'}}>
                        You have reach the maximum number of project you can create.
                    </div>
                    : null}
            </div>
        )
    }
    else {
        return <div />
    }


}

export default Project