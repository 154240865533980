import React, { useEffect, useState } from 'react'
import { useEndpoint } from '../../../../async_components';
import { READ_REVIEW_OF_A_VENDOR_BY_VENDOR, REQUEST_REMOVAL_REVIEW } from '../../../../config/api';
import { EMPTY, REMOVAL_REVIEW_SUCCESS_MSG, REMOVAL_IN_PROCESS, } from '../../../../config/constants';
import { BUTTONSTYLE } from '../../../../config/cssconstants';
import { Jfmodal, Reviewcard, Checkdevice, Notiboard } from '../../../../components';
import ControlLabel from "react-bootstrap/lib/ControlLabel"
import FormGroup from "react-bootstrap/lib/FormGroup"
import FormControl from "react-bootstrap/lib/FormControl"
import axios from 'axios'
import './Reviews.css'

const Requestremovaldialog = (props) => {
    const [msg, setMsg] = useState(EMPTY)
    const [reasonremoval, setReasonremoval] = useState(EMPTY)
    const title = <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}> Request Removal</div>
    const device = Checkdevice()
    return (
        <Jfmodal
            show={props.dialogmodal}
            onHide={() => { props.setDialogmodal(); setReasonremoval(EMPTY); setMsg(EMPTY) }}
            device={device === 'MOBILE' ? 'MOBILE' : 'WEB'}
            title={title}
            content={
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', margin: '0px 15px', marginBottom: 0, minHeight: device === 'MOBILE' ? 'calc(100vh - 98px)' : '' }}>
                    <div style={{ height: 'max_content', display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                        <ControlLabel style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <div>Reason</div>
                            <div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}> ( 10 - 1000 chars )</div>
                        </ControlLabel>
                        <FormGroup controlId="formControlsTextarea" style={{ width: '100%' }} >
                            <FormControl
                                size={'lg'}
                                style={{ padding: 0 }}
                                value={reasonremoval}
                                placeholder="Enter description"
                                onChange={(event) => { setReasonremoval(event.target.value) }}
                                componentClass="textarea"
                            />
                            {msg}
                        </FormGroup>
                    </div>
                    <div style={{ width: '100%', padding: '0px 0px 15px 0px' }}>
                        <button
                            style={{ ...BUTTONSTYLE, float: 'right', marginRight: 10 }}
                            onClick={async () => {
                                let options = {
                                    method: 'POST',
                                    url: REQUEST_REMOVAL_REVIEW,
                                    headers: { 'Authorization': 'Bearer ' + props.token, 'Content-Type': 'application/json' }, //need token from the web
                                    data: { review_id: props.reviewidtoremv, reasonremoval: reasonremoval }
                                }
                                await axios(options).then((response) => {
                                    const { data } = response // status
                                    const { message } = data
                                    if (message === 'WAIT_FOR_CONTACT') {
                                        setMsg(REMOVAL_REVIEW_SUCCESS_MSG)
                                    }
                                    else if (message === 'WAIT_PLEASE') {
                                        setMsg(REMOVAL_IN_PROCESS)
                                    }
                                }).catch((e) => {
                                    setMsg('Error Occured');
                                    // refresh()
                                })
                            }}
                        >
                            Send
                        </button>
                    </div>
                </div>
            }
        />


    )
}

const status_domitems_map = {
    'approved': { bgcolor: 'green', txt: 'Published' },
    'reject': { bgcolor: 'red', txt: 'Rejected' },
    'pending': { bgcolor: 'grey', txt: 'Pending' }
}

const Reviews = (props) => {

    const { vendor_id, token, ready, } = props
    const [dialogmodal, setDialogmodal] = useState(false)
    const [reviewidtoremv, setReviewidtormb] = useState(EMPTY)
    const [reviewcards, setReviewcards] = useState([])

    const data = useEndpoint({
        method: 'POST',
        url: READ_REVIEW_OF_A_VENDOR_BY_VENDOR,
        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
        data: { '_id': vendor_id }
    })

    useEffect(() => {
        if (data.data !== null) {
            const { reviewobj } = data.data
            if (ready) {
                var reviewcards = []
                for (let i = 0; i < reviewobj.length; i++) {
                    const { rating, reviewer_id, sources, status } = reviewobj[i]
                    reviewcards.push(
                        <div key={Math.random()} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Reviewcard
                                index={i}
                                obj={reviewobj[i]}
                                reviewer_accttype = {reviewobj[i].reviewer_accttype}
                                profileimg={reviewobj[i].reviewer_accttype === 'User' ? reviewer_id.profileimg_id.link : ''}
                                rating={rating}
                                name={reviewobj[i].reviewer_accttype === 'User' ?  reviewer_id.name : ''}
                                sources={sources}
                                status={status}
                                maxwidth={true}
                                btn={<button style={{ ...BUTTONSTYLE, backgroundColor: "#e25a5a", border: 'thin solid white', color: 'white', margin: 5, alignSelf: 'flex-end', width: 'max-content', padding: 5, borderRadius: 5 }} onClick={() => { setDialogmodal(true); setReviewidtormb(reviewobj[i]._id) }}>Request Removal</button>}
                                statusbtn={<button style={{ ...BUTTONSTYLE, backgroundColor: status_domitems_map[status]['bgcolor'], border: 'thin solid white', color: 'white', margin: 5, alignSelf: 'flex-end', width: 'max-content', padding: 5, borderRadius: 5 }}>{status_domitems_map[status].txt}</button>}
                            />
                        </div>
                    )
                }
                setReviewcards(reviewcards)
            }
        }
    }, [data.data != null])

    if (ready) {
        return (
            <div className="Vreviews_root">
                <Notiboard text="Users can give rating and review to you/products/services and if you have any objections to the rating/review you could appeal to us to have and we will proceed from there by taking necessary action." />
                <div className="Vreviews_subroot">{reviewcards}</div>
                <Requestremovaldialog
                    token={token}
                    reviewidtoremv={reviewidtoremv}
                    dialogmodal={dialogmodal}
                    setDialogmodal={() => { setDialogmodal(!dialogmodal); }}
                />
            </div>
        )
    }
    else {
        return <div />
    }
}

export default Reviews





