import React, { useState, useEffect, useRef } from 'react'
import { ARROWDOWN_ICON, EMPTY, HTTPSWEBSITE_URL, LOGIN_ERROR_MSG, production, RECAPTCHA_KEY } from '../../config/constants'
import { BUTTONSTYLE } from '../../config/cssconstants'
import { Link } from "react-router-dom";
import { VENDOR_LOGIN, VENDOR_FORGOT_PW } from '../../config/api'
import { Checkdevice, Jfmodal, Textfield, Vregsignupmain } from '../../components';
import { Icon } from 'react-icons-kit/Icon'
import { u1F3AF } from 'react-icons-kit/noto_emoji_regular/u1F3AF';
import { u1F4E3 } from 'react-icons-kit/noto_emoji_regular/u1F4E3';
import { u1F30F } from 'react-icons-kit/noto_emoji_regular/u1F30F';
import { u1F38A } from 'react-icons-kit/noto_emoji_regular/u1F38A';
import { u1F4C8 } from 'react-icons-kit/noto_emoji_regular/u1F4C8';
import { u1F465 } from 'react-icons-kit/noto_emoji_regular/u1F465';
import { u1F52E } from 'react-icons-kit/noto_emoji_regular/u1F52E';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios'
import './VL.css'
import { input_dom } from '../../components/Commonelements/Commonelements';

const bgimg = production ? "https://cereweavecloud.s3-ap-southeast-1.amazonaws.com/assets/vendorlogin/vlogin.jpeg" : "https://i.ibb.co/VLrs2cG/1.jpg"
const features = [
    { title: 'Lead-Generation', icon: <Icon size={35} icon={u1F3AF} /> },
    { title: 'Marketing Exposure', icon: <Icon size={35} icon={u1F4E3} /> },
    { title: 'Multi-Listing (Countries)', icon: <Icon size={35} icon={u1F30F} /> },
    { title: 'Multi-Listing (Wedding & Event)', icon: <Icon size={35} icon={u1F38A} /> },
    { title: 'Dashboard Analytics', icon: <Icon size={35} icon={u1F4C8} /> },
    { title: 'Profile Management', icon: <Icon size={35} icon={u1F465} /> },
    { title: 'Trend', icon: <Icon size={35} icon={u1F52E} /> }
]
function VendorLogin() {

    const device = Checkdevice()
    const [username, setUsername] = useState(EMPTY)
    const [password, setPassword] = useState(EMPTY)
    const [lostpwmodal, setLostpwmodal] = useState(false)
    const [lostpwusername, setLostpwusername] = useState(EMPTY)
    const [lostpwemail, setLostpwemail] = useState(EMPTY)
    const [lostpwmsg, setLostpwmsg] = useState(EMPTY)
    const [captachagate, setCaptchagate] = useState(false)
    const [captchares, setChaptchares] = useState(EMPTY)
    const [forgtpwbtngate, setForgtpwbtngate] = useState(false)
    // const [sendloginreq, setSendloginreq] = useState(false) // false implies not sending any req, true means is sending
    const [error, setError] = useState(EMPTY)
    // const [gterror, setGterror] = useState(false)
    const [loginstate, setLoginstate] = useState('LOGINBTN') // PROCESSING, ERROR, LOGINBTN, REDIRECTING
    const captchaRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => { // triggered by lostpw btn clicked
        if (forgtpwbtngate) {
            axios({
                method: 'POST',
                url: VENDOR_FORGOT_PW,
                data: { email: lostpwemail, username: lostpwusername, captchares }
            }).then((result) => {
                const { message } = result.data
                setLostpwmsg(message)
            })
        }
    }, [forgtpwbtngate])

    useEffect(() => {// triggered BY CAPTCHA
        captchares ? setCaptchagate(true) : setCaptchagate(false)
    }, [captchares])

    // useEffect(() => {// trigger by login clicked
    //     if (sendloginreq) {
    //         if (username.length === 0 || password.length === 0) {
    //             setError(LOGIN_ERROR_MSG['FIELDS_INCOMPLETE'])
    //             setGterror(true)
    //         }
    //         else {
    //             axios({
    //                 method: 'POST',
    //                 url: VENDOR_LOGIN,
    //                 headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
    //                 data: { provider: "userpw", username: username, password: password }
    //             }).then((result) => {
    //                 const { data, status } = result
    //                 const { error, msg_key, token } = data
    //                 if (error === false && status === 200) {
    //                     try {
    //                         localStorage.setItem("token", token)
    //                         window.location = '/'
    //                     }
    //                     catch (e) { }
    //                     setError(LOGIN_ERROR_MSG[msg_key])
    //                     setGterror(false)
    //                 }
    //                 else {
    //                     setError(LOGIN_ERROR_MSG[msg_key])
    //                     setSendloginreq(false)
    //                     setGterror(true)
    //                 }
    //             })
    //         }
    //     }
    // }, [sendloginreq])

    const forgtpwbtn = <button
        style={{ ...BUTTONSTYLE, width: '100%', borderRadius: 5 }}
        disabled={forgtpwbtngate}
        onClick={() => {
            if (lostpwusername !== '' && lostpwemail !== '') setForgtpwbtngate(true)
        }}>Send</button>

    const recaptcha = <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <ReCAPTCHA
            ref={(el) => { captchaRef.current = el }}
            sitekey={RECAPTCHA_KEY}
            onChange={(res) => { setChaptchares(res) }}
        />
    </div>

    const resetpwmodal = <Jfmodal
        show={lostpwmodal}
        onHide={() => {
            setLostpwmodal(false)
            setLostpwusername(EMPTY)
            setLostpwemail(EMPTY)
            setCaptchagate(false)
            setForgtpwbtngate(false)
        }}
        title={'Lost Password'}
        device={device}
        content={
            <div style={{ padding: 10, display: 'flex', flexDirection: 'column', textAlign: 'center', }}>
                <div style={{ padding: 5 }}>Enter your email address and we'll send you a link to change your password.</div>
                <Textfield id={'username'} label={'Username'} val={lostpwusername} type={'text'} onchange={(e) => { setLostpwusername(e.target.value); }} />
                <Textfield id={'email'} label={'Email'} val={lostpwemail} type={'text'} onchange={(e) => { setLostpwemail(e.target.value); }} />


                {captachagate ? <div style={{ height: '40px', marginTop: 8 }}>{!forgtpwbtngate ? forgtpwbtn : null}</div> : recaptcha}
                <div style={{ height: 60, marginTop: 8, padding: 10 }}>{lostpwmsg}</div>
            </div>
        }
    />

    const errdiv = <div className="VL_errorroot">{error}</div>
    const clickabledivs = [
        <div key={'nobizacct'} className="VL_linkroot" style={{ color: '#707070' }}> Dont't have a business account?<Link to="/business/apply" style={{ color: '#3897f0', paddingLeft: 5 }} >Apply</Link></div>,
        // <Link key={'personalacct'} className="VL_linkroot" to="/">Personal account login</Link>,
        <a key={'login'} href={HTTPSWEBSITE_URL} className="VL_linkroot" target="blank">Personal account login</a>,
        <a key={'forgtpw'} className="VL_linkroot" onClick={() => { setLostpwmodal(true) }}>Forgot password?</a>,
        <Link key={'pricing'} className="VL_linkroot" to="/business/pricing" >Pricing</Link>,
    ]
    const featuresdivWEB = <div className="VL_LrootWT" style={{ backgroundImage: `url(${bgimg})` }}>
        <div className="VL_Ltitle">What we have for you</div>
        {features.map(({ title, icon }, index) => {
            return <div className="VL_feature" key={title + index}>
                <div className="VL_featureicon">{icon}</div>
                <div className="VL_featuretitle">{title}</div>
            </div>
        })}
    </div>

    const featuresdivMOBILE = <div className="VL_LrootMOBILE" style={{ backgroundImage: `url(${bgimg})` }}>
        <div className="VL_Ltitle">What we have for you</div>
        {features.map(({ title, icon }, index) => {
            return <div className="VL_feature" key={title + index}>
                <div className="VL_featureicon">{icon}</div>
                <div className="VL_featuretitle">{title}</div>
            </div>
        })}
        <div className="VL_arrowhintroot">
            <div className="VL_a1" >{ARROWDOWN_ICON}</div>
            <div className="VL_a2" >{ARROWDOWN_ICON}</div>
        </div>
    </div>

    const pw_login = () => {
        setLoginstate('PROCESSING')
        if (username === EMPTY || password === EMPTY) {
            setLoginstate('ERROR')
            setError(LOGIN_ERROR_MSG['FIELDS_INCOMPLETE'])
        }
        else {
            axios({
                method: 'POST',
                url: VENDOR_LOGIN,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
                data: { provider: "userpw", username: username, password: password }
            }).then((result) => {
                const { data, status } = result
                const { error, msg_key, token } = data
                if (error === false && status === 200) {
                    try {
                        localStorage.setItem("token", token)
                        window.location = '/'
                    }
                    catch (e) { }
                    setLoginstate('REDIRECTING')
                }
                else {
                    setUsername(EMPTY)
                    setPassword(EMPTY)
                    setLoginstate('ERROR')
                    setError(LOGIN_ERROR_MSG[msg_key])
                }
            })
        }


        // if (!sendloginreq && password.length > 0 && username.length > 0) {
        //     setError(EMPTY);
        //     setSendloginreq(true);
        // }
    }


    return <div className={"Vl_root" + device}>
        {device === 'WEB' || device === 'TABLET' ? featuresdivWEB : featuresdivMOBILE}
        <div className={"VL_Rroot" + device}>
            <div className="Vl_innerroot">
                {Vregsignupmain('Business account login')}
                <div className="Vl_innerroottop">

                    <Textfield
                        id={'username/login-id'}
                        label={'Username/Login-ID'}
                        val={username}
                        type={'text'}
                        onchange={(e) => {
                            setUsername(e.target.value);
                            setError(EMPTY)
                            if(loginstate!=='LOGINBTN'){
                                setLoginstate('LOGINBTN')
                            }
                        }}
                        disabled={loginstate==='PROCESSING'}

                    />
                    <Textfield
                        id={'password'}
                        label={'Password'}
                        val={password}
                        type={'password'}
                        onchange={(e) => {
                            setPassword(e.target.value);
                            setError(EMPTY)
                            if(loginstate!=='LOGINBTN'){
                                setLoginstate('LOGINBTN')
                            }
                        }}
                        disabled={loginstate==='PROCESSING'}
                        />

                    {{
                        'PROCESSING': <div className='VL_error'>Processing</div>,
                        'REDIRECTING': <div className='VL_error'>Redirecting</div>,
                        'ERROR': <div className='VL_error'>{error}</div>,
                        'LOGINBTN': <button className='VL_loginbtn' onClick={pw_login} >Login</button>
                    }[loginstate]}


                </div>
                {/* {clickabledivs} */}
            </div>
        </div>
        {resetpwmodal}
    </div>

}

export default VendorLogin