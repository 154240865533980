import React, { useState, useEffect } from 'react';
import { Retrieve_personal_info } from '../../config/commonfunctions';
import { EMPTYSPACE, } from '../../config/constants';
import { Checkdevice, Headtitle, Notiboard } from '../../components'
import { VENDOR_READLEAD } from '../../config/api';
import axios from 'axios'
import './VendorLead.css'
const { token } = Retrieve_personal_info()

function VendorLead(props) {

    const [firsttime, setFirsttime] = useState(true)
    const [data, setData] = useState([])
    const device = Checkdevice()

    useEffect(() => {
        if (firsttime) {
            try {
                let options = {
                    method: 'POST',
                    url: VENDOR_READLEAD,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json', }, // need token from the web
                }
                axios(options).then((response) => {
                    const { status } = response
                    const { data } = response.data
                    if (status === 200) {
                        setFirsttime(false)
                        setData(data)
                    }
                    else setData([])
                })
            }
            catch (e) { setFirsttime(false) }
        }
    }, [firsttime])

    return (
        <div className="Vlead_root">
            <Headtitle name={'Lead'} />
            <div className={device === 'MOBILE' ? '' : 'container'} style={{ overflowX: 'auto', overflowY: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Notiboard text={'This page shows the potential customers from your category. The leads here will be updated and removed daily.'} />
                <div className="Vlead_subsubroot"style={{ display: 'grid', flexFlow: 'row wrap', justifyContent: 'center' }}>
                    {data.map(({ cat, vt, email, contact, qnans, name, updatedAt }, i) => {
                        return <div className="Vlead_singlelead" key={'lead' + i}>
                            <div className="Vlead_singleleaddate">{new Date(updatedAt).toLocaleDateString()}</div>
                            <div className="Vlead_singleleadcommon">{name.length > 0 ? name : '-'}</div>
                            <div className="Vlead_singleleadcommon">
                                <div>{email}</div>
                                <div>{contact}</div>
                                <div>{cat + '/' + vt}</div>
                            </div>
                            <div className="Vlead_qnansroot">
                                {qnans.map((ans, j) => <div className="Vlead_ans" key={i + 'qnans' + j} >{ans}</div>)}
                            </div>
                        </div>
                    })}
                </div>
            </div>
            {EMPTYSPACE}
        </div>
    )
}

export default VendorLead