import React, { useState } from 'react'
import { rsvp } from '../../assets/userlogin'
import { Checkdevice } from '../../components'

function Tovendor(props) {

    const device = Checkdevice()
    const [text, setText] = useState('Have A Look !')
    const [text1, setText1] = useState(' Click here to find out more')

    var wraptextcss, imgcss
    if (device === 'WEB' || device === 'TABLET') {
        wraptextcss = {
            width: 600,
            padding: '30px 5px',
            margin: 5,
            whiteSpace: 'break-spaces',
            textAlign: 'start'
        }
        imgcss = {
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: 590,
            height: 340,
        }
    }
    else {
        wraptextcss = {
            width: '100%',
            padding: '30px 5px',
            margin: 5,
            whiteSpace: 'break-spaces',
            textAlign: 'start'
        }
        imgcss = {
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: 210,
        }
    }


    // https://ibb.co/SsnRQP2
    // https://ibb.co/rp2JRXt
    // https://ibb.co/q0dzNsx
    // https://ibb.co/wWhxXch
    // https://ibb.co/SQwM11h



// Hi, I’m Jia Feng from Cereweave. I am excited to share with you our platform - a wedding and event marketplace. We are looking for wedding professionals to join Cereweave, and we would like to invite you to join us. Here are some great benefits you will get for joining us.
// 1 You would be able to reach out to more potential customers through the platform (e.g. sharing of past work done, advertisement, real-time chat systems with clients).
// 2 With our interactive analytical dashboard, you will have deeper insights on who has viewed and liked your services.
// 3 Showcase your projects, by publishing them on to our platform. Example, https://cereweave.com/projectlookup/5e4482ca5d923e7549a77b04
// 4 You would be able to list your package/price-list on our platform.
// 5 Your profile will be listed, and users could discover your profile by different tags. Example, https://cereweave.com/vendorlookup/dextercjr

// Our team is constantly working to improve our platform and make it work better for you. If you are interested to join us, I will send you a registration code so that you could register an account on our platform. If you have any other questions or would like me to clarify anything else, please, let me know. I am always glad to help in any way I can.
// Visit us here : https://www.cereweave.com/wedding
    const bgimg = rsvp
    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', color: '#707070', alignContent: 'center', minHeight: '100vh', alignItems: 'center', marginBottom:50 }}>
            <div style={wraptextcss}>
                <div style={{ fontWeight: 'bold', fontSize: 24 }}>{'Hi 👋 ' + props.match.params.name + ','}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 15 }}>I'm Jia Feng, the founder of Cereweave. Thank you for visiting Cereweave. At here I would like to reveal more about the platform and how it works. Our aim is to provide soon-to-be married couples and anyone who are preparing for a large celebration with an easy way to host, plan and shop for everything related to weddings and event celebrations on a single platform. </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 15 }}>We are aware that it is challenging for anyone to organize and plan for any large celebration because of the sheer number of things that need to be taken into consideration. One of the biggest concerns is how to find a reliable and quality vendor to provide the service and achieve the desired results. And for businesses, marketing their services and products is a mundane and boring task. Our platform strive to bridge the gap between user (eg, soon-to-be married couples ) and vendor, so that both parties could fulfil their needs.</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 15 }}>What sets us apart from other wedding platforms is that we provide a start to end one stop solution. From ideation to realization, all can be done using the services offered by our platform. </div>

                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 20, fontWeight: 'bold' }}>{'Invitation Builder'}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 0 }}>One of the notable applications we have is the free Invitation Builder. Our Invitation Builder allows users to create free digital invitations using our wide variety of templates and backgrounds for different categories. One of the potential users are wedding couples that require digital invitations. With a few clicks users could create elegant and beautiful digital invitations. Moreover, using our tagging function users could easily credit vendors on their digital invitations. Guests who received the invitation would know who had made this wedding happen and hence, achieve advertising effect. Our digital invitation support other type of events such as babyshower, housewarming and birthday party too.</div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '15px 0px' }}>
                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ backgroundImage: 'url("https://i.ibb.co/vhz8jLG/Screenshot-2020-10-12-at-10-48-44-PM.png")', ...imgcss }} />
                        <div style={{ backgroundImage: 'url("https://i.ibb.co/fDMr9vh/Screenshot-2020-10-13-at-11-55-01-AM.png")', ...imgcss }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ backgroundImage: 'url("https://i.ibb.co/hRSV6KT/Screenshot-2020-10-13-at-11-57-24-AM.png")', ...imgcss }} />
                        <div style={{ backgroundImage: 'url("https://i.ibb.co/jRbxxRn/Screenshot-2020-10-13-at-12-20-52-PM.png")', ...imgcss }} />
                    </div> */}
                    <div style={{ backgroundImage: `url("${bgimg}")`, ...imgcss }} />
                    <a style={{ display: 'flex', placeContent: 'center', color: 'white', backgroundColor: 'rgb(247, 127, 127)', borderRadius: 10, padding: 5, marginTop: 10, width: '100%' }} href="https://cereweave.com/invitation/weddingexample" target={"_blank"} onClick={() => { setText('Today is the best day of my life') }}>{text}</a>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 20, fontWeight: 'bold' }}>{'Potential Users'}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 0 }}>The number of marriages in Singapore is declining and the number of marriages is correlated to the overall population of Singapore. Our platform was built with this in mind and we have made sure that all the functions we offer will be useful in both wedding and event. Users could use our platform not only for their weddings, but also for other events or celebrations.</div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '15px 0px' }}>
                    <div>This is an example of a potential customer lifetime on our platform : </div>
                    <div style={{ width: '100%', textAlign: 'center', placeContent: 'center', padding: '15px 5px', margin: '10px 0px', border: 'thin solid #707070', borderRadius: 10 }}>
                        {'Proposing -> Wedding -> Housewarming -> Babyshower'}
                    </div>
                    <div>Users can find the products and services he/she needs on our platform. With our platform users will greatly reduce their time spent searching for vendors over the internet and also allows businesses to focus on their craft rather than endlessly searching for customers.</div>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 20, fontWeight: 'bold' }}>{'Pricing & Cost'}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 0 }}>
                    <div>Our platform is basically free and we won't stop you if you want to donate money to us. Or if you want to advertise on our platform, you're more than welcome to do so.</div>
                    <a style={{ display: 'flex', placeContent: 'center', color: 'white', backgroundColor: 'rgb(247, 127, 127)', borderRadius: 10, padding: 5, marginTop: 10, width: '100%' }} href="https://cereweave.com/business/pricing" target={"_blank"} onClick={() => { setText1('Today is the best day of my life') }}>{text1}</a>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 20, fontWeight: 'bold' }}>{'Benefits'}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 0 }}>
                    <div>1 You would be able to reach out to more potential customers through the platform (e.g. sharing of past work done, advertisement, real-time chat systems with clients).</div>
                    <div>2 With our interactive analytical dashboard, you will have deeper insights on who has viewed and liked your services.</div>
                    <div>3 Showcase your projects, by publishing them on to our platform. Example, <a href="https://cereweave.com/projectlookup/5e4482ca5d923e7549a77b04" target="blank">here</a></div>
                    <div>4 You would be able to list your package/price-list on our platform.</div>
                    <div>5 Your profile will be listed, and users could discover your profile by different tags. Example, <a href="https://cereweave.com/vendorlookup/dextercjr" target="blank">here</a> </div>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 20, fontWeight: 'bold' }}>{'On going'}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 0 }}>We are still looking for businesses to join our platform. At the moment we are developing an online wedding style quiz, that will match user to vendors based on style.</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 20, fontWeight: 'bold' }}>{'Future'}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 0 }}>We will shift our focus to user acquisition later on as for now we are still looking for businesses to join our platform. And we will be implementing in new features, rolling out new tools and improving existing ones.</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 20, fontWeight: 'bold' }}>{'Final'}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 0 }}>Please let me know if you are interested in joining us, you could DM me through Instagram. Thanks! {'😄'} </div> 
            </div>

        </div>
    )
}
                /* If you're reading this, there's a good chance you have already finished reading this lengthy article. */

export default Tovendor