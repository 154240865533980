import { production } from "./constants"
var http = production ? 'https://' : 'http://'
// export const B_W = production ? http + 'api.weaverank.com:3000/api/' : http + '192.168.31.141:3000/api/' // ec2 website  '192.168.1.76:3000' XIAOMI
// export const B_W = production ? http + 'api.weaverank.com:3000/api/' : http + '192.168.1.76:3000/api/' // ec2 website  '192.168.1.76:3000' SINGTEL
export const B_W = production ? http + 'api.weaverank.com:3000/api/' : http + 'localhost:3000/api/' 

// All
export const ALL_VENDOR_READ_DETAIL_LINK = B_W + 'all/all_vendor_read_detail'

// Pricelist
export const VENDOR_PRICELIST_CREATE = B_W + 'pricelist/create_pricelist'
export const VENDOR_ALLPRICELIST_READ_SPECIFICVENDOR = B_W + 'pricelist/read_allpricelist_specificvendor'
export const VENDOR_PRICELIST_DESTROY_SPECIFICID = B_W + 'pricelist/destroy_pricelist_specificid'

// Project
export const VENDOR_PROFILE_CREATE_PROJECT_LINK = B_W + 'project/create_project'
export const PROJECT_READ = B_W + 'project/read_project'
export const VENDOR_PROJECT_READ_SPECIFICVENDOREDITABLE = B_W + 'project/read_project_specificvendor_editable'
export const VENDOR_PROFILE_EDIT_PROJECT_LINK = B_W + 'project/edit/edit_project'
export const PROJECT_PROJECT_READ_DETAIL_LINK = B_W + 'project/read_project_specificproject'
export const VENDOR_PROJECT_DESTROY_SPECIFICID = B_W + 'project/destroy_project'

// Projectinterface
export const PROJECT_INTERFACE_INFINITESCROLL_DATA = B_W + 'projectinterface/read_projectinterface_infinitescrolldata'
export const PROJECTINTERFACE_READ = B_W + 'projectinterface/read_projectinterface'
export const PROJECT_READ_SPECIFICIDS = B_W + 'projectinterface/read_specific_projectids'

// Review
export const READ_REVIEW_OF_A_VENDOR = B_W + 'review/read_reviews'
export const READ_REVIEW_OF_A_VENDOR_BY_VENDOR = B_W + 'review/read_v_reviews'

// Vendor
export const VENDOR_LOGIN = B_W + 'vendor/vendor_login'
export const VENDOR_CREATE = B_W + 'vendor/vendor_create'
export const VENDOR_PROFILE_READ = B_W + 'vendor/read_vendor_profile'
export const VENDOR_VALIDATE = B_W + 'vendor/vendor_validate'
export const VENDOR_HOMEPAGE = B_W + 'vendor/read_vendorhomepage'
export const VENDOR_VENDORTYPE_UPDATE = B_W + 'vendor/update_vendortype'
export const VENDOR_TWOVENDORTYPE_UPDATE = B_W + 'vendor/update_multivendortype'
export const VENDOR_REMOVEPROFILEIMG =  B_W + 'vendor/rmv_profileimg'
export const VENDOR_REMOVEPROFILEBGIMG =  B_W + 'vendor/rmv_profilebg'
export const VENDOR_LOGO_UPDATE = B_W + 'vendor/vendor_profileimg_update' // vendor profile upload company logo 
export const VENDOR_BG_UPDATE = B_W + 'vendor/vendor_bgimg_update' // vendor profile upload company logo 
export const VENDOR_PUBLICPROFILE1_UPDATE = B_W + 'vendor/update_vendorpublicprofile1'
export const VENDOR_PUBLICPROFILE2_UPDATE = B_W + 'vendor/update_vendorpublicprofile2'
export const VENDOR_PRIVATEPROFILE_UPDATE = B_W + 'vendor/update_vendorprivateprofile'
export const VENDOR_CHANGEPASSWORD_UPDATE = B_W + 'vendor/update_vendorchangepassword'
export const VENDOR_READPARTNER = B_W + 'vendor/read_partner'
export const VENDOR_ADDPARTNER = B_W + 'vendor/add_partner'
export const VENDOR_SUBPARTNER = B_W + 'vendor/rmv_partner'
export const REMOV_VENDOR_FAVDATAHISTORY = B_W + 'vendor/rmv_fav'
export const REMOV_VENDOR_VIEWSDATAHISTORY = B_W + 'vendor/rmv_views'
export const VENDOR_FORGOT_PW = B_W + 'vendor/forgt_pw'
export const VENDOR_FORGTPWRESETCHECK = B_W + 'vendor/vendor_forgtpwresetcheck'
export const VENDOR_FORGTPWRESET = B_W + 'vendor/vendor_forgtpwreset'
export const VENDOR_UPDATEQNS = B_W + 'vendor/update_vendorqns' // vendor profile upload company logo 
export const VENDOR_ENABLEDELEGATE = B_W + 'vendor/update_delegate'
export const VENDOR_READLEAD = B_W + 'vendor/read_lead'

// trend
export const VENDOR_TRENDFIRSTSEARCH = B_W + 'trend/firstsearch'
export const VENDOR_TRENDSEARCH = B_W + 'trend/search'

// level
export const VENDOR_LEVELUSER = B_W + 'vendor/levelup'

// Vendortype
const uvs = 'update_vendortype_specific'
export const VENDORTYPE_VENDORSPECIFIC_UPDATE_MAP = {// vendorprofile vendor style and services filter tag map - {vendortype : api link}
    'Accessories': B_W + 'accessories/' + uvs,
    'Beautyhairandmakeup': B_W + 'beautyhairandmakeup/' + uvs,
    'Bridalboutiques': B_W + 'bridalboutiques/' + uvs,
    'Catering': B_W + 'catering/' + uvs,
    'Dance': B_W + 'dance/' + uvs,
    'Decoration': B_W + 'decoration/' + uvs,
    'Dj': B_W + 'dj/' + uvs,
    'Dressattire': B_W + 'dressattire/' + uvs,
    'Emcees': B_W + 'emcees/' + uvs,
    'Entertainment': B_W + 'entertainment/' + uvs,
    'Eventrentals': B_W + 'eventrentals/' + uvs,
    'Florists': B_W + 'florists/' + uvs,
    'Gifts': B_W + 'gifts/' + uvs,
    'Honeymoon': B_W + 'honeymoon/' + uvs,
    'Jewellery': B_W + 'jewellery/' + uvs,
    'Menswear': B_W + 'menswear/' + uvs,
    'Music': B_W + 'music/' + uvs,
    'Paperstationary': B_W + 'paperstationary/' + uvs,
    'Photobooths': B_W + 'photobooths/' + uvs,
    'Photographers': B_W + 'photographers/' + uvs,
    'Transport': B_W + 'transport/' + uvs,
    'Venue': B_W + 'venue/' + uvs,
    'Videographers': B_W + 'videographers/' + uvs,
    'Bakeryanddesserts': B_W + 'bakeryanddesserts/' + uvs,
    'Planners': B_W + 'videographers/' + uvs,
}

// Removalreview
export const REQUEST_REMOVAL_REVIEW = B_W + 'removalreview/update_request_removal_review'

// Conservation
export const READ_CONV_VENDOR_START = B_W + 'conversation/vendorstart'
export const READ_CONV_USER_START = B_W + 'conversation/userstart'
export const READ_CONV_SPECIFIC_RM_VENDOR = B_W + 'conversation/vendorreadconvspecificrm'
export const READ_CONV_SPECIFIC_RM_USER = B_W + 'conversation/userreadconvspecificrm'
export const INIT_CONV_USER = B_W + 'conversation/initconvbyuser'
export const INIT_CONV_VENDOR = B_W + 'conversation/initconvbyvendor'
export const REMOV_CONV_USER = B_W + 'conversation/removeconvuser'
export const REMOV_CONV_VENDOR = B_W + 'conversation/removeconvvendor'

// Quote Vendor
export const CREATE_V_QUOTE = B_W + 'quote/create_v_quote'
export const READ_V_QUOTE = B_W + 'quote/read_v_quote'
export const REMOVE_V_QUOTE = B_W + 'quote/remove_v_quote'
export const ARCHIVE_V_QUOTE = B_W + 'quote/archive_v_quote'
export const READARCHIVE_V_QUOTE = B_W + 'quote/read_archive_v'

// Business Apply
export const VENDOR_APPLY = B_W + 'vendorapply/vendor_apply'

// Faq
export const READ_FAQ = B_W + 'faq/readvendor'

// Blog
export const READ_BLOG = B_W + 'blog/readall'