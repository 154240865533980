import axios from 'axios'
import { VENDOR_CREATE, VENDOR_LOGIN } from '../../config/api'

export const CREATE_VENDORCREATE_ACTION = 'CREATE_VENDOR_ACTION'
export const CREATE_VENDORCREATE_ACTION_FULFILLED = 'CREATE_VENDOR_ACTION_FULFILLED'
export const CREATE_VENDORCREATE_ACTION_PENDING = 'CREATE_VENDOR_ACTION_PENDING'
export const CREATE_VENDORCREATE_ACTION_REJECTED = 'CREATE_VENDOR_ACTION_REJECTED'

export function vendorcreate_action(items) {

    const { provider, username, password1, password2, privateemail } = items
    let options = {
        method: 'POST',
        url: VENDOR_CREATE,
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
        data: { provider, username, password1, password2, privateemail }
    }
    return {
        type: CREATE_VENDORCREATE_ACTION,
        payload: axios(options)
    }
}


export const LOGIN_VENDORLOGIN_ACTION = 'LOGIN_VENDOR_ACTION'
export const LOGIN_VENDORLOGIN_ACTION_FULFILLED = 'LOGIN_VENDOR_ACTION_FULFILLED'
export const LOGIN_VENDORLOGIN_ACTION_PENDING = 'LOGIN_VENDOR_ACTION_PENDING'
export const LOGIN_VENDORLOGIN_ACTION_REJECTED = 'LOGIN_VENDOR_ACTION_REJECTED'

export function vendorlogin_action(items) {

    const { provider, username, password } = items
    let options = {
        method: 'POST',
        url: VENDOR_LOGIN,
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', },
        data: { provider, username, password }
    }
    return {
        type: LOGIN_VENDORLOGIN_ACTION,
        payload: axios(options)
    }
}