import React from 'react';
import './Appcard.css'

function Appcard({ icon, content, onClick, selected, device }) {
    var dn = device === 'WEB' || device === 'TABLET' ? 'w' : 'mt'
    return (
        <button className={selected ? "APP_CARD_ROOT_SELECTED_" + dn : "APP_CARD_ROOT_" + dn} onClick={onClick}>
            {selected?<div className="APP_CARD_TOPBAR_SELECTED"/>:<div className="APP_CARD_TOPBAR_NONSELECTED"/>}
            <div className="APP_CARD_ICON">{icon}</div>
            <div className="APP_CARD_CONTENT">{content}</div>
        </button>
    )    
}
export default Appcard