import { MIN_NUM_PROJECT_IMG, MAX_NUM_PROJECT_IMG, MIN_NUM_PROJECT_OBJ, MAX_NUM_PROJECT_OBJ, MAX_NUM_PROJECT_OBJ_TEXT_SIZE, } from '../../../../../../config/constants'

// const IsNumeric = (input) => { return (input - 0) == input && ('' + input).trim().length > 0 }

// return true implies got error,
// return false implies no error
export const Validateform = (items) => {
    const { form_key, form_parts } = items
    var counter = 0
    switch (form_key) {
        case 'name': {
            if (form_parts.name !== '') { // cannot be empty
                return false;
            }
            else return true;
        }
        case 'maintext': {

            if (form_parts.maintext === '') {
                return true
            }
            if (form_parts.maintext.split(' ').length > 0) {
                return false
            }
            return true
        }
        case 'projectmodal_img123': {

            const { projectmodal_imgpattern, projectmodal_img1, projectmodal_img2, projectmodal_img3 } = form_parts

            if (projectmodal_imgpattern === 0) {  // expect [main, left, right]
                if (projectmodal_img1 === '') return true
                if (projectmodal_img2 === '') return true
                if (projectmodal_img3 === '') return true
                return false
            }
            else if (projectmodal_imgpattern === 1) {  // expect [main, top, down]
                if (projectmodal_img1 === '') return true
                if (projectmodal_img2 === '') return true
                if (projectmodal_img3 === '') return true
                return false
            }
            else if (projectmodal_imgpattern === 2) {// expect [main]
                if (projectmodal_img1 === '') return true
                return false
            }
            return true
        }
        case 'blocks_obj_min': {

            const { blocks } = form_parts
            if (blocks === [] || blocks === undefined) return true
            for (let i = 0; i < blocks.length; i++) {
                const { value } = blocks[i].props
                if (value === 'I' || value === 'S' || value === 'T' || value === 'V' || value === 'IP') {
                    counter = counter + 1
                }
            }
            if (counter < MIN_NUM_PROJECT_OBJ) return true
            else return false
        }
        case 'blocks_obj_max': {


            const { blocks } = form_parts
            if (blocks === [] || blocks === undefined) return true
            for (let i = 0; i < blocks.length; i++) {
                const { value } = blocks[i].props
                if (value === 'I' || value === 'S' || value === 'T' || value === 'V' || value === 'IP') counter = counter + 1
            }
            if (counter > MAX_NUM_PROJECT_OBJ) return true
            else return false

        }
        case 'blocks_img_min': {

            const { blocks } = form_parts
            if (blocks === [] || blocks === undefined) {
                return true
            }
            else {
                for (let i = 0; i < blocks.length; i++) {
                    const { value } = blocks[i].props
                    if (value === 'I') {
                        counter = counter + 1
                    }
                    else if (value === 'IP') {
                        counter = counter + 1
                    }
                }
                if (counter < MIN_NUM_PROJECT_IMG) return true // gt error
                else return false
            }
        }
        case 'blocks_img_max': {

            const { blocks } = form_parts
            if (blocks === [] || blocks === undefined) {
                return true
            }
            else {
                for (let i = 0; i < blocks.length; i++) {
                    const { value } = blocks[i].props
                    if (value === 'I') {
                        counter = counter + 1
                    }
                    else if (value === 'IP') {
                        counter = counter + 1
                    }
                }

                if (counter > MAX_NUM_PROJECT_IMG) {
                    return true
                }
                else return false

            }
        }
        case 'blocks_txt_size': {

            const { blocks, blktxtmap = {} } = form_parts
            for (let i = 0; i < blocks.length; i++) {
                if (i % 2 !== 0) {
                    const { key, props } = blocks[i]
                    const { value } = props
                    if (value === 'T') {
                        if (blktxtmap.hasOwnProperty(key)) {
                            if (blktxtmap[key] === undefined || blktxtmap[key] === '' || blktxtmap[key] === null) return true
                            else if (blktxtmap[key].split(' ').length > MAX_NUM_PROJECT_OBJ_TEXT_SIZE) return true
                        }
                        else {
                            return true
                        }

                    }
                }
            }
            return false
        }
        case 'blocks_vid_size': {

            var regex = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/  // eslint-disable-line
            const { blocks, blkvidmap = {} } = form_parts

            for (let i = 0; i < blocks.length; i++) {
                if (i % 2 !== 0) {
                    const { key, props } = blocks[i]
                    const { value } = props
                    if (value === 'V') {
                        if (blkvidmap.hasOwnProperty(key)) {
                            var result = regex.test(blkvidmap[key])
                            if (result === false) return true
                        }
                        else {
                            return true
                        }
                    }
                }
            }
            return false
        }
        case 'blocks_i_ntundefined': { // for create only
            const { blocks, allimgsmap } = form_parts
            for (let i = 0; i < blocks.length; i++) {
                if (i % 2 !== 0) {
                    const { key, props } = blocks[i]
                    const { value } = props
                    if (value === 'IP') { // 2 PORTRAIT IMAGES
                        var firstimg = allimgsmap && allimgsmap[key + '1'] ? allimgsmap[key + '1'].file : null
                        var secondimg = allimgsmap && allimgsmap[key + '2'] ? allimgsmap[key + '2'].file : null
                        if (firstimg && secondimg) continue
                        else return true
                    }
                    else if (value === 'I') { // 1 IMAGE, PORTRAIT OR LANDSCAPE
                        if (allimgsmap && allimgsmap[key] && allimgsmap[key].file) continue
                        else return true
                    }
                }
            }
            return false
        }
        case 'blocks_i_ntundefinededit': { // for edit only
            const { blocks, allimgsmap } = form_parts


            var num_domimgs = 0
            for (let i = 0; i < blocks.length; i++) {
                if (i % 2 !== 0) {
                    console.log(blocks, allimgsmap)
                    const { props } = blocks[i]
                    const { value } = props
                    if (value === 'IP') { // 2 PORTRAIT IMAGES
                        num_domimgs = num_domimgs + 2
                    }
                    else if (value === 'I') { // 1 IMAGE, PORTRAIT OR LANDSCAPE
                        num_domimgs = num_domimgs + 1
                    }
                }
            }

            var all_keys = Object.keys(allimgsmap)
            var num_varimgs = 0
            for (let i = 0; i < all_keys.length; i++) {
                const { pattern, value } = allimgsmap[all_keys[i]]
                if (pattern === 'I') {
                    if (value) { // default value is null
                        num_varimgs = num_varimgs + 1
                        continue
                    }
                }
                else if (pattern === 'IP') {
                    const { value1 } = allimgsmap[all_keys[i]]
                    if (value && value1) {// default value is null
                        num_varimgs = num_varimgs + 2
                        continue
                    }
                }
            }
            console.log(num_varimgs, num_domimgs)
            return num_varimgs === num_domimgs ? false : true

        }
        default: {
            return false
        }
    }


}
