import React from 'react'
import { EMPTYSPACE } from '../../config/constants'
import { CEREWEAVE_WHITE } from '../../assets/Cereweave'
import { Usname } from '..'

function Vregsignupmain(text) {
    return (
        <div style={{ height: 200, color: 'var(--maindark)', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', height: 'max-content', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', }}>
                <div style={{ width: 100, height: 100 }}>{CEREWEAVE_WHITE}</div>
                <Usname />
                <div style={{ height: 30 }}>{text}</div>
            </div>
        </div>
    )
}

export default Vregsignupmain