import React, { useState, useEffect, } from 'react';
import axios from 'axios'
import { Retrieve_personal_info } from '../../config/commonfunctions';
import { FormControl, FormGroup, ControlLabel, InputGroup, DropdownButton, MenuItem } from "react-bootstrap"
import { EMPTYSPACE, EMPTY, PACKAGE_ICON, CURRENCY_ARR, } from '../../config/constants';
import { CREATE_V_QUOTE, READ_V_QUOTE, REMOVE_V_QUOTE, ARCHIVE_V_QUOTE, READARCHIVE_V_QUOTE } from '../../config/api';
import { BUTTONSTYLE } from '../../config/cssconstants';
import refresh from '../../components/refresh';
import { Checkdevice } from '../../components/Checkdevice';
import { Headtitle } from '../../components'
import DropzoneComponent from 'react-dropzone-component';
import '../../../node_modules/react-dropzone-component/styles/filepicker.css'
// import '../../../node_modules/dropzone/dist/min/dropzone.min.css'

const { token } = Retrieve_personal_info()

const quotestate_map = { //'#7f7fff' rgb(84, 163, 106) 
    0: <div style={{ backgroundColor: '#f9edc7', color: '#5f5f5f', padding: '6px 10px', borderRadius: 12, }}>Pending</div>,
    1: <div style={{ backgroundColor: '#d2f0cd', color: '#5f5f5f', padding: '6px 10px', borderRadius: 12 }}>Accepted</div>,
    2: <div style={{ backgroundColor: ' #ffe0df', color: '#5f5f5f', padding: '6px 10px', borderRadius: 12 }}>Rejected</div>
}


function New() {
    const CURR_DEVICE = Checkdevice()
    const [name, setName] = useState(EMPTY)
    const [price, setPrice] = useState(EMPTY)
    const [currency, setCurrency] = useState('SGD')
    // const [instalment, setInstalment] = useState(EMPTY)
    // const [quotestate, setQuotestate] = useState(EMPTY)
    // const [vti_id, setVti_id] = useState(EMPTY)
    const [u_username, setU_username] = useState(EMPTY)
    const [file, setFile] = useState([])
    // const [u_email, setU_email] = useState(EMPTY)
    // const [info, setInfo] = useState(EMPTY)
    // const [paymentmethod, setPaymentmethod] = useState(EMPTY)
    // const [viewer, setViewer] = useState(EMPTY)
    const [error, setError] = useState([])


    // block btn
    const [creategate, setCreategate] = useState(false)

    useEffect(() => {
        if (creategate === true) {
            onCreate()
        }
    }, [creategate])

    function onCreate() {
        var error = []
        if (name.length < 1 || name.length > 100) error.push(<div key={Math.random()}>Error: Service Name field</div>)
        if (u_username.length < 1 || u_username.length > 100) error.push(<div key={Math.random()}>Error: Recipient's Username field</div>)
        if (currency.length < 1) error.push(<div key={Math.random()}>Error: Currency field</div>)
        if (!(new RegExp('^[0-9]+$').test(price))) error.push(<div key={Math.random()}>Error: Price field</div>)
        if (file.length < 1 || file.length > 3) error.push(<div key={Math.random()}>Error: Document field</div>)

        if (error.length !== 0) setError(error)
        else {

            setError([])
            var data = new FormData()
            const maxFilesize = file.length > 3 ? 3 : file.length
            for (let i = 0; i < maxFilesize; i++) data.append('file', file[i])
            data.append('name', name)
            data.append('u_username', u_username)
            data.append('currency', currency)
            data.append('price', price)

            try {
                let options = {
                    method: 'POST',
                    url: CREATE_V_QUOTE,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' }, //need token from the web
                    data: data
                }
                axios(options).then((res) => {
                    const { data } = res
                    const { message } = data
                    if (message === 'SUCCESS') {
                        refresh()
                    }
                    else if (message === 'FAILURE') {
                        error.push(<div key={Math.random()}>Fail to create. User dosen't exist.</div>)
                        setError(error)
                    }
                    setCreategate(false)
                }).catch((e) => {

                })
            }
            catch (e) {
            }
        }
    }

    function createBtn() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <button style={{ ...BUTTONSTYLE }} onClick={() => { setCreategate(true) }} disabled={creategate}>Create</button>
            </div>
        )
    }

    function serviceName() {
        return (
            <div >
                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Service Name</ControlLabel>
                <FormGroup controlId="formInlineName" >
                    <FormControl
                        type="name"
                        placeholder="Enter Service Name"
                        value={name}
                        onChange={(event) => { setName(event.target.value) }}
                    />
                </FormGroup>
            </div>
        )
    }

    function recipientField() {
        return (
            // <div style={{ border: 'solid thin #D9D8D8', borderRadius: 5, padding: 10, marginBottom: 10 }}>
            <div >

                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Recipient's Username</ControlLabel>
                <FormGroup controlId="formInlineName" >
                    <FormControl
                        type="recipientusername"
                        placeholder="Enter Recipient's Username"
                        value={u_username}
                        onChange={(event) => { setU_username(event.target.value) }}
                    />
                </FormGroup>
            </div>
        )
    }

    function fileField() {
        // let vendor drop file to aws
        const config = {
            iconFiletypes: ['.pdf'],
            showFiletypeIcon: false,
            postUrl: 'no-url'
        };
        const djsConfig = {
            addRemoveLinks: true,
            acceptedFiles: ".pdf",
            autoProcessQueue: false,
            maxFiles: 3,
        };
        const eventHandlers = {
            addedfile: (new_file) => {
                var local_file = file
                local_file.push(new_file)
                setFile(local_file)
            },
            removedfile: (removed_file) => {
                var local_file = file
                local_file.splice(local_file.indexOf(removed_file), 1)
                setFile(local_file)
            }
        }
        return (
            <div>
                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row' }}>Document <div style={{ paddingLeft: 5, color: 'rgb(199, 199, 199)' }}> (  3 max {'&'} .pdf only )</div></ControlLabel>
                <DropzoneComponent
                    config={config}
                    djsConfig={djsConfig}
                    eventHandlers={eventHandlers}
                />
            </div>
        )


    }

    function errorField() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                {error}
            </div>
        )
    }

    function priceField() {
        // let vendor assign the price for the package vendor offer
        return (
            <div>
                <ControlLabel style={{ fontWeight: 500, display: 'flex', flexDirection: 'row', }}>Price</ControlLabel>
                <FormGroup >
                    <InputGroup>
                        <DropdownButton
                            style={{ fontWeight: 'bold', color: 'rgb(112, 112, 112)' }}
                            componentClass={InputGroup.Button}
                            id="input-dropdown-addon"
                            title={currency}
                            value={currency}
                        >
                            {CURRENCY_ARR.map((key, index) => {
                                return <MenuItem key={key + index} onSelect={() => { setCurrency(key) }}>{key}</MenuItem>
                            })}
                        </DropdownButton>
                        <FormControl type="text" onChange={(event) => { setPrice(event.target.value) }} value={price} />
                    </InputGroup>
                </FormGroup>
            </div>
        )
    }


    const card_w = CURR_DEVICE === 'WEB' ? '70%' : '100%'

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: card_w, display: 'flex', flexDirection: 'column', paddingBottom: 20, justifyContent: 'center' }}>
                {serviceName()}
                {recipientField()}
                {priceField()}
                {EMPTYSPACE}
                {fileField()}
                {EMPTYSPACE}
                {errorField()}
                {createBtn()}
            </div>
        </div>
    )
}

function Status() {

    // const [acceptedquote_ids, setAcceptedquote_ids] = useState([])
    // const [acct_type, setAcct_type] = useState()
    // const [deletequote_ids, setDeletequote_ids] = useState([])
    // const [pendingquote_ids, setPendingquote_ids] = useState([])
    const [quote_ids, setQuote_ids] = useState([])
    const [loaded, setLoaded] = useState(false)
    const CURR_DEVICE = Checkdevice()

    const [deletedata, setDeletedata] = useState([false]) // [gate, quote_id, quotestate, u_username, fileinfo]
    const [archivedata, setArchivedata] = useState([false]) // [gate, quote_id, quotestate ]


    useEffect(() => { // componentDidMount
        if (token !== null && token !== EMPTY && token !== undefined) init()
    }, [])

    useEffect(() => {
        if (deletedata[0]) {
            var quote_id = deletedata[1]
            var quotestate = deletedata[2]
            var u_username = deletedata[3]
            var fileinfo = deletedata[4]
            if (quotestate === 0 || quotestate === 2) { //pending state, can be deleted
                let options = {
                    method: 'POST',
                    url: REMOVE_V_QUOTE,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { 'quote_id': quote_id, 'u_username': u_username, 'fileinfo': fileinfo }
                }
                axios(options).then((res) => {
                    const { message } = res.data
                    if (message === 'SUCCESS') {
                        init()
                        setDeletedata([false])
                    }
                    else refresh()
                }).catch((e) => {
                    refresh()
                })
            }
            else { // cannot be deleted
                refresh()

            }
        }
    }, [deletedata])

    useEffect(() => {
        if (archivedata[0]) {
            var _id = archivedata[1]
            var quotestate = archivedata[2]
            if (quotestate === 1) { // quote accepted, can archive
                let options = {
                    method: 'POST',
                    url: ARCHIVE_V_QUOTE,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { 'quote_id': _id }
                }
                axios(options).then((res) => {
                    const { message } = res.data
                    if (message === 'SUCCESS') {
                        init()
                        setArchivedata([false])
                    }
                    else refresh()
                }).catch((e) => {
                    refresh()
                })
            }
            else { // cannot be deleted
                refresh()
            }
        }
    }, [archivedata])

    function init() {
        let options = {
            method: 'POST',
            url: READ_V_QUOTE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
        }
        axios(options).then((res) => {
            const { data, message } = res.data

            if (message === 'SUCCESS') {
                // setAcceptedquote_ids(data.acceptedquote_ids)
                // setAcct_type(data.acct_type)
                // setDeletequote_ids(data.deletequote_ids)
                // setPendingquote_ids(data.pendingquote_ids)
                setQuote_ids(data.quote_ids)
                setLoaded(true)
            }


        }).catch((e) => {

        })
    }

    function allQuotes() {
        if (!loaded) return <div />
        return (
            quote_ids.map((item) => {
                var { createdAt, currency, fileinfo, name, price, u_username, quotestate, _id } = item
                const card_w = CURR_DEVICE === 'WEB' ? '70%' : '100%'
                var fileinfo_dom = fileinfo.map((link) => {
                    return (
                        <div onClick={() => { window.location = link }} key={Math.random()} style={{ width: 40, height: 40, borderRadius: 5, border: 'thin solid rgb(186, 186, 186)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 5 }}>
                            {PACKAGE_ICON}
                        </div>
                    )
                })

                var date_created = (new Date(createdAt)).toLocaleDateString()

                return (
                    <div key={Math.random()} style={{ display: 'flex', flexDirection: 'column', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 5px', width: card_w, height: 'max-content', marginBottom: 40, borderRadius: 10, }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'max-content', padding: '0px 5px 5px 5px', borderRadius: 10, }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                                <div style={{ fontWeight: 'normal', marginTop: 5 }}>{date_created}</div>
                                <div style={{ fontWeight: 'bold', marginTop: 5, fontSize: 'small', whiteSpace: 'nowrap' }}>{quotestate_map[quotestate]}</div>
                            </div>
                            <h3 style={{ textDecoration: 'underline', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', whiteSpace: 'nowrap', overflowX: 'auto', overflowY: 'hidden', padding: '0px 5px' }}>
                                {name}
                            </h3>
                            <div style={{ display: 'flex', flexDirection: 'row', }}>
                                <div style={{ padding: '0px 5px', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center' }}>{'Recepient : '}</div>
                                <h4 style={{ fontWeight: '500', padding: '0px 5px', whiteSpace: 'nowrap' }}>{u_username}</h4>
                            </div>
                            {EMPTYSPACE}
                            <div style={{ padding: '0px 5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', color: '#4667b1', width: '100%', borderTop: '1px solid rgb(238, 238, 238)', borderBottom: '1px solid rgb(238, 238, 238)' }}>
                                <h3 >{currency + ' $ ' + price}</h3>
                            </div>
                            {EMPTYSPACE}
                            <div style={{ display: 'flex', flexDirection: 'row', padding: '0px 5px', }}>
                                {fileinfo_dom}
                            </div>
                            {EMPTYSPACE}
                        </div>
                        <div>
                            {
                                quotestate === 0 || quotestate === 2 ?
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', borderTop: '1px solid rgb(238, 238, 238)', alignItems: 'center' }}>
                                        <button
                                            style={{ ...BUTTONSTYLE, width: '100%', border: 'none', borderRadius: '0px 0px 10px 10px', }}
                                            onClick={() => { setDeletedata([true, _id, quotestate, u_username, fileinfo]) }}
                                            disabled={deletedata[0]}
                                            onMouseEnter={(event) => { event.target.style.backgroundColor = 'rgb(170,184,194, 0.1)'; }}
                                            onMouseLeave={(event) => { event.target.style.backgroundColor = 'transparent' }}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    :
                                    quotestate === 1 ? // quote accepted will allow archive
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', borderTop: '1px solid rgb(238, 238, 238)', alignItems: 'center' }}>
                                            <button
                                                style={{ ...BUTTONSTYLE, width: '100%', border: 'none', borderRadius: '0px 0px 10px 10px', }}
                                                onClick={() => { setArchivedata([true, _id, quotestate]) }}
                                                onMouseEnter={(event) => { event.target.style.backgroundColor = 'rgb(170,184,194, 0.1)'; }}
                                                onMouseLeave={(event) => { event.target.style.backgroundColor = 'transparent' }}
                                            >
                                                Archive
                                            </button>
                                        </div>
                                        :
                                        <div />
                            }
                        </div>
                    </div>
                )
            })
        )
    }

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingBottom: 20, alignItems: 'center', justifyContent: 'center' }}>
            {allQuotes()}
        </div>
    )

}

function Archive() {

    const [quotearchive_ids_dom, set_quotearchive_ids_dom] = useState([])
    const CURR_DEVICE = Checkdevice()

    useEffect(() => { // componentDidMount
        if (token !== null && token !== EMPTY && token !== undefined) init()
    }, [])

    function init() {
        let options = {
            method: 'POST',
            url: READARCHIVE_V_QUOTE,
            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
        }

        axios(options).then((res) => {
            const { data, message } = res.data
            if (message === 'SUCCESS') {
                // const card_w = CURR_DEVICE === 'WEB' ? '70%' : '100%'
                const { quotearchive_ids } = data
                var dom = []
                dom.push(
                    <div key={Math.random()} style={{ width: 'max-content', display: 'flex', flexDirection: 'row', fontSize: '14px', fontWeight: 'bold', textTransform: 'uppercase' }}>
                        <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            username
                        </div>
                        <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            issue date
                        </div>
                        <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            amount
                        </div>
                        <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            files
                        </div>
                    </div>
                )
                for (let i = 0; i < quotearchive_ids.length; i++) {
                    var item = quotearchive_ids[i]
                    const { currency, fileinfo, price, u_username, createdAt } = item
                    var date_created = (new Date(createdAt)).toLocaleDateString()
                    var fileinfo_dom = fileinfo.map((link) => {
                        return (
                            <div onClick={() => { window.location = link }} key={Math.random()} style={{ width: 40, height: 40, borderRadius: 5, border: 'thin solid rgb(186, 186, 186)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 5, }}>
                                {PACKAGE_ICON}
                            </div>
                        )
                    })
                    // borderBottom: 'thin solid rgb(112, 112, 112)'
                    dom.push(
                        <div key={Math.random()} style={{ width: 'max-content', height: 60, display: 'flex', flexDirection: 'row', marginTop: 5, marginBottom: 5, paddingTop: 5, paddingBottom: 5, textAlign: 'center', borderTop: '1px solid rgb(238, 238, 238)' }}>
                            <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                {u_username}
                            </div>
                            <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                {date_created}
                            </div>
                            <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                {currency + ' ' + price}
                            </div>
                            <div style={{ width: '155px', maxWidth: '155px', minWidth: '155px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', whiteSpace: 'nowrap', }}>
                                {fileinfo_dom}
                            </div>
                        </div>
                    )
                }

                set_quotearchive_ids_dom(dom)
            }

        }).catch((e) => {

        })


    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: CURR_DEVICE === 'WEB' || CURR_DEVICE !== 'MOBILE' ? 'center' : 'flex-start', }}>
            {quotearchive_ids_dom}
        </div>
    )
}

function VendorQuote(props) {

    const [view, setView] = useState('Status')

    function handleClick(val) {
        if (val === 'Status') {
            setView(val)
        }
        else if (val === 'New') {
            setView(val)
        }
        else if (val === 'Archive') {
            setView(val)
        }
    }

    function page() {
        if (view === 'Status') {
            return <Status />
        }
        else if (view === 'New') {
            return <New />
        }
        else if (view === 'Archive') {
            return <Archive />
        }
        else {
            return <div />
        }

    }

    return (
        <div style={{ flexDirection: 'column', color: 'rgb(112, 112, 112)' }}>
            <Headtitle name={'Quote'} />
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10, alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', margin: 10, padding: 10, border: 'thin solid rgb(238, 238, 238)', alignItems: 'center', borderRadius: 5 }}>
                    <button onClick={() => { handleClick('Status') }} style={{ padding: 10, letterSpacing: '0.1em', backgroundColor: view === 'Status' ? 'rgb(239, 239, 239)' : 'transparent', borderRadius: 10, fontWeight: 'bold', outline: 'none', border: 'none' }}>
                        Status
                        </button>
                    {EMPTYSPACE}
                    <button onClick={() => { handleClick('Archive') }} style={{ padding: 10, letterSpacing: '0.1em', backgroundColor: view === 'Archive' ? 'rgb(239, 239, 239)' : 'transparent', borderRadius: 10, fontWeight: 'bold', outline: 'none', border: 'none' }}>
                        Archive
                    </button>
                    {EMPTYSPACE}
                    <button onClick={() => { handleClick('New') }} style={{ padding: 10, letterSpacing: '0.1em', backgroundColor: view === 'New' ? 'rgb(239, 239, 239)' : 'transparent', borderRadius: 10, fontWeight: 'bold', outline: 'none', border: 'none' }}>
                        New
                    </button>
                </div>
            </div>
            {EMPTYSPACE}
            <div className='container' style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                {EMPTYSPACE}
                {page()}
                {EMPTYSPACE}
            </div>
            {EMPTYSPACE}
        </div>
    )
}

export default VendorQuote