import React, { useEffect, useState } from 'react'
import { Router, Route, Link, Switch } from "react-router-dom";
import { VendorProfile, VendorLogin, VendorApply, VendorSignup, Homevendor, Resetpw, Pricingmodel, Faq, VendorQuote, Notfound, Message, Tovendor, VendorLead, VendorTrend, VendorLevel, Jmap } from './pages'
import { NAV_BAR_HEIGHT, NAVBAR_ICON, EMPTY, production, STARTUP_NAME, quotegate } from './config/constants' // PAYPALDONATE SS_LINE
import { CEREWEAVE_WHITE } from './assets/Cereweave';
import { Loading, Jfmodal, refresh, Checkdevice } from './components';
import { VENDOR_VALIDATE } from './config/api';
import axios from 'axios'

// START OF GA
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history'
import { Retrieve_personal_info } from './config/commonfunctions';

// CSS
import './App.css'
import { Helmet } from 'react-helmet';

const LOADING = 0
const LOGGED = 1
const LOGOUT = 2

const WEBLINK_CSS = { display: 'flex', alignItems: 'center', outline: 0, height: 35, padding: '0px 10px', color: 'white', }

const TOPLEFTNAV_DIV = <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }}  >
    <div style={{ minHeight: 33, minWidth: 33, padding: '1px 0px', position: 'relative', margin: 0, display: 'flex', placeContent: 'center', alignItems: 'center', padding: '0px 0px 0px 3px' }}>
        <Link style={{ width: 27, height: 27, position: 'absolute' }} to="/" />
        <div style={{ width: 27, height: 27, color: '#ffffff' }}>{CEREWEAVE_WHITE}</div>
    </div>
    <Link className="navbar_cereweavetxtroot" to="/" >
        <div className="headertitlelogorealtextmobile">{STARTUP_NAME}</div>
    </Link>
</div>

const history = createBrowserHistory()
production ? ReactGA.initialize('UA-150353866-1') : ReactGA.initialize('1');
history.listen((location, action) => { ReactGA.pageview(location.pathname + location.search) });

const ValidateAcct = () => {
    try {
        var rawToken = localStorage.getItem('token')
        const currToken = rawToken.substring(4, rawToken.length)        // remove 'JWT '
        var base64Url = currToken.split('.')[1];                        // parse jwt preprocess
        var base64 = base64Url.replace('-', '+').replace('_', '/');     // parse jwt preprocess
        const jsonjwt = JSON.parse(window.atob(base64))
        const { id, acct_type } = jsonjwt
        if (acct_type === 'VENDOR') {
            return axios({
                method: 'POST',
                url: VENDOR_VALIDATE,
                headers: { 'Authorization': 'Bearer ' + currToken },
                data: { id }
            }).then((response) => {
                const res = response.data
                if (!res.error) {
                    const { displayname, username } = res.vendor
                    return { acct_type, username, displayname }
                }
                else {
                    return null
                }
            }).catch((e) => {
                return null
            })
        }
        else {
            return null
        }

    }
    catch (e) { return [false] }
}

function App(props) {

    const [auth, setAuth] = useState(LOADING) // 0 - LOADING, 1 - LOGGED, 2 - LOGOUT
    const [token, setToken] = useState('')
    const [displayname, setDisplayname] = useState('')
    const [username, setUsername] = useState('')
    const [menugate, setMenugate] = useState(false) // menu modal gate for mobile
    const [hidetop, setHidetop] = useState(null)
    const device = Checkdevice()
    useEffect(() => {
        window.addEventListener("storage", (s) => { if (s.key === 'token') { refresh() } }, false)
        setToken(localStorage.getItem('token'))
        setTimeout(() => {
            try {
                var local_token

                if (token) {
                    local_token = token
                    localStorage.setItem('token', token)
                }
                else {
                    local_token = localStorage.getItem('token')
                    setToken(local_token)
                }

                const { acct_type, username, displayname } = ValidateAcct(local_token)
                if (acct_type === 'VENDOR') { // this is for user
                    localStorage.setItem("token", local_token)
                    setAuth(LOGGED)
                    setUsername(username)
                    setDisplayname(displayname)
                }
                else {
                    const { _id, token, acct_type } = Retrieve_personal_info()
                    try {
                        return axios({
                            method: 'POST',
                            url: VENDOR_VALIDATE,
                            headers: { 'Authorization': 'Bearer ' + token },
                            data: { id: _id }
                        }).then((response) => {
                            const res = response.data
                            if (!res.error && acct_type === 'VENDOR') {
                                const { displayname, username } = res.vendor
                                localStorage.setItem("token", local_token)
                                setAuth(LOGGED)
                                setDisplayname(displayname)
                                setUsername(username)
                            }
                            else { logout() }
                        }).catch((e) => { logout() })
                    }
                    catch (e) { logout() }
                }
            }
            catch (e) { logout() }
        }, 500)
    }, [])

    const logout = () => {
        window.removeEventListener("storage", () => { refresh() }, false)
        setMenugate(false)
        setAuth(LOGOUT)
        setToken('')
        localStorage.token = EMPTY
    }

    const hidetopbar = (g) => setHidetop(g)

    const offdialog = (e) => {
        e.stopPropagation();
        setMenugate((prevState) => { return !prevState })
    }

    const NavbarHeader = () => {

        const loggedmenu_mobile = quotegate
        ?{
            'FAQ': { link: '/faq' },
            'Home': { link: '/' },
            'Quote': { link: '/quote' },
            'Lead': { link: '/bizlead' },
            'Message': { link: '/chat' },
            'Profile': { link: '/business/vendorprofile/general' },
            'Logout': { link: '/', func: logout },
        }
        :{
            'FAQ': { link: '/faq' },
            'Home': { link: '/' },
            'Quote': { link: '/quote' },
            'Lead': { link: '/bizlead' },
            'Message': { link: '/chat' },
            'Profile': { link: '/business/vendorprofile/general' },
            'Logout': { link: '/', func: logout },
        }

        const loggedmenu = quotegate
            ? {
                'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
                'Home': { link: '/' },
                'Quote': { link: '/quote' },
                'Lead': { link: '/bizlead' },
                'Message': { link: '/chat' },
                'Profile': { link: '/business/vendorprofile/general' },
                'Logout': { link: '/', func: logout },
            }
            : {
                'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
                'Home': { link: '/' },
                'Lead': { link: '/bizlead' },
                'Message': { link: '/chat' },
                'Profile': { link: '/business/vendorprofile/general' },
                'Logout': { link: '/', func: logout },
            }


        const unloggedmenu = {
            'FAQ': { link: '/faq', style: { borderRight: '1px solid white' } },
            'Apply': { link: '/business/apply' },
            'Login': { link: '/' },
        }
        // 'About' : { link: '/about' }, 


        if (device === 'WEB') { // web
            if (auth === LOGGED) {
                // return <div style={{ height: NAV_BAR_HEIGHT, maxHeight: NAV_BAR_HEIGHT, minHeight: NAV_BAR_HEIGHT, display: 'flex', zIndex: 1 }}>
                //     <div style={{ width: '100%', height: NAV_BAR_HEIGHT, maxHeight: NAV_BAR_HEIGHT, minHeight: NAV_BAR_HEIGHT, backgroundColor: '#303030', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>{/* not fixed */}
                //         {TOPLEFTNAV_DIV}
                //         <div style={{ display: 'flex', flexDirection: 'row' }}>
                //             {/* {Object.keys(loggedmenu).map((key, index) => {
                //                 const { link, func, style } = loggedmenu[key]
                //                 return <Link key={key + index} to={link} style={{ ...WEBLINK_CSS, ...style }} onClick={func}>{key}</Link>
                //             })} */}
                //             {Object.keys(loggedmenu).map((key, index) => {
                //                 const { link, func, style } = loggedmenu[key]
                //                 return <button className='navtopbaritem' key={key + index} style={{ ...style }} onClick={() => document.getElementById(key).click()}>
                //                     <Link id={`${key}`} to={link} style={{ visibility: 'hidden' }} onClick={func} />
                //                     {key}
                //                 </button>
                //             })}
                //         </div>
                //     </div>
                // </div>

                return <div className='app_navroot'>
                    {TOPLEFTNAV_DIV}
                    <div className='app_navright'>
                        {Object.keys(loggedmenu).map((key, index) => {
                            const { link, func, style } = loggedmenu[key]
                            return <button className='navtopbaritem' key={key + index} style={{ ...style }} onClick={() => document.getElementById(key).click()}>
                                <Link id={`${key}`} to={link} style={{ visibility: 'hidden' }} onClick={func} />
                                {key}
                            </button>
                        })}
                    </div>
                </div>


            }
            else {
                // return <div style={{ height: NAV_BAR_HEIGHT, maxHeight: NAV_BAR_HEIGHT, minHeight: NAV_BAR_HEIGHT, display: 'flex', zIndex: 1 }}>
                //     <div style={{ width: '100%', height: NAV_BAR_HEIGHT, maxHeight: NAV_BAR_HEIGHT, minHeight: NAV_BAR_HEIGHT, backgroundColor: '#303030', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                //         {TOPLEFTNAV_DIV}
                //         <div style={{ display: 'flex', flexDirection: 'row' }}>

                //             {Object.keys(unloggedmenu).map((key, index) => {
                //                 const { link, func, style } = unloggedmenu[key]
                //                 return <Link key={key + index} to={link} style={{ ...WEBLINK_CSS, ...style }} onClick={func}>
                //                     {key}
                //                 </Link>
                //             })}

                //             {/* {Object.keys(unloggedmenu).map((key, index) => {
                //                 const { link, func, style } = unloggedmenu[key]
                //                 return <button className='navtopbaritem' key={key + index} style={{ ...style }} onClick={func}>
                //                     <Link id={`${key}`} to={link} style={{ visibility: 'hidden' }} onClick={func} />
                //                     {key}
                //                 </button>
                //             })} */}
                //         </div>
                //     </div>
                // </div>

                return <div className='app_navroot'>
                    {TOPLEFTNAV_DIV}
                    <div className='app_navright'>
                        {Object.keys(unloggedmenu).map((key, index) => {
                            const { link, func, style } = unloggedmenu[key]
                            return <button className='navtopbaritem' key={key + index} style={{ ...style }} onClick={() => document.getElementById(key).click()}>
                                <Link id={`${key}`} to={link} style={{ visibility: 'hidden' }} onClick={func} />
                                {key}
                            </button>
                        })}
                    </div>
                </div>

            }

        }
        else { // mobile
            // const login_m_about_unauth = <Link key={'login_m_about_unauth'} to="/about" onClick={offdialog} style={{ width: '100%', letterSpacing: 1, color: GENERAL_GREY, display: 'flex', justifyContent: 'flex-start', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', lineHeight: '1.16667em', padding: '15px 0px 15px 10px' }}><div style={{ justifyItems: 'flex-start' }}>About</div></Link>
            const SidelistIcon = <button className="App_navbaricon" onClick={() => setMenugate(true)} >{NAVBAR_ICON}</button>
            const TitleMenu = <div style={{ display: 'flex', padding: '5px 0px 0px 0px', width: '100%', justifyContent: 'center', letterSpacing: 1, }}><div style={{ alignItems: 'center' }}>Menu</div></div>

            if (auth === LOGGED) {
                return <div style={{ height: NAV_BAR_HEIGHT, maxHeight: NAV_BAR_HEIGHT, minHeight: NAV_BAR_HEIGHT, display: 'flex', zIndex: 1 }}>
                    <div style={{ zIndex: 10, width: '100%', height: NAV_BAR_HEIGHT, maxHeight: NAV_BAR_HEIGHT, minHeight: NAV_BAR_HEIGHT, backgroundColor: '#303030', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        {TOPLEFTNAV_DIV}
                        {SidelistIcon}
                        <Jfmodal
                            show={menugate}
                            onHide={() => { setMenugate(false) }}
                            title={TitleMenu}
                            device={device === 'MOBILE' ? 'MOBILE' : 'WEB'}
                            content={<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100vh - 100px)' }}>
                                {Object.keys(loggedmenu_mobile).map((key, index) => {
                                    const { link } = loggedmenu_mobile[key]
                                    // return [<Link className="AppNavItemM" key={key + index} to={link} onClick={offdialog}>{key}</Link>, SS_LINE]
                                    return [<Link className="AppNavItemM" key={key + index} to={link} onClick={key === 'Logout' ? loggedmenu_mobile[key].func : offdialog}>{key}</Link>]
                                })}
                            </div>}
                        />
                    </div>
                </div>
            }
            else {
                return <div style={{ height: NAV_BAR_HEIGHT, maxHeight: NAV_BAR_HEIGHT, minHeight: NAV_BAR_HEIGHT, display: 'flex', zIndex: 1 }}>
                    <div style={{ position: 'fixed', zIndex: 10, width: '100%', height: 35, backgroundColor: '#303030', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        {TOPLEFTNAV_DIV}
                        {SidelistIcon}
                        <Jfmodal
                            show={menugate}
                            onHide={() => { setMenugate(false) }}
                            title={TitleMenu}
                            device={device === 'MOBILE' ? 'MOBILE' : 'WEB'}
                            content={<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100vh - 100px)' }}>
                                {Object.keys(unloggedmenu).map((key, index) => {
                                    const { link } = unloggedmenu[key]
                                    // return [<Link className="AppNavItemM" key={key + index} to={link} onClick={offdialog}>{key}</Link>, SS_LINE]
                                    return [<Link className="AppNavItemM" key={key + index} to={link} onClick={offdialog}>{key}</Link>]
                                })}
                            </div>}
                        />
                    </div>
                </div>
            }
        }
    }

    // START OF ROUTING DOM --------------------------------------------------

    const Quote_dom = (props) => {
        return auth === LOGGED ? <VendorQuote  {...props} /> : null
    }

    const Chat_dom = (props) => {
        return auth === LOGGED ? <Message  {...props} /> : null
    }

    const Tovendor_dom = (props) => {
        return <Tovendor {...props} hidetopbar={hidetopbar} />
    }

    const Lead_dom = (props) => {
        return auth === LOGGED ? <VendorLead {...props} /> : null
    }

    const Trend_dom = (props) => {
        return auth === LOGGED ? <VendorTrend {...props} un={username} dn={displayname} /> : null
    }

    const Level_dom = (props) => {
        return auth === LOGGED ? <VendorLevel {...props} un={username} dn={displayname} /> : null
    }

    const Vendorprofile_dom = (props) => {
        return auth === LOGGED ? <VendorProfile {...props} /> : null
    }

    const Resetvpw_dom = (props) => {
        return auth === LOGOUT ? <Resetpw {...props} ut={'v'} /> : null
    }

    const Home_dom = (props) => {
        if (auth === LOGGED) {
            return <Homevendor {...props} />
        }
        else if (auth === LOGOUT) {
            return <VendorLogin {...props} />
        }
        else return null
    }

    const Bizapply_dom = () => {
        return auth === LOGOUT ? <VendorApply /> : null
    }

    const Bizsignup_dom = (props) => {
        return auth === LOGOUT ? <VendorSignup {...props} /> : null
    }

    const Bizpricing_dom = (props) => {
        return <Pricingmodel {...props} />
    }

    const Faq_dom = (props) => {
        return <Faq {...props} accttype={'Vendor'} />
    }

    const Map_dom = (props) => {
        return <Jmap {...props} hidetopbar={this.hidetopbar} />
    }

    const reacthelmet = <Helmet><title>{`${STARTUP_NAME} - Wedding & Event Business | Grow Your Business With WeaveBliss`}</title><meta name="description" content="User Login" /></Helmet>

    return <Router history={history} >
        <div>
            {reacthelmet}
            {(auth === LOADING || hidetop) ? null : NavbarHeader()}
            {auth === LOADING
                ? <Loading />
                : <Switch>

                    {/* VENDOR */}
                    <Route exact path="/" render={Home_dom} />
                    {quotegate ? <Route exact path="/quote" render={Quote_dom} /> : null}
                    <Route exact path="/chat" render={Chat_dom} />

                    {/* VENDOR only*/}
                    <Route exact path="/business/vendorprofile" render={Vendorprofile_dom} />
                    <Route exact path="/business/vendorprofile/:page" render={Vendorprofile_dom} />
                    <Route exact path="/business/trend" render={Trend_dom} />
                    <Route exact path="/business/level" render={Level_dom} />
                    <Route exact path="/bizlead" render={Lead_dom} />
                    <Route path="/resetpassword/v/:resettoken" exact component={Resetvpw_dom} />

                    {/* UNAUTH only */}
                    <Route exact path="/business/apply" render={Bizapply_dom} />
                    <Route exact path="/business/signup" render={Bizsignup_dom} />
                    <Route exact path="/business/pricing" render={Bizpricing_dom} />

                    {/* ALL */}
                    <Route path="/faq" exact>{Faq_dom}</Route>
                    <Route path="/map" render={Map_dom} />

                    {/* Word to Vendor */}
                    <Route path="/vendor/tovendor/:name" exact component={Tovendor_dom} />

                    {/* Not found */}
                    <Route path="*" component={Notfound} />
                    {auth === LOGOUT ? <Route path="*" component={Notfound} /> : null}

                </Switch>}
        </div>
    </Router>
}

export default App
